import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  infoCard: {
    display: 'flex !important',
    marginTop: theme.spacing(4),
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: '#ffcd66',
    width: '60%',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
  title: {
    marginBottom: '1rem',
    fontSize: '3rem',
    fontFamily: 'Roboto Slab',
    textAlign: 'center',
  },
  subTitle: {
    marginBottom: '5rem',
    fontSize: '1.5rem',
    fontFamily: 'Roboto Slab',
    textAlign: 'center',
  },
}));
