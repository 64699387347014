import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import moment from 'moment';
import React from 'react';
import { getAllValidWeekendsOfTheMonth } from 'utils/validator';

import useStyles from './AlternateWeekendsPossession.css.jsx';

function AlternateWeekendsPossession(props) {
  const classes = useStyles();
  const alternateWeekendsPossession = props.alternateWeekendsPossession;
  const monthIndex = props.monthIndex;
  const monthRealIndex = props.monthRealIndex;
  const schoolBreaks = props.schoolBreaks;
  const firstPopulatedDate = props.firstPopulatedDate;
  const allWeekendsOfTheMonth = getAllValidWeekendsOfTheMonth(
    monthIndex - 1,
    schoolBreaks,
    firstPopulatedDate,
    schoolBreaks.summer.end,
  );

  const disabled = props.disabled;
  let weekend = props.weekend.index ? props.weekend.index : '';
  function onChangeAlternateWeekendsPossession(event) {
    const index = event.target.value;

    if (props.onChangeAlternateWeekendsPossession) {
      props.onChangeAlternateWeekendsPossession(monthIndex, index, monthRealIndex);
    }
  }

  if (alternateWeekendsPossession.start && allWeekendsOfTheMonth.length > 0 && !weekend) {
    const start = moment(alternateWeekendsPossession.start);
    const weekendIndex = allWeekendsOfTheMonth.findIndex(
      ({ day1 }) => day1.format('YYYY-MM-DD') === start.format('YYYY-MM-DD'),
    );
    if (weekendIndex >= 0) {
      weekend = weekendIndex;
    }
  }

  if (!alternateWeekendsPossession) {
    return null;
  }
  return (
    <>
      <FormControl fullWidth variant="outlined" className={classes.formControl} disabled={disabled}>
        <InputLabel htmlFor="alternateWeekend">
          {moment(firstPopulatedDate).add(monthIndex, 'months').format('MMMM')}
        </InputLabel>
        <Select value={weekend} onChange={onChangeAlternateWeekendsPossession} labelWidth={160}>
          {allWeekendsOfTheMonth.map((range, index) => (
            <MenuItem key={index} value={index}>
              {`${range.day1.format('MMM DD, YYYY')} - ${range.day3.format('MMM DD, YYYY')}`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}

export default AlternateWeekendsPossession;
