import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { saveUserSettings } from 'reducers/user.js';
import Button from 'shared/Button/Button';
import Loader from 'shared/Loader/Loader.jsx';
import Typography from 'shared/Typography/Typography';
import firebase from 'utils/firebase';
import History from 'utils/history.js';

import useStyle from './Settings.css.jsx';

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&:before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    '&:after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
    backgroundColor: '#3A506E',
  },
}));

const Settings = () => {
  const classes = useStyle();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user.user);
  const saving = useSelector(state => state.user.saving);
  const loadingUser = useSelector(state => state.user.loading);

  const [distribEmails, setDistribEmails] = useState(true);

  useEffect(() => {
    firebase.analytics().logEvent('page_view_account_settings');
  }, []);

  useEffect(() => {
    if (
      (user.settings && user.settings.emailDistribution) ||
      (user.settings && user.settings.emailDistribution === false)
    ) {
      setDistribEmails(user.settings.emailDistribution);
    }
  }, [user]);

  const onChangeDistributionEmails = event => {
    setDistribEmails(event.target.checked);
  };

  function onSave() {
    const settings = {
      emailDistribution: distribEmails,
    };
    dispatch(saveUserSettings({ settings }));
    firebase.analytics().logEvent('page_action_account_settings_save');
  }

  function onCancel() {
    History.push('/');
    firebase.analytics().logEvent('page_action_account_settings_cancel');
  }

  if (loadingUser) {
    return <Loader />;
  }
  return (
    <div className={classes.settingContainer}>
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={12} md={8}>
          <Typography variant="h5">{t('accountPageSettingsTab')}</Typography>

          <List>
            <ListItem alignItems="flex-start">
              <FormControl fullWidth variant="outlined">
                <Typography align="left" variant="subtitle2">
                  {t('settingsPageDistributionTitle')}
                </Typography>

                <FormControlLabel
                  control={
                    <MaterialUISwitch
                      sx={{ m: 1 }}
                      checked={distribEmails}
                      onChange={onChangeDistributionEmails}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label={
                    distribEmails
                      ? t('settingDistributionEmailsSubscribe')
                      : t('settingDistributionEmailsUnsubscribe')
                  }
                />
              </FormControl>
            </ListItem>
            <Divider component="li" />
          </List>
        </GridItem>
        <GridItem xs={12} sm={12} md={8}>
          <div className={classes.actions}>
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={onCancel}
                disabled={saving}
                style={{ marginRight: '1rem' }}
              >
                {t('actionCancel')}
              </Button>
              <Button variant="contained" color="primary" onClick={onSave} disabled={saving}>
                {t('actionSave')}
              </Button>
            </div>
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default Settings;
