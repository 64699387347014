import MomentUtils from '@date-io/moment';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Account from 'Account/Routes.jsx';
import App from 'App/App.jsx';
import Modals from 'Modals/Modals.jsx';
import Profile from 'Profile/Routes.jsx';
import Snackbar from 'Snackbar/Snackbar.jsx';
import SnackbarProvider from 'SnackbarProvider.jsx';
import 'assets/scss/material-kit-pro-react.scss?v=1.7.0';
import 'i18n';
import 'index.css';
import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import reducer from 'reducers/combined';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import AuthRoute from 'shared/AuthRoute/AuthRoute.jsx';
import firebase from 'utils/firebase';
import history from 'utils/history.js';

const extra = thunk.withExtraArgument({ firebase });
const store = createStore(reducer, composeWithDevTools(applyMiddleware(extra)));

const theme = createTheme({
  typography: {
    // htmlFontSize: 10
  },
  palette: {
    // primary: { main: "rgb(26,115,232)" },
    primary: { main: '#3a516e' },
    secondary: { main: '#1f9ca3' },
  },
  custom: {
    color: {
      link: '#3C4858',
      drawerBackground: '#202D3D',
      mainBackground: 'rgba(246, 247, 249, 1)',
      color1: 'rgba(58, 81, 110, 1)',
      color2: 'rgba(255, 122, 120, 1)',
      color3: 'rgba(255, 205, 102, 1)',
      color4: 'rgba(31, 156, 163, 1)',
      color5: 'rgba(145, 242, 216, 1)',
    },
  },
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <SnackbarProvider>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Provider store={store}>
          <Router history={history}>
            <App>
              <Snackbar>
                <Switch>
                  <Route path="/account" component={Account} />
                  <AuthRoute path="/profile" component={Profile} />
                  <Redirect to="/account" />
                </Switch>
                <Modals />
              </Snackbar>
            </App>
          </Router>
        </Provider>
      </MuiPickersUtilsProvider>
    </SnackbarProvider>
  </ThemeProvider>,
  document.getElementById('root'),
);
