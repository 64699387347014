import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Step from '@material-ui/core/Step';
import StepContent from '@material-ui/core/StepContent';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import Switch from '@material-ui/core/Switch';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { closeModal } from 'reducers/modals';
import { getSummerPossessionDryRun, updateSummerPossession } from 'reducers/summerPossession';
import AlertBox from 'shared/AlertBox/AlertBox';
import Button from 'shared/Button/Button';
import Icon from 'shared/Icons/Icons';
import Loader from 'shared/Loader/Loader';
import SummaryList from 'shared/SummaryList/SummaryList';
import Typography from 'shared/Typography/Typography';
import WarningBox from 'shared/WarningBox/WarningBox';
import { getCurrentSchoolYear, isDateClashingWithNCPWeekends } from 'utils/calendar';
import { getCurrentCalendar } from 'utils/calendar';
import * as datesUtil from 'utils/dates';
import { defaultSecondaryRange1Dates } from 'utils/dates';
import firebase from 'utils/firebase';
import {
  validPrimarySummerSessionRange1,
  validPrimarySummerSessionRange2,
  validSecondarySummerSessionDates,
} from 'utils/validator';

import ModalContent from '../ModalContent/ModalContent';
import useStyles from './SummerPossessions.css.jsx';

function SummerPossessions() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [shouldFetchDryRunDates, setShouldFetchDryRunDates] = useState(true);
  const [shouldUseDefaultDates, setShouldUseDefaultDates] = useState(false);
  const [secondaryFirstStart, setSecondaryFirstStart] = useState(null);
  const [secondaryFirstEnd, setSecondaryFirstEnd] = useState(null);
  const [secondarySecondStart, setSecondarySecondStart] = useState(null);
  const [secondarySecondEnd, setSecondarySecondEnd] = useState(null);
  const [primaryFirstStart, setPrimaryFirstStart] = useState(null);
  const [primaryFirstEnd, setPrimaryFirstEnd] = useState(null);
  const [primarySecondStart, setPrimarySecondStart] = useState(null);
  const [primarySecondEnd, setPrimarySecondEnd] = useState(null);
  const [primaryRange1, setPrimaryRange1] = useState('');
  const [primaryRange2, setPrimaryRange2] = useState('');

  const [secondarySecondResetDisabled, setSecondarySecondResetDisabled] = useState(true);
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const calendar = useSelector(state => state.calendar);
  const currentCalendar = getCurrentCalendar(calendar.list.calendars, calendar.currentCalendarId);
  const [
    isSecondaryFirstRangeClashingWithNCPWeekends,
    setIsSecondaryFirstRangeClashingWithNCPWeekends,
  ] = useState(false);
  const [
    isSecondarySecondRangeClashingWithNCPWeekends,
    setIsSecondarySecondRangeClashingWithNCPWeekends,
  ] = useState(false);
  const [
    isPrimaryFirstRangeClashingWithNCPWeekends,
    setIsPrimaryFirstRangeClashingWithNCPWeekends,
  ] = useState(false);
  const [
    isPrimarySecondRangeClashingWithNCPWeekends,
    setIsPrimarySecondRangeClashingWithNCPWeekends,
  ] = useState(false);
  const [isPrimaryWeekendsClashingWithNCPWeekends, setIsPrimaryWeekendsClashingWithNCPWeekends] =
    useState(false);

  const { summerPossessions, schoolBreaks, withinDistance, allWeekends } = currentCalendar;
  const fathersDay = datesUtil.fathersDay(currentCalendar.schoolYear);
  const dates = useSelector(state => state.summerPossession.dryRun.dates);
  const dryRunLoading = useSelector(state => state.summerPossession.dryRun.loading);
  const loading = useSelector(state => state.summerPossession.update.loading);
  const [validSecondarySummerSessions, setValidSecondarySummerSessions] = useState(
    validSecondarySummerSessionDates({}),
  );

  const [validPrimaryRange1, setValidPrimaryRange1] = useState(validPrimarySummerSessionRange1({}));
  const [validPrimaryRange2, setValidPrimaryRange2] = useState(
    validPrimarySummerSessionRange2({
      schoolBreaks,
      secondaryFirstStart,
      secondaryFirstEnd,
      secondarySecondStart,
      secondarySecondEnd,
      dates,
    }),
  );
  const steps = t('modalSummerPossessionsSteps', { returnObjects: true });

  useEffect(() => {
    firebase.analytics().logEvent('modal_show_edit_summer_possession');
  }, []);

  useEffect(() => {
    setValidSecondarySummerSessions(
      validSecondarySummerSessionDates({
        schoolBreaks,
        secondaryFirstStart,
        secondaryFirstEnd,
        secondarySecondStart,
        secondarySecondEnd,
        withinDistance,
      }),
    );
  }, [
    schoolBreaks,
    secondaryFirstStart,
    secondaryFirstEnd,
    secondarySecondStart,
    secondarySecondEnd,
    withinDistance,
  ]);

  // useEffect(() => {
  //   setValidPrimarySummerSessions(
  //     validPrimarySummerSessionDates({
  //       schoolBreaks,
  //       primaryFirstStart,
  //       primaryFirstEnd,
  //       primarySecondStart,
  //       primarySecondEnd
  //     })
  //   );
  // }, [
  //   schoolBreaks,
  //   primaryFirstStart,
  //   primaryFirstEnd,
  //   primarySecondStart,
  //   primarySecondEnd
  // ]);

  useEffect(() => {
    setValidPrimaryRange1(
      validPrimarySummerSessionRange1({
        secondaryFirstStart,
        secondaryFirstEnd,
        secondarySecondStart,
        secondarySecondEnd,
      }),
    );
  }, [secondaryFirstStart, secondaryFirstEnd, secondarySecondStart, secondarySecondEnd]);

  useEffect(() => {
    setValidPrimaryRange2(
      validPrimarySummerSessionRange2({
        schoolBreaks,
        secondaryFirstStart,
        secondaryFirstEnd,
        secondarySecondStart,
        secondarySecondEnd,
        dates,
      }),
    );
  }, [
    schoolBreaks,
    secondaryFirstStart,
    secondaryFirstEnd,
    secondarySecondStart,
    secondarySecondEnd,
    dates,
  ]);

  useEffect(() => {
    if (!summerPossessions || !summerPossessions.secondary) {
      return;
    }

    // user has filled out default date for range 1
    if (
      !summerPossessions.secondary.possessions ||
      JSON.stringify(summerPossessions.secondary.possessions) === JSON.stringify({})
    ) {
      setShouldUseDefaultDates(true);
      const defaultDates = defaultSecondaryRange1Dates(schoolBreaks);
      setSecondaryFirstStart(defaultDates.start);
      setSecondaryFirstEnd(defaultDates.end);
      return;
    }

    const secondaryPossessions = summerPossessions.secondary.possessions;

    if (
      secondaryPossessions.range1 &&
      secondaryPossessions.range1.start &&
      secondaryPossessions.range1.end
    ) {
      setSecondaryFirstStart(moment(secondaryPossessions.range1.start));
      setSecondaryFirstEnd(moment(secondaryPossessions.range1.end));
    }

    if (
      secondaryPossessions.range2 &&
      secondaryPossessions.range2.start &&
      secondaryPossessions.range2.end
    ) {
      setSecondarySecondStart(moment(secondaryPossessions.range2.start));
      setSecondarySecondEnd(moment(secondaryPossessions.range2.end));
    }
    const primaryPossessions = summerPossessions.primary
      ? summerPossessions.primary.possessions
      : null;

    if (
      primaryPossessions &&
      primaryPossessions.range3 &&
      primaryPossessions.range3.start &&
      primaryPossessions.range3.end
    ) {
      setPrimaryFirstStart(moment(primaryPossessions.range3.start));
      setPrimaryFirstEnd(moment(primaryPossessions.range3.end));
    }

    if (
      primaryPossessions.range4 &&
      primaryPossessions.range4.start &&
      primaryPossessions.range4.end
    ) {
      setPrimarySecondStart(moment(primaryPossessions.range4.start));
      setPrimarySecondEnd(moment(primaryPossessions.range4.end));
    }
  }, [schoolBreaks, summerPossessions]);

  useEffect(() => {
    if (
      !summerPossessions ||
      !summerPossessions.primary ||
      !summerPossessions.primary.possessions ||
      JSON.stringify(summerPossessions.primary.possessions) === JSON.stringify({})
    ) {
      return;
    }

    const primaryPossessions = summerPossessions.primary.possessions;

    if (
      primaryPossessions.range1 &&
      primaryPossessions.range1.start &&
      primaryPossessions.range1.end
    ) {
      if (validPrimaryRange1.length) {
        const index = validPrimaryRange1.findIndex(currentRange => {
          const sameStart =
            currentRange.start.format('YYYY-MM-DD') === primaryPossessions.range1.start;
          const sameEnd = currentRange.end.format('YYYY-MM-DD') === primaryPossessions.range1.end;
          return sameStart && sameEnd;
        });

        if (index !== -1) {
          setPrimaryRange1(index);
        }
      }
    }
  }, [validPrimaryRange1, summerPossessions]);

  /*function handlePrimaryFirstCheckbox(event) {
    setCheckedPrimaryFirst(event.target.checked);
    setPrimaryFirstStart(null);
    setPrimaryFirstEnd(null);
  }*/
  function handlePrimaryFirstReset() {
    setPrimaryFirstStart(null);
    setPrimaryFirstEnd(null);
  }
  /*function handlePrimarySecondCheckbox(event) {
    setCheckedPrimarySecond(event.target.checked);
    setPrimarySecondStart(null);
    setPrimarySecondEnd(null);
  }  */
  function handlePrimarySecondReset() {
    setPrimarySecondStart(null);
    setPrimarySecondEnd(null);
  }
  /*function handleSecondaryFirstCheckbox(event) {
    setCheckedSecondaryFirst(event.target.checked);
    setSecondaryFirstStart(null);
    setSecondaryFirstEnd(null);
  }  */
  function handleSecondaryFirstReset() {
    setSecondaryFirstStart(null);
    setSecondaryFirstEnd(null);
    setShouldUseDefaultDates(false);
  }
  /*function handleSecondarySecondCheckbox(event) {
    setCheckedSecondarySecond(event.target.checked);
    setSecondarySecondStart(null);
    setSecondarySecondEnd(null);
  }  */
  function handleSecondarySecondReset() {
    setSecondarySecondStart(null);
    setSecondarySecondEnd(null);
    setShouldUseDefaultDates(false);
  }
  function onChangeDefaultDates() {
    const useDefault = !shouldUseDefaultDates;
    //console.log(withinDistance);
    setShouldUseDefaultDates(useDefault);
    if (useDefault) {
      const defaultDate = defaultSecondaryRange1Dates(schoolBreaks, withinDistance);
      if (withinDistance === 1 || withinDistance === true) {
        //console.log("withinDistance",withinDistance);
        setSecondaryFirstStart(defaultDate.start);
        setSecondaryFirstEnd(defaultDate.end);
        setSecondarySecondStart(null);
        setSecondarySecondEnd(null);
      } else {
        //console.log("withinDistance",withinDistance);
        setSecondaryFirstStart(defaultDate.start);

        setSecondaryFirstEnd(moment(defaultDate.end));
        setSecondarySecondStart(null);
        setSecondarySecondEnd(null);
      }
    } else {
      setSecondaryFirstStart(null);
      setSecondaryFirstEnd(null);
      setSecondarySecondStart(null);
      setSecondarySecondEnd(null);
    }
    setPrimaryRange1('');
    setPrimaryRange2('');
  }

  function onSecondaryFirstStart(date) {
    //setCheckedSecondaryFirst(false);
    setSecondaryFirstStart(date);
    if (moment(date).format('YYYY-MM-DD') !== moment(secondaryFirstStart).format('YYYY-MM-DD')) {
      setSecondaryFirstEnd(null);
      setSecondarySecondStart(null);
      setSecondarySecondEnd(null);
    }
    setPrimaryRange1('');
    setPrimaryRange2('');
    setShouldFetchDryRunDates(true);
  }

  function onSecondaryFirstEnd(date) {
    //setCheckedSecondaryFirst(false);
    if (!withinDistance && !isSecondaryFirstRangeClashingWithNCPWeekends) {
      const rv = isDateClashingWithNCPWeekends(secondaryFirstStart, date, allWeekends);
      setIsSecondaryFirstRangeClashingWithNCPWeekends(rv);
    }
    // console.log(isSecondaryFirstRangeClashingWithNCPWeekends);
    setSecondaryFirstEnd(date);
    if (moment(date).format('YYYY-MM-DD') !== moment(secondaryFirstEnd).format('YYYY-MM-DD')) {
      setSecondarySecondStart(null);
      setSecondarySecondEnd(null);
    }
    setPrimaryRange1('');
    setPrimaryRange2('');
    setShouldFetchDryRunDates(true);
    setSecondarySecondResetDisabled(false);
  }

  function onSecondarySecondStart(date) {
    //setCheckedSecondarySecond(false);
    setSecondarySecondStart(date);
    if (moment(date).format('YYYY-MM-DD') !== moment(secondarySecondStart).format('YYYY-MM-DD')) {
      setSecondarySecondEnd(null);
    }

    setPrimaryRange1('');
    setPrimaryRange2('');
    setShouldFetchDryRunDates(true);
  }

  function onSecondarySecondEnd(date) {
    //setCheckedSecondarySecond(false);
    if (!withinDistance && !isSecondarySecondRangeClashingWithNCPWeekends) {
      const rv = isDateClashingWithNCPWeekends(secondarySecondStart, date, allWeekends);
      setIsSecondarySecondRangeClashingWithNCPWeekends(rv);
    }
    // console.log(isSecondarySecondRangeClashingWithNCPWeekends);
    setSecondarySecondEnd(date);
    setPrimaryRange1('');
    setPrimaryRange2('');
    setShouldFetchDryRunDates(true);
  }
  function onPrimaryFirstStart(date) {
    // setCheckedPrimaryFirst(false);
    setPrimaryFirstStart(date);
    setPrimaryFirstEnd(null);
    setPrimarySecondStart(null);
    setPrimarySecondEnd(null);
    //setPrimaryRange1("");
    //setPrimaryRange2("");
    setShouldFetchDryRunDates(true);
  }

  function onPrimaryFirstEnd(date) {
    //setCheckedPrimaryFirst(false);
    if (!withinDistance && !isPrimaryFirstRangeClashingWithNCPWeekends) {
      const rv = isDateClashingWithNCPWeekends(primaryFirstStart, date, allWeekends);
      setIsPrimaryFirstRangeClashingWithNCPWeekends(rv);
    }
    setPrimaryFirstEnd(date);
    setPrimarySecondStart(null);
    setPrimarySecondEnd(null);
    //setPrimaryRange1("");
    //setPrimaryRange2("");
    setShouldFetchDryRunDates(true);
  }

  function onPrimarySecondStart(date) {
    //setCheckedPrimaryFirst(false);
    setPrimarySecondStart(date);
    setPrimarySecondEnd(null);
    //setPrimaryRange1("");
    //setPrimaryRange2("");
    setShouldFetchDryRunDates(true);
  }

  function onPrimarySecondEnd(date) {
    if (!withinDistance && !isPrimarySecondRangeClashingWithNCPWeekends) {
      const rv = isDateClashingWithNCPWeekends(primarySecondStart, date, allWeekends);
      setIsPrimarySecondRangeClashingWithNCPWeekends(rv);
    }
    setPrimarySecondEnd(date);
    //setPrimaryRange1("");
    //setPrimaryRange2("");
    setShouldFetchDryRunDates(true);
    //const firstRange = moment(primaryFirstEnd).clone().subtract(primaryFirstStart);
  }
  function onChangePrimaryRange1(event) {
    const index = event.target.value;
    if (index === '') {
      setPrimaryRange1('');
      if (primaryRange2 === '') {
        setIsPrimaryWeekendsClashingWithNCPWeekends(false);
      }
      return;
    }
    const startDate = validPrimaryRange1[index].start;
    const endDate = validPrimaryRange1[index].end;

    if (!withinDistance && !isPrimaryWeekendsClashingWithNCPWeekends) {
      const rv = isDateClashingWithNCPWeekends(startDate, endDate, allWeekends);
      setIsPrimaryWeekendsClashingWithNCPWeekends(rv);
    }
    setPrimaryRange1(index);
    setShouldFetchDryRunDates(true);
  }

  function onChangePrimaryRange2(event) {
    const index = event.target.value;
    if (index === '') {
      setPrimaryRange2('');
      if (primaryRange1 === '') {
        setIsPrimaryWeekendsClashingWithNCPWeekends(false);
      }
      return;
    }
    if (!withinDistance) {
      const startDate = validPrimaryRange1[index].start;
      const endDate = validPrimaryRange1[index].end;
      if (!isPrimaryWeekendsClashingWithNCPWeekends) {
        const rv = isDateClashingWithNCPWeekends(startDate, endDate, allWeekends);
        setIsPrimaryWeekendsClashingWithNCPWeekends(rv);
        // setValidPrimaryRange2(validPrimaryRange1);
        // This is because i am resuing primaryRange2 for >100 miles rule for 2nd weekend
      }
    }
    setPrimaryRange2(index);
  }

  function onChangeTermsAndConditions() {
    setTermsAndConditions(!termsAndConditions);
  }

  function onCloseModal() {
    firebase.analytics().logEvent('modal_cancel_edit_summer_possession');
    dispatch(closeModal());
  }

  function handleBack() {
    if (!withinDistance && activeStep === 3) {
      setActiveStep(prevActiveStep => prevActiveStep - 1);
    }
    if (withinDistance && activeStep === 4) {
      setActiveStep(prevActiveStep => prevActiveStep - 1);
    }
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  }

  function handleNext() {
    if (activeStep === 1 && shouldFetchDryRunDates) {
      setShouldFetchDryRunDates(false);
      const payload = createPayload();
      dispatch(getSummerPossessionDryRun(payload));
    }
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  }

  function totalSecondaryDays() {
    const diff1 = secondaryFirstEnd
      ? moment(secondaryFirstEnd).diff(moment(secondaryFirstStart), 'days')
      : 0;
    const diff2 = secondarySecondEnd
      ? moment(secondarySecondEnd).diff(moment(secondarySecondStart), 'days')
      : 0;
    return diff1 + diff2;
  }

  function primaryShouldNotExceed21Days() {
    if (!primaryFirstStart || !primaryFirstEnd) {
      return false;
    }
    const differ = moment(primaryFirstEnd).clone().diff(moment(primaryFirstStart), 'days');
    if (differ > 14) {
      return true;
    }

    const firstRange = moment(primaryFirstEnd).clone().diff(moment(primaryFirstStart), 'days');

    if (firstRange >= 21) {
      return true;
    }
    return false;
  }

  function createPayload() {
    const payload = {
      schoolYear: getCurrentSchoolYear(
        moment(schoolBreaks.summer.start),
        moment(currentCalendar.firstPopulatedDate),
        false,
      ),
      primary: { possessions: {} },
      secondary: { possessions: {} },
    };

    if (primaryRange1 !== '') {
      const weekend = validPrimaryRange1[primaryRange1];
      payload.primary.possessions.range1 = {
        start: weekend.start.format('YYYY-MM-DD'),
        end: weekend.end.format('YYYY-MM-DD'),
      };
    }

    if (primaryRange2 !== '') {
      const weekend = withinDistance
        ? validPrimaryRange2[primaryRange2]
        : validPrimaryRange1[primaryRange2];
      payload.primary.possessions.range2 = {
        start: weekend.start.format('YYYY-MM-DD'),
        end: weekend.end.format('YYYY-MM-DD'),
      };
    }

    if (primaryFirstStart && primaryFirstEnd) {
      payload.primary.possessions.range3 = {
        start: primaryFirstStart.format('YYYY-MM-DD'),
        end: primaryFirstEnd.format('YYYY-MM-DD'),
      };
    }

    if (primarySecondStart && primarySecondEnd) {
      payload.primary.possessions.range4 = {
        start: primarySecondStart.format('YYYY-MM-DD'),
        end: primarySecondEnd.format('YYYY-MM-DD'),
      };
    }

    if (secondaryFirstStart && secondaryFirstEnd) {
      payload.secondary.possessions.range1 = {
        start: secondaryFirstStart.format('YYYY-MM-DD'),
        end: secondaryFirstEnd.format('YYYY-MM-DD'),
      };
    }

    if (secondarySecondStart && secondarySecondEnd) {
      payload.secondary.possessions.range2 = {
        start: secondarySecondStart.format('YYYY-MM-DD'),
        end: secondarySecondEnd.format('YYYY-MM-DD'),
      };
    }
    //console.log(payload);
    return payload;
  }

  function onSaveSummerSessions() {
    const payload = createPayload();
    firebase.analytics().logEvent('modal_action_edit_summer_possession_save', payload);
    dispatch(updateSummerPossession(payload));
  }
  function isStepValid(step) {
    if (step === 0) {
      return (
        shouldUseDefaultDates ||
        (secondaryFirstStart &&
          secondaryFirstEnd &&
          (secondarySecondStart ? secondarySecondStart && secondarySecondEnd : true))
      );
    } else if (step === 4) {
      return termsAndConditions;
    } else {
      return true;
    }
  }

  function renderFathersDay(day, selectedDate, dayInCurrentMonth, dayComponent) {
    // this is a bit hacky solution but will work for now
    if (day.isSame(fathersDay, 'day') && dayInCurrentMonth) {
      let className = 'MuiPickersDay-day';
      if (dayComponent.props.disabled) {
        className += ' MuiPickersDay-dayDisabled';
      }
      className += ` ${classes.fathersDay}`;
      return React.cloneElement(dayComponent, { className });
    }
    return dayComponent;
  }

  function getStepContent(step) {
    if (step === 0) {
      const switchLabel = withinDistance
        ? 'modalSummerPossessionsSecondaryDefaultDateLabel'
        : 'modalSummerPossessionsSecondary100MilesDefaultDateLabel';
      return (
        <div>
          <Typography variant="subtitle2">
            {t('modalSummerPossessionsSecondaryDefaultDate')}
          </Typography>
          <FormControlLabel
            label={t(switchLabel)}
            control={
              <Switch
                color="primary"
                checked={shouldUseDefaultDates}
                onChange={onChangeDefaultDates}
                value="defaultDates"
              />
            }
          />
          <div className={classes.fathersDayLegend}></div>
          <div className={classes.labelAndCheckBox}>
            <Typography variant="subtitle2" className={classes.labelText}>
              {t('secondaryRange1Title')}
            </Typography>
            <FormControlLabel
              control={<Button onClick={handleSecondaryFirstReset}>{t('resetDatesTitle')}</Button>}
            ></FormControlLabel>
          </div>
          <div className={classes.datePickerContainer}>
            <div className={classes.firstDatePicker}>
              <DatePicker
                autoOk
                margin="normal"
                fullWidth
                inputVariant="outlined"
                format="MMM DD, YYYY"
                id={`secondaryFirstStart`}
                label={t('labelSchoolBreakStartDate')}
                // shouldDisableDate={}
                initialFocusedDate={validSecondarySummerSessions.firstStartDate.min}
                renderDay={renderFathersDay}
                disabled={
                  validSecondarySummerSessions.firstStartDate.disabled || shouldUseDefaultDates
                }
                minDate={validSecondarySummerSessions.firstStartDate.min}
                maxDate={validSecondarySummerSessions.firstStartDate.max}
                value={secondaryFirstStart}
                onChange={onSecondaryFirstStart}
              />
            </div>

            <div className={classes.secondDatePicker}>
              <DatePicker
                autoOk
                margin="normal"
                fullWidth
                inputVariant="outlined"
                format="MMM DD, YYYY"
                id={`secondaryFirstEnd`}
                label={t('labelSchoolBreakEndDate')}
                // shouldDisableDate={shouldDisableSecondaryFirstEndDate}
                initialFocusedDate={validSecondarySummerSessions.firstEndDate.min}
                renderDay={renderFathersDay}
                disabled={
                  validSecondarySummerSessions.firstEndDate.disabled || shouldUseDefaultDates
                }
                minDate={validSecondarySummerSessions.firstEndDate.min}
                maxDate={validSecondarySummerSessions.firstEndDate.max}
                value={secondaryFirstEnd}
                onChange={onSecondaryFirstEnd}
              />
            </div>
          </div>

          <div className={classes.labelAndCheckBox}>
            <Typography variant="subtitle2" className={classes.labelText}>
              {t('secondaryRange2Title')}
            </Typography>
            <FormControlLabel
              control={
                /* <Checkbox
                  className={classes.checkBox}
                  checked={checkedSecondarySecond}
                  onChange={handleSecondarySecondCheckbox}
                  ></Checkbox> */
                <Button
                  onClick={handleSecondarySecondReset}
                  disabled={secondarySecondResetDisabled}
                >
                  {t('resetDatesTitle')}
                </Button>
              }
              //label={t("resetDatesTitle")}
            ></FormControlLabel>
          </div>
          <div className={classes.datePickerContainer}>
            <div className={classes.firstDatePicker}>
              <DatePicker
                autoOk
                margin="normal"
                fullWidth
                inputVariant="outlined"
                format="MMM DD, YYYY"
                id={`secondarySecondStart`}
                label={t('labelSchoolBreakStartDate')}
                // shouldDisableDate={shouldDisableSecondaryStartDate({ fathersDay, isSecondaryMother })}
                initialFocusedDate={validSecondarySummerSessions.secondStartDate.min}
                renderDay={renderFathersDay}
                disabled={
                  validSecondarySummerSessions.secondStartDate.disabled || shouldUseDefaultDates
                }
                minDate={validSecondarySummerSessions.secondStartDate.min}
                maxDate={validSecondarySummerSessions.secondStartDate.max}
                value={secondarySecondStart}
                onChange={onSecondarySecondStart}
              />
            </div>

            <div className={classes.secondDatePicker}>
              <DatePicker
                autoOk
                margin="normal"
                fullWidth
                inputVariant="outlined"
                format="MMM DD, YYYY"
                id={`secondarySecondEnd`}
                label={t('labelSchoolBreakEndDate')}
                // shouldDisableDate={shouldDisableSecondarySecondEndDate}
                initialFocusedDate={validSecondarySummerSessions.secondEndDate.min}
                renderDay={renderFathersDay}
                disabled={
                  validSecondarySummerSessions.secondEndDate.disabled || shouldUseDefaultDates
                }
                minDate={validSecondarySummerSessions.secondEndDate.min}
                maxDate={validSecondarySummerSessions.secondEndDate.max}
                value={secondarySecondEnd}
                onChange={onSecondarySecondEnd}
              />
            </div>
          </div>
          {isSecondaryFirstRangeClashingWithNCPWeekends ||
          isSecondarySecondRangeClashingWithNCPWeekends ? (
            <WarningBox text={t('warningNCPWeekendsOverride')} />
          ) : (
            <div></div>
          )}
        </div>
      );
    } else if (step === 1) {
      return (
        <>
          <Typography variant="subtitle2">{t('primaryWeeekend1Description')}</Typography>
          <FormControl fullWidth variant="outlined" className={classes.formControl}>
            <InputLabel id="test-select-label" htmlFor="primaryWeekend1">
              {t('primaryWeeekend1Title')}
            </InputLabel>
            <Select value={primaryRange1} onChange={onChangePrimaryRange1} labelWidth={355}>
              <MenuItem value="">
                {validPrimaryRange1.length > 0
                  ? t('modalSummerPossessionsChooseOptionalWeekend')
                  : t('modalSummerPossessionsChooseOptionalWeekendEmpty')}
              </MenuItem>
              {validPrimaryRange1.map((range, index) => (
                <MenuItem
                  key={index}
                  value={index}
                  disabled={
                    primaryRange2 || primaryRange2 === 0
                      ? index === primaryRange2 ||
                        index === primaryRange2 - 1 ||
                        index === primaryRange2 + 1
                      : false
                  }
                >
                  {`${range.start.format('MMM DD, YYYY')} - ${range.end.format('MMM DD, YYYY')}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {!withinDistance && totalSecondaryDays() > 30 ? (
            <div>
              <FormControl fullWidth variant="outlined" className={classes.formControl}>
                <InputLabel htmlFor="primaryWeekend2">
                  {t('primaryWeeekend2GT100MilesTitle')}
                </InputLabel>
                <Select value={primaryRange2} onChange={onChangePrimaryRange2} labelWidth={355}>
                  <MenuItem value="">
                    {validPrimaryRange1.length > 0
                      ? t('modalSummerPossessionsChooseOptionalWeekend')
                      : t('modalSummerPossessionsChooseOptionalWeekendEmpty')}
                  </MenuItem>
                  {validPrimaryRange1.map((range, index) => (
                    <MenuItem
                      key={index}
                      value={index}
                      disabled={
                        primaryRange1 || primaryRange1 === 0
                          ? index === primaryRange1 ||
                            index === primaryRange1 - 1 ||
                            index === primaryRange1 + 1
                          : false
                      }
                    >
                      {`${range.start.format('MMM DD, YYYY')} - ${range.end.format(
                        'MMM DD, YYYY',
                      )}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          ) : (
            <div></div>
          )}
          {isPrimaryWeekendsClashingWithNCPWeekends ? (
            <WarningBox text={t('warningNCPWeekendsOverride')} />
          ) : (
            <div></div>
          )}
        </>
      );
    } else if (step === 2) {
      if (withinDistance) {
        return (
          <>
            <div>
              <Typography variant="subtitle2">{t('primaryWeeekend2Description')}</Typography>
              {dryRunLoading ? (
                <div className={classes.loader}>
                  <CircularProgress />
                </div>
              ) : (
                <FormControl fullWidth variant="outlined" className={classes.formControl}>
                  <InputLabel htmlFor="primaryWeekend2">{t('primaryWeeekend2Title')}</InputLabel>
                  <Select value={primaryRange2} onChange={onChangePrimaryRange2} labelWidth={415}>
                    <MenuItem value="">
                      {validPrimaryRange2.length > 0
                        ? t('modalSummerPossessionsChooseOptionalWeekend')
                        : t('modalSummerPossessionsChooseOptionalWeekendEmpty')}
                    </MenuItem>
                    {validPrimaryRange2.map((range, index) => (
                      <MenuItem key={index} value={index}>
                        {`${range.start.format('MMM DD, YYYY')} - ${range.end.format(
                          'MMM DD, YYYY',
                        )}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </div>
            {withinDistance && isPrimaryWeekendsClashingWithNCPWeekends ? (
              <WarningBox text={t('warningNCPWeekendsOverride')} />
            ) : (
              <div></div>
            )}
          </>
        );
      } else {
        return <></>;
      }
    } else if (step === 3) {
      if (withinDistance === false) {
        return (
          <div>
            <div className={classes.fathersDayLegend}>
              {/* <div className={classes.fathersDayLegendItem}></div> */}
              {/* <Typography variant="body2" className={classes.fathersDayLegendItemText}>
                {t("modalSummerPossessionsSecondaryFathersDayLegend")}
              </Typography> */}
            </div>
            <div className={classes.labelAndCheckBox}>
              <Typography variant="subtitle2" className={classes.labelText}>
                {t('primaryRange1Title')}
              </Typography>
              <FormControlLabel
                control={
                  /*  <Checkbox
                  className={classes.checkBox}
                  checked={checkedPrimaryFirst}
                  onChange={handlePrimaryFirstCheckbox}
                  ></Checkbox> */
                  <Button onClick={handlePrimaryFirstReset}>{t('resetDatesTitle')}</Button>
                }
                //label={t("resetDatesTitle")}
              ></FormControlLabel>
            </div>
            <div className={classes.datePickerContainer}>
              <div className={classes.firstDatePicker}>
                <DatePicker
                  autoOk
                  margin="normal"
                  fullWidth
                  inputVariant="outlined"
                  format="MMM DD, YYYY"
                  id={`primaryFirstStart`}
                  label={t('labelSchoolBreakStartDate')}
                  // shouldDisableDate={shouldDisablePrimaryFirstStartDate}
                  initialFocusedDate={schoolBreaks.summer.start}
                  renderDay={renderFathersDay}
                  disabled={false}
                  minDate={schoolBreaks.summer.start}
                  maxDate={moment(schoolBreaks.summer.end).clone().subtract(14, 'days')}
                  value={primaryFirstStart}
                  onChange={onPrimaryFirstStart}
                />
              </div>

              <div className={classes.secondDatePicker}>
                <DatePicker
                  autoOk
                  margin="normal"
                  fullWidth
                  inputVariant="outlined"
                  format="MMM DD, YYYY"
                  id={`primaryFirstEnd`}
                  label={t('labelSchoolBreakEndDate')}
                  // shouldDisableDate={shouldDisablePrimaryFirstEndDate}
                  initialFocusedDate={schoolBreaks.summer.end}
                  renderDay={renderFathersDay}
                  disabled={false}
                  minDate={moment(primaryFirstStart).clone().add(1, 'days')}
                  maxDate={moment(schoolBreaks.summer.end).clone().subtract(8, 'days')}
                  value={primaryFirstEnd}
                  onChange={onPrimaryFirstEnd}
                />
              </div>
            </div>
            <div className={classes.labelAndCheckBox}>
              <Typography variant="subtitle2" className={classes.labelText}>
                {t('primaryRange2Title')}
              </Typography>
              <FormControlLabel
                control={
                  /* <Checkbox
                  className={classes.checkBox}
                  checked={checkedPrimarySecond}
                  onChange={handlePrimarySecondCheckbox}
                  ></Checkbox> */
                  <Button onClick={handlePrimarySecondReset}>{t('resetDatesTitle')}</Button>
                }
                // label={t("resetDatesTitle")}
              ></FormControlLabel>
            </div>
            <div className={classes.datePickerContainer}>
              <div className={classes.firstDatePicker}>
                <DatePicker
                  autoOk
                  margin="normal"
                  fullWidth
                  inputVariant="outlined"
                  format="MMM DD, YYYY"
                  id={`primarySecondStart`}
                  label={t('labelSchoolBreakStartDate')}
                  // shouldDisableDate={shouldDisablePrimarySecondStartDate}
                  initialFocusedDate={moment(primaryFirstStart).clone().add(1, 'days')}
                  renderDay={renderFathersDay}
                  disabled={primaryShouldNotExceed21Days()}
                  minDate={moment(primaryFirstStart).clone().add(1, 'days')}
                  maxDate={moment(schoolBreaks.summer.end).clone().subtract(14, 'days')}
                  value={primarySecondStart}
                  onChange={onPrimarySecondStart}
                />
              </div>

              <div className={classes.secondDatePicker}>
                <DatePicker
                  autoOk
                  margin="normal"
                  fullWidth
                  inputVariant="outlined"
                  format="MMM DD, YYYY"
                  id={`primarySecondEnd`}
                  label={t('labelSchoolBreakEndDate')}
                  // shouldDisableDate={shouldDisablePrimarySecondEndDate}
                  initialFocusedDate={moment(primarySecondStart).clone().add(7, 'days')}
                  renderDay={renderFathersDay}
                  disabled={primaryShouldNotExceed21Days()}
                  minDate={moment(primaryFirstEnd).clone().add(1, 'days')}
                  maxDate={moment(schoolBreaks.summer.end).clone().subtract(8, 'days')}
                  value={primarySecondEnd}
                  onChange={onPrimarySecondEnd}
                />
              </div>
            </div>
            {isPrimaryFirstRangeClashingWithNCPWeekends ||
            isPrimarySecondRangeClashingWithNCPWeekends ? (
              <WarningBox text={t('warningNCPWeekendsOverride')} />
            ) : (
              <div></div>
            )}
          </div>
        );
      } else {
        return (
          <div>{<AlertBox variant="info" title={t('alertWithin100milesSummerPossessions')} />}</div>
        );
      }
    } else if (step === 4) {
      const range1 = validPrimaryRange1[primaryRange1];
      const range2 = withinDistance
        ? validPrimaryRange2[primaryRange2]
        : validPrimaryRange1[primaryRange2];

      const sections = [
        {
          title: t('modalSummerPossessionsSectionSecondaryTitle'),
          sections: [],
        },
        { title: t('modalSummerPossessionsSectionPrimaryTitle'), sections: [] },
        { title: t('modalSummerPossessionsSectionPrimaryTitle'), sections: [] },
      ];

      if (secondaryFirstStart && secondaryFirstEnd) {
        sections[0].sections.push({
          title: t('secondaryRange1Title'),
          value: `${secondaryFirstStart.format('L')} - ${secondaryFirstEnd.format('L')}`,
          icon: <Icon icon="calendar-day" size="lg" />,
        });
      } else {
        // should not be able to happen :O
      }

      if (secondarySecondStart && secondarySecondEnd) {
        sections[0].sections.push({
          title: t('secondaryRange2Title'),
          value: `${secondarySecondStart.format('L')} - ${secondarySecondEnd.format('L')}`,
          icon: <Icon icon="calendar-day" size="lg" />,
        });
      } else {
        sections[0].sections.push({
          title: t('secondaryRange2Title'),
          value: t('secondaryRange2NoValue'),
          icon: <Icon icon="calendar-day" size="lg" />,
        });
      }

      if (primaryRange1 !== '' && range1 && range1.start && range1.end) {
        sections[1].sections.push({
          title: t('primaryWeeekend1Title'),
          value: `${range1.start.format('L')} - ${range1.end.format('L')}`,
          icon: <Icon icon="calendar-day" size="lg" />,
        });
      } else {
        sections[1].sections.push({
          title: t('primaryWeeekend1Title'),
          value: t('primaryWeeekendNotChosen'),
          icon: <Icon icon="calendar-day" size="lg" />,
        });
      }

      if (primaryRange2 !== '' && range2 && range2.start && range2.end) {
        sections[1].sections.push({
          title: withinDistance ? t('primaryWeeekend2Title') : t('primaryWeeekend2GT100MilesTitle'),
          value: `${range2.start.format('L')} - ${range2.end.format('L')}`,
          icon: <Icon icon="calendar-day" size="lg" />,
        });
      } else {
        sections[1].sections.push({
          title: withinDistance ? t('primaryWeeekend2Title') : t('primaryWeeekend2GT100MilesTitle'),
          value: t('primaryWeeekendNotChosen'),
          icon: <Icon icon="calendar-day" size="lg" />,
        });
      }
      if (primaryFirstStart && primaryFirstEnd) {
        sections[2].sections.push({
          title: t('primaryRange1Title'),
          value: `${primaryFirstStart.format('L')} - ${primaryFirstEnd.format('L')}`,
          icon: <Icon icon="calendar-day" size="lg" />,
        });
      } else {
        // should not be able to happen :O
      }

      if (primarySecondStart && primarySecondEnd) {
        sections[2].sections.push({
          title: t('primaryRange2Title'),
          value: `${primarySecondStart.format('L')} - ${primarySecondEnd.format('L')}`,
          icon: <Icon icon="calendar-day" size="lg" />,
        });
      } else {
        // sections[2].sections.push({
        //   title: t("primaryRange2Title"),
        //   value: t("primaryRange2NoValue"),
        //   icon: <Icon icon="calendar-day" size="lg" />
        // });
      }
      if (withinDistance) {
        sections.pop();
      }
      return <SummaryList sections={sections} />;
    } else if (step === 5) {
      return (
        <>
          <div>
            <Typography variant="body1">
              <Trans i18nKey="actionAcceptTermsAndConditionsDescription">
                <b></b>
              </Trans>
            </Typography>
          </div>

          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={termsAndConditions}
                  onChange={onChangeTermsAndConditions}
                  value="checkedB"
                  color="primary"
                  inputProps={{
                    'aria-label': 'terms and conditions',
                  }}
                />
              }
              label={
                <Trans i18nKey="actionAcceptTermsAndConditions">
                  <Link
                    style={{ color: '#9c27b0' }}
                    to="/ourdayscalendar-terms-condition.pdf"
                    target="_blank"
                  ></Link>
                </Trans>
              }
            ></FormControlLabel>
          </FormControl>
        </>
      );
    } else {
      return <div>Unknown</div>;
    }
  }

  function showStep(index) {
    if (index === 2 && !withinDistance) {
      return false;
    }
    if (index === 3 && withinDistance) {
      return false;
    }
    return true;
  }

  function getActions() {
    return (
      <>
        <Button onClick={onCloseModal}>Cancel</Button>
        <Button disabled={activeStep === 0} onClick={handleBack}>
          {t('actionBack')}
        </Button>
        {activeStep < steps.length - 2 && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleNext}
            disabled={!isStepValid(activeStep)}
          >
            {t('actionNext')}
          </Button>
        )}
        {activeStep === steps.length - 2 && (
          <Button
            variant="contained"
            color="primary"
            onClick={onSaveSummerSessions}
            disabled={!isStepValid(activeStep) || loading}
          >
            {t('actionEditSummerPossessions')}
          </Button>
        )}
      </>
    );
  }

  if (!schoolBreaks) {
    return (
      <div className={classes.loaderContainer}>
        <Loader />
      </div>
    );
  }

  return (
    <ModalContent
      title={t('modalTitleSummerPossessions', {
        calendarName: currentCalendar.accessCard.calendarName,
      })}
      subtitle={t('modalSubTitleSummerPossessions', {
        calendarName: currentCalendar.accessCard.calendarName,
      })}
      subtitle2={t('modalSubTitle2SummerPossessions')}
      actions={getActions()}
      loading={loading}
    >
      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        classes={{ root: classes.stepperRoot }}
      >
        {steps.map((label, index) => {
          const stepProps = {};
          stepProps.completed = {};
          return (
            showStep(index) && (
              <Step key={label}>
                <StepLabel icon={'►'}>{label}</StepLabel>
                <StepContent>{getStepContent(index)}</StepContent>
              </Step>
            )
          );
        })}
      </Stepper>
    </ModalContent>
  );
}

export default SummerPossessions;
