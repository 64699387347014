import moment from 'moment';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { closeModal } from 'reducers/modals';
import Button from 'shared/Button/Button';
import * as calendarUtil from 'utils/calendar';
import firebase from 'utils/firebase';

import ModalContent from '../ModalContent/ModalContent';

export default function AlertExtendSharedCalendar() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const calendars = useSelector(state => state.sharedCalendars.list.calendars);
  const currentCalendarId = useSelector(state => state.sharedCalendars.currentCalendarId);

  const currentCalendar = calendarUtil.getCurrentCalendar(calendars, currentCalendarId);

  useEffect(() => {
    firebase.analytics().logEvent('modal_view_extend_calendar_warning');
  }, []);

  function onCloseModal() {
    firebase.analytics().logEvent('modal_cancel_extend_calendar_warning');
    dispatch(closeModal());
  }

  function getActions() {
    return (
      <>
        <Button onClick={onCloseModal} color="primary">
          {t('actionOk')}
        </Button>
      </>
    );
  }

  return (
    <ModalContent
      title={t('alertExtendSharedCalendarTitle', {
        date: moment(currentCalendar.lastPopulatedDate).format('MMM YYYY'),
      })}
      actions={getActions()}
    ></ModalContent>
  );
}
