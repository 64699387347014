import { ALTERNATE_WEEKENDS_EDIT } from 'constants';
import {
  DEFAULT_WEEKENDS,
  EDIT_CHILD_INFO,
  ELECT_SPO,
  ELECT_SPO_CUSTOM,
  FIRST_WEEKEND,
  PARENT1_IS,
  PARENT_STARTING_VISITATION_LABEL,
  WITHIN_DISTANCE,
} from 'constants.js';
import React from 'react';
import { useSelector } from 'react-redux';
import AlertBox from 'shared/AlertBox/AlertBox';
import Parent1MotherFather from 'shared/Forms/CreateCalendar/Parent1MotherFather';
import DefaultWeekends from 'shared/Forms/DefaultWeekends';
import RotationCalendarStartingDate from 'shared/Forms/EditCalendar/RotationCalendarStartingDate';
import RotationFirstParent from 'shared/Forms/EditCalendar/RotationFirstParent';
import EditCalendarSchoolBreak from 'shared/Forms/EditCalendarSchoolBreak';
import EditChildInfo from 'shared/Forms/EditChildInfo';
import ElectSPO from 'shared/Forms/ElectSPO';
import CustomPossession from 'shared/Forms/LegalStep/Customize/CustomPossession';
import WithinDistance from 'shared/Forms/WithinDistance';
import Typography from 'shared/Typography/Typography';

import AlternativeWeekends from './AlternativeWeekends';
import style from './SummaryList.module.css';
import { ALTERNATE_WEEKENDS } from 'constants';
import AlternativeWeekendsEdit from './AlternativeWeekendsEdit';

export default function SummaryList(props) {
  function onSelect(event) {
    event.currentTarget.select();
  }
  const templateSchoolBreaks = useSelector(state => state.schoolBreaks.templates.schoolBreaks);

  return (
    <>
      {props.sections.map((outer, index) => {
        return (
          outer && (
            <div className={style.sectionContainer} key={index}>
              <Typography variant="subtitle2">{outer.title}</Typography>
              <div className={style.section}>
                {outer.sections.map((inner, index) => {
                  return inner ? (
                    <div
                      className={style.itemContainer}
                      style={{
                        display: inner.type === ELECT_SPO_CUSTOM ? 'block' : '',
                      }}
                      key={index}
                    >
                      {inner.icon && inner.title && (
                        <div
                          style={{ display: 'flex', alignItems: 'center' }}
                          className={style.itemBox}
                        >
                          <div className={style.itemIconContainer}>{inner.icon}</div>
                          <div className={style.itemTitle}>{inner.title}</div>
                        </div>
                      )}
                      {inner.icon && inner.type === EDIT_CHILD_INFO && (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <div className={style.itemIconContainer}>{inner.icon}</div>
                        </div>
                      )}
                      {inner.onChangeDate && inner.type === EDIT_CHILD_INFO && (
                        <EditChildInfo
                          childName={inner.childName}
                          date={inner.value}
                          entryIndex={inner.entryIndex}
                          disabled={inner.disabled}
                          onChangeDate={inner.onChangeDate}
                          onChangeName={inner.onChangeName}
                          maxLength={inner.maxLength}
                        />
                      )}

                      {inner.onChange && !inner.type && (
                        <input
                          disabled={inner.disabled}
                          type="text"
                          className={style.itemInput}
                          value={inner.value}
                          onClick={onSelect}
                          onChange={inner.onChange}
                          maxLength={inner.maxLength}
                        ></input>
                      )}
                      {!inner.onChange && !inner.type && (
                        <div className={style.itemText}>{inner.value}</div>
                      )}
                      {inner.onChangeStartDate && inner.onChangeEndDate && inner.type && (
                        <EditCalendarSchoolBreak
                          value={inner.value}
                          type={inner.type}
                          disabled={inner.disabled}
                          onChangeEndDate={inner.onChangeEndDate}
                          onChangeStartDate={inner.onChangeStartDate}
                          templateSchoolBreak={templateSchoolBreaks[inner.type]}
                        />
                      )}

                      {inner.type === ELECT_SPO && (
                        <ElectSPO
                          electSPO={inner.value}
                          onChangeElectSPO={inner.onChange}
                          disabled={inner.disabled}
                        />
                      )}
                      {inner.type === ELECT_SPO_CUSTOM && (
                        <CustomPossession
                          customPossessionDays={inner.value}
                          setCustomPossessionDays={inner.setCustomPossessionDays}
                          showDayStart={false}
                          showUserName={true}
                          disabled={inner.disabled}
                        />
                      )}
                      {inner.type === WITHIN_DISTANCE && (
                        <WithinDistance
                          withinDistance={inner.value}
                          disabled={inner.disabled}
                          onChangeWithinDistance={inner.onChange}
                        />
                      )}
                      {inner.type === PARENT1_IS && (
                        <Parent1MotherFather
                          parent1MotherFatherDay={inner.value}
                          setParent1MotherFatherDay={inner.onChange}
                          disabled={inner.disabled}
                          parent1Name={inner.parent1Name}
                        />
                      )}
                      {inner.type === FIRST_WEEKEND && (
                        <RotationCalendarStartingDate
                          date={inner.value}
                          onChange={inner.onChange}
                          electSPO={inner.electSPO}
                          disabled={inner.disabled}
                          lastPopulatedDate={inner.lastPopulatedDate}
                          firstPopulatedDate={inner.firstPopulatedDate}
                        />
                      )}
                      {inner.type === PARENT_STARTING_VISITATION_LABEL && (
                        <RotationFirstParent
                          parentStartingDay={inner.value}
                          setParentStartingDay={inner.onChange}
                          electSPO={inner.electSPO}
                          disabled={inner.disabled}
                        />
                      )}
                      {inner.type === DEFAULT_WEEKENDS && (
                        <DefaultWeekends
                          defaultWeekends={inner.value}
                          disabled={inner.disabled}
                          onChangeDefaultWeekends={inner.onChange}
                        />
                      )}
                      {inner.type === ALTERNATE_WEEKENDS ? (
                        <AlternativeWeekends
                          value={inner.value}
                          onChangeAlternateWeekendsPossession={inner.onChange}
                          disabled={inner.disabled}
                        />
                      ) : null}
                      {inner.type === ALTERNATE_WEEKENDS_EDIT ? (
                        <AlternativeWeekendsEdit
                          value={inner.value}
                          onChangeAlternateWeekendsPossession={inner.onChange}
                          disabled={inner.disabled}
                        />
                      ) : null}
                    </div>
                  ) : (
                    <div key={index}></div>
                  );
                })}

                {outer.info && (
                  <div className={style.infoContainer}>
                    <AlertBox variant="info" description={outer.info} />
                  </div>
                )}
              </div>
            </div>
          )
        );
      })}
    </>
  );
}
