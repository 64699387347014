import api from 'utils/api.js';

export const FETCH_CONFIGURATIONS_START = 'configurations/FETCH_CONFIGURATIONS_START';
export const FETCH_CONFIGURATIONS_DONE = 'configurations/FETCH_CONFIGURATIONS_DONE';
export const FETCH_CONFIGURATIONS_ERROR = 'configurations/FETCH_CONFIGURATIONS_ERROR';

const initialState = {
  loading: true,
  configurations: {
    freeTrialPeriod: {
      period: 30,
      unit: 'days',
    },
  },
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CONFIGURATIONS_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case FETCH_CONFIGURATIONS_DONE: {
      return {
        ...state,
        configurations: action.configurations,
        loading: false,
      };
    }
    case FETCH_CONFIGURATIONS_ERROR: {
      // only set eror and use default configurations
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }

    default: {
      return { ...state };
    }
  }
};

export const getConfigurations =
  () =>
  async (dispatch, { firebase }) => {
    dispatch({ type: FETCH_CONFIGURATIONS_START });
    try {
      if (firebase.auth().currentUser) {
        const configurations = await api.getConfigurations();
        dispatch({ type: FETCH_CONFIGURATIONS_DONE, configurations });
      }
    } catch (error) {
      dispatch({ type: FETCH_CONFIGURATIONS_ERROR, error });
    }
  };
