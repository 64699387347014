import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import { DEFAULT_WEEKENDS_COPY } from 'constants.js';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function DefaultWeekends(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const defaultWeekends = props.defaultWeekends;

  function onChangeDefaultWeekends(event) {
    if (props.onChangeDefaultWeekends) {
      props.onChangeDefaultWeekends(event.target.value);
    }
  }

  return (
    <FormControl fullWidth className={classes.formControl} variant="outlined">
      <InputLabel htmlFor="defaultWeekends">{t('labelDefaultWeekends')}</InputLabel>
      <Select
        value={defaultWeekends}
        onChange={onChangeDefaultWeekends}
        labelWidth={75}
        disabled={props.disabled}
      >
        <MenuItem value="">{t('labelDefaultWeekends')}</MenuItem>
        {DEFAULT_WEEKENDS_COPY.map((defaultWeekends, index) => (
          <MenuItem key={index} value={index}>
            {defaultWeekends}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default DefaultWeekends;
