import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { closeModal } from 'reducers/modals';
import Button from 'shared/Button/Button';
import firebase from 'utils/firebase';

import ModalContent from '../ModalContent/ModalContent';

function RestrictedPrevModal() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    firebase.analytics().logEvent('modal_restricted_prev_date');
  }, []);

  function onCloseModal() {
    firebase.analytics().logEvent('modal_cancel_restricted_prev_date');
    dispatch(closeModal());
  }

  return (
    <ModalContent
      title={t('modalRestrictedPrevDate')}
      actions={
        <Button color="primary" onClick={onCloseModal}>
          {t('actionOk')}
        </Button>
      }
    ></ModalContent>
  );
}

export default RestrictedPrevModal;
