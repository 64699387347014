import { MODAL_PAYMENT } from 'constants.js';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openModal } from 'reducers/modals';
import Button from 'shared/Button/Button';
import ExpiredCalendarSubscriptionIcon from 'shared/Icons/svgs/ExpireSubscription.jsx';

import useStyles from './ExpiredPage.css.jsx';

const ExpiredCalendarSubscription = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  function onPaymentModal() {
    return dispatch(
      openModal({
        type: MODAL_PAYMENT,
        disableClose: false,
      }),
    );
  }
  return (
    <div className={classes.root}>
      <div className={classes.imageBox}>
        <ExpiredCalendarSubscriptionIcon />
      </div>
      <div className={classes.titleBox}>{t('expiredSubscriptionTitle')}</div>
      <div
        className={classes.subtitleBox}
        dangerouslySetInnerHTML={{ __html: t('expiredSubscriptionSubtitle') }}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={onPaymentModal}
        className={classes.button}
      >
        {t('expiredSubscriptionButton')}
      </Button>
    </div>
  );
};

export default ExpiredCalendarSubscription;
