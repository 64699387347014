import { MODAL_PAYMENT } from 'constants.js';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, openModal } from 'reducers/modals';
import Button from 'shared/Button/Button';
import firebase from 'utils/firebase';

import ModalContent from '../ModalContent/ModalContent';

function PaymentExpired() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userData = useSelector(state => state.user.user);
  const { paymentExpDate, freeAccessExpDate } = userData;

  useEffect(() => {
    firebase.analytics().logEvent('modal_show_paywall');
  }, []);

  function onCloseModal() {
    firebase.analytics().logEvent('modal_cancel_paywall');
    dispatch(closeModal());
  }

  function onUpdatePayment() {
    firebase.analytics().logEvent('modal_action_paywall_continue');
    dispatch(openModal({ type: MODAL_PAYMENT, disableClose: false }));
  }

  function subtitle(freeAccessExpDate, paymentExpDate) {
    const currentDate = moment(moment().format('YYYY-MM-DD'));
    const maxExpDate = paymentExpDate
      ? moment.max(moment(freeAccessExpDate), moment(paymentExpDate))
      : moment(freeAccessExpDate);
    const dayDiff = Number(maxExpDate.diff(currentDate, 'day'));
    let days = '';
    switch (dayDiff) {
      case 0:
        days = 'Today';
        break;
      case 1:
        days = '1 day';
        break;
      default:
        days = `${dayDiff} days`;
    }
    let subtitleString = t('goingToExpireSubtitle', {
      days,
    });

    return subtitleString;
  }

  return (
    <ModalContent
      title={t('modalPaymentExpiredTitle')}
      subtitle={subtitle(freeAccessExpDate, paymentExpDate)}
      // icon={<Icon icon="stop" size="xl" color={red[500]} />}
      actions={
        <>
          <Button color="primary" onClick={onCloseModal}>
            {t('actionSkip')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ color: 'white', width: '200px' }}
            onClick={onUpdatePayment}
          >
            {t('expiredSubscriptionButton')}
          </Button>
        </>
      }
    ></ModalContent>
  );
}

export default PaymentExpired;
