export const GET_TEMPLATE_ALL_WEEKENDS_START = 'allWeekends/GET_TEMPLATE_ALL_WEEKENDS_START';
export const GET_TEMPLATE_ALL_WEEKENDS_DONE = 'allWeekends/GET_TEMPLATE_ALL_WEEKENDS_DONE';
export const GET_TEMPLATE_ALL_WEEKENDS_ERROR = 'allWeekends/GET_TEMPLATE_ALL_WEEKENDS_ERROR';

export const getAllWeekendsTemplateName = schoolYear => {
  return `Weekends${schoolYear}`;
};

const initialState = {
  templates: {
    allWeekends: {},
    loading: false,
    error: null,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_TEMPLATE_ALL_WEEKENDS_START: {
      return {
        ...state,
        templates: {
          ...state.templates,
          allWeekends: {},
          loading: true,
          error: null,
        },
      };
    }
    case GET_TEMPLATE_ALL_WEEKENDS_DONE: {
      return {
        ...state,
        templates: {
          ...state.templates,
          allWeekends: action.allWeekends,
          loading: false,
          error: null,
        },
      };
    }
    case GET_TEMPLATE_ALL_WEEKENDS_ERROR: {
      return {
        ...state,
        templates: {
          ...state.templates,
          allWeekends: {},
          loading: false,
          error: action.error,
        },
      };
    }

    default: {
      return { ...state };
    }
  }
};

export const getAllWeekendsTemplates =
  schoolYear =>
  async (dispatch, getState, { firebase }) => {
    dispatch({ type: GET_TEMPLATE_ALL_WEEKENDS_START });
    const db = firebase.firestore();
    const docName = getAllWeekendsTemplateName(schoolYear);
    try {
      const allWeekendsTemplates = await db.collection('templates').doc(docName).get();

      const allWeekends = allWeekendsTemplates.data();
      dispatch({
        type: GET_TEMPLATE_ALL_WEEKENDS_DONE,
        allWeekends: allWeekends,
      });
    } catch (error) {
      dispatch({ type: GET_TEMPLATE_ALL_WEEKENDS_ERROR, error });
    }
  };
