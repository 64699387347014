import { Box, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { editCalendarParentName } from 'reducers/calendar';
import { closeModal } from 'reducers/modals';
import Button from 'shared/Button/Button';
import firebase from 'utils/firebase';

import * as calendarUtil from '../../utils/calendar';
import ModalContent from '../ModalContent/ModalContent';
import styles from './EditParentName.module.css';

const CustomRadio = withStyles({
  colorSecondary: {
    color: '#3A506E',
    '&$checked': {
      color: '#3A506E',
    },
  },
  checked: {},
})(Radio);

const EditParentName = () => {
  const { t } = useTranslation();
  const calendars = useSelector(state => state.calendar.list.calendars);
  const currentCalendarId = useSelector(state => state.calendar.currentCalendarId);
  const dispatch = useDispatch();
  const calendarId = useSelector(state => state.calendar.currentCalendarId);
  const loading = useSelector(state => state.calendar.editParentNameLoading);
  const currentCalendar = calendarUtil.getCurrentCalendar(calendars, currentCalendarId);
  const accessCard = currentCalendar && currentCalendar.accessCard;
  const [parent1Name, setParent1Name] = useState('');
  const [parent2Name, setParent2Name] = useState('');
  const [radioValue, setRadioValue] = useState(accessCard && accessCard.role);

  useEffect(() => {
    if (currentCalendar && currentCalendar.parent1Name) setParent1Name(currentCalendar.parent1Name);
    if (currentCalendar && currentCalendar.parent2Name) setParent2Name(currentCalendar.parent2Name);
  }, [currentCalendar]);

  const onCloseModal = () => {
    firebase.analytics().logEvent('modal_cancel_edit_Parent_Name');
    dispatch(closeModal());
  };

  const onConfirm = () => {
    firebase.analytics().logEvent('modal_action_edit_Parent_Name', {
      calendarId,
      parent1Name,
      parent2Name,
      role: radioValue,
    });
    dispatch(
      editCalendarParentName({
        calendarId,
        parent1Name,
        parent2Name,
        role: radioValue,
      }),
    );
  };

  const handleRadioButtonChange = event => {
    event.preventDefault;
    setRadioValue(event.target.value);
  };

  function getActions() {
    return (
      <>
        <Button onClick={onCloseModal} color="primary">
          {t('actionCancel')}
        </Button>
        <Button
          color="primary"
          onClick={onConfirm}
          disabled={loading || !parent1Name || !parent2Name || !radioValue}
        >
          {t('actionSave')}
        </Button>
      </>
    );
  }
  const handleInputParent1 = event => {
    setParent1Name(event.target.value);
  };
  const handleInputParent2 = event => {
    setParent2Name(event.target.value);
  };

  return (
    <>
      <ModalContent title={t('titleEditParentName')} actions={getActions()} loading={loading}>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={radioValue}
            onChange={handleRadioButtonChange}
          >
            <Box className={styles.fieldsBox}>
              <Box className={styles.inputBox}>
                <span className={styles.inputTitle}>{t('inputLabel1')}</span>
                <TextField
                  margin="normal"
                  id={`Additional_1`}
                  label={t('label')}
                  placeholder={t('label')}
                  variant="outlined"
                  value={parent1Name}
                  onChange={handleInputParent1}
                  inputProps={{}}
                />
                <FormControlLabel
                  value="primary"
                  control={<CustomRadio />}
                  label={t('radioLabel1')}
                />
              </Box>

              <Box className={styles.inputBox}>
                <span className={styles.inputTitle}>{t('inputLabel2')}</span>
                <TextField
                  margin="normal"
                  id={`Additional_2`}
                  label={t('label')}
                  placeholder={t('label')}
                  variant="outlined"
                  value={parent2Name}
                  onChange={handleInputParent2}
                  inputProps={{}}
                />
                <FormControlLabel
                  value="secondary"
                  control={<CustomRadio />}
                  label={t('radioLabel2')}
                />
              </Box>
            </Box>
          </RadioGroup>
        </FormControl>
      </ModalContent>
    </>
  );
};

export default EditParentName;
