import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import EmptyState from 'Profile/Incomplete/EmptyState/EmptyState';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getIncompleteCalendars } from 'reducers/incompleteCalendar';
import {
  changeCurrentIncompleteCalendar,
  deleteIncompleteCalendar,
} from 'reducers/incompleteCalendar.js';
import { getUser } from 'reducers/user';
import Icon from 'shared/Icons/Icons';
import firebase from 'utils/firebase';

import Loader from '../../shared/Loader/Loader';
import useStyles from './Incomplete.css.jsx';

const IncompleteCalendars = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();

  const user = useSelector(state => state.user.user);
  const incompleteCalendars = useSelector(state => state.incompleteCalendar.incompleteCalendars);
  const loading = useSelector(state => state.incompleteCalendar.loading);

  useEffect(() => {
    firebase.analytics().logEvent('get_users');
    dispatch(getUser());
  }, []);
  useEffect(() => {
    if (user && user.incomplete) {
      firebase.analytics().logEvent('get_incomplete_calendars_by_user');
      dispatch(getIncompleteCalendars(user.incomplete));
    }
  }, [user, dispatch]);

  const onHandleClick = data => {
    firebase.analytics().logEvent('choose_current_incomplete_calendar');
    dispatch(changeCurrentIncompleteCalendar(data));
  };

  const deleteIncompleteCalendarById = calendarId => {
    firebase.analytics().logEvent('delete_incomplete_calendar', calendarId);
    dispatch(deleteIncompleteCalendar(calendarId));
  };
  if (user && (!user.incomplete || user.incomplete.length === 0)) {
    return <EmptyState />;
  }

  return loading ? (
    <Loader />
  ) : (
    <List
      classes={{
        root: classes.listRoot,
      }}
    >
      {incompleteCalendars &&
        incompleteCalendars.map(cal => {
          return (
            <div key={cal.calendarId} className={classes.box}>
              <ListItem
                classes={{
                  root: classes.listItem,
                  selected: classes.listSelected,
                  button: classes.button,
                }}
                button
                onClick={() => cal.accessCard && onHandleClick(cal)}
              >
                <ListItemText
                  primary={t(`${cal && cal.accessCard && cal.accessCard.calendarName}`)}
                />
              </ListItem>
              <ListItemIcon
                onClick={() => deleteIncompleteCalendarById(cal.calendarId)}
                classes={{ root: classes.listItemIcon }}
              >
                <Icon icon="delete" size="lg" />
              </ListItemIcon>
            </div>
          );
        })}
    </List>
  );
};
export default IncompleteCalendars;
