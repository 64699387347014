import { FETCH_CALENDARS_DELAY, MODAL_SUCCESS_CREATE_CALENDAR } from 'constants.js';
import moment from 'moment';
import { openModal } from 'reducers/modals';
import { addSnackbar } from 'reducers/snackbar';
import { getCurrentSchoolYear } from 'utils/calendar';
import History from 'utils/history.js';

import { getCalendars, getCalendarsQtt, setCurrentCalendarId } from './calendar';

export const CREATE_CALENDAR_START = 'createCalendar/CREATE_CALENDAR_START';
export const CREATE_CALENDAR_DONE = 'createCalendar/CREATE_CALENDAR_DONE';
export const CREATE_CALENDAR_ERROR = 'createCalendar/CREATE_CALENDAR_ERROR';
export const GET_TEMPLATE_SCHOOLBREAK_START = 'createCalendar/GET_TEMPLATE_SCHOOLBREAK_START';
export const GET_TEMPLATE_SCHOOLBREAK_DONE = 'createCalendar/GET_TEMPLATE_SCHOOLBREAK_DONE';
export const GET_TEMPLATE_SCHOOLBREAK_ERROR = 'createCalendar/GET_TEMPLATE_SCHOOLBREAK_ERROR';
export const GET_TEMPLATE_ALL_WEEKENDS_START = 'createCalendar/GET_TEMPLATE_ALL_WEEKENDS_START';
export const GET_TEMPLATE_ALL_WEEKENDS_DONE = 'createCalendar/GET_TEMPLATE_ALL_WEEKENDS_DONE';
export const GET_TEMPLATE_ALL_WEEKENDS_ERROR = 'createCalendar/GET_TEMPLATE_ALL_WEEKENDS_ERROR';
export const GET_TEMPLATE_HOLIDAYS_START = 'createCalendar/GET_TEMPLATE_HOLIDAYS_START';
export const GET_TEMPLATE_HOLIDAYS_DONE = 'createCalendar/GET_TEMPLATE_HOLIDAYS_DONE';
export const GET_TEMPLATE_HOLIDAYS_ERROR = 'createCalendar/GET_TEMPLATE_HOLIDAYS_ERROR';

const initialState = {
  loading: false,
  error: null,
  schoolBreaks: {},
  allWeekends: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_CALENDAR_START: {
      return { ...state, loading: true, error: null };
    }
    case CREATE_CALENDAR_DONE: {
      return { ...state, loading: false, error: null };
    }
    case CREATE_CALENDAR_ERROR: {
      return { ...state, loading: false, error: action.error };
    }
    case GET_TEMPLATE_SCHOOLBREAK_DONE: {
      return { ...state, schoolBreaks: action.schoolBreaks };
    }
    case GET_TEMPLATE_ALL_WEEKENDS_DONE: {
      return { ...state, allWeekends: action.allWeekends };
    }
    case GET_TEMPLATE_HOLIDAYS_DONE: {
      return { ...state, holidays: action.holidays };
    }
    default: {
      return { ...state };
    }
  }
};

export const createCalendar =
  data =>
  async (dispatch, getState, { firebase }) => {
    dispatch({ type: CREATE_CALENDAR_START });
    if (data.schoolBreaks && data.allWeekends) {
      const formattedSchoolBreaks = {};
      for (let [key, values] of Object.entries(data.schoolBreaks)) {
        formattedSchoolBreaks[key] = {
          start: moment(values.start).format('YYYY-MM-DD'),
          end: moment(values.end).format('YYYY-MM-DD'),
        };
      }

      const formattedAllWeekends = {};
      for (let [key, values] of Object.entries(data.allWeekends)) {
        formattedAllWeekends[key] = {
          start: moment(values.start).format('YYYY-MM-DD'),
          end: moment(values.end).format('YYYY-MM-DD'),
        };
      }
      data.schoolBreaks = formattedSchoolBreaks;
      data.allWeekends = formattedAllWeekends;
    }

    const createCal = firebase.functions().httpsCallable('createCalendar_v3');
    try {
      const response = await createCal(data);
      const currentCalendarId = response.data;
      // add a little delay to prevent white screen
      setTimeout(() => {
        dispatch({ type: CREATE_CALENDAR_DONE });
        dispatch(setCurrentCalendarId(currentCalendarId));
        History.push(
          `/profile/calendars/${moment().format('YYYY-MM')}?calendarId=${currentCalendarId}`,
        );
        dispatch(openModal({ type: MODAL_SUCCESS_CREATE_CALENDAR, disableClose: true }));
        dispatch(getCalendars({}));
        dispatch(getCalendarsQtt());
      }, FETCH_CALENDARS_DELAY);
    } catch (error) {
      console.error(error);
      //console.error(JSON.stringify(error));
      dispatch({ type: CREATE_CALENDAR_ERROR, error });
      dispatch(
        addSnackbar({
          text: `${error.message}`,
          variant: 'error',
          closeButton: true,
          //retryButton: () => dispatch(createCalendar(data))
        }),
      );
    }
  };

export const getSchoolBreakTemplates =
  () =>
  async (dispatch, getState, { firebase }) => {
    dispatch({ type: GET_TEMPLATE_SCHOOLBREAK_START });
    const schoolYear = getCurrentSchoolYear(moment(), moment());
    const templates = firebase.functions().httpsCallable('getSchoolBreakTemplates');

    try {
      const data = { year: schoolYear };
      const response = await templates(data);
      const schoolBreaks = response.data.schoolBreaks;
      const formattedSchoolBreaks = {};
      for (let [key, values] of Object.entries(schoolBreaks)) {
        if (key === 'holidays') {
          for (let [name, day] of Object.entries(values)) {
            formattedSchoolBreaks[key].push({ holiday: name, date: day });
          }
        } else {
          formattedSchoolBreaks[key] = {
            start: moment(values.start),
            end: moment(values.end),
          };
        }
      }
      dispatch({
        type: GET_TEMPLATE_SCHOOLBREAK_DONE,
        schoolBreaks: formattedSchoolBreaks,
      });
    } catch (error) {
      dispatch({ type: GET_TEMPLATE_SCHOOLBREAK_ERROR, error });
    }
  };

export const getAllWeekendsTemplates =
  () =>
  async (dispatch, getState, { firebase }) => {
    dispatch({ type: GET_TEMPLATE_ALL_WEEKENDS_START });
    const schoolYear = getCurrentSchoolYear(moment(), moment());
    const db = firebase.firestore();
    const docName = `Weekends${schoolYear}`;
    try {
      const allWeekendsTemplates = await db.collection('templates').doc(docName).get();

      const allWeekends = allWeekendsTemplates.data();
      const formattedAllWeekends = {};
      for (let [key, values] of Object.entries(allWeekends)) {
        formattedAllWeekends[key] = {
          start: moment(values.start),
          end: moment(values.end),
        };
      }
      dispatch({
        type: GET_TEMPLATE_ALL_WEEKENDS_DONE,
        allWeekends: formattedAllWeekends,
      });
    } catch (error) {
      dispatch({ type: GET_TEMPLATE_ALL_WEEKENDS_ERROR, error });
    }
  };

export const getHolidaysTemplates =
  () =>
  async (dispatch, getState, { firebase }) => {
    dispatch({ type: GET_TEMPLATE_HOLIDAYS_START });
    const schoolYear = getCurrentSchoolYear(moment(), moment());
    const templates = firebase.functions().httpsCallable('getSchoolBreakTemplates');
    try {
      const data = { year: schoolYear };
      const response = await templates(data);
      const holidays = response.data.schoolBreaks.holidays;
      const formattedHolidays = {};
      for (let [key, value] of Object.entries(holidays)) {
        formattedHolidays[key] = {
          key: moment(value),
        };
      }
      dispatch({ type: GET_TEMPLATE_HOLIDAYS_DONE, holidays: formattedHolidays });
    } catch (error) {
      dispatch({ type: GET_TEMPLATE_HOLIDAYS_ERROR, error });
    }
  };
