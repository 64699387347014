import FormControl from '@material-ui/core/FormControl';
import { MODAL_EDIT_SCHOOL_BREAK_CUSTODIAN, SCHOOL_BREAK_NEWYEAR } from 'constants.js';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { modifySchoolBreakDate } from 'reducers/editSchoolBreakDate';
import { closeModal } from 'reducers/modals';
import { openModal } from 'reducers/modals';
import { getSchoolBreakTemplates } from 'reducers/schoolBreaks';
import Button from 'shared/Button/Button';
import SchoolBreakDatePicker from 'shared/Forms/SchoolBreakDatePicker';
import WarningBox from 'shared/WarningBox/WarningBox';
import { getCurrentCalendar } from 'utils/calendar';
import firebase from 'utils/firebase';
import { isValidSchoolBreakDate } from 'utils/validator';

import ModalContent from '../ModalContent/ModalContent';

export default function EditSchoolBreakDate() {
  const dispatch = useDispatch();
  const calendars = useSelector(state => state.calendar.list.calendars);
  const calendarId = useSelector(state => state.editSchoolBreakDate.calendarId);
  const currentCalendar = getCurrentCalendar(calendars, calendarId);
  const name = useSelector(state => state.editSchoolBreakDate.name);
  const schoolYear = useSelector(state => state.editSchoolBreakDate.schoolYear);
  const currentStartDate = useSelector(state => state.editSchoolBreakDate.startDate);
  const currentEndDate = useSelector(state => state.editSchoolBreakDate.endDate);
  const loading = useSelector(state => state.editSchoolBreakDate.loading);
  const templateSchoolBreaks = useSelector(state => state.schoolBreaks.templates.schoolBreaks);
  const [schoolBreak, setSchoolBreak] = useState({
    start: moment(currentStartDate),
    end: moment(currentEndDate),
  });
  const now = moment().startOf('day');
  const isStartDateBeforeToday = schoolBreak.start.isBefore(now, 'day');
  const isEndDateBeforeToday = schoolBreak.end && schoolBreak.end.isBefore(now, 'day');
  const { t } = useTranslation();
  useEffect(() => {
    firebase.analytics().logEvent('modal_show_edit_school_break', { name, schoolYear });
  }, [name, schoolYear]); //NOTE that in theory whenever one of these changes, the effect will be executed, but in our case we never modify these in this modal

  useEffect(() => {
    dispatch(getSchoolBreakTemplates(schoolYear));
  }, [dispatch, schoolYear]);

  function onCloseModal() {
    firebase.analytics().logEvent('modal_cancel_edit_school_break');
    dispatch(closeModal());
  }

  function onHandleStartDate(date) {
    const start = date.startOf('day');
    setSchoolBreak({ ...schoolBreak, start, end: null });
  }

  function onHandleEndDate(date) {
    const end = date.endOf('day');
    setSchoolBreak({ ...schoolBreak, end });
  }

  function onConfirm() {
    const data = {
      calendarId,
      schoolYear,
      name,
      schoolBreak,
    };
    dispatch(modifySchoolBreakDate(data));
    firebase.analytics().logEvent('modal_action_edit_school_break', data);
  }

  function getTitle() {
    let breakName;
    switch (name) {
      case 'christmas_newyear':
        breakName = 'Christmas-New Year break';
        break;
      case 'summer':
        breakName = 'Summer break';
        break;
      case 'spring':
        breakName = 'Spring break';
        break;
      case 'thanksgiving':
        breakName = 'Thanksgiving break';
        break;
      default:
        breakName = name;
    }
    if (templateSchoolBreaks[name]) {
      const calendarName = currentCalendar.accessCard.calendarName;
      const year = moment(templateSchoolBreaks[name].end).format('YYYY');
      return `${calendarName}: ${breakName} ${year}`;
    }
  }

  function shouldShowConfirmButton() {
    if (isEndDateBeforeToday) {
      return null;
    }

    return (
      <Button
        color="primary"
        onClick={onConfirm}
        disabled={loading || !isValidSchoolBreakDate(schoolBreak.start, schoolBreak.end)[0]}
      >
        {t('actionConfirm')}
      </Button>
    );
  }
  function openEditSchoolBreakCustodianModal() {
    dispatch(
      openModal({
        type: MODAL_EDIT_SCHOOL_BREAK_CUSTODIAN,
        disableClose: false,
      }),
    );
  }

  function getActions() {
    return (
      <>
        <Button onClick={onCloseModal} color="primary">
          {t('actionCancel')}
        </Button>
        {shouldShowConfirmButton()}
      </>
    );
  }

  function warningBoxText() {
    if (isStartDateBeforeToday && isEndDateBeforeToday) {
      const text = t('modalEditSchoolBreakPastStartAndEndDates');
      return <WarningBox text={text} />;
    } else if (isStartDateBeforeToday) {
      const text = t('modalEditSchoolBreakPastStartDate');
      return <WarningBox text={text} />;
    }
  }

  function formTitle(name) {
    if (name === SCHOOL_BREAK_NEWYEAR) {
      return 'modalEditSchoolBreakFormTitleNewYear';
    }
  }

  return (
    <>
      <ModalContent
        title={getTitle()}
        subtitle={t('modalEditSchoolBreakSubTitle')}
        actions={getActions()}
        loading={loading}
      >
        {warningBoxText()}
        <FormControl component="fieldset" fullWidth>
          <SchoolBreakDatePicker
            type={name}
            title={formTitle(name)}
            startProps={{
              disabled: isStartDateBeforeToday,
              disablePast: !isStartDateBeforeToday,
            }}
            endProps={{
              disabled: isEndDateBeforeToday,
              disablePast: !isEndDateBeforeToday,
            }}
            schoolBreak={schoolBreak}
            templateSchoolBreak={templateSchoolBreaks && templateSchoolBreaks[name]}
            onChangeStartDate={onHandleStartDate}
            onChangeEndDate={onHandleEndDate}
          />
        </FormControl>
        {!isEndDateBeforeToday && (
          <Button onClick={openEditSchoolBreakCustodianModal} color="primary">
            {t('actionChangeCustodian')}
          </Button>
        )}
      </ModalContent>
    </>
  );
}
