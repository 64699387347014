import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import environment from 'environment.js';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from 'reducers/modals';
import Button from 'shared/Button/Button';
import Typography from 'shared/Typography/Typography';
import firebase from 'utils/firebase';

import ModalContent from '../ModalContent/ModalContent.jsx';
import useStyles from './Payment.css.jsx';

export default function Payment() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useSelector(state => state.auth.user);
  const configurations = useSelector(state => state.configurations.configurations);
  const freeTrialPeriod = configurations.freeTrialPeriod;

  useEffect(() => {
    firebase.analytics().logEvent('modal_view_product_list');
    firebase.analytics().logEvent('view_item_list', {
      item_category: 'calendars',
    });
  }, []);

  //FIXME: when the modal is closed with the cross-icon at the top right, this method is not called,
  //some other method is called closing the modal. We want to send cancel_checkout at that place too
  function onCloseModal() {
    firebase.analytics().logEvent('cancel_checkout');
    firebase.analytics().logEvent('modal_cancel_product_list');
    dispatch(closeModal());
  }

  const onBuy = (sku, price, currency) => () => {
    firebase.analytics().logEvent('begin_checkout', {
      currency: currency,
      value: price,
    });
    const pathname = window.location.origin + window.location.pathname;
    window
      .Stripe(environment.stripe.publishableKey)
      .redirectToCheckout({
        clientReferenceId: user.uid,
        customerEmail: user.email,
        items: [{ sku, quantity: 1 }],
        successUrl: pathname + '?session_id={CHECKOUT_SESSION_ID}#payment-success',
        cancelUrl: pathname + '#payment',
      })
      .then(result => {
        console.log(result);
      });
  };

  function getActions() {
    return (
      <>
        <Button color="primary" onClick={onCloseModal}>
          {t('actionCancel')}
        </Button>
      </>
    );
  }

  return (
    <ModalContent
      title={t('modalProductsTitle')}
      subtitle={t('modalProductsSubTitle', {
        period: freeTrialPeriod.period,
        unit: freeTrialPeriod.unit,
      })}
      actions={getActions()}
    >
      <Typography variant="h6" gutterBottom>
        {t('modalProductListTitle')}
      </Typography>
      <List dense className={classes.root}>
        <div>
          {environment.stripe.products.map(product => {
            return (
              <div key={product.id}>
                <ListItem button>
                  <ListItemAvatar>
                    <Avatar alt={`Avatar ${product.name}`} src={product.image} variant="square" />
                  </ListItemAvatar>
                  <div>
                    <ListItemText primary={product.name} secondary={product.description} />
                    <div className={classes.promoText}>{product.promoText}</div>
                  </div>
                  <ListItemSecondaryAction>
                    <Button
                      variant="contained"
                      onClick={onBuy(product.sku, product.price, product.currency)}
                      color="primary"
                    >
                      {`${t('actionBuy')} `}
                      {product.currency}
                      {product.price}
                    </Button>
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider variant="inset" component="li" />
              </div>
            );
          })}

          {/*
          Remember to import Trans if using this
           <br/><br/>
            <Typography variant="body1">
            <Trans i18nKey="actionAcceptTermsAndConditionsDescription">
              <b></b>
            </Trans>
          </Typography> */}
        </div>
      </List>
    </ModalContent>
  );
}
