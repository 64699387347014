import { MODAL_EDIT_CALENDAR_DATE } from 'constants.js';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { addDateAdditional } from 'reducers/editCalendarDate';
import { closeModal, openModal } from 'reducers/modals';
import Button from 'shared/Button/Button';
import { getCurrentCalendar } from 'utils/calendar';
import firebase from 'utils/firebase';

import ModalContent from '../ModalContent/ModalContent';

const ReviewDateAdditional = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const calendarId = useSelector(state => state.editCalendarDate.calendarId);
  const additionalInfo = useSelector(state => state.editCalendarDate.additionalInfo);
  const loading = useSelector(state => state.editCalendarDate.loading);
  const date = useSelector(state => state.editCalendarDate.date);
  const calendars = useSelector(state => state.calendar.list.calendars);
  const currentCalendar = getCurrentCalendar(calendars, calendarId);
  const currentDate = useSelector(state => state.calendar.currentDate);

  const onCloseModal = () => {
    firebase.analytics().logEvent('modal_cancel_review_date_additional');
    dispatch(closeModal());
  };

  const onOpenAdditionalModal = () => {
    firebase.analytics().logEvent('modal_open_update_date_additional');
    dispatch(openModal({ type: MODAL_EDIT_CALENDAR_DATE, disableClose: false }));
  };

  const onDeleteAdditional = () => {
    firebase.analytics().logEvent('modal_action_delete_additional', {
      calendarId: calendarId,
      currentDate: currentDate,
      date: date,
    });
    dispatch(
      addDateAdditional({
        calendarId,
        currentDate,
        date,
        additionalInfo: '',
      }),
    );
  };

  const getActions = () => {
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <Button style={{ background: 'red', color: '#ffff' }} onClick={onDeleteAdditional}>
            {t('actionDelete')}
          </Button>
        </div>
        <div>
          <Button onClick={onCloseModal} color="primary">
            {t('actionClose')}
          </Button>
          <Button color="primary" onClick={onOpenAdditionalModal}>
            {t('actionUpdate')}
          </Button>
        </div>
      </div>
    );
  };

  return (
    <ModalContent
      title={`${currentCalendar.accessCard.calendarName}: ${date.format('LL')}`}
      title2={additionalInfo}
      actions={getActions()}
      loading={loading}
    ></ModalContent>
  );
};
export default ReviewDateAdditional;
