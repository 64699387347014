import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 240;

export default makeStyles(theme => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    padding: 0,
  },
  title: {
    flexGrow: 1,
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.custom.color.mainBackround,
    [theme.breakpoints.up('md')]: {
      left: drawerWidth,
    },
  },
  header: {
    height: '64px',
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.1rem',
    boxShadow: '0 -1px 0 #404854 inset',
    flexShrink: 0,
    position: 'sticky',
    zIndex: 1,
    background: theme.custom.color.drawerBackground,
    top: 0,
    color: '#fff',
  },
  headerLogo: {
    margin: '0 16px',
    width: '36px',
  },
  listItem: {
    fontSize: '.9rem',
  },
  listItemIcon: {
    color: 'inherit',
    minWidth: '32px',
  },
  listSelected: {
    color: '#64b5f6',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  spacer: {
    flexGrow: 1,
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    background: theme.custom.color.drawerBackground,
    color: '#fff',
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '68px',
    backgroundColor: theme.custom.color.mainBackround,
    width: `calc(100% - ${drawerWidth}px)`,
    '@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none)': {
      // IE11 media query
      paddingTop: '124px',
    },
  },
  button: {
    backgroundColor: 'rgba(255,255,255,0)',
    color: 'rgba(255,255,255,.7)',
    '&:hover,&:focus': {
      backgroundColor: 'rgba(255,255,255,0)',
      color: '#64b5f6',
    },
  },
  stickySideMenu: {
    bottom: 0,
    position: 'sticky',
    zIndex: 1,
    background: theme.custom.color.drawerBackground,
    boxShadow: '0 1px 0 #404854 inset',
  },
  card: {
    margin: theme.spacing(2),
    marginBottom: 0,
    backgroundColor: '#ffcd66',
    width: 'auto',
  },
  cardBody: {
    padding: theme.spacing(1),
    fontSize: '0.9em',
  },
  cardTitle: {
    fontWeight: '900',
    transform: 'uppercase',
  },
  outlinedPrimary: {
    color: 'white',
    border: '1px solid white',
    margin: theme.spacing(2),
  },
  'contact-accordion': {
    background: 'transparent!important',
    border: 'none!important',
    boxShadow: 'none!important',
    margin: '0!important',
    borderRadius: '0!important',
    '&:before': { display: 'none' },
  },
  'contact-accordion-summary': {
    padding: '0!important',
    color: '#DCDCDC!important',
    justifyContent: 'space-between!important',
    height: '24px!important',
    minHeight: '24px!important',
    '&:hover': { color: '#64b5f6!important' },
    '&>div': {
      margin: '0!important',
      flexGrow: '0!important',
      alignItems: 'center',
    },
  },
  'contact-accordion-details': {
    paddingLeft: '0!important',
    paddingRight: '0!important',
  },
  expandIcon: {
    color: '#DCDCDC',
  },
}));
