import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  header: {
    padding: theme.spacing(2),
    borderBottom: '1px solid #efefef',
    fontSize: '1.2rem',
    minHeight: '4.2rem',
    display: 'flex',
    alignItems: 'center',
    position: 'sticky',
    top: 0,
    backgroundColor: '#fff',
    zIndex: 2,
    boxShadow: '0px 0px 2px 0px rgba(0,0,0,0.2)',
    flexShrink: 0,
  },
  headerTitle: {
    flexGrow: 1,
  },
  headerClose: {
    flexBasis: '50px',
    flexShrink: 0,
  },
  textContent: {
    whiteSpace: 'pre-wrap',
    padding: theme.spacing(2),
    '&>p>a': {
      color: '#0D64DB',
    },
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '4.8rem',
  },
  content: {
    padding: theme.spacing(2),
    flexGrow: 1,
  },
  actionsContainer: {
    flex: '0 0 auto',
    display: 'flex',
    flexDirection: 'column',
    position: 'sticky',
    bottom: 0,
    backgroundColor: '#fff',
    boxShadow: '0px 0px 2px 0px rgba(0,0,0,0.2)',
    zIndex: 2,
  },
  actions: {
    flex: '0 0 auto',
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  loader: {
    height: '4px',
  },
}));
