import withStyles from '@material-ui/core/styles/withStyles';
import image from 'assets/img/bg_splittime_2.jpg';
import loginPageStyle from 'assets/jss/material-kit-pro-react/views/loginPageStyle.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Header from 'components/Header/Header.jsx';
import moment from 'moment';
import React, { useEffect } from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserTimeZone } from 'reducers/user.js';
import Footer from 'shared/Footer/Footer';
import Typography from 'shared/Typography/Typography';
import firebase from 'utils/firebase';

import useStyles from './Login.css.jsx';

function Login(props) {
  const { classes } = props;
  const { t } = useTranslation();
  const styles = useStyles();
  const dispatch = useDispatch();
  const authUser = useSelector(state => state.auth.user);
  const configurations = useSelector(state => state.configurations.configurations);
  const freeTrialPeriod = configurations.freeTrialPeriod;

  const uiConfig = {
    signInFlow: 'popup',
    signInSuccessUrl: `/profile/calendars/${moment().format('YYYY-MM')}`,
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      { provider: 'apple.com' },
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
    ],
    credentialHelper: 'none',
    callbacks: {
      signInSuccessWithAuthResult: authResult => {
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        setTimeout(() => {
          dispatch(updateUserTimeZone({ timeZone, userId: authResult.user.uid }));
        }, 10000);
      },
    },
  };

  useEffect(() => {
    firebase.analytics().logEvent('page_show_login');
    ReactGA.pageview('/login');
  }, []);

  if (authUser !== null) {
    props.history.replace(`/profile/calendars/${moment().format('YYYY-MM')}`);
    return null;
  }

  return (
    <div>
      <Header absolute color="transparent" brand={t('appTitle')} />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: 'url(' + image + ')',
          backgroundSize: 'cover',
          backgroundPosition: 'top center',
        }}
      >
        <div className={classes.container}>
          <Typography variant="h1" className={styles.title} gutterBottom>
            {t('loginMainTitle')}
          </Typography>
          <Typography variant="h2" className={styles.subTitle} gutterBottom>
            {t('loginMainSubtitle')}
          </Typography>
          <GridContainer justifyContent="center">
            <GridItem xs={12} sm={12} md={4}>
              <Card>
                <form className={classes.form}>
                  <CardHeader color="primary" signup className={classes.cardHeader}>
                    <h4 className={classes.cardTitle}>
                      {t('signinTitle', {
                        period: freeTrialPeriod.period,
                        unit: freeTrialPeriod.unit,
                      })}
                    </h4>
                  </CardHeader>
                  <CardBody signup>
                    <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
                  </CardBody>
                </form>
              </Card>
            </GridItem>
          </GridContainer>

          {/* <Card className={styles.infoCard}>
            <CardBody>
              <Typography variant="h6" gutterBottom>
                {t("signinHelpTextTitle")}
              </Typography>
              <Typography variant="body1" gutterBottom>
                {t("signinHelpTextBody")}
              </Typography>
            </CardBody>
          </Card> */}
        </div>
        <Footer position="bottom" theme="dark" />
      </div>
    </div>
  );
}

export default withStyles(loginPageStyle)(Login);
