import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { getCurrentCalendar } from 'utils/calendar';

import { PARENT_STARTING_VISITATION } from '../../../../constants.js';
import useStyles from './RotationFirstParent.css.jsx';

const RotationFirstParent = props => {
  const { setParentStartingDay, parentStartingDay } = props;
  const classes = useStyles();
  const calendar = useSelector(state => state.calendar);
  const currentCalendar = getCurrentCalendar(calendar.list.calendars, calendar.currentCalendarId);

  return (
    <Box className={classes.root}>
      <FormControl fullWidth className={classes.formControl} variant="outlined">
        <FormLabel
          id="demo-row-radio-buttons-group-label"
          className={classes.formLabel}
        ></FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          defaultValue={parentStartingDay}
          value={parentStartingDay}
          onChange={event => setParentStartingDay(Number(event.target.value))}
        >
          {PARENT_STARTING_VISITATION.map((value, index) => (
            <FormControlLabel
              key={value.label}
              value={value.value}
              control={<Radio />}
              disabled={props.disabled}
              label={
                index === 0 && currentCalendar && currentCalendar.parent1Name
                  ? currentCalendar.parent1Name
                  : index === 1 && currentCalendar && currentCalendar.parent2Name
                  ? currentCalendar.parent2Name
                  : value.label
              }
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export default RotationFirstParent;
