import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { userRole } from 'Modals/EditCalendarDate/EditCalendarDate';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { modifySchoolBreaksCustodian } from 'reducers/editSchoolBreakCustodian';
import { closeModal } from 'reducers/modals';
import Button from 'shared/Button/Button';
import { getCurrentCalendar } from 'utils/calendar';
import firebase from 'utils/firebase';

import ModalContent from '../ModalContent/ModalContent';

export function EditSchoolBreakCustodian() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const calendars = useSelector(state => state.calendar.list.calendars);
  const calendarId = useSelector(state => state.editSchoolBreakDate.calendarId);
  const currentCalendar = getCurrentCalendar(calendars, calendarId);
  const name = useSelector(state => state.editSchoolBreakDate.name);
  const templateSchoolBreaks = useSelector(state => state.schoolBreaks.templates.schoolBreaks);
  const loading = useSelector(state => state.editSchoolBreakCustodian.loading);
  const [newOwner, setNewOwner] = useState('1');

  const role = currentCalendar.accessCard.role;

  useEffect(() => {
    firebase.analytics().logEvent('modal_show_edit_school_break_custodian');
  }, []);

  const getTitle = () => {
    let breakName;
    switch (name) {
      case 'christmas_newyear':
        breakName = 'Christmas-New Year break';
        break;
      case 'summer':
        breakName = 'Summer break';
        break;
      case 'spring':
        breakName = 'Spring break';
        break;
      case 'thanksgiving':
        breakName = 'Thanksgiving break';
        break;
      default:
        breakName = name;
    }
    if (templateSchoolBreaks[name]) {
      const calendarName = currentCalendar.accessCard.calendarName;
      const year = moment(templateSchoolBreaks[name].end).format('YYYY');
      return `${calendarName}: ${breakName} ${year}`;
    }
  };

  const onCloseModal = () => {
    firebase.analytics().logEvent('modal_cancel_edit_school_break_custodian');
    dispatch(closeModal());
  };
  const onConfirm = () => {
    const calendarId = currentCalendar.accessCard.calendarId;
    const start = moment(getSchollBreak().start);
    const end = moment(getSchollBreak().end);

    firebase.analytics().logEvent('modal_action_edit_school_break_custodian', {
      calendarId: calendarId,
      startDateToModify: start,
      endDateToModify: end,
      newOwner: newOwner,
    });
    dispatch(modifySchoolBreaksCustodian({ calendarId, start, end, newOwner }));
  };

  const onHandleChange = event => {
    setNewOwner(event.target.value);
  };

  const getSchollBreak = () => {
    if (currentCalendar && name) {
      return currentCalendar.schoolBreaks[name];
    }
  };

  function getActions() {
    return (
      <>
        <Button onClick={onCloseModal} color="primary">
          {t('actionCancel')}
        </Button>
        <Button color="primary" onClick={onConfirm}>
          {t('actionConfirm')}
        </Button>
      </>
    );
  }
  // 1-primary
  // 2-secondary
  return (
    <ModalContent
      title={getTitle()}
      actions={getActions()}
      title2={t('modalEditSchoolBreakCustodianTitle2')}
      loading={loading}
    >
      <FormControl component="fieldset">
        <RadioGroup aria-label="owner" name="owner" value={newOwner} onChange={onHandleChange}>
          <FormControlLabel
            value={role === userRole.primary ? '1' : '2'}
            control={<Radio color="primary" />}
            label={t('ownerChangeFullDayPrimaryColorMeaning')}
            labelPlacement="end"
          />
          <FormControlLabel
            value={role === userRole.primary ? '2' : '1'}
            control={<Radio color="primary" />}
            label={t('ownerChangeFullDaySecondaryColorMeaning', {
              name:
                role === userRole.primary
                  ? currentCalendar.parent2Name
                    ? currentCalendar.parent2Name
                    : 'Parent 2'
                  : currentCalendar.parent1Name
                  ? currentCalendar.parent1Name
                  : 'Parent 1',
            })}
            labelPlacement="end"
          />
          <FormControlLabel
            value="0"
            control={<Radio color="primary" />}
            label={t('actionResetOriginal')}
            labelPlacement="end"
          />
        </RadioGroup>
      </FormControl>
    </ModalContent>
  );
}
