import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    color: 'black',
  },
  wrapper: {
    color: 'inherit',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  labelIcon: {
    minHeight: '46px',
  },
  selected: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    color: '#fff !important',
  },
}));
