import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { addDateAdditional } from 'reducers/editCalendarDate';
import { modifyCalendarDate } from 'reducers/editCalendarDate';
import { closeModal } from 'reducers/modals';
import Button from 'shared/Button/Button';
import { getCurrentCalendar } from 'utils/calendar';
import firebase from 'utils/firebase';

import ModalContent from '../ModalContent/ModalContent';

const AddNewDateAdditional = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const calendarId = useSelector(state => state.editCalendarDate.calendarId);
  const additionalInfo = useSelector(state => state.editCalendarDate.additionalInfo);
  const loading = useSelector(state => state.editCalendarDate.loading);
  const date = useSelector(state => state.editCalendarDate.date);
  const calendars = useSelector(state => state.calendar.list.calendars);
  const currentCalendar = getCurrentCalendar(calendars, calendarId);
  const currentDate = useSelector(state => state.calendar.currentDate);
  const [value, setValue] = useState(additionalInfo ? additionalInfo : '');
  const onCloseModal = () => {
    firebase.analytics().logEvent('modal_cancel_add_new_date_additional');
    dispatch(closeModal());
  };
  const onConfirm = () => {
    firebase.analytics().logEvent('modal_action_add_new_date_additional', {
      calendarId: calendarId,
      currentDate: currentDate,
      additionalInfo: value,
      date: date,
    });
    dispatch(
      modifyCalendarDate({
        ...props.dayDate,
      }),
    );
    setTimeout(() => {
      dispatch(
        addDateAdditional({
          calendarId,
          currentDate,
          date,
          additionalInfo: value,
        }),
      );
    }, 1000);
  };

  const handleInput = event => {
    setValue(event.target.value);
  };

  function getActions() {
    return (
      <>
        <Button onClick={onCloseModal} color="primary">
          {t('actionCancel')}
        </Button>
        <Button color="primary" onClick={onConfirm} disabled={loading}>
          {t('actionSave')}
        </Button>
      </>
    );
  }
  return (
    <>
      <ModalContent
        title={`${currentCalendar.accessCard.calendarName}: ${date.format('LL')}`}
        title2={additionalInfo ? t('title2UpdateAdditional') : t('title2AddAdditional')}
        actions={getActions()}
        loading={loading}
      >
        <FormControl fullWidth>
          <TextField
            margin="normal"
            id={`Additional`}
            label={t('titleAddAdditiional')}
            placeholder={t('placeholderAddAdditional')}
            variant="outlined"
            value={value}
            onChange={handleInput}
            inputProps={{}}
          />
        </FormControl>
      </ModalContent>
    </>
  );
};

export default AddNewDateAdditional;
