import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'shared/Button/Button';
import Icon from 'shared/Icons/Icons';
import Typography from 'shared/Typography/Typography';

import style from './ChildBirthday.module.css';

function ChildBirthday(props) {
  const { t } = useTranslation();
  const name = props.name;
  const dateOfBirth = props.dateOfBirth;
  const index = props.index;
  function onChangeChildName(event) {
    if (props.onChangeChildName) {
      props.onChangeChildName(event.target.value);
    }
  }

  function onChangeChildDateOfBirth(date, value) {
    if (props.onChangeChildDateOfBirth) {
      props.onChangeChildDateOfBirth(date, value);
    }
  }

  function onDeleteChild() {
    if (props.onDeleteChild) {
      props.onDeleteChild();
    }
  }

  return (
    <>
      <Typography variant="subtitle2">{t('labelAddChildNameTitle')}</Typography>
      <div className={style.container}>
        <div className={style.textInput}>
          <FormControl fullWidth>
            <TextField
              margin="normal"
              id={`childName-${index}`}
              label={t('labelAddChildName')}
              placeholder={t('placeholderAddChildName')}
              variant="outlined"
              value={name}
              onChange={onChangeChildName}
              inputProps={{
                maxLength: 50,
              }}
            />
          </FormControl>
        </div>

        <div className={style.datePicker}>
          <DatePicker
            autoOk
            fullWidth
            inputVariant="outlined"
            disableFuture
            openTo="year"
            margin="normal"
            format="MMM DD, YYYY"
            maxDate={moment()}
            minDate={moment().subtract(20, 'year')}
            label={t('labelAddChildDateOfBirth')}
            placeholder={t('placeholderAddChildDateOfBirth')}
            views={['year', 'month', 'date']}
            value={dateOfBirth}
            initialFocusedDate={moment().subtract(3, 'year')}
            onChange={onChangeChildDateOfBirth}
          />
        </div>

        <div className={style.button}>
          {index !== 0 && (
            <Button size="small" color="primary" onClick={onDeleteChild}>
              <Icon icon="close" size="lg" />
            </Button>
          )}
        </div>
      </div>
    </>
  );
}

export default ChildBirthday;
