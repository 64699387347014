import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getUserDevices } from 'reducers/user';
import Icon from 'shared/Icons/Icons';
import Typography from 'shared/Typography/Typography';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.custom.color.mainBackround,
    [theme.breakpoints.up('md')]: {
      left: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
    },
    // maxHeight: 58,
    // overflow: "scroll",
  },
  title: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },

  ie: {
    display: 'none',
    '@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none)': {
      // IE11 media query
      display: 'block',
      backgroundColor: '#ffcd66',
      padding: theme.spacing(1),
    },
  },
  avatar: {
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    '&>span': {
      color: 'rgba(0, 0, 0, 0.54)',
      borderRadius: '50%',
      overflow: 'hidden',
    },
    '&>span>img': {
      width: 24,
      objectFit: 'contain',
    },
  },
}));

function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

function ResponsiveDrawer(props) {
  const classes = useStyles();
  const authUser = useSelector(state => state.auth.user);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const devices = useSelector(state => state.user.devices);

  function handleDrawerToggle() {
    if (props.handleDrawerToggle) {
      props.handleDrawerToggle();
    }
  }
  useEffect(() => {
    dispatch(getUserDevices());
  }, [dispatch]);
  return (
    <>
      <CssBaseline />
      <ElevationScroll {...props}>
        <AppBar position="fixed" color="inherit" classes={{ root: classes.appBar }}>
          <div className={classes.ie}>{t('appUnsupportedBrowserIE')}</div>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <Icon icon="bars" />
            </IconButton>
            <Typography variant="h5" className={classes.title}>
              {t('appTitle')}
            </Typography>
            <div>
              {devices && devices.web && (
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <Typography
                    style={{
                      fontSize: '12px',
                      fonFamily: 'Roboto, Helvetica, Arial, sans-serif',
                    }}
                  >
                    WEB last logged at {moment(devices.web).format('MMM DD YYYY HH:mm')}
                  </Typography>
                </div>
              )}
              {devices && devices.android && (
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <Typography
                    style={{
                      fontSize: '12px',
                      fonFamily: 'Roboto, Helvetica, Arial, sans-serif',
                    }}
                  >
                    Andriod last logged at {moment(devices.android).format('MMM DD YYYY HH:mm')}
                  </Typography>
                  <Icon icon="mobile" />
                </div>
              )}
              {devices && devices.ios && (
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <Typography
                    style={{
                      fontSize: '12px',
                      fonFamily: 'Roboto, Helvetica, Arial, sans-serif',
                    }}
                  >
                    IOS last logged at {moment(devices.ios).format('MMM DD YYYY HH:mm')}
                  </Typography>
                  <Icon icon="mobile" />
                </div>
              )}
            </div>

            <IconButton
              component={Link}
              to="/profile/account"
              className={classes.avatar}
              style={{ height: '24px' }}
            >
              {authUser.photoURL && (
                <img className={classes.avatar} alt="My profile avatar" src={authUser.photoURL} />
              )}
              {!authUser.photoURL && <Icon icon="user" />}
            </IconButton>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
    </>
  );
}

export default ResponsiveDrawer;
