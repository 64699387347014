import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from 'shared/Typography/Typography';

function CalendarName(props) {
  const { t } = useTranslation();
  const calendarName = props.calendarName;

  function onChangeCalendarName(event) {
    if (props.onChangeCalendarName) {
      props.onChangeCalendarName(event.target.value);
    }
  }

  return (
    <>
      <Typography variant="subtitle2">{t('titleCalendarName')}</Typography>
      <FormControl fullWidth variant="outlined">
        <TextField
          margin="normal"
          id="calendarName"
          label={t('labelCalendarName')}
          placeholder={t('placeholderCalendarName')}
          variant="outlined"
          value={calendarName}
          onChange={onChangeCalendarName}
          inputProps={{
            maxLength: 50,
          }}
        />
      </FormControl>
    </>
  );
}

export default CalendarName;
