import { Typography } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LEGAL_ROLE_PRIMARY } from 'constants.js';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { modifyCalendarDate } from 'reducers/editCalendarDate';
import { closeModal } from 'reducers/modals';
import Button from 'shared/Button/Button';
import { getCurrentCalendar } from 'utils/calendar';
import firebase from 'utils/firebase';

import ModalContent from '../ModalContent/ModalContent';

export const userRole = {
  primary: 'primary',
  secondary: 'secondary',
};

export default function EditCalendarDate() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const calendarId = useSelector(state => state.editCalendarDate.calendarId);
  const currentOwner = useSelector(state => state.editCalendarDate.currentOwner);
  const schoolYear = useSelector(state => state.editCalendarDate.schoolYear);
  const date = useSelector(state => state.editCalendarDate.date);
  const role = useSelector(state => state.editCalendarDate.role);
  const modified = useSelector(state => state.editCalendarDate.modified);
  const additionalInfoData = useSelector(state => state.editCalendarDate.additionalInfo);
  const loading = useSelector(state => state.editCalendarDate.loading);
  const calendars = useSelector(state => state.calendar.list.calendars);
  const [newOwner, setNewOwner] = useState(`${currentOwner}`);
  const currentCalendar = getCurrentCalendar(calendars, calendarId);
  const [showDayOption, setShowDayOption] = useState(true);
  const [dayOptionChecked, setDayOptionChecked] = useState(true);
  const [dayTimeStart, setDayTimeStart] = useState(moment().hour(12).minute(0));
  const [dayTimeEnd, setDayTimeEnd] = useState(moment().hour(18).minutes(0));
  const [validErr, setValidErr] = useState(null);
  const [fromDate, setFromDate] = useState(moment(date));
  const [toDate, setToDate] = useState(moment(date));
  const [additionalInfo, setAdditionalInfo] = useState(
    additionalInfoData ? additionalInfoData : '',
  );

  useEffect(() => {
    firebase.analytics().logEvent('modal_show_edit_calendar_date');
  }, []);

  function onCloseModal() {
    firebase.analytics().logEvent('modal_cancel_edit_calendar_date');
    dispatch(closeModal());
  }

  function onHandleChange(event) {
    if (Number(event.target.value) === 1 || Number(event.target.value) === 2) {
      setShowDayOption(true);
    }
    if (Number(event.target.value) === 0) {
      setShowDayOption(false);
    }
    setNewOwner(event.target.value);
  }

  function onConfirm() {
    firebase.analytics().logEvent('modal_action_edit_calendar_date', {
      calendarId: calendarId,
      schoolYear: schoolYear,
      date: date,
    });

    dispatch(
      modifyCalendarDate({
        calendarId,
        schoolYear,
        newOwner,
        startDate: fromDate,
        endDate: toDate,
        allDay: dayOptionChecked,
        start: dayTimeStart.format('HH:mm'),
        end: dayTimeEnd.format('HH:mm'),
        additionalInfo,
      }),
    );
  }
  function textLabel(value, role) {
    if (value === 0) {
      return t('actionResetOriginal');
    }

    const isMyDay = isItMyDay(value, role);
    if (isMyDay) {
      return t('ownerChangeFullDayPrimaryColorMeaning');
    } else {
      return t('ownerChangeFullDaySecondaryColorMeaning', {
        name:
          role === userRole.primary
            ? currentCalendar.parent2Name
              ? currentCalendar.parent2Name
              : 'Parent 2'
            : currentCalendar.parent1Name
            ? currentCalendar.parent1Name
            : 'Parent 1',
      });
    }
  }

  function isItMyDay(owner, role) {
    if (owner === 1 && role === LEGAL_ROLE_PRIMARY) return true;
    if (owner === 1 && role !== LEGAL_ROLE_PRIMARY) return false;
    if (owner === 2 && role === LEGAL_ROLE_PRIMARY) return false;
    if (owner === 2 && role !== LEGAL_ROLE_PRIMARY) return true;
  }
  // function handleAdditionalButton() {
  //   const dayData = {
  //     calendarId,
  //     schoolYear,
  //     newOwner,
  //     startDate: fromDate,
  //     endDate: toDate,
  //     allDay: dayOptionChecked,
  //     start: dayTimeStart.format('HH:mm'),
  //     end: dayTimeEnd.format('HH:mm'),
  //   };
  //   dispatch(
  //     openModal({
  //       type: MODAL_ADD_DATE_ADDITIONAL,
  //       disableClose: false,
  //       customData: dayData,
  //     }),
  //   );
  // }

  function handleCheckBox(event) {
    setDayOptionChecked(event.target.checked);
    setDayTimeStart(moment().hour(12).minute(0));
    setDayTimeEnd(moment().hour(18).minutes(0));
    setValidErr(null);
  }

  const handleInput = event => {
    setAdditionalInfo(event.target.value);
  };

  // function onHandleTime(event) {
  //   const name = event.target.id;
  //   setDayTime({ ...dayTime, [name]: event.target.value });
  // }
  function getActions() {
    return (
      <>
        <Button onClick={onCloseModal} color="primary">
          {t('actionCancel')}
        </Button>
        <Button
          color="primary"
          onClick={onConfirm}
          disabled={loading || validErr || !fromDate || !toDate}
        >
          {t('actionConfirm')}
        </Button>
      </>
    );
  }

  return (
    <>
      <ModalContent
        title={`${currentCalendar.accessCard.calendarName}`}
        subtitle2={t('modalEditCalendarDateGeneralSubTitle2')}
        actions={getActions()}
        loading={loading}
      >
        <FormControl component="fieldset">
          <Box display={'flex'} gap={'24px'}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                views={['day']}
                label="From"
                format={'MMM DD, YYYY'}
                minDate={moment(currentCalendar.firstPopulatedDate)}
                maxDate={moment(currentCalendar.lastPopulatedDate).subtract(2, 'days')}
                value={fromDate}
                onChange={newValue => {
                  setFromDate(newValue);
                  setToDate(null);
                }}
                renderInput={params => (
                  <TextField {...params} helperText={null} placeholder="tt.mm.jjjj" />
                )}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                views={['day']}
                label="To"
                format={'MMM DD, YYYY'}
                minDate={fromDate ? moment(fromDate) : moment(currentCalendar.firstPopulatedDate)}
                maxDate={moment(currentCalendar.lastPopulatedDate)}
                value={toDate}
                defaultValue={toDate}
                onChange={newValue => {
                  setToDate(newValue);
                }}
                renderInput={params => <TextField {...params} helperText={null} />}
              />
            </LocalizationProvider>
          </Box>
          <RadioGroup
            aria-label="owner"
            name="owner"
            value={newOwner}
            onChange={onHandleChange}
            style={{ marginTop: '20px' }}
          >
            {/* {currentOwner !== 1 && ( */}
            <FormControlLabel
              value="1"
              control={<Radio color="primary" />}
              label={textLabel(1, role)}
              labelPlacement="end"
            />
            {/* )} */}
            {/* {currentOwner !== 2 && ( */}
            <FormControlLabel
              value="2"
              control={<Radio color="primary" />}
              label={textLabel(2, role)}
              labelPlacement="end"
            />
            {/* )} */}
            {modified && (
              <FormControlLabel
                value="0"
                control={<Radio color="primary" />}
                label={textLabel(0, role)}
                labelPlacement="end"
              />
            )}
          </RadioGroup>
        </FormControl>
        {showDayOption && (
          <div>
            <FormControlLabel
              label={t('editCalendarDayCheckBoxLabel')}
              control={<Checkbox checked={dayOptionChecked} onChange={handleCheckBox} />}
            />
            {!dayOptionChecked && (
              <Stack
                component="form"
                noValidate
                direction="row"
                alignItems="center"
                style={{
                  gap: '1rem',
                  marginTop: '1rem',
                }}
              >
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <span style={{ margin: 0, fontSize: '1rem' }}>From</span>
                  <TimePicker
                    sx={{ width: 150, margin: 0 }}
                    views={['hours', 'minutes']}
                    value={dayTimeStart}
                    minTime={moment().hour(0).minutes(3)}
                    maxTime={dayTimeEnd.clone().subtract(30, 'minutes')}
                    onChange={value => {
                      setDayTimeStart(value);
                    }}
                    onError={err => {
                      setValidErr(!!err);
                    }}
                  />
                  <span style={{ margin: 0, fontSize: '1rem' }}>to</span>
                  <TimePicker
                    sx={{ width: 150, margin: 0 }}
                    views={['hours', 'minutes']}
                    value={dayTimeEnd}
                    maxTime={moment().hour(23).minute(59)}
                    minTime={dayTimeStart.clone().add(30, 'minutes')}
                    onChange={value => {
                      setDayTimeEnd(value);
                    }}
                    onError={err => {
                      setValidErr(!!err);
                    }}
                  />
                </LocalizationProvider>
              </Stack>
            )}
          </div>
        )}

        <div style={{ width: '100%', marginTop: '1rem' }}>
          {/* <Button disabled={validErr} onClick={handleAdditionalButton} color="primary">
            {additionalInfo ? t('actionalUpdateAdditional') : t('actionAddAdditional')}
          </Button> */}
          <FormControl fullWidth>
            <Typography>
              {additionalInfo ? t('title2UpdateAdditional') : t('title2AddAdditional')}
            </Typography>
            <TextField
              margin="normal"
              id={`Additional`}
              label={t('titleAddAdditiional')}
              placeholder={t('placeholderAddAdditional')}
              variant="outlined"
              value={additionalInfo}
              onChange={handleInput}
              inputProps={{}}
            />
          </FormControl>
        </div>
      </ModalContent>
    </>
  );
}
