export const GET_TEMPLATE_SCHOOL_BREAKS_START = 'schoolBreaks/GET_TEMPLATE_SCHOOL_BREAKS_START';
export const GET_TEMPLATE_SCHOOL_BREAKS_DONE = 'schoolBreaks/GET_TEMPLATE_SCHOOL_BREAKS_DONE';
export const GET_TEMPLATE_SCHOOL_BREAKS_ERROR = 'schoolBreaks/GET_TEMPLATE_SCHOOL_BREAKS_ERROR';
const initialState = {
  templates: {
    schoolBreaks: {},
    loading: false,
    error: null,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_TEMPLATE_SCHOOL_BREAKS_START: {
      return {
        ...state,
        templates: {
          ...state.templates,
          schoolBreaks: {},
          loading: true,
          error: null,
        },
      };
    }
    case GET_TEMPLATE_SCHOOL_BREAKS_DONE: {
      return {
        ...state,
        templates: {
          ...state.templates,
          schoolBreaks: action.schoolBreaks,
          loading: false,
          error: null,
        },
      };
    }
    case GET_TEMPLATE_SCHOOL_BREAKS_ERROR: {
      return {
        ...state,
        templates: {
          ...state.templates,
          schoolBreaks: {},
          loading: false,
          error: action.error,
        },
      };
    }

    default: {
      return { ...state };
    }
  }
};

export const getSchoolBreakTemplates =
  schoolYear =>
  async (dispatch, getState, { firebase }) => {
    dispatch({ type: GET_TEMPLATE_SCHOOL_BREAKS_START });
    const templates = firebase.functions().httpsCallable('getSchoolBreakTemplates');
    // console.log(schoolYear);
    try {
      const data = { year: schoolYear };
      const response = await templates(data);

      if (firebase.auth().currentUser) {
        const schoolBreaks = response.data.schoolBreaks;
        dispatch({
          type: GET_TEMPLATE_SCHOOL_BREAKS_DONE,
          schoolBreaks: schoolBreaks,
        });
      }
    } catch (error) {
      dispatch({ type: GET_TEMPLATE_SCHOOL_BREAKS_ERROR, error });
    }
  };
