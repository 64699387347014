import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'column',
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
    backgroundColor: '#fff',
    [theme.breakpoints.up('lg')]: {
      marginRight: theme.spacing(10),
      marginLeft: theme.spacing(10),
    },
    flexGrow: 1,
    borderRadius: '4px',
    boxShadow:
      '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  main: {
    display: 'flex',
    flexGrow: 1,
    padding: '16px',
    backgroundColor: 'white',
  },
  calendarContainer: {
    margin: theme.spacing(0),
    [theme.breakpoints.up('sm')]: {
      height: 'calc(100vh)',
    },
    [theme.breakpoints.up('md')]: {
      height: 'calc(50vh)',
    },
    [theme.breakpoints.up('lg')]: {
      height: 'calc(60vh)',
    },
  },
  calendarFullday: {
    backgroundColor: theme.custom.color.color4,
  },
  calendarOtherFullday: {
    backgroundColor: theme.custom.color.color2,
  },
  calendarAM: {
    backgroundColor: theme.custom.color.color5,
  },
  calendarPM: {
    backgroundColor: theme.custom.color.color5,
  },
  calendarUnknown: {
    backgroundColor: theme.custom.color.color3,
  },
  legend: {
    display: 'flex',
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
    flexWrap: 'wrap',
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '4px 0',
  },
  legendText: {
    padding: '0 8px',
  },
  legendMyFullday: {
    borderRadius: '50%',
    display: 'inline-block',
    width: '22px',
    height: '22px',
    backgroundColor: theme.custom.color.color4,
  },
  legendOtherFullday: {
    borderRadius: '50%',
    display: 'inline-block',
    width: '22px',
    height: '22px',
    backgroundColor: theme.custom.color.color2,
  },
  legendAM: {
    borderRadius: '50%',
    display: 'inline-block',
    width: '22px',
    height: '22px',
    backgroundColor: theme.custom.color.color5,
  },
  legendPM: {
    borderRadius: '50%',
    display: 'inline-block',
    width: '22px',
    height: '22px',
    backgroundColor: theme.custom.color.color5,
  },
  legendUnknown: {
    borderRadius: '50%',
    display: 'inline-block',
    width: '22px',
    height: '22px',
    backgroundColor: theme.custom.color.color3,
  },
  card: {
    display: 'flex',
    margin: theme.spacing(0),
    backgroundColor: theme.custom.color.color3,
    width: 'auto',
  },
  cardBody: {
    color: theme.custom.color.color1,
    padding: theme.spacing(1),
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  titleMonth: {
    fontSize: '1.6rem',
    fontWeight: '500',
    alignSelf: 'center',
    lineHeight: '48px',
    cursor: 'pointer',
    [theme.breakpoints.up('sm')]: {
      // alignSelf: "flex-start",
    },
  },
  titleButtons: {
    // alignItems: "center",
    display: 'flex',
    // alignSelf: "center",

    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      // alignSelf: "flex-end",
    },
  },
  shareBtnBox: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  summerPossesionContainer: {
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end',
    },
  },
  slepoversCountContainer: {
    width: '300px',
    padding: '1rem',
    backgroundColor: '#F3F3F3',
    height: '100%',
    borderRadius: '10px',
  },
  dataInput: {
    border: 'none',
    width: '90px',
  },
  PieChartBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '1rem',
    height: '40px',
  },
  PieChart1: {
    fontWeight: '800',
    // height: "100%",
    borderRadius: '50%',
    backgroundColor: 'rgba(31, 156, 163, 1)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50px',
    height: '50px',
  },
  PieChart2: {
    fontWeight: '800',
    color: '#FFFF',
    // height: "100%",
    borderRadius: '50%',
    backgroundColor: 'rgba(255, 122, 120, 1)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50px',
    height: '50px',
  },
}));
