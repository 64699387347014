export const MODAL_CREATE_CALENDAR = 'MODAL_CREATE_CALENDAR';
export const MODAL_EDIT_SCHOOL_BREAK = 'MODAL_EDIT_SCHOOL_BREAK';
export const MODAL_EDIT_CALENDAR_DATE = 'MODAL_EDIT_CALENDAR_DATE';
export const MODAL_EDIT_WEEKENDS = 'MODAL_EDIT_WEEKENDS';
export const MODAL_EXTEND_CALENDAR = 'MODAL_EXTEND_CALENDAR';
export const MODAL_INFO_PARENTS_DAY = 'MODAL_INFO_PARENTS_DAY';
export const MODAL_INFO_MOTHER_DAY = 'MODAL_INFO_MOTHER_DAY';
export const MODAL_INFO_FATHER_DAY = 'MODAL_INFO_FATHER_DAY';
export const MODAL_INFO_MODIFIED_DAY = 'MODAL_INFO_MODIFIED_DAY';
export const MODAL_EXTEND_SHARED_CALENDAR = 'MODAL_EXTEND_SHARED_CALENDAR';
export const MODAL_INFO_LAST_DAY = 'MODAL_INFO_LAST_DAY';
export const MODAL_SUCCESS_EXTEND_CALENDAR = 'MODAL_SUCCESS_EXTEND_CALENDAR';
export const MODAL_SUCCESS_CREATE_CALENDAR = 'MODAL_SUCCESS_CREATE_CALENDAR';
export const MODAL_SUCCESS_MODIFY_SUMMER_SESSIONS = 'MODAL_SUCCESS_MODIFY_SUMMER_SESSIONS';
export const MODAL_EDIT_BIRTHDAY = 'MODAL_EDIT_BIRTHDAY';

export const MODAL_ONBOARDING = 'MODAL_ONBOARDING';
export const MODAL_CALENDAR_INFO = 'MODAL_CALENDAR_INFO';
export const MODAL_SUMMER_SESSIONS = 'MODAL_SUMMER_SESSIONS';
export const MODAL_SUMMER_SESSIONS_ERROR = 'MODAL_SUMMER_SESSIONS_ERROR';
export const MODAL_ALERT_EXTEND_CALENDAR = 'MODAL_ALERT_EXTEND_CALENDAR';
export const MODAL_SUMMER_SESSIONS_DATES = 'MODAL_SUMMER_SESSIONS_DATES';
export const MODAL_PAYMENT = 'MODAL_PAYMENT';
export const MODAL_PAYMENT_SUCCESS = 'MODAL_PAYMENT_SUCCESS';
export const MODAL_PAYMENT_EXPIRED = 'MODAL_PAYMENT_EXPIRED';
export const MODAL_PAYMENT_EXPIRED_OWNER_CALENDAR = 'MODAL_PAYMENT_EXPIRED_OWNER_CALENDAR';
export const MODAL_DELETE_USER = 'MODAL_DELETE_USER';
export const MODAL_EDIT_SCHOOL_BREAK_CUSTODIAN = 'MODAL_EDIT_SCHOOL_BREAK_CUSTODIAN';
export const MODAL_RESTRICTED_PREV_DATE = 'MODAL_RESTRICTED_PREV_DATE';
export const MODAL_ADD_DATE_ADDITIONAL = 'MODAL_ADD_DATE_ADDITIONAL';
export const MODAL_REVIEW_DATE_ADDITIONAL = 'MODAL_REVIEW_DATE_ADDITIONAL';
export const MODAL_SHARE = 'MODAL_SHARE';
export const MODAL_EDIT_PARENT1_NAME = 'MODAL_EDIT_PARENT1_NAME';
export const MODAL_EDIT_PARENT2_NAME = 'MODAL_EDIT_PARENT2_NAME';
export const MODAL_DOWNLOAD_CALENDAR = 'MODAL_DOWNLOAD_CALENDAR';
export const MODAL_EXTEND_OLD_CALENDAR = 'MODAL_EXTEND_OLD_CALENDAR';
export const MODAL_MAJOR_UPDATE_INFORMATION = 'MODAL_MAJOR_UPDATE_INFORMATION';

export const EVENT_TYPE_TODAY = 'EVENT_TYPE_TODAY';
export const EVENT_TYPE_SCHOOLBREAK = 'EVENT_TYPE_SCHOOLBREAK';
export const EVENT_TYPE_BIRTHDAY = 'EVENT_TYPE_BIRTHDAY';
export const EVENT_TYPE_DATE = 'EVENT_TYPE_DATE';
export const EVENT_TYPE_MODIFIED = 'EVENT_TYPE_MODIFIED';
export const EVENT_TYPE_PARENTS_DAY = 'EVENT_TYPE_PARENTS_DAY';
export const EVENT_TYPE_LASTDAY = 'EVENT_TYPE_LASTDAY';
export const EVENT_TYPE_WEEKENDS = 'EVENT_TYPE_WEEKENDS';
export const EVENT_TYPE_HOLIDAY = 'EVENT_TYPE_HOLIDAY';
export const EVENT_TYPE_ADDITIONAL_INFO = 'EVENT_TYPE_ADDITIONAL_INFO';
export const SCHOOL_BREAK_THANKSGIVING = 'thanksgiving';
export const SCHOOL_BREAK_CHRISTMAS = 'christmas';
export const SCHOOL_BREAK_NEWYEAR = 'newyear';
export const SCHOOL_BREAK_SPRING = 'spring';
export const SCHOOL_BREAK_SUMMER = 'summer';
export const SCHOOL_BREAK_CHRISTMAS_NEW_YEAR = 'christmas_newyear';
export const FETCH_CALENDARS_DELAY = 2000;

export const LEGAL_ROLE = 'LEGAL_ROLE';
export const LEGAL_ROLE_PRIMARY = 'primary';
export const LEGAL_ROLE_SECONDARY = 'secondary';
export const LEGAL_ROLE_VIEW = 'view';
export const LEGAL_ROLES = [LEGAL_ROLE_PRIMARY, LEGAL_ROLE_SECONDARY];
export const LEGAL_ROLES_COPY = ["I'M THE CUSTODIAL PARENT", "I'M THE NON-CUSTODIAL PARENT"];
export const EDIT_CHILD_INFO = 'EDIT_CHILD_INFO';
export const PARENT_ROLE = 'PARENT_ROLE';
export const ELECT_SPO_CUSTOM = 'ELECT_SPO_CUSTOM';
export const PARENTAL_ROLES = ['MOTHER', 'FATHER'];
export const PARENTAL_ROLES_COPY = ["I'M THE MOTHER", "I'M THE FATHER"];
export const ELECT_SPO_TEXAS = ['Texas SPO', 'Texas E-SPO'];
export const PARENT_STARTING_VISITATION_LABEL = 'PARENT_STARTING_VISITATION';
export const ELECT_SPO_DAYS_ROTATION = [
  '2-2-3',
  '2-2-5-5',
  '3-3-4-4',
  'Alternating Week',
  'Alternating Weekends',
];
export const ELECT_SPO_ALTERNATING_WEEKENDS = 'Alternating Weekends';
export const ELECT_SPO_ALTERNATING_WEEK = 'Alternating Week';
export const ALTERNATING_FIRST_WEEKEND_OF_THE_MONTH = [
  { label: 'Custodial Parent', value: 'Custodial' },
  { label: 'Non-custodial Parent', value: 'NonCustodial' },
];
export const PARENT_STARTING_VISITATION = [
  { label: 'Parent 1', value: 1 },
  { label: 'Parent 2', value: 2 },
];
export const ELECT_SPO_COPY = [
  '2-2-3',
  '2-2-5-5',
  '3-3-4-4',
  'Alternating Week',
  'Alternating Weekends',
  'Texas SPO',
  'Texas E-SPO',
  'CUSTOMIZE',
];
export const primaryParentValue = 1;
export const secondaryParentValue = 2;
export const customizeRotationRules = {
  fixed: 0,
  weekly: 1,
  every2weeks: 2,
  every3weeks: 3,
  every4weeks: 4,
};

export const CUSTOMIZE_PARENTS = [
  { label: 'Parent 1', value: primaryParentValue },
  { label: 'Parent 2', value: secondaryParentValue },
];
export const CUSTOMIZE_OPTIONS = [
  { label: 'Fixed (no rotation)', value: customizeRotationRules.fixed },
  { label: 'Every other week', value: customizeRotationRules.weekly },
  { label: 'Every 2 weeks', value: customizeRotationRules.every2weeks },
  { label: 'Every 3 weeks', value: customizeRotationRules.every3weeks },
  { label: 'Every 4 weeks', value: customizeRotationRules.every4weeks },
];
export const ELECT_SPO = 'ELECT_SPO';
export const WITHIN_DISTANCE = 'WITHIN_DISTANCE';
export const PARENT1_IS = 'PARENT1_IS';
export const FIRST_WEEKEND = 'FIRST_WEEKEND';
export const WITHIN_DISTANCE_COPY = [
  'YES, WE LIVE WITHIN 100 MILES',
  'NO, WE LIVE MORE THAN 100 MILES APART',
];
export const DEFAULT_WEEKENDS = 'DEFAULT_WEEKENDS';
export const DEFAULT_WEEKENDS_COPY = [
  'DEFAULT (1st, 3rd, and 5th weekends of each month)',
  'ALTERNATE Weekends Possession',
];
export const ALTERNATE_WEEKENDS = 'ALTERNATE_WEEKENDS';
export const ALTERNATE_WEEKENDS_EDIT = 'ALTERNATE_WEEKENDS_EDIT';
export const ALTERNATE_WEEKENDS_POSSESSION_COPY = [
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'MAY',
  'JUN',
  'JUL',
  'AUG',
  'SEP',
  'OCT',
  'NOV',
  'DEC',
];
export const PARENT1_MOTHER_FATHER = [
  { label: 'the father', value: 'father' },
  { label: 'the mother', value: 'mother' },
];
export const LEGAL_STATES = [{ name: 'Texas', abbrevation: 'TX' }];
export const LEGAL_STATE = 'LEGAL_STATE';
export const MONTHLY_WEEKEND_POSSESSION = ['MONTHLY WEEKEND POSSESSION'];
export const JAN_WEEKEND_POSSESSION = 'January';
export const FEB_WEEKEND_POSSESSION = 'February';
export const MAR_WEEKEND_POSSESSION = 'March';
export const APR_WEEKEND_POSSESSION = 'April';
export const MAY_WEEKEND_POSSESSION = 'May';
export const JUN_WEEKEND_POSSESSION = 'June';
export const JUL_WEEKEND_POSSESSION = 'July';
export const AUG_WEEKEND_POSSESSION = 'August';
export const SEP_WEEKEND_POSSESSION = 'September';
export const OCT_WEEKEND_POSSESSION = 'October';
export const NOV_WEEKEND_POSSESSION = 'November';
export const DEC_WEEKEND_POSSESSION = 'December';

export const WORDPRESS_SITE = 'https://ourdayscalendar.com';

export const CONTACT_US = 'support@ourdayscalendar.com';

export const LAST_TIME_SHOW_EXPIRED_MODAL = 'last_time_show_expired_modal';
export const ONBOARDING_OPENED_BEFORE = 'onboarding_opened_before';
