import TextField from '@material-ui/core/TextField';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { clearCalendarState } from 'reducers/calendar';
import { closeModal } from 'reducers/modals';
import { deleteUser } from 'reducers/user';
import Button from 'shared/Button/Button';
import firebase from 'utils/firebase';

import ModalContent from '../ModalContent/ModalContent.jsx';
import useStyles from './Confirmation.css.jsx';

export default function ConfirmationDeleteUser() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [credential, setCredential] = useState({});
  const user = firebase.auth().currentUser;
  const providerId = user.providerData[0].providerId;

  function getActions() {
    return (
      <>
        <Button color="primary" onClick={onCloseModal}>
          {t('actionCancel')}
        </Button>
        <Button color="primary" onClick={onConfirmDelete}>
          {t('actionConfirm')}
        </Button>
      </>
    );
  }
  function onConfirmDelete() {
    dispatch(deleteUser(credential));
    dispatch(clearCalendarState());
    dispatch(closeModal());
  }

  function onCloseModal() {
    firebase.analytics().logEvent('cancel_deleting');
    firebase.analytics().logEvent('modal_cancel_deleting_account');
    dispatch(closeModal());
  }

  function handleInput(e) {
    setCredential({ ...credential, [e.target.type]: e.target.value });
  }

  return (
    <ModalContent
      title={t('modalDeleteAccountTitle')}
      subtitle={t('modalDeleteAccountSubtitle')}
      actions={getActions()}
    >
      {providerId === 'password' ? (
        <>
          <span className={classes.formTitle}>{t('modalDeleteAccountProvideCredential')}</span>

          <div className={classes.form}>
            <TextField
              onChange={handleInput}
              required
              id="standard-required"
              label="Email"
              type="email"
            />
            <TextField
              onChange={handleInput}
              required
              id="standard-password-input"
              label="Password"
              type="password"
            />
          </div>
        </>
      ) : null}
    </ModalContent>
  );
}
