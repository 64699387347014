import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AlternateWeekendsPossession from 'shared/Forms/AlternateWeekendsPossession';
import Typography from 'shared/Typography/Typography';
import { getValidMonthsInTheCalendar } from 'utils/calendar';

import useStyles from '../../Modals/CreateCalendar/CreateCalendar.css.jsx';

const AlternativeWeekends = ({ onChangeAlternateWeekendsPossession, value, disabled }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { secondAugustWeekendIndex, weekendIndices, schoolBreaks, alternateWeekendsPossession } =
    value;
  const items = [];
  let loop = 0;
  const lastIndex = getValidMonthsInTheCalendar(moment(), schoolBreaks.summer.end);

  while (loop + 8 < lastIndex) {
    // console.log(loop+10, lastIndex);
    items.push(
      <div key={Math.random()} className={classes.alternateWeekendsPossessionWrapper}>
        <AlternateWeekendsPossession
          key={loop}
          disabled={disabled}
          className={classes.alternateWeekendsPossessionMonth1}
          monthIndex={loop}
          schoolBreaks={schoolBreaks}
          alternateWeekendsPossession={
            alternateWeekendsPossession[moment().add(loop, 'months').format('MMMM')]
          }
          weekIndex={''}
          weekend={
            loop >= 12
              ? secondAugustWeekendIndex
              : weekendIndices[moment().add(loop, 'months').format('MMMM')]
          }
          onChangeAlternateWeekendsPossession={onChangeAlternateWeekendsPossession}
        ></AlternateWeekendsPossession>
        {loop + 9 < lastIndex ? (
          <AlternateWeekendsPossession
            key={loop + 1}
            className={classes.alternateWeekendsPossessionMonth2}
            monthIndex={loop + 1}
            schoolBreaks={schoolBreaks}
            alternateWeekendsPossession={
              alternateWeekendsPossession[
                moment()
                  .add(loop + 1, 'months')
                  .format('MMMM')
              ]
            }
            weekIndex={''}
            weekend={
              loop >= 11
                ? secondAugustWeekendIndex
                : weekendIndices[
                    moment()
                      .add(loop + 1, 'months')
                      .format('MMMM')
                  ]
            }
            disabled={loop + 9 >= lastIndex || disabled ? true : false}
            onChangeAlternateWeekendsPossession={onChangeAlternateWeekendsPossession}
          ></AlternateWeekendsPossession>
        ) : (
          <div className={classes.alternateWeekendsPossessionMonth2}></div>
        )}
      </div>,
    );
    loop += 2;
  }

  return (
    <>
      {
        <div
          style={{
            width: '100%',
          }}
        >
          <Typography variant="subtitle2">
            {t('labelAddAlternateWeekendsPossessionTitle')}
          </Typography>
          <div>{items}</div>
        </div>
      }
      {/* {allValid ? (
        <div></div>
      ) : (
        <WarningBox text={t("warningAlternateWeekends")} />
      )} */}
    </>
  );
};
export default AlternativeWeekends;
