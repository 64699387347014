export const EDIT_BIRTHDAY_START = 'editBirthday/EDIT_BIRTHDAY_START';
export const EDIT_BIRTHDAY_DONE = 'editBirthday/EDIT_BIRTHDAY_DONE';
export const EDIT_BIRTHDAY_ERROR = 'editBirthday/EDIT_BIRTHDAY_ERROR';
export const SET_CURRENT_BIRTHDAY = 'editBirthday/SET_CURRENT_BIRTHDAY';

const initialState = {
  name: '',
  date: null,
  loading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case EDIT_BIRTHDAY_START: {
      return { ...state, loading: true, error: null };
    }
    case EDIT_BIRTHDAY_DONE: {
      return { ...state, loading: false, error: null };
    }
    case EDIT_BIRTHDAY_ERROR: {
      return { ...state, loading: false, error: action.error };
    }
    case SET_CURRENT_BIRTHDAY: {
      return {
        ...state,
        name: action.name,
        date: action.date,
      };
    }
    default: {
      return { ...state };
    }
  }
};

export const editBirthday = () => async () => {
  // const { calendarId, name, date } = data;
  // dispatch({ type: EDIT_BIRTHDAY_START });
  // try {
  //   const db = firebase.firestore();
  //   db.collection("calendars")
  //     .doc(calendarId)
  //     .collection("birthdays")
  //     .doc("children")
  //     .set({  });
  //     dispatch({ type: EDIT_BIRTHDAY_DONE });
  // } catch (error) {
  //   console.error("editBirthday", error);
  //   dispatch({ type: EDIT_BIRTHDAY_ERROR, error });
  //   dispatch(
  //     addSnackbar({
  //       text: "Could not edit bithday",
  //       variant: "error",
  //       closeButton: true,
  //       retryButton: () => dispatch(editBirthday(data))
  //     })
  //   );
  // }
};

export const setCurrentBirthday = data => ({
  type: SET_CURRENT_BIRTHDAY,
  name: data.name,
  date: data.date,
});
