import { ELECT_SPO_TEXAS, MODAL_SUCCESS_EXTEND_CALENDAR } from 'constants.js';
import moment from 'moment';
import { getCalendars } from 'reducers/calendar';
import { openModal } from 'reducers/modals';
import { addSnackbar } from 'reducers/snackbar';

import * as calendarUtil from '../utils/calendar';
import history from '../utils/history.js';

export const EXTEND_CALENDAR_START = 'extendCalendar/EXTEND_CALENDAR_START';
export const EXTEND_CALENDAR_DONE = 'extendCalendar/EXTEND_CALENDAR_DONE';
export const EXTEND_CALENDAR_ERROR = 'extendCalendar/EXTEND_CALENDAR_ERROR';
export const GET_TEMPLATE_SCHOOLBREAK_START = 'extendCalendar/GET_TEMPLATE_SCHOOLBREAK_START';
export const GET_TEMPLATE_SCHOOLBREAK_DONE = 'extendCalendar/GET_TEMPLATE_SCHOOLBREAK_DONE';
export const GET_TEMPLATE_SCHOOLBREAK_ERROR = 'extendCalendar/GET_TEMPLATE_SCHOOLBREAK_ERROR';
export const SET_SCHOOLBREAK = 'extendCalendar/SET_SCHOOLBREAK';

const initialState = {
  loading: false,
  error: null,
  schoolBreaks: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case EXTEND_CALENDAR_START: {
      return { ...state, loading: true, error: null };
    }
    case EXTEND_CALENDAR_DONE: {
      return { ...state, loading: false, error: null };
    }
    case EXTEND_CALENDAR_ERROR: {
      return { ...state, loading: false, error: action.error };
    }
    case GET_TEMPLATE_SCHOOLBREAK_DONE: {
      return { ...state, schoolBreaks: action.schoolBreaks };
    }
    case SET_SCHOOLBREAK: {
      return {
        ...state,
        schoolBreaks: {
          ...state.schoolBreaks,
          [action.name]: {
            ...state.schoolBreaks[action.name],
            start: action.start,
            end: action.end,
          },
        },
      };
    }
    default: {
      return { ...state };
    }
  }
};

export const extendCalendar =
  data =>
  async (dispatch, getState, { firebase }) => {
    dispatch({ type: EXTEND_CALENDAR_START });
    const { calendar } = getState();
    const { currentCalendarId } = calendar;
    if (data.schoolBreaks) {
      const formattedSchoolBreaks = {};
      for (let [key, values] of Object.entries(data.schoolBreaks)) {
        formattedSchoolBreaks[key] = {
          start: moment(values.start).format('YYYY-MM-DD'),
          end: moment(values.end).format('YYYY-MM-DD'),
        };
      }

      data.calendarId = currentCalendarId;
      data.schoolBreaks = formattedSchoolBreaks;
    }
    //data.schoolYear = ; // FIXME: Don't hard code this value

    try {
      const extendCal = firebase.functions().httpsCallable('extendCalendar_v3');
      await extendCal(data);

      const currentCalendar = calendarUtil.getCurrentCalendar(
        calendar.list.calendars,
        calendar.currentCalendarId,
      );

      const lastMonth = currentCalendar.datesIds[currentCalendar.datesIds.length - 1];

      history.replace(
        `/profile/calendars/${moment(lastMonth)
          .add(1, 'month')
          .format('YYYY-MM')}?calendarId=${currentCalendarId}`,
      );
      dispatch({ type: EXTEND_CALENDAR_DONE });
      dispatch(getCalendars({}));
      if (ELECT_SPO_TEXAS.includes(currentCalendar.electedSecondary)) {
        dispatch(openModal({ type: MODAL_SUCCESS_EXTEND_CALENDAR, disableClose: true }));
      }
    } catch (error) {
      console.error(JSON.stringify(error));
      dispatch({ type: EXTEND_CALENDAR_ERROR, error });
      dispatch(
        addSnackbar({
          text: `${error.message}`,
          variant: 'error',
          closeButton: true,
          //retryButton: () => dispatch(extendCalendar(data))
        }),
      );
    }
  };

export const getSchoolBreakTemplates =
  schoolYear =>
  async (dispatch, getState, { firebase }) => {
    dispatch({ type: GET_TEMPLATE_SCHOOLBREAK_START });
    const templates = firebase.functions().httpsCallable('getSchoolBreakTemplates');
    try {
      const data = { year: schoolYear };
      const response = await templates(data);
      const schoolBreaks = response.data.schoolBreaks;
      const formattedSchoolBreaks = {};
      for (let [key, values] of Object.entries(schoolBreaks)) {
        formattedSchoolBreaks[key] = {
          start: moment(values.start),
          end: moment(values.end),
        };
      }

      dispatch({
        type: GET_TEMPLATE_SCHOOLBREAK_DONE,
        schoolBreaks: formattedSchoolBreaks,
      });
    } catch (error) {
      dispatch({ type: GET_TEMPLATE_SCHOOLBREAK_ERROR, error });
    }
  };

export const setSchoolBreak =
  ({ name, end, start }) =>
  async dispatch => {
    dispatch({ type: SET_SCHOOLBREAK, name, end, start });
  };
