import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '1rem',
  },
  deleteBtn: {
    background: 'red',
    color: '#FFFFFF',
    '&:hover': {
      background: 'red',
    },
  },
}));
