import {
  SCHOOL_BREAK_CHRISTMAS,
  SCHOOL_BREAK_CHRISTMAS_NEW_YEAR,
  SCHOOL_BREAK_NEWYEAR,
  SCHOOL_BREAK_SPRING,
  SCHOOL_BREAK_SUMMER,
  SCHOOL_BREAK_THANKSGIVING,
} from 'constants.js';
import moment from 'moment';
import { getAllWeekendsOfTheMonth, getCurrentSchoolYear } from 'utils/calendar';

export const fathersDay = schoolYear => {
  // FIXME: remove hard coded father's day
  const dates = {
    1920: moment('2020-06-21'),
    2021: moment('2021-06-20'),
    2122: moment('2022-06-19'),
    2223: moment('2023-06-18'),
    2324: moment('2024-06-16'),
    2425: moment('2025-06-15'),
    2526: moment('2026-06-21'),
    2627: moment('2027-06-20'),
    2728: moment('2028-06-18'),
    2829: moment('2029-06-17'),
    2930: moment('2030-06-16'),
  };
  return dates[schoolYear];
};

export const mothersDay = schoolYear => {
  const dates = {
    1920: moment('2020-05-09'),
    2021: moment('2021-05-09'),
    2022: moment('2022-06-08'),
    2023: moment('2023-05-14'),
  };
  return dates[schoolYear];
};

export const isValidCustomPossession = data => {
  if (Object.keys(data).length === 0) {
    return [false, 'Must select a value'];
  } else {
    return [true, null];
  }
};

export const isValidLegalZipCode = value => {
  const regex = new RegExp('^[0-9]+$');
  if (!regex.test(value)) {
    return [false, 'Can only contain numbers'];
  } else if (value.length !== 5) {
    return [false, 'Must contain 5 digits'];
  } else {
    return [true, null];
  }
};

export const isValidCalendarName = value => {
  if (value.length < 1 || value.length > 50) {
    return [false, 'Must be 1-20 characters'];
  } else {
    return [true, null];
  }
};

export const isValidChildrenName = value => {
  if (value.length < 1 || value.length > 50) {
    return [false, 'Must be 1-20 characters'];
  } else {
    return [true, null];
  }
};

export const isValidChildrenValue = children => {
  const valid = [];
  children.forEach(child => {
    child.name < 1 || child.name > 50 ? valid.push(false) : valid.push(true);
    valid.push(moment(child['b-day']).isValid());
  });
  return !valid.includes(false);
};

export const isValidSelectableValue = value => {
  if (value === '') {
    return [false, 'Must select a value'];
  } else {
    return [true, null];
  }
};

export const isValidChildrenDateOfBirth = date => {
  if (!date) {
    return [false, 'Invalid date'];
  }
  const isValid = moment(date).isValid();
  const isAfter = moment(date).isAfter(moment());
  if (!isValid) {
    return [false, 'Invalid date'];
  } else if (isAfter) {
    return [false, 'Cannot be a future date'];
  } else {
    return [true, null];
  }
};

export const includeSchoolBrakesValues = (schoolBreaks, types) => {
  if (!schoolBreaks) {
    return false;
  }
  let valid = false;
  let invalidCounter = 0;

  types.forEach(type => {
    const validType =
      moment(schoolBreaks[type].start).isValid() && moment(schoolBreaks[type].end).isValid();
    if (!validType) {
      invalidCounter++;
    }
  });
  if (invalidCounter > 0) {
    valid = false;
  } else {
    valid = true;
  }
  return valid;
};

export const isIncludedInSchoolBreaks = (startDate, schoolBreaks) => {
  if (!startDate) {
    return false;
  }
  const isValid = moment(startDate).isValid();
  if (!isValid) {
    return false;
  }

  const endDate = moment(startDate).clone().add(1, 'days');

  const isFallingInThanksGiving =
    moment(startDate).isSameOrAfter(schoolBreaks.thanksgiving.start, 'days') &&
    endDate.isSameOrBefore(schoolBreaks.thanksgiving.end, 'days');
  const isFallingInChristmas =
    moment(startDate).isSameOrAfter(schoolBreaks.christmas.start, 'days') &&
    endDate.isSameOrBefore(schoolBreaks.christmas.end, 'days');
  const isFallingInNewYear =
    moment(startDate).isSameOrAfter(schoolBreaks.newyear.start, 'days') &&
    endDate.isSameOrBefore(schoolBreaks.newyear.end, 'days');
  //const isFallingInSpringBreak = startDate.isSameOrAfter(schoolBreaks.spring.start, "days") && endDate.isSameOrBefore(schoolBreaks.spring.end, "days");
  //const isFallingInSummerBreak = startDate.isSameOrAfter(schoolBreaks.summer.start, "days") && endDate.isSameOrBefore(schoolBreaks.summer.end, "days");

  return isFallingInThanksGiving || isFallingInChristmas || isFallingInNewYear;
  //isFallingInSpringBreak ||
  //isFallingInSummerBreak);
};

export const areAllWeekendsOfTheMonthInSchoolbreaks = (month, schoolBreaks, firstPopulatedDate) => {
  const weekends = getAllWeekendsOfTheMonth(month, firstPopulatedDate);
  const first = weekends[0];
  const second = weekends[1];
  const third = weekends[2];
  const fourth = weekends[3];
  const fifth = weekends[4];

  const rv =
    isIncludedInSchoolBreaks(first, schoolBreaks) &&
    isIncludedInSchoolBreaks(second, schoolBreaks) &&
    isIncludedInSchoolBreaks(third, schoolBreaks) &&
    isIncludedInSchoolBreaks(fourth, schoolBreaks) &&
    (fifth ? isIncludedInSchoolBreaks(fifth, schoolBreaks) : true);
  return rv;
};

export function getAllValidWeekendsOfTheMonth(
  monthIndex,
  schoolBreaks,
  firstPopulatedDate,
  lastPopulatedDate,
) {
  monthIndex = Number(monthIndex);
  let allWeekendsOfTheMonth = [];
  const schoolYear = getCurrentSchoolYear(
    moment(lastPopulatedDate).add(1, 'day'),
    moment(lastPopulatedDate).add(1, 'year'),
    false,
    true,
  );
  const weekends = getAllWeekendsOfTheMonth(monthIndex + 1, firstPopulatedDate);
  for (let i = 0; i < 5; i++) {
    if (weekends[i]) {
      if (
        !isIncludedInSchoolBreaks(weekends[i], schoolBreaks) &&
        !isClashingWithMothersOrFathersDay(weekends[i], schoolYear)
      ) {
        const lastDateOfCalendar = moment(schoolBreaks['summer'].end).endOf('month');
        const next = moment(weekends[i]).clone().add(2, 'days');
        if (!weekends[i].isAfter(lastDateOfCalendar)) {
          allWeekendsOfTheMonth.push({
            day1: moment(weekends[i]),
            day3: moment(next),
          });
        } else {
          break;
        }
      }
    }
  }
  return allWeekendsOfTheMonth;
}

export const isClashingWithMothersOrFathersDay = (startDate, schoolYear) => {
  const endDate = moment(startDate).add(2, 'days');
  const midDate = moment(startDate).add(1, 'days');
  const year = schoolYear.split('/')[1];

  return (
    startDate.isSame(fathersDay(year)) ||
    startDate.isSame(mothersDay(year)) ||
    midDate.isSame(fathersDay(year)) ||
    midDate.isSame(mothersDay(year)) ||
    endDate.isSame(fathersDay(year)) ||
    endDate.isSame(mothersDay(year))
  );
};

export const isValidAlternateWeekendsPossession = startDate => {
  if (!startDate) {
    return [false, 'Invalid', 'Invalid input date'];
  }
  const isValid = moment(startDate).isValid();
  if (!isValid) {
    return [false, 'Invalid', 'Invalid date'];
  }
  const date = moment(startDate);
  const day = date.weekday();
  if (day !== 5 && day !== 6 && day !== 7) {
    return [false, date.format('MMMM'), 'Not a weekend'];
  }
  return [true, date.format('MMMM'), 'correct date'];
};

export const isValidSchoolBreakDate = (startDate, endDate) => {
  if (!startDate || !endDate) {
    return [false, 'Invalid date'];
  }
  const isValid = moment(startDate).isValid() && moment(endDate).isValid();
  const isStartDateBeforeEndDate = moment(startDate).isSameOrBefore(endDate, 'day');
  if (!isValid) {
    return [false, 'Invalid date'];
  } else if (!isStartDateBeforeEndDate) {
    return [false, 'Start date must be before end date'];
  } else {
    return [true, null];
  }
};

export const validAlternateWeekendsPossessionDatesRanges = data => {
  // const { start, month } = data;
  const start = moment(data.start);
  const month = moment(data.month);
  if (month) {
    const templateStartDate = moment().clone().startOf('year');
    const earliestDate = templateStartDate.clone().add(month - 1, 'month');
    const earlyday = earliestDate.weekday();
    if (earlyday < 7) {
      earliestDate.clone().add(7 - earlyday, 'days');
    }
    const templateEndDate = moment().clone().endOf('year');
    const latemostDate = templateEndDate.clone().endOf(12 - month, 'month');
    const lateday = latemostDate.weekday();
    if (lateday < 7) {
      latemostDate.clone().subtract(lateday, 'days');
    }
    return {
      start: {
        min: earliestDate.clone(),
        max: latemostDate.clone(),
      },
      end: {
        min: start ? start.clone().add(1, 'days') : undefined,
        max: start ? start.clone().add(1, 'days') : undefined,
      },
    };
  }
};

export const validSchoolBreakDatesRanges = data => {
  const { type, templateSchoolBreak } = data;
  const start = moment(data.start);
  if (type === SCHOOL_BREAK_THANKSGIVING && templateSchoolBreak) {
    const templateStartDate = moment(templateSchoolBreak.start);
    const earliestDate = templateStartDate.clone().startOf('month');
    // const latestDate = templateStartDate.clone().add(1, 'month').date(14);

    return {
      start: {
        min: earliestDate.clone(),
        max: start ? start.clone().month(11).date(8) : undefined,
      },
      end: {
        min: start ? start.clone().add(2, 'days') : undefined,
        // max: start ? moment.min([start.clone().add(20, 'days'), latestDate.clone()]) : undefined,
        max: start ? start.clone().month(11).date(10) : undefined,
      },
    };
  } else if (type === SCHOOL_BREAK_CHRISTMAS_NEW_YEAR && templateSchoolBreak) {
    const templateStartDate = moment(templateSchoolBreak.start);
    const earliestDate = templateStartDate.clone().date(11);
    const latestStartDate = templateStartDate.clone().date(29);
    const templateEndDate = moment(templateSchoolBreak.end);
    return {
      start: {
        min: earliestDate.clone(),
        max: latestStartDate.clone().subtract(2, 'days'),
      },
      end: {
        min: start ? templateStartDate.clone().date(28) : undefined,
        max: templateEndDate.clone().endOf('month'),
      },
    };
  } else if (type === SCHOOL_BREAK_CHRISTMAS && templateSchoolBreak) {
    const templateStartDate = moment(templateSchoolBreak.start);
    const earliestDate = templateStartDate.clone().date(15);
    const latestDate = templateStartDate.clone().date(29);
    return {
      start: {
        min: earliestDate.clone(),
        max: latestDate.clone().subtract(3, 'days'),
      },
      end: {
        min: start ? start.clone().date(27) : undefined,
        max: latestDate.clone().date(27),
        // max: start
        //   ? moment.min([start.clone().add(13, "days"), latestDate.clone()])
        //   : undefined,
      },
    };
  } else if (type === SCHOOL_BREAK_NEWYEAR && templateSchoolBreak) {
    const templateEndDate = moment(templateSchoolBreak.end);
    const templateStartDate = moment(templateSchoolBreak.start);
    const latestDate = templateEndDate.clone().date(14);
    const earliestDate = templateStartDate.clone().date(11);
    return {
      start: {
        min: earliestDate.clone(),
        max: start
          ? moment.min([earliestDate.clone().add(12, 'days'), latestDate.clone()])
          : undefined,
      },
      end: {
        min: start ? start.clone().add(1, 'days') : undefined,
        max: start ? moment.min([start.clone().add(20, 'days'), latestDate.clone()]) : undefined,
      },
    };
  } else if (type === SCHOOL_BREAK_SPRING && templateSchoolBreak) {
    const templateStartDate = moment(templateSchoolBreak.start);
    const templateEndDate = moment(templateSchoolBreak.end);
    const earliestDate = templateStartDate.clone().month(1).startOf('month');
    return {
      start: {
        min: earliestDate.clone(),
        max: templateEndDate.clone().month(3).endOf('month').subtract(2, 'days'),
      },
      end: {
        min: start ? start.clone().add(2, 'days') : undefined,
        max: templateEndDate.clone().month(3).endOf('month'),
      },
    };
  } else if (type === SCHOOL_BREAK_SUMMER && templateSchoolBreak) {
    const templateStartDate = moment(templateSchoolBreak.start);
    const templateEndDate = moment(templateSchoolBreak.end);
    const earliestDate = templateStartDate.clone().month(4).date(1);
    const latestDate = templateEndDate.clone().endOf('month');
    const minEndDate = latestDate.clone().date(8); //the earliest end-date for summer-break is 8th of aug due to summer-possessions
    return {
      start: {
        min: earliestDate.clone(),
        max: minEndDate.clone().subtract(30, 'days'),
      },
      end: {
        min: start ? minEndDate : undefined,
        max: start ? latestDate.clone() : undefined,
      },
    };
  } else {
    return {
      start: {
        min: undefined,
        max: undefined,
      },
      end: {
        min: undefined,
        max: undefined,
      },
    };
  }
};

export const validPrimarySummerSessionDates = data => {
  const { schoolBreaks, primaryFirstStart, primaryFirstEnd, primarySecondStart, primarySecondEnd } =
    data;

  let totalDaysFirstRange = 0;
  let totalDaysSecondRange = 0;
  let firstStartDate = { min: null, max: null, disabled: true };
  let firstEndDate = { min: null, max: null, disabled: true };
  let secondStartDate = { min: null, max: null, disabled: true };
  let secondEndDate = { min: null, max: null, disabled: true };
  let summerBreakStart = null;
  let summerBreakLastStartDate = null;
  let summerBreakLastEndDate = null;

  if (primaryFirstStart && primaryFirstEnd) {
    totalDaysFirstRange = primaryFirstEnd.diff(primaryFirstStart, 'days') + 1;
  }

  if (primarySecondStart && primarySecondEnd) {
    totalDaysSecondRange = primarySecondEnd.diff(primarySecondStart, 'days') + 1;
  }

  if (schoolBreaks) {
    summerBreakStart = moment(schoolBreaks.summer.start).startOf('day');
    summerBreakLastStartDate = moment(schoolBreaks.summer.end).subtract(13, 'days').endOf('day');
    summerBreakLastEndDate = moment(schoolBreaks.summer.end).subtract(7, 'days').endOf('day');
  }

  if (summerBreakStart && summerBreakLastStartDate) {
    firstStartDate.min = summerBreakStart;
    firstStartDate.max = summerBreakLastStartDate;
    firstStartDate.disabled = false;
  }

  if (primaryFirstStart && summerBreakLastEndDate) {
    firstEndDate.min = primaryFirstStart.clone().add(6, 'days').startOf('day');
    firstEndDate.max = moment.min([
      summerBreakLastEndDate,
      primaryFirstStart.clone().add(30, 'days').endOf('day'),
    ]);
    firstEndDate.disabled = false;
  }

  if (primaryFirstEnd && summerBreakLastStartDate) {
    secondStartDate.min = primaryFirstEnd.clone().add(1, 'days').startOf('day');
    secondStartDate.max = summerBreakLastStartDate;
    secondStartDate.disabled =
      totalDaysFirstRange > 24 || primaryFirstEnd.isSameOrAfter(summerBreakLastStartDate, 'days');
  }

  if (primarySecondStart && summerBreakLastEndDate) {
    secondEndDate.min = primarySecondStart.clone().add(6, 'days').startOf('day');
    secondEndDate.max = moment.min([
      summerBreakLastEndDate,
      primarySecondStart
        .clone()
        //.add(30 - totalDaysFirstRange, "days")
        .add(48 - totalDaysFirstRange, 'days')
        .endOf('day'),
    ]);
    secondEndDate.disabled = false;
  }

  return {
    totalDaysFirstRange,
    totalDaysSecondRange,
    firstStartDate,
    firstEndDate,
    secondStartDate,
    secondEndDate,
  };
};

export const validSecondarySummerSessionDates = data => {
  const {
    schoolBreaks,
    secondaryFirstStart,
    secondaryFirstEnd,
    secondarySecondStart,
    secondarySecondEnd,
    withinDistance,
    isSecondaryMother,
  } = data;
  let maxRange = withinDistance ? (secondarySecondStart ? 32 : 31) : secondarySecondStart ? 43 : 42;
  let totalDaysFirstRange = 0;
  let totalDaysSecondRange = 0;
  let firstStartDate = { min: null, max: null, disabled: true };
  let firstEndDate = { min: null, max: null, disabled: true };
  let secondStartDate = { min: null, max: null, disabled: true };
  let secondEndDate = { min: null, max: null, disabled: true };
  let summerBreakStart = null;
  let summerBreakLastStartDate = null;
  let summerBreakLastEndDate = null;

  if (secondaryFirstStart && secondaryFirstEnd) {
    totalDaysFirstRange = secondaryFirstEnd.diff(secondaryFirstStart, 'days');
  }

  if (secondarySecondStart && secondarySecondEnd) {
    totalDaysSecondRange = secondarySecondEnd.diff(secondarySecondStart, 'days');
  }

  if (schoolBreaks) {
    summerBreakStart = moment(schoolBreaks.summer.start).startOf('day').add(1, 'days');
    summerBreakLastStartDate = moment(schoolBreaks.summer.end).subtract(13, 'days').endOf('day');
    summerBreakLastEndDate = moment(schoolBreaks.summer.end).subtract(7, 'days').endOf('day');
  }

  if (summerBreakStart && summerBreakLastStartDate) {
    firstStartDate.min = summerBreakStart;
    firstStartDate.max = summerBreakLastStartDate;
    firstStartDate.disabled = false;
  }

  if (secondaryFirstStart && summerBreakLastEndDate) {
    if (isSecondaryMother) {
      firstEndDate.min = secondaryFirstStart.clone().add(4, 'days').startOf('day');
    } else {
      firstEndDate.min = secondaryFirstStart.clone().add(6, 'days').startOf('day');
    }
    firstEndDate.max = moment.min([
      summerBreakLastEndDate,
      secondaryFirstStart.clone().add(maxRange, 'days').endOf('day'),
    ]);
    firstEndDate.disabled = false;
  }

  if (secondaryFirstEnd && summerBreakLastStartDate) {
    secondStartDate.min = secondaryFirstEnd.clone().add(1, 'days').startOf('day');
    secondStartDate.max = summerBreakLastStartDate;
    secondStartDate.disabled =
      totalDaysFirstRange > maxRange - 6 ||
      secondaryFirstEnd.isSameOrAfter(summerBreakLastStartDate, 'days');
  }

  if (secondarySecondStart && summerBreakLastEndDate) {
    secondEndDate.min = secondarySecondStart.clone().add(6, 'days').startOf('day');
    secondEndDate.max = moment.min([
      summerBreakLastEndDate,
      secondarySecondStart
        .clone()
        //.add(30 - totalDaysFirstRange, "days")
        .add(maxRange - totalDaysFirstRange - 1, 'days')
        .endOf('day'),
    ]);
    secondEndDate.disabled = false;
  }
  return {
    totalDaysFirstRange,
    totalDaysSecondRange,
    firstStartDate,
    firstEndDate,
    secondStartDate,
    secondEndDate,
  };
};

export const shouldDisableSecondaryStartDate =
  ({ fathersDay, isSecondaryMother }) =>
  date => {
    // never disable if secondary is not mother
    if (!isSecondaryMother) {
      return false;
    }

    // disable father's day
    if (moment(fathersDay).isSame(moment(date), 'day')) {
      return true;
    }

    // disable 7 days before father's day
    const fathersDayDiffToDate = moment(fathersDay).diff(date, 'day');
    if (fathersDayDiffToDate < 7 && fathersDayDiffToDate > 0) {
      return true;
    }

    return false;
  };

export function shouldDisableSecondaryEndDate(fathersDay, isSecondaryMother, date) {
  // always return true if secondary is not mother
  if (!isSecondaryMother) {
    return false;
  }

  // disable father's day
  if (moment(fathersDay).isSame(moment(date), 'day')) {
    return true;
  }

  // if father's day is after minimum date disable 7 days after father's day
  const fathersDayDiffToDate = moment(fathersDay).diff(moment(date), 'day');
  if (fathersDayDiffToDate < 0 && fathersDayDiffToDate > -7) {
    return true;
  }

  return false;
}

export function shouldDisablePrimaryDate(fathersDay, isPrimaryMother, date) {
  // always return true if secondary is not mother
  if (!isPrimaryMother) {
    return false;
  }

  // disable father's day
  if (moment(fathersDay).isSame(moment(date), 'day')) {
    return true;
  }
  return false;
}

export const validPrimarySummerSessionRange1 = data => {
  const { secondaryFirstStart, secondaryFirstEnd, secondarySecondStart, secondarySecondEnd } = data;

  let primaryRange1List = [];

  if (secondaryFirstStart && secondaryFirstEnd) {
    const start = secondaryFirstStart.clone();
    const end = secondaryFirstEnd.clone();
    const weekends = findWeekendBetweenDates({ start, end });
    primaryRange1List = primaryRange1List.concat(weekends);
  }

  if (secondarySecondStart && secondarySecondEnd) {
    const start = secondarySecondStart.clone();
    const end = secondarySecondEnd.clone();
    const weekends = findWeekendBetweenDates({ start, end });
    primaryRange1List = primaryRange1List.concat(weekends);
  }

  return primaryRange1List;
};

export const validPrimarySummerSessionRange2 = data => {
  const {
    schoolBreaks,
    secondaryFirstStart,
    secondaryFirstEnd,
    secondarySecondStart,
    secondarySecondEnd,
    dates,
  } = data;

  let primaryRange2List = [];
  const secondDateRange = [];

  if (!dates) {
    return primaryRange2List;
  }

  //Find the ranges in summer period where secondary has NOT choosen as his/hers summer possessions
  if (
    schoolBreaks &&
    secondaryFirstStart &&
    secondaryFirstEnd &&
    secondarySecondStart &&
    secondarySecondEnd
  ) {
    secondDateRange.push({
      start: moment(schoolBreaks.summer.start).startOf('day'),
      end: secondaryFirstStart.clone().subtract(1, 'days'),
    });
    secondDateRange.push({
      start: secondaryFirstEnd.clone().add(1, 'days'),
      end: secondarySecondStart.clone().subtract(1, 'days'),
    });
    secondDateRange.push({
      start: secondarySecondEnd.clone(),
      end: moment(schoolBreaks.summer.end),
    });
  } else if (
    schoolBreaks &&
    secondaryFirstStart &&
    secondaryFirstEnd &&
    !secondarySecondStart &&
    !secondarySecondEnd
  ) {
    secondDateRange.push({
      start: moment(schoolBreaks.summer.start).startOf('day'),
      end: secondaryFirstStart.clone().subtract(1, 'days'),
    });
    secondDateRange.push({
      start: secondaryFirstEnd.clone(),
      end: moment(schoolBreaks.summer.end),
    });
  }

  //Add all weekends found above into list of available weekends for primary weekend 2
  for (let i = 0; i < secondDateRange.length; i++) {
    const start = secondDateRange[i].start.clone();
    const end = secondDateRange[i].end.clone();
    const weekends = findWeekendBetweenDates({ start, end });
    primaryRange2List = primaryRange2List.concat(weekends);
  }

  //Remove any weekend falling too close (min 7 days) from end of summer period
  if (schoolBreaks) {
    const summerBreakLastEndDate = moment(schoolBreaks.summer.end).subtract(7, 'days').endOf('day');

    primaryRange2List = primaryRange2List.filter(range => {
      const { end } = range;
      const isSundayAfterLastAllowedDate = end.clone().endOf('day').isAfter(summerBreakLastEndDate);
      return !isSundayAfterLastAllowedDate;
    });
  }

  //Remove any weekend not beloning to secondary, ie Friday OR Sunday must be owned by Secondary
  primaryRange2List = primaryRange2List.filter(range => {
    const { start } = range;
    const fridayOwner = dates[start.format('YYYY-MM-DD')].owner;
    const saturday = start.clone().add(1, 'days');
    const saturdayOwner = dates[saturday.format('YYYY-MM-DD')].owner;
    const weekendHasSecondary = fridayOwner === 2 || saturdayOwner === 2;
    return weekendHasSecondary;
  });

  return primaryRange2List;
};

export const findWeekendBetweenDates = data => {
  const { start, end } = data;
  const weekends = [];

  const a = start.clone();
  const b = end.clone();
  for (let d = a; d.diff(b, 'days') <= 0; d.add(1, 'days')) {
    const addTwoDays = d.clone().add(2, 'days');
    const isFriday = d.isoWeekday() === 5;
    const isSunday = addTwoDays.isoWeekday() === 7;
    const isInsideRange = b.isSameOrAfter(addTwoDays);
    if (isFriday && isSunday && isInsideRange) {
      weekends.push({ start: d.clone(), end: addTwoDays });
    }
  }

  return weekends;
};
