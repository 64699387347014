import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  loaderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '80vh',
  },
  formControl: {
    marginBottom: theme.spacing(2),
  },
  promoText: {
    color: 'red',
    fontWeight: '600',
    marginTop: '-6px',
  },
}));
