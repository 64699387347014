import moment from 'moment';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import Account from './Account/Account';
import Admin from './Admin/Admin';
import Calendars from './Calenders/Calenders';
import Checkins from './Checkins/Checkins';
import Drawer from './Drawer.jsx';
import IncompleteCalendars from './Incomplete/Incomplete';
import Shared from './Shared/Calenders';

export default function Routes({ match }) {
  return (
    <>
      <Drawer match={match}>
        <Switch>
          <Route path={`${match.path}/account`} exact component={Account}></Route>
          <Route
            path={`${match.path}/calendars/:calendarDate(20[0-9]{2}-[0-1]{1}[0-9]{1})`}
            exact
            component={Calendars}
          />
          <Route
            path={`${match.path}/shared/:calendarDate(20[0-9]{2}-[0-1]{1}[0-9]{1})`}
            exact
            component={Shared}
          />
          <Route path={`${match.path}/checkins`} exact component={Checkins}></Route>
          <Route path={`${match.path}/draft`} exact component={IncompleteCalendars}></Route>
          <Route path={`${match.path}/admin`} exact component={Admin}></Route>
          <Redirect to={`${match.path}/calendars/${moment().format('YYYY-MM')}`} exact />
        </Switch>
      </Drawer>
    </>
  );
}
