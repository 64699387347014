import React from 'react';

function Logo() {
  return (
    <svg viewBox="0 0 1187 1141" xmlns="http://www.w3.org/2000/svg">
      <g fillRule="nonzero" fill="none">
        <path
          d="M0 288v760.28C0 1099.4 41.6 1141 92.73 1141h1001.54c51.13 0 92.73-41.6 92.73-92.72V288H0z"
          fill="#C4C4C4"
        />
        <path
          d="M1094.27 28H92.73C41.6 28 0 69.66 0 120.86V288h1187V120.86c0-51.2-41.6-92.86-92.73-92.86zm-945.9 185.71A55.68 55.68 0 0192.73 158a55.68 55.68 0 0155.64-55.71A55.68 55.68 0 01204.03 158a55.68 55.68 0 01-55.65 55.71zm890.25 0A55.68 55.68 0 01982.99 158a55.68 55.68 0 0155.64-55.71 55.68 55.68 0 0155.65 55.71 55.68 55.68 0 01-55.64 55.71z"
          fill="#1F9CA3"
        />
        <g fill="#FFF">
          <path d="M594 455c-40.9 0-74.17 33.25-74.17 74.14 0 40.9 33.27 74.15 74.17 74.15s74.17-33.26 74.17-74.15c0-40.89-33.27-74.14-74.17-74.14zM408.58 455c-40.9 0-74.16 33.25-74.16 74.14 0 40.9 33.26 74.15 74.16 74.15s74.17-33.26 74.17-74.15c0-40.89-33.26-74.14-74.17-74.14zM223.17 455c-40.9 0-74.17 33.25-74.17 74.14 0 40.9 33.26 74.15 74.17 74.15 40.9 0 74.16-33.26 74.16-74.15 0-40.89-33.26-74.14-74.16-74.14zM779.42 455c-40.9 0-74.17 33.25-74.17 74.14 0 40.9 33.26 74.15 74.17 74.15 40.9 0 74.16-33.26 74.16-74.15 0-40.89-33.26-74.14-74.16-74.14zM964.83 603.29c40.9 0 74.17-33.26 74.17-74.15 0-40.89-33.26-74.14-74.17-74.14-40.9 0-74.16 33.25-74.16 74.14 0 40.9 33.26 74.15 74.16 74.15zM594 640.36c-40.9 0-74.17 33.25-74.17 74.14s33.27 74.14 74.17 74.14 74.17-33.25 74.17-74.14-33.27-74.14-74.17-74.14zM408.58 640.36c-40.9 0-74.16 33.25-74.16 74.14s33.26 74.14 74.16 74.14 74.17-33.25 74.17-74.14-33.26-74.14-74.17-74.14zM223.17 640.36c-40.9 0-74.17 33.25-74.17 74.14s33.26 74.14 74.17 74.14c40.9 0 74.16-33.25 74.16-74.14s-33.26-74.14-74.16-74.14zM779.42 640.36c-40.9 0-74.17 33.25-74.17 74.14s33.26 74.14 74.17 74.14c40.9 0 74.16-33.25 74.16-74.14s-33.26-74.14-74.16-74.14zM964.83 640.36c-40.9 0-74.16 33.25-74.16 74.14s33.26 74.14 74.16 74.14S1039 755.4 1039 714.5s-33.26-74.14-74.17-74.14zM594 825.71c-40.9 0-74.17 33.26-74.17 74.15 0 40.89 33.27 74.14 74.17 74.14s74.17-33.25 74.17-74.14c0-40.9-33.27-74.15-74.17-74.15zM408.58 825.71c-40.9 0-74.16 33.26-74.16 74.15 0 40.89 33.26 74.14 74.16 74.14s74.17-33.25 74.17-74.14c0-40.9-33.26-74.15-74.17-74.15zM223.17 825.71c-40.9 0-74.17 33.26-74.17 74.15 0 40.89 33.26 74.14 74.17 74.14 40.9 0 74.16-33.25 74.16-74.14 0-40.9-33.26-74.15-74.16-74.15zM779.42 825.71c-40.9 0-74.17 33.26-74.17 74.15 0 40.89 33.26 74.14 74.17 74.14 40.9 0 74.16-33.25 74.16-74.14 0-40.9-33.26-74.15-74.16-74.15zM964.83 825.71c-40.9 0-74.16 33.26-74.16 74.15 0 40.89 33.26 74.14 74.16 74.14s74.17-33.25 74.17-74.14c0-40.9-33.26-74.15-74.17-74.15z" />
        </g>
        <path
          d="M148 186c-15.46 0-28-12.49-28-27.9V27.9A27.95 27.95 0 01148 0c15.46 0 28 12.49 28 27.9v130.2a27.95 27.95 0 01-28 27.9zM1039 186c-15.46 0-28-12.49-28-27.9V27.9A27.95 27.95 0 011039 0c15.46 0 28 12.49 28 27.9v130.2a27.95 27.95 0 01-28 27.9z"
          fill="#5C546A"
        />
        <circle fill="#FFCD66" cx="223" cy="529" r="74" />
        <circle fill="#3A516E" cx="594" cy="900" r="74" />
        <circle fill="#FF7A78" cx="779" cy="714" r="74" />
      </g>
    </svg>
  );
}

export default Logo;
