import { Box } from '@material-ui/core';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import React from 'react';

import styles from './CalendarTitle.module.css';

function CalendarTitle({ currentDate, datesIds, onChangeCurrentDate, calendarCreatedDate }) {
  const minDate = calendarCreatedDate;
  const maxDate = datesIds[datesIds.length - 1];

  const onDateChange = value => {
    if (moment(value).format('YYYY-MM') !== moment(currentDate).format('YYYY-MM')) {
      onChangeCurrentDate(moment(value));
    }
  };
  return (
    <Box style={{ maxWidth: '166px' }} className={styles.calendar_title_wrapper}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          views={['year', 'month']}
          // format='YYYY'
          openTo="month"
          minDate={new Date(moment(minDate).format('YYYY-MM'))}
          maxDate={new Date(maxDate)}
          value={new Date(currentDate)}
          onAccept={newValue => {
            onDateChange(newValue);
          }}
          onChange={() => {}}
          renderInput={params => <TextField {...params} helperText={null} />}
        />
      </LocalizationProvider>
    </Box>
  );
}
export default CalendarTitle;
