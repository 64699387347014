import MobileStepper from '@material-ui/core/MobileStepper';
import { useTheme } from '@material-ui/core/styles';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import environment from 'environment.js';
import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import { closeModal } from 'reducers/modals';
import Button from 'shared/Button/Button';
import Typography from 'shared/Typography/Typography';
import firebase from 'utils/firebase';

import logo1 from '../../assets/img/onboarding/onboarding_1.jpg';
import logo2 from '../../assets/img/onboarding/onboarding_2.jpg';
import logo3 from '../../assets/img/onboarding/onboarding_3.jpg';
import useStyles from './Onboarding.css.jsx';

function Onboarding() {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  //console.log(environment);
  const monthsText = environment.stripe.products
    .map(product => {
      return product.months;
    })
    .join(', ');
  const priceText = environment.stripe.products
    .map(product => {
      return `${product.currency}${product.price}`;
    })
    .join(', ');

  const tutorialSteps = [
    {
      title: t('modalOnBoarding1Title'),
      subtitle: t('modalOnBoarding1Subtitle'),
      bodySection1: t('modalOnBoarding1BodySection1'),
      bodySection2: 'modalOnBoarding1BodySection2',
      imageName: 'onboarding_1.jpg',
    },
    {
      title: t('modalOnBoarding2Title'),
      subtitle: t('modalOnBoarding2Subtitle'),
      bodySection1: t('modalOnBoarding2BodySection1', {
        months: monthsText,
        prices: priceText,
      }),
      bodySection2: 'modalOnBoarding2BodySection2',
      imageName: 'onboarding_2.jpg',
    },
    {
      title: t('modalOnBoarding3Title'),
      subtitle: t('modalOnBoarding3Subtitle'),
      bodySection1: t('modalOnBoarding3BodySection1'),
      bodySection2: 'modalOnBoarding3BodySection2',
      imageName: 'onboarding_3.jpg',
    },
  ];
  const maxSteps = tutorialSteps.length;

  useEffect(() => {
    firebase.analytics().logEvent('modal_show_onboarding');
    firebase.analytics().logEvent('tutorial_begin');
  }, []);

  const handleNext = () => {
    firebase.analytics().logEvent('modal_action_onboarding_next');
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    firebase.analytics().logEvent('modal_action_onboarding_back');
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleStepChange = step => {
    setActiveStep(step);
  };

  const close = () => {
    firebase.analytics().logEvent('modal_cancel_onboarding');
    firebase.analytics().logEvent('tutorial_tutorial_complete');
    dispatch(closeModal());
  };

  return (
    <div className={classes.root}>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
      >
        {tutorialSteps.map((step, index) => (
          <div key={index}>
            <img
              className={classes.img}
              src={index === 0 ? logo1 : index === 1 ? logo2 : logo3}
              alt={step.title}
            />
            <div className={classes.content}>
              <Typography variant="h5" gutterBottom>
                {step.title}
              </Typography>
              <Typography variant="h6" gutterBottom>
                {step.subtitle}
              </Typography>
              <Typography
                variant="body1"
                dangerouslySetInnerHTML={{ __html: tutorialSteps[activeStep].bodySection1 }}
              />
              <br />
              <br />
              <Typography variant="body1">
                <Trans i18nKey={tutorialSteps[activeStep].bodySection2}>
                  <b></b>
                </Trans>
              </Typography>
            </div>
          </div>
        ))}
      </SwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="bottom"
        variant="text"
        activeStep={activeStep}
        classes={{ positionBottom: classes.positionBottom }}
        nextButton={
          activeStep === maxSteps - 1 ? (
            <Button size="small" onClick={close}>
              {t('actionDone')}
              {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </Button>
          ) : (
            <Button size="small" onClick={handleNext}>
              {t('actionNext')}
              {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </Button>
          )
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            {t('actionBack')}
          </Button>
        }
      />
    </div>
  );
}

export default Onboarding;
