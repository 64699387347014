import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    borderRadius: '5px',
  },
  purchaseButton: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  loader: {
    marginTop: theme.spacing(4),
  },
  emptyStateContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));
