import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  stepperRoot: {
    padding: 0,
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  loaderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '80vh',
  },
  loader: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
}));
