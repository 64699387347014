import { Dialog } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  MODAL_ADD_DATE_ADDITIONAL,
  MODAL_ALERT_EXTEND_CALENDAR,
  MODAL_CALENDAR_INFO,
  MODAL_CREATE_CALENDAR,
  MODAL_DELETE_USER,
  MODAL_DOWNLOAD_CALENDAR,
  MODAL_EDIT_BIRTHDAY,
  MODAL_EDIT_CALENDAR_DATE,
  MODAL_EDIT_PARENT1_NAME,
  MODAL_EDIT_PARENT2_NAME,
  MODAL_EDIT_SCHOOL_BREAK,
  MODAL_EDIT_SCHOOL_BREAK_CUSTODIAN,
  MODAL_EDIT_WEEKENDS,
  MODAL_EXTEND_CALENDAR,
  MODAL_EXTEND_OLD_CALENDAR,
  MODAL_EXTEND_SHARED_CALENDAR,
  MODAL_INFO_FATHER_DAY,
  MODAL_INFO_LAST_DAY,
  MODAL_INFO_MODIFIED_DAY,
  MODAL_INFO_MOTHER_DAY,
  MODAL_MAJOR_UPDATE_INFORMATION,
  MODAL_ONBOARDING,
  MODAL_PAYMENT,
  MODAL_PAYMENT_EXPIRED,
  MODAL_PAYMENT_EXPIRED_OWNER_CALENDAR,
  MODAL_PAYMENT_SUCCESS,
  MODAL_RESTRICTED_PREV_DATE,
  MODAL_REVIEW_DATE_ADDITIONAL,
  MODAL_SHARE,
  MODAL_SUCCESS_CREATE_CALENDAR,
  MODAL_SUCCESS_EXTEND_CALENDAR,
  MODAL_SUCCESS_MODIFY_SUMMER_SESSIONS,
  MODAL_SUMMER_SESSIONS,
  MODAL_SUMMER_SESSIONS_DATES,
  MODAL_SUMMER_SESSIONS_ERROR,
} from 'constants.js';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { closeModal } from 'reducers/modals';
import { openModal } from 'reducers/modals.js';

import AlertExtendCalendar from './AlertExtendCalendar/AlertExtendCalendar.jsx';
import AlertExtendSharedCalendar from './AlertExtendCalendar/ErrorExtendShared.jsx';
import AlertExtendOldCalendar from './AlertExtendCalendar/OldCalendar';
import CalendarInfo from './CalendarInfo/CalendarInfo.jsx';
import ConfirmationDeleteUser from './Confirmation/ConfirmationDeleteUser';
import CreateCalendar from './CreateCalendar/CreateCalendar.jsx';
import AddNewDateAdditional from './DateAdditional/AddNewDateAdditional.jsx';
import ReviewDateAdditional from './DateAdditional/ReviewDateAdditional.jsx';
import ModalDownloadPdf from './DownloadPdf/DownloadPdf.jsx';
import EditBirthday from './EditBirthday/EditBirthday.jsx';
import EditCalendarDate from './EditCalendarDate/EditCalendarDate.jsx';
import EditParentName from './EditParentName/EditParentName.jsx';
import EditSchoolBreakDate from './EditSchoolBreakDate/EditSchoolBreakDate.jsx';
import { EditSchoolBreakCustodian } from './EditSchoolBreaksCustodian/EditSchoolBreakCustodian.jsx';
import EditWeekend from './EditWeekend/EditWeekend.jsx';
import ExtendCalendar from './ExtendCalendar/ExtendCalendar.jsx';
import InformAboutLastDay from './InformAboutLastDay/InformAboutLastDay.jsx';
import InformAboutModifiedDay from './InformAboutModifiedDay/InformAboutModifiedDay.jsx';
import InformAboutParentsDay from './InformAboutParentsDay/InformAboutParentsDay.jsx';
import MajorUpdateWarning from './MajorUpdateWarning/MajorUpdateWarning';
import ModalShare from './ModalShare/ModalShare.jsx';
import useStyles from './Modals.css.jsx';
import Onboarding from './Onboarding/Onboarding.jsx';
import Payment from './Payment/Payment.jsx';
import PaymentExpired from './PaymentExpired/PaymentExpired.jsx';
import PaymentExpiredOwnerCalendar from './PaymentExpired/PaymentExpiredOwnerCalendar';
import PaymentSuccess from './PaymentSuccess/PaymentSuccess.jsx';
import RestrictedPrevModal from './RestrictedPrevDate/restrictedPrevDate.jsx';
import SuccessCreateCalendar from './SuccessCreateCalendar/SuccessCreateCalendar.jsx';
import SuccessExtendCalendar from './SuccessExtendCalendar/SuccessExtendCalendar.jsx';
import SuccessModifySummerSessions from './SuccessModifySummerSessions/SuccessModifySummerSessions.jsx';
import SummerPossessions from './SummerPossessions/SummerPossessions.jsx';
import SummerPossessionsDates from './SummerPossessionsDates/SummerPossessionsDates.jsx';
import SummerPossessionsError from './SummerPossessionsError/SummerPossessionsError.jsx';

function Modals(props) {
  const classes = useStyles();
  const theme = useTheme();
  const type = useSelector(state => state.modals.type);
  const customData = useSelector(state => state.modals.customData);
  const open = useSelector(state => state.modals.open);
  const disableClose = useSelector(state => state.modals.disableClose);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const hash = props.location.hash;

  function onCloseModal(event, reason) {
    if (disableClose && reason === 'backdropClick') {
      return false;
    }
    dispatch(closeModal());
  }
  useEffect(() => {
    if (hash === '#payment-success') {
      dispatch(openModal({ type: MODAL_PAYMENT_SUCCESS, disableClose: false }));
    } else if (hash === '#payment') {
      dispatch(openModal({ type: MODAL_PAYMENT, disableClose: false }));
    }
  }, [hash, dispatch]);

  if (!open) {
    return null;
  }

  function renderModal(type, customData) {
    if (type === MODAL_CREATE_CALENDAR) {
      return <CreateCalendar />;
    } else if (type === MODAL_EDIT_CALENDAR_DATE) {
      return <EditCalendarDate />;
    } else if (type === MODAL_EDIT_SCHOOL_BREAK) {
      return <EditSchoolBreakDate />;
    } else if (type === MODAL_EDIT_WEEKENDS) {
      return <EditWeekend />;
    } else if (type === MODAL_EXTEND_CALENDAR) {
      return <ExtendCalendar />;
    } else if (type === MODAL_SUCCESS_EXTEND_CALENDAR) {
      return <SuccessExtendCalendar />;
    } else if (type === MODAL_SUCCESS_CREATE_CALENDAR) {
      return <SuccessCreateCalendar />;
    } else if (type === MODAL_INFO_MOTHER_DAY) {
      return <InformAboutParentsDay dayName={'mother'} />;
    } else if (type === MODAL_INFO_FATHER_DAY) {
      return <InformAboutParentsDay dayName={'father'} />;
    } else if (type === MODAL_INFO_MODIFIED_DAY) {
      return <InformAboutModifiedDay />;
    } else if (type === MODAL_INFO_LAST_DAY) {
      return <InformAboutLastDay />;
    } else if (type === MODAL_EDIT_BIRTHDAY) {
      return <EditBirthday />;
    } else if (type === MODAL_ONBOARDING) {
      return <Onboarding />;
    } else if (type === MODAL_SUCCESS_MODIFY_SUMMER_SESSIONS) {
      return <SuccessModifySummerSessions />;
    } else if (type === MODAL_CALENDAR_INFO) {
      return <CalendarInfo />;
    } else if (type === MODAL_SUMMER_SESSIONS) {
      return <SummerPossessions />;
    } else if (type === MODAL_SUMMER_SESSIONS_ERROR) {
      return <SummerPossessionsError />;
    } else if (type === MODAL_PAYMENT) {
      return <Payment />;
    } else if (type === MODAL_PAYMENT_SUCCESS) {
      return <PaymentSuccess />;
    } else if (type === MODAL_PAYMENT_EXPIRED) {
      return <PaymentExpired />;
    } else if (type === MODAL_PAYMENT_EXPIRED_OWNER_CALENDAR) {
      return <PaymentExpiredOwnerCalendar />;
    } else if (type === MODAL_RESTRICTED_PREV_DATE) {
      return <RestrictedPrevModal />;
    } else if (type === MODAL_SUMMER_SESSIONS_DATES) {
      return <SummerPossessionsDates {...customData} />;
    } else if (type === MODAL_ALERT_EXTEND_CALENDAR) {
      //NOTE: show warning if trying to extend calendar before 1st July for the current year!
      const nowDate = moment();
      const lastPopulatedDate = moment(customData);
      const earliestAllowedDate = lastPopulatedDate.clone().month(6).date(1); //1st of July for the year of lastPopulatedDate
      if (nowDate.isBefore(earliestAllowedDate)) {
        return <AlertExtendCalendar warning={true} />;
      } else {
        return <AlertExtendCalendar warning={false} />;
      }
    } else if (type === MODAL_DELETE_USER) {
      return <ConfirmationDeleteUser />;
    } else if (type === MODAL_EDIT_SCHOOL_BREAK_CUSTODIAN) {
      return <EditSchoolBreakCustodian />;
    } else if (type === MODAL_ADD_DATE_ADDITIONAL) {
      return <AddNewDateAdditional dayDate={customData} />;
    } else if (type === MODAL_REVIEW_DATE_ADDITIONAL) {
      return <ReviewDateAdditional dayDate={customData} />;
    } else if (type === MODAL_SHARE) {
      return <ModalShare />;
    } else if (type === MODAL_EDIT_PARENT1_NAME) {
      return <EditParentName />;
    } else if (type === MODAL_EDIT_PARENT2_NAME) {
      return <EditParentName />;
    } else if (type === MODAL_DOWNLOAD_CALENDAR) {
      return <ModalDownloadPdf />;
    } else if (type === MODAL_EXTEND_OLD_CALENDAR) {
      return <AlertExtendOldCalendar />;
    } else if (type === MODAL_MAJOR_UPDATE_INFORMATION) {
      return <MajorUpdateWarning />;
    } else if (type === MODAL_EXTEND_SHARED_CALENDAR) {
      return <AlertExtendSharedCalendar />;
    }
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      classes={{ paper: classes.dialogRoot }}
      open={open}
      fullWidth
      onClose={onCloseModal}
      aria-labelledby="form-dialog-title"
      scroll="paper"
      disableEnforceFocus={true}
      disableEscapeKeyDown={disableClose}
    >
      {renderModal(type, customData)}
    </Dialog>
  );
}

export default withRouter(Modals);
