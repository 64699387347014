import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    color: '#3a516e',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: 'linear-gradient(-26deg, #ffcd6680 0%, #3a516e51 70%, #3a516eaa 90%);',
  },
  icon: {
    fontSize: '10rem',
  },
  title: {
    marginBottom: theme.spacing(2),
    maxWidth: '360px',
  },
  titleRoot: {
    fontWeight: 600,
  },
  subtitle: {
    marginBottom: theme.spacing(3),
    maxWidth: '360px',
  },
}));
