import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { closeModal } from 'reducers/modals';
import Button from 'shared/Button/Button';
import firebase from 'utils/firebase';

import ModalContent from '../ModalContent/ModalContent';

export default function InformAboutParentsDay({ dayName }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    firebase.analytics().logEvent('modal_show_inform_about_parents_day');
  }, []);

  function onCloseModal() {
    firebase.analytics().logEvent('modal_cancel_inform_about_parents_day');
    dispatch(closeModal());
  }

  return (
    <>
      <ModalContent
        title={t(dayName === 'father' ? 'fathersDayTitle' : 'mothersDayTitle')}
        subtitle={t(dayName === 'father' ? 'fathersDayInfo' : 'mothersDayInfo')}
        actions={
          <Button onClick={onCloseModal} color="primary">
            {t('actionOk')}
          </Button>
        }
      />
    </>
  );
}
