import { addSnackbar } from 'reducers/snackbar';

export const PAYMENTS_START = 'payment/PAYMENTS_START';
export const PAYMENTS_DONE = 'payment/PAYMENTS_DONE';
export const PAYMENTS_ERROR = 'payment/PAYMENTS_ERROR';

export const GET_PRODUCTS_START = 'payment/GET_PRODUCTS_START';
export const GET_PRODUCTS_DONE = 'payment/GET_PRODUCTS_DONE';
export const GET_PRODUCTS_ERROR = 'payment/GET_PRODUCTS_ERROR';

const initialState = {
  products: {
    loading: false,
    list: [],
    error: null,
  },
  payment: {
    list: [],
    loading: false,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCTS_START: {
      return {
        ...state,
        products: {
          ...state.products,
          loading: true,
          list: [],
          error: null,
        },
      };
    }
    case GET_PRODUCTS_DONE: {
      return {
        ...state,
        products: {
          ...state.products,
          loading: false,
          list: action.products,
          error: null,
        },
      };
    }
    case GET_PRODUCTS_ERROR: {
      return {
        ...state,
        products: {
          ...state.products,
          loading: false,
          list: [],
          error: action.error,
        },
      };
    }

    case PAYMENTS_START: {
      return {
        ...state,
        payment: {
          ...state.payment,
          list: [],
          loading: true,
          error: null,
        },
      };
    }
    case PAYMENTS_DONE: {
      return {
        ...state,
        payment: {
          ...state.payment,
          list: action.list,
          loading: false,
          error: null,
        },
      };
    }
    case PAYMENTS_ERROR: {
      return {
        ...state,
        payment: {
          ...state.payment,
          list: [],
          loading: false,
          error: action.error,
        },
      };
    }

    default: {
      return { ...state };
    }
  }
};

export const getPayments =
  () =>
  async (dispatch, getState, { firebase }) => {
    dispatch({ type: PAYMENTS_START });
    const getPay = firebase.functions().httpsCallable('stripeGetPaymentIntentsV2');

    try {
      const response = await getPay({ limit: 20 });
      dispatch({ type: PAYMENTS_DONE, list: response.data });
    } catch (error) {
      dispatch({ type: PAYMENTS_ERROR, error });
    }
  };

export const getProducts =
  () =>
  async (dispatch, getState, { firebase }) => {
    dispatch({ type: GET_PRODUCTS_START });
    const db = firebase.firestore();

    try {
      let products = [];
      const repsonse = await db.collection('products').get();
      repsonse.forEach(doc => {
        products.push({
          id: doc.id,
          ...doc.data(),
        });
      });

      dispatch({ type: GET_PRODUCTS_DONE, products });
    } catch (error) {
      console.error(JSON.stringify(error));
      dispatch(
        addSnackbar({
          text: `${error.message}`,
          variant: 'error',
          closeButton: true,
        }),
      );
      dispatch({ type: GET_PRODUCTS_ERROR, error });
    }
  };
