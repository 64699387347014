import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import RotationCalendarStartingDate from 'shared/Forms/EditCalendar/RotationCalendarStartingDate/index.jsx';
import Icon from 'shared/Icons/Icons.jsx';

import { PARENT_STARTING_VISITATION } from '../../../../constants.js';
import useStyles from './ParentStartingDay.css.jsx';

const ParentStartingDay = props => {
  const { setParentStartingDay, parentStartingDay, startingDateValue, setStartingDateValue } =
    props;
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box className={classes.root}>
      <Box>
        <Box className={classes.dateBox}>
          <Box className={classes.dateBox_icon}>
            <Icon icon={'calendar-day'} size={'xl'} color={'rgba(0, 0, 0, 0.87)'} />
            <Typography className={classes.dateBox_icon_title}>{t('startingDate')}</Typography>
          </Box>
          <RotationCalendarStartingDate
            inputStyle={{ margin: 0 }}
            onChange={setStartingDateValue}
            date={startingDateValue}
            firstPopulatedDate={moment().startOf('month').format()}
            // lastPopulatedDate={moment().add(1, 'year').month(7).endOf('month').format()}
          />
          {/* <Box className={classes.dateBox_date}>
            {moment().startOf('month').format('MMM DD, YYYY')}
          </Box> */}
        </Box>
        <Box>
          <Box className={classes.subtitle}>{t('startingDateExplanation')}</Box>
        </Box>
      </Box>

      <Box>
        <FormControl fullWidth className={classes.formControl} variant="outlined">
          <FormLabel id="demo-row-radio-buttons-group-label" className={classes.formLabel}>
            {t('parentStartingVisitation')}
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            defaultValue={parentStartingDay}
            value={parentStartingDay}
            onChange={event => setParentStartingDay(Number(event.target.value))}
          >
            {PARENT_STARTING_VISITATION.map(value => (
              <FormControlLabel
                key={value.label}
                value={value.value}
                control={<Radio />}
                label={value.label}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Box>
    </Box>
  );
};

export default ParentStartingDay;
