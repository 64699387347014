import { MODAL_CREATE_CALENDAR } from 'constants.js';
import { openModal } from 'reducers/modals';
import { addSnackbar } from 'reducers/snackbar';

import { getUser } from './user';

export const FETCH_INCOMPLETE_CALENDAR_START = 'incomplete/FETCH_INCOMPLETE_CALENDAR_START';
export const FETCH_INCOMPLETE_CALENDAR_END = 'incomplete/FETCH_INCOMPLETE_CALENDAR_END';
export const FETCH_INCOMPLETE_CALENDAR_ERROR = 'incomplete/FETCH_INCOMPLETE_CALENDAR_ERROR';
export const CHANGE_CURRENT_CALENDAR = 'incomplete/CHANGE_CURRENT_CALENDAR';
export const DELETE_DRAFT_CALENDAR_START = 'incomplete/DELETE_DRAFT_CALENDAR_START';
export const SUCCESS_DELETED_DRAFT_CALENDAR = 'incomplete/SUCCESS_DELETED_DRAFT_CALENDAR';
export const UPDATE_DRAFT_CALENDARS_QTT = 'incomplete/UPDATE_DRAFT_CALENDARS_QTT';
export const UPDATE_DRAFT_CALENDARS_QTT_ERROR = 'incomplete/UPDATE_DRAFT_CALENDARS_QTT_ERROR';

const initialState = {
  incompleteCalendars: [],
  loading: false,
  error: null,
  currentIncompleteCalendar: null,
  draftCallQtt: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_INCOMPLETE_CALENDAR_START: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case FETCH_INCOMPLETE_CALENDAR_END: {
      return {
        ...state,
        loading: false,
        error: null,
        incompleteCalendars: action.incompleteCalendars,
      };
    }
    case FETCH_INCOMPLETE_CALENDAR_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case CHANGE_CURRENT_CALENDAR: {
      return {
        ...state,
        currentIncompleteCalendar: action.currentIncompleteCalendar,
      };
    }
    case UPDATE_DRAFT_CALENDARS_QTT: {
      return {
        ...state,
        draftCallQtt: action.draftCallQtt,
      };
    }
    case UPDATE_DRAFT_CALENDARS_QTT_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case DELETE_DRAFT_CALENDAR_START: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case SUCCESS_DELETED_DRAFT_CALENDAR: {
      return {
        ...state,
        loading: false,
        error: null,
      };
    }
    default: {
      return { ...state };
    }
  }
};

export const getDraftCalendarsQtt =
  () =>
  async (dispatch, getState, { firebase }) => {
    try {
      const db = firebase.firestore();
      let { auth } = getState();
      const userId = auth.user.uid;

      const userResponse = await db.collection('users').doc(userId).get();

      const userData = userResponse.data();
      const { incomplete } = userData;
      dispatch({ type: UPDATE_DRAFT_CALENDARS_QTT, draftCallQtt: incomplete.length });
    } catch (error) {
      dispatch({ type: UPDATE_DRAFT_CALENDARS_QTT_ERROR, error });
    }
  };

export const getIncompleteCalendars =
  incompleteIds =>
  async (dispatch, getState, { firebase }) => {
    try {
      dispatch({ type: FETCH_INCOMPLETE_CALENDAR_START });
      const db = firebase.firestore();
      let { auth } = getState();
      const userId = auth.user.uid;
      // const schoolYear = calendarUtil.getCurrentSchoolYear(
      //   currentDate,
      //   firstPopulatedDate,
      //   true
      // );

      let draftCalendars = [];
      for (let index = 0; index < incompleteIds.length; index++) {
        const result = await db.collection('incompleteCalendars').doc(incompleteIds[index]).get();
        const data = result.data();

        const birthdaysPromise = db
          .collection('incompleteCalendars')
          .doc(incompleteIds[index])
          .collection('birthdays')
          .doc('children')
          .get();

        const accessCardsPromise = db
          .collection('incompleteCalendars')
          .doc(incompleteIds[index])
          .collection('accessCards')
          .doc(userId)
          .get();

        const schoolBreaksPromise = await db
          .collection('incompleteCalendars')
          .doc(incompleteIds[index])
          .collection('schoolBreaks')
          .get();

        const allWeekendsPromise = await db
          .collection('incompleteCalendars')
          .doc(incompleteIds[index])
          .collection('allWeekends')
          .get();

        const [birthdaysResponse, accessCardsResponse] = await Promise.all([
          birthdaysPromise,
          accessCardsPromise,
        ]);

        const schoolBreakResponse = schoolBreaksPromise.docs.map(doc => doc.data());
        const allWeekendsResponse = allWeekendsPromise.docs.map(doc => doc.data());
        if (schoolBreakResponse && schoolBreakResponse.length > 0) {
          data.schoolBreaks = schoolBreakResponse[0];
        }
        if (birthdaysResponse.exists) {
          const birthdays = birthdaysResponse.data();
          data.birthdays = birthdays['b-days'];
        }
        if (accessCardsResponse.exists) {
          const accessCard = accessCardsResponse.data();
          data.accessCard = accessCard;
        }
        if (allWeekendsResponse && allWeekendsResponse.length > 0) {
          data.allWeekends = allWeekendsResponse[0];
        }
        data.calendarId = incompleteIds[index];
        draftCalendars.push(data);
      }
      dispatch({
        type: FETCH_INCOMPLETE_CALENDAR_END,
        incompleteCalendars: draftCalendars,
      });
    } catch (error) {
      dispatch(
        addSnackbar({
          text: `${error.message}`,
          variant: 'error',
          closeButton: true,
          //retryButton: () => dispatch(getCalendarDates(calendarId))
        }),
      );
      dispatch({ type: FETCH_INCOMPLETE_CALENDAR_ERROR, error: error.message });
    }
  };

export const changeCurrentIncompleteCalendar =
  data =>
  async (dispatch, getState, { firebase }) => {
    try {
      firebase.analytics().logEvent('create_calendar_from_incomplete');
      dispatch({
        type: CHANGE_CURRENT_CALENDAR,
        currentIncompleteCalendar: data,
      });
      dispatch(openModal({ type: MODAL_CREATE_CALENDAR, disableClose: true }));
    } catch (error) {
      dispatch(
        addSnackbar({
          text: `${error.message}`,
          variant: 'error',
          closeButton: true,
          //retryButton: () => dispatch(getCalendarDates(calendarId))
        }),
      );
    }
  };
export const deleteIncompleteCalFromState = () => async dispatch => {
  try {
    dispatch({
      type: CHANGE_CURRENT_CALENDAR,
      currentIncompleteCalendar: null,
    });
  } catch (error) {
    dispatch(
      addSnackbar({
        text: `${error.message}`,
        variant: 'error',
        closeButton: true,
        //retryButton: () => dispatch(getCalendarDates(calendarId))
      }),
    );
  }
};

export const deleteIncompleteCalendar =
  calendarId =>
  async (dispatch, getState, { firebase }) => {
    try {
      dispatch({
        type: CHANGE_CURRENT_CALENDAR,
        currentIncompleteCalendar: null,
      });
      dispatch({
        type: DELETE_DRAFT_CALENDAR_START,
      });
      const deleteCal = firebase.functions().httpsCallable('deleteIncompleteCalendarV2');
      await deleteCal({ calendarId });
      dispatch(getUser());
      dispatch({
        type: SUCCESS_DELETED_DRAFT_CALENDAR,
      });
    } catch (error) {
      dispatch(
        addSnackbar({
          text: `${error.message}`,
          variant: 'error',
          closeButton: true,
          //retryButton: () => dispatch(getCalendarDates(calendarId))
        }),
      );
    }
  };
