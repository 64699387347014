import { customizeRotationRules } from 'constants';
import { primaryParentValue, secondaryParentValue } from 'constants';
import { LAST_TIME_SHOW_EXPIRED_MODAL, ONBOARDING_OPENED_BEFORE } from 'constants.js';
import moment from 'moment';
import { isClashingWithMothersOrFathersDay, isIncludedInSchoolBreaks } from 'utils/validator';

export const getYearsFrom = schoolYear => {
  const yearPrefix = '20';
  const thisYear = yearPrefix + schoolYear.substr(0, 2);
  const nextYear = yearPrefix + schoolYear.substr(2, 2);
  return [+thisYear, +nextYear];
};

export const firstSchoolYearDateFrom = schoolYear => {
  const schoolYearStartMonth = 7;
  const [firstYear] = getYearsFrom(schoolYear);
  return moment().year(firstYear).month(schoolYearStartMonth).startOf('month');
};

export const getChristmasYear = templateSchoolBreaks => {
  const schoolBreak = templateSchoolBreaks.christmas_newyear;
  const schoolYear = getCurrentSchoolYear(moment(), moment());
  const christmasYear = schoolBreak ? moment(schoolBreak.start).format('YYYY') : schoolYear;
  return christmasYear;
};

export const getNewyearYear = templateSchoolBreaks => {
  const schoolBreak = templateSchoolBreaks.christmas_newyear;
  const schoolYear = getCurrentSchoolYear(moment(), moment());
  const newYearYear = schoolBreak ? moment(schoolBreak.end).format('YYYY') : schoolYear;
  return newYearYear;
};

export const getFirstWeekend = (months, firstPopulatedDate) => {
  const date = moment(firstPopulatedDate);
  const firstDay = date.clone().add(months, 'months').startOf('month');
  const day = firstDay.weekday();
  if (day <= 5) {
    firstDay.add(5 - day, 'days');
  }
  if (day > 5) {
    firstDay.add(12 - day, 'days');
  }
  return firstDay;
};

export const getSpecificWeekendOfTheMonth = (num, month) => {
  const first = getFirstWeekend(month);
  const specific = moment(first)
    .clone()
    .add((num - 1) * 7, 'days');

  if (num <= 4) {
    return specific;
  } else {
    if (
      moment(first)
        .clone()
        .endOf('month')
        .isSameOrAfter(
          moment(first)
            .clone()
            .add((num - 1) * 7, 'days'),
        )
    ) {
      return specific;
    } else {
      return null;
    }
  }
};

export const getAllWeekendsOfTheMonth = (months, firstPopulatedDate) => {
  const first = getFirstWeekend(months, firstPopulatedDate);
  const second = moment(first).clone().add(7, 'days');
  const third = moment(first).clone().add(14, 'days');
  const fourth = moment(first).clone().add(21, 'days');
  const fifth = moment(first)
    .clone()
    .endOf('month')
    .isSameOrAfter(moment(first).clone().add(28, 'days'))
    ? moment(first).clone().add(28, 'days')
    : null;
  return [first, second, third, fourth, fifth];
};

export const isExtendedByVacation = (startDate, templateSchoolBreaks) => {
  if (!templateSchoolBreaks.holidays) {
    return [false, false, false];
  }
  const endDate = moment(startDate).add(2, 'days');
  const extendingAfter = endDate.clone().add(1, 'day');
  let rv1 = false;
  let rv2 = false;
  let rv3 = false;
  // console.log(templateSchoolBreaks);
  for (let [, value] of Object.entries(templateSchoolBreaks.holidays)) {
    // console.log(key, value);
    if (moment(startDate).isSame(moment(value))) {
      rv1 = true;
      rv2 = true;
      break;
    }
    if (moment(extendingAfter).isSame(moment(value))) {
      rv1 = true;
      rv3 = true;
      break;
    }
  }
  return [rv1, rv2, rv3];
};

function getDefaultWeekendsList(
  schoolBreaks,
  templateSchoolBreaks,
  schoolYearLongFormat,
  firstPopulatedDate,
  lastPopulatedDate,
) {
  const defaultWeekendsList = [];
  for (let j = 0; j < 12; j++) {
    const monthWeekends = getAllWeekendsOfTheMonth(j, firstPopulatedDate);
    for (let i = 0; i < 5; i++) {
      if (monthWeekends[i] && i % 2 === 0) {
        if (
          monthWeekends[i].isBefore(moment(firstPopulatedDate)) ||
          monthWeekends[i].isAfter(moment(lastPopulatedDate))
        ) {
          continue;
        }
        if (
          !isIncludedInSchoolBreaks(monthWeekends[i], schoolBreaks) &&
          !isClashingWithMothersOrFathersDay(monthWeekends[i], schoolYearLongFormat)
        ) {
          const next = moment(monthWeekends[i]).clone().add(2, 'days').format('YYYY-MM-DD');
          let start = monthWeekends[i].format('YYYY-MM-DD');
          let end = next;
          const after = moment(next).clone();
          // if (isIncludedInSchoolBreaks(start, schoolBreaks)) {
          //   start = moment(start).clone().subtract(1, "days").format("YYYY-MM-DD");
          // }
          // if (isIncludedInSchoolBreaks(after, schoolBreaks)) {
          //   end = after.format("YYYY-MM-DD");
          // }
          const rv = isExtendedByVacation(start, templateSchoolBreaks);
          if (rv[0] && rv[1]) {
            start = moment(start).clone().subtract(1, 'days').format('YYYY-MM-DD');
          }
          if (rv[0] && rv[2]) {
            end = after.format('YYYY-MM-DD');
          }
          defaultWeekendsList.push({ start: start, end: end });
        }
      }
    }
  }
  return defaultWeekendsList;
}

function getAlternateWeekendsList(
  alternateWeekendsPossession,
  secondAugustAlternateWeekendsPossession,
  schoolBreaks,
  templateSchoolBreaks,
) {
  const altWeekendsList = [];
  for (let j = 8; j < 20; j++) {
    let i = j - 1;
    if (j >= 12) {
      i = i % 12;
    }
    const monthName = getMonthName(i);
    if (alternateWeekendsPossession[monthName].start) {
      //console.log(alternateWeekendsPossession[monthName].start);
      const next = moment(alternateWeekendsPossession[monthName].start).clone().add(2, 'days');
      let start = alternateWeekendsPossession[monthName].start;
      let end = next.format('YYYY-MM-DD');
      const after = moment(next).clone().add(1, 'days');
      // if (isIncludedInSchoolBreaks(start, schoolBreaks)) {
      //   start = moment(start).clone().subtract(1, "days").format("YYYY-MM-DD");
      // }
      // if (isIncludedInSchoolBreaks(after, schoolBreaks)) {
      //   end = after.format("YYYY-MM-DD");
      // }
      const rv = isExtendedByVacation(start, templateSchoolBreaks);
      if (rv[0] && rv[1]) {
        start = moment(start).clone().subtract(1, 'days').format('YYYY-MM-DD');
      }
      if (rv[0] && rv[2]) {
        end = after.format('YYYY-MM-DD');
      }
      //console.log(start, end);
      altWeekendsList.push({ start: start, end: end });
    }
  }
  if (secondAugustAlternateWeekendsPossession.start) {
    const next = moment(secondAugustAlternateWeekendsPossession.start).clone().add(2, 'days');
    let start = secondAugustAlternateWeekendsPossession.start;
    let end = next.format('YYYY-MM-DD');
    const after = moment(next).clone().add(1, 'days');
    if (isIncludedInSchoolBreaks(start, schoolBreaks)) {
      start = moment(start).clone().subtract(1, 'days').format('YYYY-MM-DD');
    }
    if (isIncludedInSchoolBreaks(after, schoolBreaks)) {
      end = after.format('YYYY-MM-DD');
    }
    altWeekendsList.push({ start: start, end: end });
  }
  return altWeekendsList;
}

export const getMoreThan100MilesWeekends = (
  alternateWeekendsPossession,
  secondAugustAlternateWeekendsPossession = null,
  schoolBreaks,
  templateSchoolBreaks,
  defaultWeekends,
  schoolYearLongFormat,
  firstPopulatedDate,
  lastPopulatedDate,
) => {
  let allWeekendsList = [];
  if (Number(defaultWeekends) === 0) {
    allWeekendsList = getDefaultWeekendsList(
      schoolBreaks,
      templateSchoolBreaks,
      schoolYearLongFormat,
      firstPopulatedDate,
      lastPopulatedDate,
    );
  } else if (Number(defaultWeekends) === 1) {
    allWeekendsList = getAlternateWeekendsList(
      alternateWeekendsPossession,
      secondAugustAlternateWeekendsPossession,
      schoolBreaks,
      templateSchoolBreaks,
    );
  } else {
    // Do nothing
  }
  getMoreThan100MilesWeekends.entries = allWeekendsList.length;
  return allWeekendsList;
};

export const isDateClashingWithNCPWeekends = (startDate, endDate, allWeekends) => {
  let rv = false;
  if (!allWeekends) {
    // console.log("Weekend set is empty");
    return rv;
  }
  for (let i = 0; i < 36; i++) {
    if (!allWeekends[i]) {
      continue;
    }
    const start = allWeekends[i].start;
    const end = allWeekends[i].end;
    for (let date = moment(startDate); !date.isSame(moment(endDate)); date = date.add(1, 'days')) {
      if (moment(date).isBetween(moment(start).subtract(1, 'days'), moment(end).add(1, 'days'))) {
        rv = true;
        // console.log(date, "Yes Clashing");
        break;
      }
    }
    if (rv) {
      break;
    }
  }
  return rv;
};

export const getValidMonthsInTheCalendar = (firstPopulatedDate, lastPopulatedDate) => {
  let beginDate = moment();
  if (beginDate.isBefore(moment(firstPopulatedDate))) {
    beginDate = moment(firstPopulatedDate);
  }
  let months;
  for (months = 9; beginDate.isBefore(moment(lastPopulatedDate)); months++) {
    beginDate.add(1, 'month');
  }
  return months;
};

export const getMonthName = monthIndex => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  return months[monthIndex];
};

export const getCurrentSchoolYear = (
  currentDate,
  firstPopulatedDate,
  offsetSchoolBreak = false,
  longFormat = false,
) => {
  const format = longFormat ? 'YYYY' : 'YY';
  const separator = longFormat ? '/' : '';
  const currentMonth = currentDate.clone().month() + 1; // 0 index
  const currentYear = currentDate.clone().year();

  const current = currentDate.clone();
  const previous = currentDate.clone().subtract(1, 'years');
  const next = currentDate.clone().add(1, 'years');

  const firstPopulatedDateYear = firstPopulatedDate.clone().year();
  const currentFirstPopulatedDate = firstPopulatedDate.clone();
  const nextFirstPopulatedDate = firstPopulatedDate.clone().add(1, 'years');
  const periodBreakerMonth = 8;
  if (offsetSchoolBreak === false) {
    // When no offset has to be taken into account then return early with previous simple logic
    return currentMonth <= periodBreakerMonth
      ? `${previous.format(format)}${separator}${current.format(format)}`
      : `${current.format(format)}${separator}${next.format(format)}`;
  }

  if (firstPopulatedDateYear < currentYear) {
    // If calendar was created before aug, and user has scrolled to aug, then the schoolbreaks for the year calendar was created should be returned, not to next year.
    // Remember that school breaks spans to around middle of aug for current year.
    return currentMonth < periodBreakerMonth + 1
      ? `${previous.format(format)}${separator}${current.format(format)}`
      : `${current.format(format)}${separator}${next.format(format)}`;
  } else {
    // When firstPopulatedDate is same or larger than currentYear, it means user has scrolled back in the calendar widget to time before the calendar was created
    // In this case always return the first/earliest-avaialbe schoolYear
    // Example: User creates his/hers first calendar in Aug (period-breaker should be 8 so that the newly created schoolbreaks are fetched, not the once for previous schoolyear)
    return `${currentFirstPopulatedDate.format(format)}${separator}${nextFirstPopulatedDate.format(
      format,
    )}`;
  }
};

export const getCurrentCalendar = (calendars, currentCalendarId) => {
  if (calendars && currentCalendarId) {
    return Object.values(calendars).find(calendar => calendar.calendarId === currentCalendarId);
  }
};

export const isSubscriptionExpired = (userPaymentExpDate, userFreeAccessExpDate) => {
  const currentDate = moment().format('YYYY-MM-DD');
  const lastPaymentExpDate = userPaymentExpDate
    ? moment.max([moment(userPaymentExpDate), moment(userFreeAccessExpDate)]).format('YYYY-MM-DD')
    : moment(userFreeAccessExpDate).format('YYYY-MM-DD');
  return moment(currentDate).isAfter(lastPaymentExpDate);
};

export const shouldShowSummerPossessionsWarning = ({
  currentDate,
  lastPopulatedDate,
  summerPossessions,
}) => {
  const lastPop = moment(lastPopulatedDate);
  const minimumDate = lastPop.clone().month(2).date(1); // 1st of March

  const isAfterMinimumDate = currentDate.isSameOrAfter(minimumDate, 'month');
  const isBeforeLastPopulatedDate = currentDate.isBefore(lastPop, 'month');
  const hasUpdatedSummerPossessions = !!summerPossessions?.secondary;
  return isAfterMinimumDate && isBeforeLastPopulatedDate && !hasUpdatedSummerPossessions;
};

export const shouldExtendCalendar = (currentCalendar, currentDate) => {
  return (
    currentCalendar && moment(currentDate).isSameOrAfter(currentCalendar.lastPopulatedDate, 'month')
  );
};
export const shouldUpdatePayment = (userData, currentDate) => {
  const c = currentDate.endOf('month');
  const freeAccessExpDate = moment(userData.freeAccessExpDate).endOf('month');
  if (userData.paymentExpDate) {
    const paymentExpDate = moment(userData.paymentExpDate).endOf('month');
    return c.isSameOrAfter(moment.max([freeAccessExpDate, paymentExpDate]), 'month');
  } else {
    return c.isSameOrAfter(freeAccessExpDate, 'month');
  }
};
export const shouldExtendCalendarDatePicker = (currentCalendar, currentDate) => {
  return currentCalendar && moment(currentDate).isAfter(currentCalendar.lastPopulatedDate, 'month');
};
export const shouldUpdatePaymentDatePicker = (userData, currentDate) => {
  const c = currentDate.endOf('month');
  const freeAccessExpDate = moment(userData.freeAccessExpDate).endOf('month');
  if (userData.paymentExpDate) {
    const paymentExpDate = moment(userData.paymentExpDate).endOf('month');
    return c.isAfter(moment.max([freeAccessExpDate, paymentExpDate]), 'month');
  } else {
    return c.isAfter(freeAccessExpDate, 'month');
  }
};

export const checkSubscription = user => {
  let subscriptionExpired = false;
  let subscriptionAlmostExpired = false;
  const maxSubscriptionDate = user.paymentExpDate
    ? moment.max(moment(user.paymentExpDate), moment(user.freeAccessExpDate))
    : moment(user.freeAccessExpDate);
  const currentDay = moment(moment().format('YYYY-MM-DD'));
  const lastTimeShowModal = localStorage.getItem(LAST_TIME_SHOW_EXPIRED_MODAL + user.uid);

  const dayDiff = maxSubscriptionDate.diff(currentDay, 'days');
  if (dayDiff >= 0 && dayDiff <= 5) {
    if (
      lastTimeShowModal &&
      moment(lastTimeShowModal).format('YYYY-MM-DD') === currentDay.format('YYYY-MM-DD')
    ) {
      return [subscriptionAlmostExpired, subscriptionExpired];
    } else {
      localStorage.setItem(
        LAST_TIME_SHOW_EXPIRED_MODAL + user.uid,
        currentDay.format('YYYY-MM-DD'),
      );
      subscriptionAlmostExpired = true;
      return [subscriptionAlmostExpired, subscriptionExpired];
    }
  } else if (dayDiff > 2) {
    return [subscriptionAlmostExpired, subscriptionExpired];
  } else if (dayDiff < 0) {
    subscriptionExpired = true;
    return [subscriptionAlmostExpired, subscriptionExpired];
  }
};

export const shouldOpenOnboardingModal = () => {
  const openedBefore = localStorage.getItem(ONBOARDING_OPENED_BEFORE);
  if (openedBefore) {
    return false;
  } else {
    localStorage.setItem(ONBOARDING_OPENED_BEFORE, true);
    return true;
  }
};

export const convertCustomizeToNewVersion = customizePossessionRules => {
  const initCustomize = {
    MO: { parent: secondaryParentValue, rotation: customizeRotationRules.fixed },
    TU: { parent: secondaryParentValue, rotation: customizeRotationRules.fixed },
    WE: { parent: secondaryParentValue, rotation: customizeRotationRules.fixed },
    TH: { parent: secondaryParentValue, rotation: customizeRotationRules.fixed },
    FR: { parent: secondaryParentValue, rotation: customizeRotationRules.fixed },
    SA: { parent: secondaryParentValue, rotation: customizeRotationRules.fixed },
    SU: { parent: secondaryParentValue, rotation: customizeRotationRules.fixed },
  };
  let shouldTransform = false;

  for (const [key, value] of Object.entries(customizePossessionRules)) {
    if (!value.parent) {
      shouldTransform = true;
      initCustomize[key].parent = primaryParentValue;
    }
  }
  if (shouldTransform) {
    return initCustomize;
  } else {
    return customizePossessionRules;
  }
};
