import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import Login from './Login';

export default function Routes({ match }) {
  return (
    <>
      <Route path={`${match.path}/login`} exact component={Login}></Route>
      <Redirect to={`${match.path}/login`} />
    </>
  );
}
