import { Box } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import {
  CUSTOMIZE_OPTIONS,
  customizeRotationRules,
  primaryParentValue,
  secondaryParentValue,
} from 'constants.js';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import RotationCalendarStartingDate from 'shared/Forms/EditCalendar/RotationCalendarStartingDate/index.jsx';
import Icon from 'shared/Icons/Icons.jsx';
import { getCurrentCalendar } from 'utils/calendar';

import styles from './CustomPossession.module.css';

const weekDays = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'];
const weekDaysLabel = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

const useStyles = makeStyles(theme => ({
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const CustomPossession = props => {
  const {
    customPossessionDays,
    setCustomPossessionDays,
    showDayStart,
    showUserName,
    startingDateValue,
    setStartingDateValue,
    disabled,
  } = props;

  const { t } = useTranslation();
  const classes = useStyles();

  const calendar = useSelector(state => state.calendar);
  const currentCalendar = getCurrentCalendar(calendar.list.calendars, calendar.currentCalendarId);

  const CUSTOMIZE_PARENTS = [
    {
      label:
        showUserName && currentCalendar && currentCalendar.parent1Name
          ? currentCalendar.parent1Name
          : 'Parent 1',
      value: primaryParentValue,
    },
    {
      label:
        showUserName && currentCalendar && currentCalendar.parent2Name
          ? currentCalendar.parent2Name
          : 'Parent 2',
      value: secondaryParentValue,
    },
  ];

  const handleParentSelect = (day, parentValueIndex) => {
    customPossessionDays[day].parent = CUSTOMIZE_PARENTS[parentValueIndex].value;
    setCustomPossessionDays({ ...customPossessionDays });
  };
  const handleRotationSelect = (day, rotationValueIndex) => {
    customPossessionDays[day].rotation = CUSTOMIZE_OPTIONS[rotationValueIndex].value;
    setCustomPossessionDays({ ...customPossessionDays });
  };

  return (
    <div className={styles.customPossessionContainer}>
      {showDayStart && (
        <Box>
          <Box className={styles.dateBox}>
            <Box>
              <Box className={styles.dateBox_icon}>
                <Icon icon={'calendar-day'} size={'xl'} color={'rgba(0, 0, 0, 0.87)'} />
                <Box className={styles.dateBox_icon_title}>{t('startingDate')}</Box>
              </Box>
            </Box>
            <RotationCalendarStartingDate
              inputStyle={{ margin: 0 }}
              onChange={setStartingDateValue}
              date={startingDateValue}
              firstPopulatedDate={moment().startOf('month').format()}
              lastPopulatedDate={moment().month(7).endOf('month').format()}
            />
            {/* <Box className={styles.dateBox_date}>
            {moment().startOf('month').format('MMM DD, YYYY')}
          </Box> */}
          </Box>
          <Box className={styles.subtitle}>{t('startingDateExplanation')}</Box>
        </Box>
      )}
      <Box className={styles.customPossessionContainerTitle}>{t('customPossessionTitle')}</Box>
      <div className={styles.customPossessionBox}>
        <Box className={styles.tableHead}>
          <Box className={styles.th}>{t('parent')}</Box>
          <Box className={styles.th}>{t('recurrence')}</Box>
        </Box>
        <div className={styles.table_body}>
          {weekDays.map((day, index) => (
            <Box className={styles.table_body_content} key={day}>
              <Box
                className={styles.day_box}
                style={{
                  background:
                    customPossessionDays[day].parent === CUSTOMIZE_PARENTS[0].value &&
                    customPossessionDays[day].rotation === customizeRotationRules.fixed
                      ? '#1F9CA3'
                      : customPossessionDays[day].parent === CUSTOMIZE_PARENTS[1].value &&
                        customPossessionDays[day].rotation === customizeRotationRules.fixed
                      ? '#FF7A78'
                      : customPossessionDays[day].parent === CUSTOMIZE_PARENTS[1].value &&
                        customPossessionDays[day].rotation !== customizeRotationRules.fixed
                      ? 'linear-gradient(270deg,#1F9CA3  50.09%, #FF7A78 50.09%)'
                      : 'linear-gradient(270deg, #FF7A78 50.09%, #1F9CA3 50.09%)',
                }}
              >
                {weekDaysLabel[index]}
              </Box>
              <Box className={styles.table_selects_box}>
                <FormControl fullWidth className={classes.formControl} variant="outlined">
                  <Select
                    onChange={event => handleParentSelect(day, event.target.value)}
                    value={CUSTOMIZE_PARENTS.findIndex(
                      item => item.value === customPossessionDays[day].parent,
                    )}
                    disabled={disabled}
                  >
                    {CUSTOMIZE_PARENTS.map((parent, index) => (
                      <MenuItem key={index} value={index}>
                        {parent.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth className={classes.formControl} variant="outlined">
                  <Select
                    onChange={event => handleRotationSelect(day, event.target.value)}
                    value={CUSTOMIZE_OPTIONS.findIndex(
                      item => item.value === customPossessionDays[day].rotation,
                    )}
                    disabled={disabled}
                  >
                    {CUSTOMIZE_OPTIONS.map((option, index) => (
                      <MenuItem key={index} value={index}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CustomPossession;
