import { SnackbarProvider } from 'notistack';
import React from 'react';

import useStyles from './SnackbarProvider.css.jsx';

export default function Providers({ children }) {
  const styles = useStyles();

  return (
    <SnackbarProvider
      maxSnack={3}
      autoHideDuration={2500}
      classes={{
        variantInfo: styles.snackbarVariantInfo,
        variantWarning: styles.snackbarVariantWarning,
        variantError: styles.snackbarVariantError,
        variantSuccess: styles.snackbarVariantSuccess,
      }}
    >
      {children}
    </SnackbarProvider>
  );
}
