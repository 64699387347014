import firebase from './firebase.js';

export const deleteCalendar = ({ calendarId }) => {
  const deleteCal = firebase.functions().httpsCallable('deleteCalendarV2');
  return deleteCal({ calendarId });
};

export const rebuildCalendar = ({ calendarId }) => {
  const rebuildCal = firebase.functions().httpsCallable('rebuildCalendarV2');
  return rebuildCal({ calendarId });
};
export const editCalendar = data => {
  const editCal = firebase.functions().httpsCallable('editCalendar_v3');
  return editCal(data);
};

export const getConfigurations = async () => {
  const db = firebase.firestore();
  const doc = await db.collection('configuration').doc('ALL').get();

  return doc.data();
};

export const calendarsReport = ({ startDate, endDate }) => {
  const getReportData = firebase.functions().httpsCallable('usersCalendarReportV2');
  return getReportData({ startDate, endDate });
};
export const paymentReport = ({ startDate, endDate }) => {
  const getReportData = firebase.functions().httpsCallable('usersPaymentReportsV2');
  return getReportData({ startDate, endDate });
};
export const usersReport = ({ startDate, endDate }) => {
  const getReportData = firebase.functions().httpsCallable('userReportV2');
  return getReportData({ startDate, endDate });
};

export default {
  deleteCalendar,
  rebuildCalendar,
  getConfigurations,
  editCalendar,
  calendarsReport,
  paymentReport,
  usersReport,
};
