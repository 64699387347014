import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  root: {
    whiteSpace: 'pre-wrap',
  },
  h1: {
    fontFamily: 'Roboto Slab',
    fontWeight: '700',
  },
  h2: {
    fontFamily: 'Roboto Slab',
    fontWeight: '700',
  },
  h3: {
    fontFamily: 'Roboto Slab',
    fontWeight: '700',
  },
  h4: {
    fontFamily: 'Roboto Slab',
    fontWeight: '700',
  },
  h5: {
    fontFamily: 'Roboto Slab',
    fontWeight: '700',
  },
  h6: {
    fontFamily: 'Roboto Slab',
    fontWeight: '600',
  },
  subtitle1: {
    fontFamily: 'Roboto Slab',
    fontWeight: '500',
  },
  subtitle2: {
    fontFamily: 'Roboto Slab',
    fontWeight: '500',
    fontSize: '15px',
  },
  body1: {
    fontFamily: 'Roboto',
  },
  body2: {
    fontFamily: 'Roboto',
  },
}));
