import moment from 'moment';
import { getCalendars } from 'reducers/calendar';
import { closeModal } from 'reducers/modals';
import { addSnackbar } from 'reducers/snackbar';

export const CURRENT_CALENDAR_DAY = 'editCalendarDate/CURRENT_CALENDAR_DAY';
export const MODIFY_CALENDAR_DAY_START = 'editCalendarDate/MODIFY_CALENDAR_DAY_START';
export const MODIFY_CALENDAR_DAY_DONE = 'editCalendarDate/MODIFY_CALENDAR_DAY_DONE';
export const MODIFY_CALENDAR_DAY_ERROR = 'editCalendarDate/MODIFY_CALENDAR_DAY_ERROR';

const initialState = {
  calendarId: '',
  currentOwner: '',
  schoolYear: '',
  date: '',
  role: '',
  additionalInfo: '',
  modified: false,
  loading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CURRENT_CALENDAR_DAY: {
      return {
        ...state,
        calendarId: action.data.calendarId,
        currentOwner: action.data.owner,
        schoolYear: action.data.schoolYear,
        date: action.data.date,
        role: action.data.role,
        modified: action.data.modified,
        loading: false,
        additionalInfo: action.data.additionalInfo,
      };
    }
    case MODIFY_CALENDAR_DAY_START: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case MODIFY_CALENDAR_DAY_DONE: {
      return {
        ...state,
        loading: false,
      };
    }
    case MODIFY_CALENDAR_DAY_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    default: {
      return { ...state };
    }
  }
};

export const currentCalendarDay = data => ({
  type: CURRENT_CALENDAR_DAY,
  data,
});

export const modifyCalendarDate =
  data =>
  async (dispatch, getState, { firebase }) => {
    dispatch({ type: MODIFY_CALENDAR_DAY_START });
    const modifyCal = firebase.functions().httpsCallable('modifyCalendarDates_v3');
    const payload = {
      calendarId: data.calendarId,
      startDate: data.startDate.format('YYYY-MM-DD'),
      endDate: data.endDate.format('YYYY-MM-DD'),
      newOwner: data.newOwner,
      allDay: data.allDay,
      start: data.start,
      end: data.end,
      additionalInfo: data.additionalInfo,
    };
    try {
      await modifyCal(payload);
      dispatch({ type: MODIFY_CALENDAR_DAY_DONE });
      dispatch(getCalendars({}));
      dispatch(closeModal());
    } catch (error) {
      dispatch({ type: MODIFY_CALENDAR_DAY_ERROR, error });
      dispatch(
        addSnackbar({
          text: `${error.message}`,
          variant: 'error',
        }),
      );
    }
  };

export const addDateAdditional =
  data =>
  async (dispatch, getState, { firebase }) => {
    dispatch({ type: MODIFY_CALENDAR_DAY_START });
    const { calendarId, currentDate, date, additionalInfo } = data;
    const db = firebase.firestore();
    try {
      const calendarRef = db.collection('calendars').doc(calendarId);
      const dateRef = db
        .collection('calendars')
        .doc(calendarId)
        .collection('dates')
        .doc(moment(currentDate).format('YYYY-MM'));

      const datesPromise = dateRef.get();

      const [dateResponse] = await Promise.all([datesPromise]);

      const dates = dateResponse.data();
      for (const [key, value] of Object.entries(dates)) {
        if (value.date === moment(date).format('YYYY-MM-DD')) {
          dates[key].additionalInfo = additionalInfo;
        }
      }

      await dateRef.update({ ...dates });
      await calendarRef.update({ updatedDate: moment().format(`YYYY-MM-DD HH:mm`) });

      dispatch({ type: MODIFY_CALENDAR_DAY_DONE });
      dispatch(getCalendars({}));
      dispatch(closeModal());
    } catch (error) {
      dispatch({ type: MODIFY_CALENDAR_DAY_ERROR, error });
      dispatch(
        addSnackbar({
          text: `${error.message}`,
          variant: 'error',
        }),
      );
    }
  };
