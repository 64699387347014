import MaterialButton from '@material-ui/core/Button';
import React from 'react';

// import useStyle from "./Button.css.jsx";

function Button(props) {
  const { children, ...rest } = props;
  return <MaterialButton {...rest}>{children}</MaterialButton>;
}

export default Button;
