import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useTheme } from '@material-ui/core/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { MODAL_CREATE_CALENDAR, MODAL_ONBOARDING, WORDPRESS_SITE } from 'constants.js';
// import { CONTACT_US } from 'constants.js';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { clearCalendarState } from 'reducers/calendar';
import { getCalendarsQtt } from 'reducers/calendar.js';
import { changeCurrentIncompleteCalendar } from 'reducers/incompleteCalendar.js';
import { getIncompleteCalendars } from 'reducers/incompleteCalendar.js';
import { getDraftCalendarsQtt } from 'reducers/incompleteCalendar.js';
import { openModal } from 'reducers/modals';
import { getSharedCalendarsQtt } from 'reducers/sharedCalendars.js';
import Button from 'shared/Button/Button.jsx';
import Icon from 'shared/Icons/Icons';
import CalendarCircleIcon from 'shared/Icons/svgs/CalendarCircleUser.jsx';
import Logo from 'shared/Logo/Logo.jsx';
import firebase from 'utils/firebase';

import useStyles from './Drawer.css.jsx';
import Header from './Header.jsx';

function ResponsiveDrawer(props) {
  const { container, children } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const calendar = useSelector(state => state.calendar);
  const sharedCalendars = useSelector(state => state.sharedCalendars);
  const draftCalendars = useSelector(state => state.incompleteCalendar);
  const user = useSelector(state => state.user.user);
  const currentDate = moment(moment()).format('YYYY-MM');
  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }
  function closeToggle() {
    setMobileOpen(false);
  }
  function onOpenOnBoarding() {
    firebase.analytics().logEvent('menu_action_onboarding');
    setMobileOpen(false);
    dispatch(openModal({ type: MODAL_ONBOARDING, disableClose: false }));
  }

  // function mailto() {
  //   firebase.analytics().logEvent('menu_action_mailto');
  //   window.location.href = `mailto:${CONTACT_US}`;
  // }

  function onCreatCalendar() {
    firebase.analytics().logEvent('menu_action_create_calendar');
    dispatch(changeCurrentIncompleteCalendar(null));
    setMobileOpen(false);
    dispatch(openModal({ type: MODAL_CREATE_CALENDAR, disableClose: true }));
  }

  function handleLogout() {
    firebase.analytics().logEvent('menu_action_create_logout');
    firebase.analytics().logEvent('logout');
    dispatch(clearCalendarState());
    firebase
      .auth()
      .signOut()
      .then(
        () => {
          console.log('signed out');
        },
        error => {
          console.log(error);
          console.log('something went wrong');
        },
      );
  }

  function isSelected(path) {
    return path === window.location.pathname;
  }
  useEffect(() => {
    dispatch(getSharedCalendarsQtt());
    dispatch(getCalendarsQtt());
    dispatch(getDraftCalendarsQtt());
  }, [user]);

  useEffect(() => {
    user && user.incomplete && dispatch(getIncompleteCalendars(user.incomplete));
  }, [user]);

  const drawer = (
    <>
      <a className={classes.header} href={`${WORDPRESS_SITE}`}>
        <div className={classes.header}>
          <div className={classes.headerLogo}>
            <Logo />
          </div>
          <div>{t('appTitle')}</div>
        </div>
      </a>

      {/* <Card className={classes.card}>
        <CardBody className={classes.cardBody}>
          <div>{t("appSupportedStates")}</div>
        </CardBody>
      </Card> */}

      <Button
        variant="outlined"
        color="primary"
        classes={{ outlinedPrimary: classes.outlinedPrimary }}
        onClick={onCreatCalendar}
      >
        {t('actionCreateCalendar')}
      </Button>

      <List>
        <ListItem
          classes={{
            root: classes.listItem,
            selected: classes.listSelected,
            button: classes.button,
          }}
          button
          component={Link}
          to={`/profile/calendars/${currentDate}`}
          selected={isSelected(`/profile/calendars/${currentDate}`)}
          onClick={closeToggle}
        >
          <ListItemIcon classes={{ root: classes.listItemIcon }}>
            <Icon icon="calendar" size="lg" />
          </ListItemIcon>
          <ListItemText
            style={{ whiteSpace: 'nowrap' }}
            primary={t('sideMenuCalendars', { qtt: calendar.calendarsQtt })}
          />
        </ListItem>
        <ListItem
          classes={{
            root: classes.listItem,
            selected: classes.listSelected,
            button: classes.button,
          }}
          button
          component={Link}
          to={`/profile/shared/${currentDate}`}
          selected={isSelected(`/profile/shared/${currentDate}`)}
          onClick={closeToggle}
        >
          <ListItemIcon classes={{ root: classes.listItemIcon }} style={{ marginLeft: '-2px' }}>
            <CalendarCircleIcon />
          </ListItemIcon>
          <ListItemText
            primary={t('sideMenuShared', { qtt: sharedCalendars.sharedCalendarsQtt })}
          />
        </ListItem>
        <ListItem
          classes={{
            root: classes.listItem,
            selected: classes.listSelected,
            button: classes.button,
          }}
          button
          component={Link}
          to={`/profile/draft/`}
          selected={isSelected(`/profile/draft/`)}
          onClick={closeToggle}
        >
          <ListItemIcon classes={{ root: classes.listItemIcon }}>
            <Icon icon="calendar_draft" size="lg" />
          </ListItemIcon>
          <ListItemText
            primary={t('sideMenuIncompleteCalendars', {
              qtt: draftCalendars.draftCallQtt,
            })}
          />
        </ListItem>

        <ListItem
          classes={{
            root: classes.listItem,
            selected: classes.listSelected,
            button: classes.button,
          }}
          button
          component={Link}
          to="/profile/account"
          selected={isSelected('/profile/account')}
          onClick={closeToggle}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <Icon icon="user" size="lg" />
          </ListItemIcon>
          <ListItemText primary={t('sideMenuAccount')} />
        </ListItem>
        {user && user.role === 'admin' && (
          <ListItem
            classes={{
              root: classes.listItem,
              selected: classes.listSelected,
              button: classes.button,
            }}
            button
            component={Link}
            to="/profile/admin"
            selected={isSelected('/profile/admin')}
            onClick={closeToggle}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <Icon icon="admin" size="lg" />
            </ListItemIcon>
            <ListItemText primary={t('sideMenuAdmin')} />
          </ListItem>
        )}
      </List>
      <div className={classes.spacer}></div>
      <div className={classes.stickySideMenu}>
        <List>
          <ListItem
            classes={{
              root: classes.listItem,
              selected: classes.listSelected,
              button: classes.button,
            }}

            // onClick={mailto}
          >
            {/* <ListItemText primary={t('sideMenuContactUs')} /> */}
            <Accordion className={classes['contact-accordion']}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
                className={classes['contact-accordion-summary']}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <Icon icon="letter" size="lg" />
                </ListItemIcon>
                <ListItemText>{t('sideMenuContactUs')}</ListItemText>
              </AccordionSummary>
              <AccordionDetails className={classes['contact-accordion-details']}>
                <a href={`mailto:${t('appEmail')}`} style={{ color: '#DCDCDC' }}>
                  {t('appEmail')}
                </a>
              </AccordionDetails>
            </Accordion>
          </ListItem>

          <ListItem
            classes={{
              root: classes.listItem,
              selected: classes.listSelected,
              button: classes.button,
            }}
            button
            onClick={onOpenOnBoarding}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <Icon icon="help" size="lg" />
            </ListItemIcon>
            <ListItemText primary={t('sideMenuOnboarding')} />
          </ListItem>
          <ListItem
            classes={{
              root: classes.listItem,
              selected: classes.listSelected,
              button: classes.button,
            }}
            button
            component="a"
            href={`${WORDPRESS_SITE}/about-us/`}
            onClick={closeToggle}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <Icon icon="info" size="lg" />
            </ListItemIcon>
            <ListItemText primary={t('sideMenuAbout')} />
          </ListItem>
          <ListItem
            classes={{
              root: classes.listItem,
              selected: classes.listSelected,
              button: classes.button,
            }}
            button
            onClick={handleLogout}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <Icon icon="sign-out" size="lg" />
            </ListItemIcon>
            <ListItemText primary={t('sideMenuSignOut')} />
          </ListItem>
        </List>
      </div>
    </>
  );

  return (
    <div className={classes.root}>
      <Header handleDrawerToggle={handleDrawerToggle} />
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden mdUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>{children}</main>
    </div>
  );
}

// ResponsiveDrawer.propTypes = {
//   container: PropTypes.instanceOf(
//     typeof Element === "undefined" ? Object : Element
//   ),
//   children: PropTypes.arrayOf(Element)
// };

export default ResponsiveDrawer;
