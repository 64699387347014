import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        appDomain: 'OurDaysCalendar.com',
        appEmail: 'support@ourdayscalendar.com',

        appTitle: 'Our Days Calendar',
        appSupportedStates: 'Texas SPO is available.',
        appUnsupportedBrowserIE:
          'Internet explorer is not a supported browser. You may experience issues',
        none: 'NONE',
        yes: 'Yes',
        no: 'No',

        share: 'Share',
        download: 'Download',

        loginMainTitle: 'Automated co-parenting calendar',
        loginMainSubtitle: 'Made to be Easy and Helpful',

        signinTitle: '{{ period }} {{ unit, dateUnit }} Free Trial! Get Started Today!',
        signinHelpTextTitle: "Don't have an account yet?",
        signinHelpTextBody: "Press any 'Sign in'-button to create an account.",

        sideMenuCalendars: 'My calendars ({{qtt}})',
        sideMenuIncompleteCalendars: 'Draft ({{qtt}})',
        sideMenuShared: 'Shared with me ({{qtt}})',
        sideMenuCheckIn: 'Checkin',
        sideMenuAccount: 'Account',
        sideMenuAdmin: 'Admin',
        sideMenuAbout: 'About',
        sideMenuOnboarding: 'Onboarding',
        sideMenuContactUs: 'Contact us',
        sideMenuSignOut: 'Sign out',

        actionClose: 'Close',
        actionCancel: 'Cancel',
        actionConfirm: 'Confirm',
        actionExtend: 'Extend',
        actionContinue: 'Continue',
        actionSave: 'Save',
        actionSaveIncomplete: 'Save draft',
        actionDeleteAccount: 'Delete Account',
        actionDone: 'Done',
        actionDelete: 'Delete',
        actionOk: 'Ok',
        actionSkip: 'Skip',
        actionBack: 'Back',
        actionNext: 'Next',
        actionUpdate: 'Update',
        actionRemove: 'Remove',
        actionCreateCalendar: 'Create calendar',
        actionDeleteCalendar: 'Delete calendar',
        actionRebuildCalendar: 'Rebuild calendar',
        actionAddChild: 'Add child',
        actionEditSummerPossessions: 'Update summer possessions',
        actionShowSummerPossessions: 'Summer possessions',
        actionAcceptTermsAndConditionsDescription:
          '<0>This software is not a substitute for the advice of an attorney</0>.\n',
        actionAcceptTermsAndConditions:
          'Yes, I agree to the <0>terms & conditions</0> (opens in new tab)',
        actionExtendCalendar: 'Extend calendar',
        actionBuy: 'Buy',
        actionUpdatePayment: 'Extend access',
        actionResetOriginal: 'Reset',
        actionNewPayment: 'View products',
        actionRetry: 'Retry',
        actionShowTodayMonth: "Show today's month",
        actionShowFirstGeneratedMonth: 'Go to first generated month',
        actionShowLastGeneratedMonth: 'Go to last generated month',
        actionShowLatestAccessibleMonth: 'Go to latest accessible month',
        actionShowProducts: 'Show products',
        actionChangeCustodian: 'Shift the school break to the other parent',
        actionAddAdditional: 'Additional info',
        actionalUpdateAdditional: 'Update additional info',
        actionDontShowAgain: "Don't show again",

        alertBoxRebuildCalendarTitle: 'Warning zone',
        alertBoxRebuildCalendarDescription:
          'Do not rebuild this calendar until you confirm with the support team.',

        alertBoxDeleteCalendarTitle: 'Danger zone',
        alertBoxDeleteCalendarDescription: 'To activate DELETE button, type its name below.',

        eventTitleBirthday: "{{name}}'s birthday  🎂",
        schoolBreak_thanksgiving: 'Thanksgiving break {{year}}',
        schoolBreak_christmas: 'Christmas break {{year}}',
        schoolBreak_newyear: 'New year break {{year}} (start date is set automatically)',
        schoolBreak_christmas_newyear: 'Christmas-New year break {{christmasYear}}-{{newyearYear}}',
        schoolBreak_spring: 'Spring break {{year}}',
        schoolBreak_summer: 'Summer break {{year}}',

        schoolBreakThanksgiving: 'Thanksgiving break',
        editSchoolBreak_christmas_newYear: 'Christmas-New year break',
        schoolBreakChristmas: 'Christmas-New year break',
        schoolBreakNewYear: 'New Year break',
        schoolBreakSpring: 'Spring break',
        schoolBreakSummer: 'Summer break',

        error404Title: '404',
        error404SubTitle: 'Page not found :(',
        error404Description: 'Ooooups! Looks like you got lost.',

        createCalendarSteps: [
          'Calendar name',
          //"School zip code",
          'Info child(ren)',
          'Visitation',
          'School breaks',

          "NCP's Weekends",
          "NCP's Alternate Weekends",
          'Summary',
          'Disclaimer',
        ],

        extendCalendarSteps: [
          //"Calendar name",
          //"School zip code",
          //"Info child(ren)",
          'School breaks',
          //"Visitation",
          "NCP's Weekends",
          "NCP's Alternate Weekends",
          'Summary',
          'Disclaimer',
        ],

        warningLegalZipCode: 'Create a separate calendar if different schedules apply.',
        warningLegalState: 'TEXAS SPO and E-SPO is available',
        warningWithInDistance:
          'Parents living within or more than 100 Miles apart',
        warningEditPastDate: 'Only upcoming days can be modified.',
        warningEditSharedCalendar:
          '{{email}} shared this calendar with you. They can modify it.',
        warningAlternateWeekends:
          "You haven't selected alternate weekend for one or more months, are you sure you want to proceed?",
        warningNCPWeekendsOverride:
          "One or more of summer possession dates were NCP's chosen weekends, here changed to your elected weekends.",
        alertWithin100milesDefaultWeekends: 'If you live within 100 miles, click next',
        alertDefaultWeekends: 'If you opted for default weekends, click next',
        alertAlternateWeekends: 'If you live within 100 miles, click next',
        alertWithin100milesSummerPossessions:
          "If you live within 100 miles, you don't need to pick 21 days window, click next",
        checkinEmptyStateTitle: 'Coming soon...',
        checkinEmptyStateSubTitle:
          'The checkin-feature to save location and time will be available in a future release.',

        secondaryRange1Title: 'NCP period 1',
        secondaryRange2Title: 'NCP period 2',
        primaryRange1Title: 'CP period 1',
        primaryRange2Title: 'CP period 2',
        primaryPeriod1Title: 'CP period 1',
        primaryPeriod2Title: 'CP period 2',

        primaryRange2NoValue: 'No period chosen',
        secondaryRange2NoValue: 'No period chosen',
        primaryWeeekend1Title: 'CP Summer possession weekend',
        primaryWeeekend1Description: 'Per SPO & ESPO',
        primaryWeeekend2Title: 'CP Extended Summer Possession weekend',
        primaryWeeekend2GT100MilesTitle: 'CP Extended Summer Possession weekend',
        primaryWeeekend2Description: 'Per SPO & ESPO.',
        primaryWeeekendNotChosen: 'No weekend chosen',
        primaryRangeNotChosen: 'No period chosen',

        titleAddAdditiional: 'Additional info',
        title2AddAdditional: 'Your notes',
        title2UpdateAdditional: 'Update notes',
        placeholderAddAdditional: 'E.g. bring child books, grandparents...',

        titleEditParentName: "Edit parent's name",
        label: 'Name',
        inputLabel1: 'Parent 1',
        inputLabel2: 'Parent 2',
        radioLabel1: 'I am Parent 1',
        radioLabel2: 'I am Parent 2',

        titleCalendarName: '(1-50 alphanumeric characters)',
        labelCalendarName: 'Calendar name',
        labelVerifyDeleteCalendarName: 'Calendar name (case-sensitive)',
        labelSchoolBreakStartDate: 'Start date',
        placeholderSchoolBreakStartDate: 'E.g. 06/14/2024',
        labelSchoolBreakEndDate: 'End date',
        furtherInformationStart: 'Day that child is released for break',
        furtherInformationEnd: 'Last day of the break',
        placeholderSchoolBreakEndDate: 'E.g. 07/30/2024',
        labelSchoolZipCode: 'School zip code',
        titleLegalZipCode: '(5 digits, numbers only)',
        labelLegalZipCode: 'School zip code where child attends school',
        placeholderLegalZipCode: 'E.g. 12345',
        labelAddChildNameTitle: '(1-50 alphanumeric characters)',
        labelAddChildName: 'Name',
        placeholderAddChildName: 'E.g. John Smith',
        labelAddChildDateOfBirth: 'Date of Birth',
        placeholderAddChildDateOfBirth: 'E.g. 12/26/2022',
        labelLegalState: 'Legal state',
        labelParentalRole: 'Parental role',
        labelLegalRole: 'Legal role',
        labelSecondaryElectSPOQuestion: 'Schedule',
        labelDefaultWeekends: 'Weekends',
        labelWithInDistance: '100 miles rule',
        labelAlternateWeekendsPossession: 'Which alternate weekends have you choosen?',
        labelAllWeekends: 'Which weekends have you choosen?',
        alternateWeekendTitle: "Custodial parent's alternate weekend",
        defaultSchoolBreakDatesTitle: 'Use default',
        labelAlternateWeekendsChoose: 'Please choose an alternate weekend from ',
        placeholderAddAlternateWeekendsPossession: 'E.g. 26/02/2024',
        placeholderCalendarName: 'E.g. My Calendar',
        labelAddAlternateWeekendsPossessionTitle: 'Please select one weekend from each month',
        labelAlternateWeekendsStartDate: 'Start Date',
        accountPagePaymentTab: 'Purchases',
        accountPageSettingsTab: 'Settings',
        settingsPageDistributionTitle: 'Email notifications',
        settingDistributionEmailsSubscribe: 'Subscribe',
        settingDistributionEmailsUnsubscribe: 'Unsubscribed',
        accountPageInfoTab: 'Info',
        accountPageAdminStat: 'Admin statistics',
        adminSendingWelcomeEmail: 'Send a welcome email',
        adminAllowWelcomeEmail: 'Allowed',
        adminStoppedWelcomeEmail: 'Stopped',
        infoPageTitle: 'Info',
        infoPageDisplayName: 'Display name',
        infoPageEmail: 'E-mail',
        infoPageEmailVerified: 'E-mail verified',
        infoPageMemberSince: 'Member since',
        infoPageNumberCalendars: 'Calendars',
        infoPagePaymentExpirationDate: 'Purchased access till',
        infoPageFreeAccessUntil: 'Free access till',

        paymentPageEmptyState:
          'To extend your access please select a product.',

        paymentPagePaymentExpDateFuture: 'Purchased access till {{date}}',
        paymentPagePaymentExpDatePast: "Access till {{date}}",
        paymentPageListTitle: 'A $ {{amount}} purchase was made on {{date}}',
        paymentPagePreviousCharges: 'Previous purchases',

        modalTitleEditBirthday: "{{name}}'s {{years, ordinal}} birthday  🎂🥳",
        modalSubTitleEditBirthday: 'Happy birthday!! 🎂',
        modalTitleCreateCalendar: 'Create calendar',
        modalSubTitleCreateCalendar:
          '~_~',
        modalTitleSummerPossessions: 'Summer Possessions for {{calendarName}}',
        modalSubTitleSummerPossessions:
          ' Calendar “{{calendarName}}” will be updated.',
        modalSubTitle2SummerPossessions:
          'Note, according to most decrees parents must inform each other of their Summer possessions before a specific date e.g., April 1st.',
        modalTitleExtendCalendar: 'Extend calendar {{calendarName}} for {{schoolYear}}',
        modalSubTitleExtendCalendar:
          'Enter the school breaks for {{schoolYear}} to extend calendar “{{calendarName}}”.',
        modalSubtitleExtendNonTexasCalendar:
          'Extend calendar “{{calendarName}}”.',

        modalPaymentExpiredTitle: 'Expiration reminder!',
        modalPaymentOfOwnerCalendarExpiredTitle:
          'Owner of this calendar has not renewed their access.',
        modalPaymentExpiredFreeAccessExpDate: 'You have free access until {{freeAccessExpDate}}.',
        modalRestrictedPrevDate: 'The calendar goes as far back as the date it was created.',
        modalPaymentExpiredPaymentExpDate: 'You purchased access until {{paymentExpDate}}',
        modalPaymentExpiredPaymentExpDateMissing:
          'To extend your access, please select a product.',

        modalOnBoarding1Title: 'Welcome',
        modalOnBoarding1Subtitle: "You've made a smart choice 👏🏼",
        modalOnBoarding1BodySection1:
          "We're glad to have you, and we'll do our best to save you time, so you can spend it on your child(ren) instead.",
        modalOnBoarding1BodySection2: 'You can click on any upcoming day to edit it.',

        modalOnBoarding2Title: 'Pricing and Free Trial ',
        modalOnBoarding2Subtitle: 'Enjoy Free trial period! No strings attached!',
        modalOnBoarding2BodySection1:
          "Use Our Days Co-parenting Calendar for a complimentary trial period. Afterwards, you can extend your access with 6, 12, or 24 months.</br></br>For details, navigate to the 'Account' section.",
        modalOnBoarding2BodySection2: 'Enjoy!',

        modalOnBoarding3Title: 'Feedback',
        modalOnBoarding3Subtitle: 'We value your input 🙏🏼',
        modalOnBoarding3BodySection1:
          'We want to build this service based on your needs.\nSend suggestions to: $t(appEmail)',
        modalOnBoarding3BodySection2:
          '💖 Your help in spreading the word and rating Our Days app on Apple Store and Google Play would mean a great deal to us!\n\n Thank you!',

        modalModifiedDayTitle: 'Modified day',
        modalModifiedDaySubTitle:
          'You have modified this day. You can always reset it to its original value.',
        modalLastGeneratedDayTitle: "Calendar's last day",
        modalLastGeneratedDaySubTitle:
          'This is the last generated day. You can extend with one school year at a time.',

        modalEditWeekendSubTitle: 'You can modify alternate weekend per ESPO & SPO',
        modalEditWeekendWarningSubTitle: 'Default weekend warning',
        modalEditWeekendDay2WarningSubTitle: 'Weekend day 2 warning',
        modalEditWeekendNotAllowedForDay2:
          'You cannot modify day 2 of the weekend, please edit day 1',
        modalEditWeekendNotAllowedForDefaultWeekends: 'Default weekends cant be modified',
        modalEditWeekendsPastStartAndEndDates:
          'This weekend has passed. You can modify upcoming days.',

        modalEditSchoolBreakSubTitle: 'Modify school breaks.',
        modalEditSchoolBreakPastStartAndEndDates: 'Sharing calendar is free',
        modalEditSchoolBreakPastStartDate: 'You can modify upcoming dates.',
        modalEditSchoolBreakFormTitleNewYear: 'Christmas/New year {{year}}',
        modalEditWeekendsPastDate: 'You can modify upcoming dates',
        modalExtendCalendarSchoolBreakTitle: 'Enter the dates {{year}}',

        modalProductsTitle: 'Extend access',
        modalProductsSubTitle:
          'No subscriptions, no gotchas.\nEnjoy!',
        modalProductListTitle: 'Choose a product',

        modalCalendarCreatedSuccessfullyTitle: 'Calendar generated successfully 📆',
        modalCalendarCreatedSuccessfullySubTitle:
          'By using Our Days calendar you saved time.',
        modalCalendarCreatedSuccessfullySubTitle2: 'Enjoy 💖',

        modalPaymentSucessTitle: 'Purchase success',
        modalPaymentSucessSubTitle:
          '🙏🏼 Thank you! It might take a few minutes before your account is updated.',

        modalModifiedSummerPossessionsSuccessfullyTitle: 'Summer possession updated',
        modalModifiedSummerPossessionsSuccessfullySubTitle:
          'The summer possession was successfully updated',
        modalModifiedSummerPossessionsSuccessfullySubTitle2:
          'Using $t(appTitle) helps to save time. Enjoy 💖',

        modalCalendarExtendedSuccessfullyTitle: 'The calendar was successfully extended 📆',
        modalCalendarExtendedSuccessfullySubTitle:
          'By using this calendar you save time and money.',
        modalCalendarExtendedSuccessfullySubTitle2: 'Enjoy 💖',

        modalEditCalednarDateMineTitle2: 'This is your day with child(ren) 🥳',
        modalEditCalednarDateOthersTitle2: 'This is the other custodian’s day with the child(ren)',
        modalEditCalendarDateMineSubTitle: 'The child(ren) stays over.',
        modalEditCalendarDateOthersSubTitle:
          'The child(ren) stays over with the other custodian.',
        modalEditCalendarDateGeneralSubTitle2: 'Modify selected period',

        modalEditSchoolBreakCustodianTitle2: 'You can modify upcoming dates',

        modalSummerPossessionsSteps: [
          'Non-custodial parent’s date ranges',
          'CP Summer Weekend Possession',
          'CP Extended Summer Possession',
          'Custodial parent’s date ranges',
          'Summary',
          'Disclaimer',
        ],
        modalSummerPossessionsSecondaryFathersDayLegend:
          "-Father's day is marked with yellow in the date pickers below.\n-Period must be at least 7 consecutive days",
        modalSummerPossessionsPrimaryDefaultDate: 'Use default dates',
        modalSummerPossessionsSecondaryDefaultDate: 'Use default dates',
        modalSummerPossessionsSecondaryDefaultDateLabel: '1st-31st of July',
        modalSummerPossessionsSecondary100MilesDefaultDateLabel: '15th June-27th July',
        modalSummerPossessionsChooseOptionalWeekend: 'Select a weekend',
        modalSummerPossessionsChooseOptionalWeekendEmpty: 'No available weekend to select',

        modalSummerPossessionsSectionSecondaryTitle: 'Non-Custodial parent',
        modalSummerPossessionsSectionPrimaryTitle: 'Custodial parent',

        modalSummerPossessionsErrorTitle:
          "Your input is missing, summer possessions can't be generated",
        modalSummerPossessionsErrorSubTitle:
          'Follow the steps to extend the calendar.',
        modalSummerPossessionsErrorSubTitle2:
          'To update summer possessions for the current school year, review your inputs in the calendar.',

        modalSummerPossessionsDatesTitle: 'Summer possessions {{schoolYear}} for {{calendarName}}',
        modalSummerPossessionsDatesSubTitle:
          'The default Summer Possession period for Non-Custodial Parent is 1st-31st July',

        modalDownloadPfd: 'Download',

        modalDeleteAccountTitle: 'Delete Account',
        modalDeleteAccountSubtitle:
          ' Are you sure you want to delete your account?\n Deleting your account removes all your data from Our Days Calendar.',
        modalDeleteAccountProvideCredential: 'Please, provide your credential:',

        modalEditCalendarFillEror: 'Please, fill all dates',

        alertExtendCalendarTitle: 'EXTEND THE CALENDAR FOR THE COMING SCHOOL YEAR',
        alertExtendOldCalendarTitle:
          'Older version calendars are not modifiable. Please create a new calendar.',
        alertExtendSharedCalendarTitle:
          'The originator of this calendar created schedule till {{date}}',
        alertExtendCalendarSubTitle: 'Click "Continue" to enter the dates.',
        alertExtendCalendarWarning:
          'The last generated date for this calendar: {{lastPopulatedDate}}',
        alertExtendCalendarDanger:
          'Got questions or feedback?\nemail: info@ourdayscalendar.com',

        ownerFullDayPrimaryColorMeaning: 'Child is with me.',
        ownerFullDayPrimaryColorMeaningOnlyView: 'Child is with {{name}}.',
        ownerFullDaySecondaryColorMeaning: 'Child is with other custodian.',
        ownerChangeFullDayPrimaryColorMeaning: 'Assign to Me',
        ownerChangeFullDaySecondaryColorMeaning: 'Assign to {{name}}',

        createCalendarEmptyStateTitle: 'Go ahead and create your calendar',
        sharedCalendarEmptyStateTitle: 'You don’t have shared calendars',
        createCalendarEmptyStateSubTitle:
          'Save time and money by using Our Days calendar.',
        createCalendarEmptyStateButtonTitle: 'Create my first calendar',

        incompleteCalendarEmptyStateTitle: "There is no draft calendar",

        createCalendarSchoolbreakTitle: 'Please input dates for {{schoolYear}}\n',

        infoAboutClickingDates:
        "Our Days also on <a style='color:#3366CC' href='https://apps.apple.com/us/app/our-days-calendar/id1524196903?l' target='_blank'>Apple Store</a> and <a style='color:#3366CC'  href='https://play.google.com/store/apps/details?id=com.ourdayscalendar.android.app&hl=en_GB&gl=US' target='_blank'>Google Play</a>",

        infoMissingSchoolBreakForGivenSchoolYear: 'Not set for given school-year',

        calendarInfoGeneralTitle: 'General',
        calendarName: 'Calendar name',
        calendarCreatedAt: 'Created',
        calendarUpdatedAt: 'Updated',
        calendarEarliestDay: "Calendar's first date",
        // Last date: "Last date",
        calendarSchoolBreaks: 'School breaks',
        calendarId: 'Calendar ID',

        calendarInfoBirthdayTitle: 'Child(ren)',
        calendarInfoSchoolbreakTitle: 'School breaks for {{schoolYear}}',
        calendarInfoWeekendsTitle: 'Weekends',
        calendarInfoSchoolbreakInfo:
          'Our Days is available on Apple Store and Google Play.',
        calendarInfoLegalTitle: 'Visitation',

        calendarInfoSchoolbreak: 'School breaks',

        customPossessionTitle: 'Custom weekly recurrence',
        customPossessionSubTitle: 'Repeat on:',

        fathersDayTitle: "Happy Father's Day",
        mothersDayTitle: "Happy Mother's Day",
        fathersDayInfo: 'Visitation during this weekend is generally with the father.',
        mothersDayInfo: 'Visitation during this weekend is generally with the mother.',

        calendarEventModifiedName: 'Modified',
        calendarEventSchoolBreak: 'School break {{schoolBreak}}',
        calendarEventToday: 'Today',

        calendarEventWeekends: 'Weekend',
        calendarEventHoliday: 'Holiday',
        calendarCountSleepTitle: 'Count sleepovers',

        christmas_day: 'Christmas day',
        christmas_eve: 'Christmas Eve',
        columbus_day: 'Columbus day',
        day_after_thanksgiving: 'Black Friday',
        fathers_day: "Father's day",
        mothers_day: "Mother's day",
        patrick_day: "Saint Patrick's day",
        good_friday: 'Good Friday',
        easter: 'Easter Sunday',
        valentines_day: "Valentine's day",
        independence_day: 'Independence day',
        halloween: 'Halloween',
        veterans_day: "Veteran's day",
        labor_day: 'Labor Day',
        martin_luther_king_jnr_day: 'Martin Luther King Jr. day',
        memorial_day: 'Memorial day',
        new_years_day: "New Year's day",
        new_years_eve: "New Year's Eve",
        thanksgiving: 'Thanksgiving day',
        washingtons_birthday: "George Washington's birthday",

        privacyTitle: 'Privacy Policy',
        privacyBody: 'Please read our <0>Privacy Policy</0>',

        resetDatesTitle: 'Reset range',

        termsConditionsTitle: 'Terms & Conditions',
        termsConditionsBody: 'Please read our <0>Terms & Condition</0>',

        outsideCalendarRangeTitle: 'Calendar {{calendarName}} is not accessible',
        outsideCalendarRangeSubtitleInvalidDate:
          'Incorrect date given in the url. Revise the date to see correct month.',
        outsideCalendarRangeSubtitleBeforeFirstPopulatedDate:
          'This calendar started {{firstPopulatedDate}}, there is no data prior to this date.',
        outsideCalendarRangeSubtitleAfterLastPopulatedDate:
          'The current calendar has generated schedules until {{lastPopulatedDate}}. To see months after this date, please extend your access.',
        outsideCalendarRangeSubtitleFreeAccessExpired:
          'Free trial ends {{freeAccessExpDate}}.',
        outsideCalendarRangeSubtitlePaymentExpired:
          'Purchased access till {{paymentExpDate}}.',
        outsideCalendarRangeDescriptionFreeAccessExpiredAndPaymentExpired:
          'To extend the calendar choose a product.',

        footerLinkAboutUs: 'About us',
        footerLinkTermsConditions: 'Terms & Condition',
        footerLinkPrivacyPolicy: 'Privacy Policy',
        footerCompanyAddress: '© {{ year }} Our Days LLC, Houston, USA',

        unavailableDate: 'N/A',

        adminFormCalendarReport: 'Download calendar report',
        adminFormPaymentReport: 'Download payment report',
        adminFormUserReport: 'Download user report',

        editCalendarDayCheckBoxLabel: 'Sleeps over',

        modalShareTitle: 'SHARE CALENDAR',
        modalShareSubtitle: 'Note, "view" access will be granted!',
        modalShareInputTitle: 'Share with:',
        modalCurrentlyShared: 'Shared with:',
        modalMajorUpdateTitle: 'Hi {{displayName}}!',
        modalMajorUpdateText:
          'Due to an upgrade, older calendars are unmodifieble. We apologize for any inconvience. Please create a new calendar.',
        modalMajorUpdateTextParagraph: 'We hope that you find the new features useful.',

        expiredSubscriptionTitle: 'Your access has expired!',
        expiredSharedCalendarSubscriptionTitle: 'Access Expired!',
        expiredSubscriptionSubtitle: 'To extend your access, please see the</br> Products page.',
        expiredSharedCalendarSubscriptionSubtitle:
          'The owner of this calendar has expired access.</br> Please contact them.',
        expiredSubscriptionButton: 'Products',
        goingToExpireSubtitle:
          'Your access will expire in <b>{{days}}</b>. To extend your access, please visit the Products page.</br></br>For assistance contact:\
           <a style="display:block" href="mailto:support@ourdayscalendar.com">support@ourdayscalendar.com</a>',
        startingDate: 'Start date',
        parentStartingVisitation: 'Parent starting visitation',
        startingDateExplanation: 'The new schedule starts on this date.',
        parent1is: 'Parent 1 is',
        parent1WithName: '{{name}} is',
        parent: 'Parent',
        recurrence: 'Recurrence',
      },
    },
  },
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
    format: function (value, format) {
      if (format === 'ordinal') {
        if (value === 1 || (value > 20 && value % 10 === 1)) {
          return `${value}st`;
        } else if (value === 2 || (value > 20 && value % 10 === 2)) {
          return `${value}nd`;
        } else if (value === 3 || (value > 20 && value % 10 === 3)) {
          return `${value}rd`;
        } else {
          return `${value}th`;
        }
      }
      if (format === 'dateUnit') {
        if (value === 'days') {
          return `days`;
        } else if (value === 'weeks') {
          return `weeks`;
        } else if (value === 'months') {
          return `months`;
        }
      }
    },
  },
});
