import { green } from '@material-ui/core/colors';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { closeModal } from 'reducers/modals';
import Button from 'shared/Button/Button';
import Icon from 'shared/Icons/Icons';
import firebase from 'utils/firebase';

import ModalContent from '../ModalContent/ModalContent';

export default function SuccessExtendCalendar() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    firebase.analytics().logEvent('modal_show_modify_summer_possession_success');
  }, []);

  function onCloseModal() {
    firebase.analytics().logEvent('modal_cancel_modify_summer_possession_success');
    dispatch(closeModal());
  }

  return (
    <ModalContent
      title={t('modalModifiedSummerPossessionsSuccessfullyTitle')}
      subtitle={t('modalModifiedSummerPossessionsSuccessfullySubTitle')}
      subtitle2={t('modalModifiedSummerPossessionsSuccessfullySubTitle2')}
      icon={<Icon icon="success" color={green[500]} />}
      actions={
        <Button color="primary" onClick={onCloseModal}>
          {t('actionOk')}
        </Button>
      }
    ></ModalContent>
  );
}
