import { Box } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';

import useStyles from './RotationCalendarStartDate.css.jsx';

const RotationCalendarStartingDate = props => {
  const { firstPopulatedDate, date, onChange, inputStyle = {} } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box className={classes.root}>
      <DatePicker
        disabled={props.disabled}
        autoOk
        fullWidth
        inputVariant="outlined"
        margin="normal"
        format="MMM DD, YYYY"
        // maxDate={moment(lastPopulatedDate)}
        minDate={moment(firstPopulatedDate).startOf('month')}
        label={t('startingDate')}
        placeholder={t('startingDate')}
        views={['date']}
        value={date}
        style={{ ...inputStyle }}
        onChange={date => {
          onChange(date.format('YYYY-MM-DD'));
        }}
      />
    </Box>
  );
};

export default RotationCalendarStartingDate;
