import { Box } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import Typography from 'shared/Typography/Typography';

import styles from './Loader.module.css';

function Loader() {
  return (
    <div className={styles.main}>
      <CircularProgress />
      <Box style={{ width: '100%', textAlign: 'center' }}>
        <Typography variant="h6" className={styles.loading}>
          Loading
        </Typography>
      </Box>
    </div>
  );
}

export default Loader;
