import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from 'shared/Icons/Icons';
import Typography from 'shared/Typography/Typography';
import firebase from 'utils/firebase';

import useStyles from './Checkins.css.jsx';

function Checkins() {
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    firebase.analytics().logEvent('page_show_checkins');
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.overlay}></div>
      <div className={classes.icon}>
        <Icon icon="near-me" />
      </div>
      <div className={classes.title}>
        <Typography classes={{ root: classes.titleRoot }} variant="h4">
          {t('checkinEmptyStateTitle')}
        </Typography>
      </div>
      <div className={classes.subtitle}>
        <Typography variant="subtitle2">{t('checkinEmptyStateSubTitle')}</Typography>
      </div>
    </div>
  );
}

export default Checkins;
