import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  adminContainer: {
    padding: '1rem',
    display: 'flex',
    gap: '2rem',
    alignItems: 'center',
    flexDirection: 'column',
  },
  pickers: {
    display: 'flex',
    justifyContent: 'center',
    gap: '2rem',
  },
  buttonBox: {
    display: 'flex',
  },
  button: {
    padding: '1rem',
    color: '#494747',
    fontSize: '1rem',
    fontWeight: '500',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    '&:hover': {
      color: '#494747',
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
      borderRadius: '5px',
    },
  },
}));
