import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import rrulePlugin from '@fullcalendar/rrule';
import { withStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import landingPageStyle from 'assets/jss/material-kit-pro-react/views/landingPageStyle.jsx';
import CalendarTitle from 'components/CalendarTitle/CalendatTitle';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody';
import SleepCounter from 'components/SleepOverCouter/SleppCounter';
import { ELECT_SPO_TEXAS, LEGAL_ROLE_VIEW, MODAL_CALENDAR_INFO } from 'constants.js';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { openModal } from 'reducers/modals';
import Button from 'shared/Button/Button';
import Icon from 'shared/Icons/Icons';
import Loader from 'shared/Loader/Loader';
import Typography from 'shared/Typography/Typography.jsx';
// import WarningBox from 'shared/WarningBox/WarningBox.jsx';
import * as calendarUtil from 'utils/calendar';

import useStyles from './Calendars.css.jsx';
import {
  calendarEventsBuilder,
  handleParent,
  onChangeCurrentDate,
  onChangeDateNext,
  onChangeDatePrevious,
  onChangeDateToday,
  onDateClick,
  onEventClick,
  onShareHandle,
  onSummerSessions,
  printToPdf,
} from './renderCalendarLogic.js';

const parent1 = 'parent1';
const parent2 = 'parent2';
let touchStart = 0;
let touchEnd = 0;

const RenderCalendar = ({
  calendars,
  currentCalendarId,
  calendarParent1Name,
  calendarParent2Name,
  currentPagePath,
  history,
  currentDate,
  // openSummerPossessionsWarnings = false,
  // setOpenSummerPossessionsWarnings,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const calendarRef = React.createRef();

  const userOwnerDataLoading = useSelector(state => state.user.loading);
  const templateSchoolBreaks = useSelector(state => state.schoolBreaks.templates.schoolBreaks);

  const currentCalendar = calendarUtil.getCurrentCalendar(calendars, currentCalendarId);
  const schoolYear = currentCalendar && currentCalendar.schoolYear;
  const shouldExtendCalendar = calendarUtil.shouldExtendCalendar(currentCalendar, currentDate);
  const shouldExtendCalendarDatePicker = calendarUtil.shouldExtendCalendarDatePicker(
    currentCalendar,
    currentDate,
  );
  // const summerPossessions = currentCalendar && currentCalendar.summerPossessions;
  const lastPopulatedDate = currentCalendar && currentCalendar.lastPopulatedDate;
  const role = currentCalendar && currentCalendar.accessCard.role;
  const events =
    currentCalendar && calendarEventsBuilder({ currentCalendar, classes, templateSchoolBreaks, t });
  function handleTouchStart(touchStartEvent) {
    touchStart = touchStartEvent.targetTouches[0].clientX;
  }

  function handleTouchMove(touchMoveEvent) {
    touchEnd = touchMoveEvent.targetTouches[0].clientX;
  }

  function handleTouchEnd() {
    if (touchStart - touchEnd > 75 && touchEnd > 0) {
      touchStart = 0;
      touchEnd = 0;
      onChangeDateNext({
        currentCalendar,
        shouldExtendCalendar,
        dispatch,
        calendarRef,
        currentPagePath,
        currentCalendarId,
        history,
      });
    }

    if (touchStart - touchEnd < -75 && touchEnd > 0) {
      touchStart = 0;
      touchEnd = 0;
      onChangeDatePrevious({
        calendarRef,
        currentPagePath,
        currentCalendarId,
        currentCalendar,
        dispatch,
        history,
      });
    }
  }

  if (!currentCalendar) {
    return <Loader />;
  }

  if (userOwnerDataLoading) {
    return <Loader />;
  }
  return (
    <div
      id="print_to_pdf"
      style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
    >
      {/* {calendarUtil.shouldShowSummerPossessionsWarning({
        currentDate,
        lastPopulatedDate: currentCalendar.lastPopulatedDate,
        summerPossessions,
      }) &&
        ELECT_SPO_TEXAS.includes(currentCalendar.electedSecondary) &&
        openSummerPossessionsWarnings && (
          <WarningBox
            showClose={true}
            setOpenWarnings={setOpenSummerPossessionsWarnings}
            openWarnings={openSummerPossessionsWarnings}
            text={
              <>
                <span>{t('warningUpdateSummerPossessions')}</span>
                <Button
                  color="primary"
                  onClick={() => {
                    onSummerSessions({
                      currentCalendar,
                      shouldExtendCalendar,
                      dispatch,
                      openModal,
                    });
                  }}
                >
                  {t('actionWarningSummerPossessions')}
                </Button>
              </>
            }
          />
        )} */}
      <div className={classes.titleContainer}>
        <CalendarTitle
          currentDate={currentDate}
          onChangeCurrentDate={date =>
            onChangeCurrentDate({
              date,
              dispatch,
              openModal,
              currentCalendar,
              shouldExtendCalendarDatePicker,
              currentPagePath,
              currentCalendarId,
              history,
            })
          }
          datesIds={currentCalendar.datesIds}
          calendarCreatedDate={currentCalendar.createdDate}
        />

        <SleepCounter classes={classes} calendar={currentCalendar} />

        <div className={classes.titleButtons}>
          <div className={classes.shareBtnBox}>
            {ELECT_SPO_TEXAS.includes(currentCalendar.electedSecondary) &&
              currentCalendar.accessCard.role !== 'view' && (
                <Button
                  color="primary"
                  variant="outlined"
                  size="small"
                  onClick={() =>
                    onSummerSessions({
                      currentCalendar,
                      shouldExtendCalendar,
                      dispatch,
                      openModal,
                    })
                  }
                >
                  {t('actionShowSummerPossessions')}
                </Button>
              )}
            {role === LEGAL_ROLE_VIEW ? null : (
              <Button
                variant="contained"
                style={{ color: '#fff', backgroundColor: '#1976D2' }}
                startIcon={<Icon icon="user" />}
                onClick={() => onShareHandle({ dispatch })}
              >
                {t('share')}
              </Button>
            )}
          </div>
          <div>
            <IconButton
              aria-label="Calender today"
              onClick={() =>
                onChangeDateToday({ calendarRef, currentPagePath, currentCalendarId, history })
              }
            >
              <Icon icon="calendar-day" />
            </IconButton>

            <IconButton
              aria-label="Calender today"
              onClick={() =>
                printToPdf({ dispatch, currentCalendarId, schoolYear, currentCalendar })
              }
            >
              <Icon icon="export" />
            </IconButton>

            <IconButton
              aria-label="Calender previous month"
              onClick={() =>
                onChangeDatePrevious({
                  calendarRef,
                  currentPagePath,
                  currentCalendarId,
                  currentCalendar,
                  dispatch,
                  history,
                })
              }
            >
              <Icon icon="arrow-left" />
            </IconButton>
            <IconButton
              aria-label="Calender next month"
              onClick={() =>
                onChangeDateNext({
                  currentCalendar,
                  shouldExtendCalendar,
                  dispatch,
                  calendarRef,
                  currentPagePath,
                  currentCalendarId,
                  history,
                })
              }
            >
              <Icon icon="arrow-right" />
            </IconButton>
            <IconButton
              aria-label="Info"
              onClick={() =>
                dispatch(openModal({ type: MODAL_CALENDAR_INFO, disableClose: false }))
              }
            >
              <Icon icon="info" />
            </IconButton>
          </div>
        </div>
      </div>

      <div
        className={classes.calendarContainer}
        onTouchMove={touchMoveEvent => handleTouchMove(touchMoveEvent)}
        onTouchStart={touchStartEvent => handleTouchStart(touchStartEvent)}
        onTouchEnd={touchEndEvent => handleTouchEnd(touchEndEvent)}
      >
        <FullCalendar
          ref={calendarRef}
          firstDay={0}
          dateClick={onDateClick({ events, schoolYear, dispatch, t, currentCalendar })}
          eventClick={info => onEventClick({ info, currentCalendar, dispatch, schoolYear, t, events })}
          header={{
            left: '',
            center: '',
            right: '',
          }}
          validRange={{
            start: moment(currentCalendar.createdDate).startOf('month').format('YYYY-MM-DD'),
            end: moment(lastPopulatedDate).endOf('month').add(1, 'day').format('YYYY-MM-DD'),
          }}
          defaultDate={currentDate.format('YYYY-MM-DD')}
          defaultView="dayGridMonth"
          plugins={[dayGridPlugin, interactionPlugin, rrulePlugin]}
          events={events}
          height="parent"
          contentHeight="auto"
        />
      </div>

      <div className={classes.legend}>
        <div className={classes.legendItem}>
          <span className={classes.legendMyFullday}></span>
          <span className={classes.legendText}>
            <Typography
              variant="subtitle2"
              onClick={() => handleParent({ parent1, dispatch })}
              style={{ cursor: 'pointer' }}
            >
              {calendarParent1Name ? calendarParent1Name : 'Parent 1'}
            </Typography>
          </span>
        </div>

        <div className={classes.legendItem}>
          <span className={classes.legendOtherFullday}></span>
          <span className={classes.legendText}>
            <Typography
              variant="subtitle2"
              onClick={() => handleParent({ parent2, dispatch })}
              style={{ cursor: 'pointer' }}
            >
              {calendarParent2Name ? calendarParent2Name : 'Parent 2'}
            </Typography>
          </span>
        </div>
        {role === LEGAL_ROLE_VIEW ? null : (
          <div className={classes.right}>
            <Card className={classes.card}>
              <CardBody className={classes.cardBody}>
                <Typography
                  variant="body1"
                  dangerouslySetInnerHTML={{ __html: t('infoAboutClickingDates') }}
                />
              </CardBody>
            </Card>
          </div>
        )}
      </div>
    </div>
  );
};

export default withStyles(landingPageStyle)(RenderCalendar);
