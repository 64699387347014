import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from 'shared/Typography/Typography';
import { validSchoolBreakDatesRanges } from 'utils/validator';

import style from './SchoolBreakDatePicker.module.css';

function SchoolBreakDatePicker(props) {
  const { t } = useTranslation();
  const [hasEndCustomError, setHasEndCustomError] = useState(false);
  const schoolBreak = props.schoolBreak;
  const templateSchoolBreak = props.templateSchoolBreak;
  const startProps = props.startProps;
  const endProps = props.endProps;
  const type = props.type;
  const title = props.title || 'schoolBreak';
  const ranges = validSchoolBreakDatesRanges({
    type,
    start: schoolBreak.start,
    templateSchoolBreak,
  });
  function onChangeStartDate(date, value) {
    if (props.onChangeStartDate) {
      props.onChangeStartDate(date, value);
    }
  }

  function onChangeEndDate(date, value) {
    if (props.onChangeEndDate) {
      props.onChangeEndDate(date, value);
    }
  }

  if (!schoolBreak) {
    return null;
  }

  return (
    <>
      <Typography variant="subtitle2">
        {t(title, {
          context: type,
          year: templateSchoolBreak && moment(templateSchoolBreak.end).format('YYYY'),
        })}
      </Typography>
      <div className={style.schoolBreakDateWrapper}>
        <div className={style.schoolBreakStartDate}>
          <DatePicker
            fullWidth
            autoOk
            inputVariant="outlined"
            margin="normal"
            minDate={ranges.start.min}
            maxDate={ranges.start.max}
            format="MMM DD, YYYY"
            id={`schoolBreakStartDate-${type}`}
            label={t('labelSchoolBreakStartDate')}
            value={schoolBreak.start ? moment(schoolBreak.start) : null}
            initialFocusedDate={templateSchoolBreak && moment(templateSchoolBreak.start)}
            onChange={onChangeStartDate}
            {...startProps}
          />
          <span className={style.furtherInfo}>{t('furtherInformationStart')}</span>
        </div>
        <div className={style.schoolBreakEndDate}>
          <DatePicker
            fullWidth
            autoOk
            inputVariant="outlined"
            margin="normal"
            minDate={ranges.end.min}
            maxDate={ranges.end.max}
            format="MMM DD, YYYY"
            id={`schoolBreakEndDate-${type}`}
            label={t('labelSchoolBreakEndDate')}
            value={schoolBreak.end ? moment(schoolBreak.end) : null}
            initialFocusedDate={templateSchoolBreak && moment(templateSchoolBreak.end)}
            onChange={onChangeEndDate}
            onError={error => {
              error && type === 'christmas' && setHasEndCustomError(true);
              !error && setHasEndCustomError(false);
            }}
            {...(hasEndCustomError ? { error: true } : {})}
            {...(hasEndCustomError
              ? { helperText: 'Christmas break (per SPO) ends on Dec. 28th' }
              : {})}
            {...endProps}
          />
          <span className={style.furtherInfo}>{t('furtherInformationEnd')}</span>
        </div>
      </div>
    </>
  );
}

export default SchoolBreakDatePicker;
