import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  settingContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },

  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));
