import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { closeModal } from 'reducers/modals';
import Button from 'shared/Button/Button';
import firebase from 'utils/firebase';

import ModalContent from '../ModalContent/ModalContent';

export default function SummerPossessionsError() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    firebase.analytics().logEvent('modal_show_error_summer_possession');
  }, []);

  function onCloseModal() {
    firebase.analytics().logEvent('modal_cancel_error_summer_possession');
    dispatch(closeModal());
  }

  function getActions() {
    return (
      <>
        <Button onClick={onCloseModal} color="primary">
          {t('actionOk')}
        </Button>
      </>
    );
  }

  return (
    <>
      <ModalContent
        title={t('modalSummerPossessionsErrorTitle')}
        subtitle={t('modalSummerPossessionsErrorSubTitle')}
        subtitle2={t('modalSummerPossessionsErrorSubTitle2')}
        actions={getActions()}
      />
    </>
  );
}
