import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  root: {
    marginTop: '40px',
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',
  },
  dateBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dateBox_icon: {
    display: 'flex',
    gap: '12px',
    alignItems: 'center',
  },
  dateBox_icon_title: {
    color: 'rgba(33, 33, 33, 0.87)',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '19px',
    width: '190px',
    maxWidth: '190px',
    '@media (max-width: 600px)': {
      width: 'auto',
      marginRight: '16px',
    },
  },
  dateBox_date: {
    color: '#212121',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
  },
  formLabel: {
    color: '#212121!important',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '19px',
  },
  subtitle: {
    color: '#79747E',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    marginTop: '16px',
  },
  'MuiFormControl-marginNormal': {
    margin: '0!important',
  },
}));
