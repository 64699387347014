import { TextField } from '@mui/material';
import Box from '@mui/material/Box';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useStyles from './SleepCounter.css.jsx';

function SleepCounter({ calendar }) {
  const { t } = useTranslation();

  const classes = useStyles();
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [sleepDays, setSleepDays] = useState({
    owner1: 0,
    owner2: 0,
  });
  const allDates = calendar.allDates;
  let owner2count = 0;
  let owner1count = 0;
  useEffect(() => {
    allDates &&
      fromDate &&
      toDate &&
      toDate.isAfter(fromDate) &&
      allDates.map(e => {
        for (let key in e) {
          if (
            moment(e[key].date).isSameOrAfter(fromDate) &&
            moment(e[key].date).isSameOrBefore(toDate)
          ) {
            if (Number(e[key].owner) === 1) {
              owner1count++;
            } else {
              owner2count++;
            }
          }
        }
      });
    setSleepDays({ owner1: owner1count, owner2: owner2count });
  }, [fromDate, toDate]);
  function countPercent(ownerQtt) {
    const totalSleppDays = sleepDays.owner1 + sleepDays.owner2;
    return `${Math.round((ownerQtt * 100) / totalSleppDays)}`;
  }
  return (
    <Box className={classes.container}>
      <Box className={classes.title}>{t('calendarCountSleepTitle')}</Box>
      <Box className={classes.content_box}>
        <Box className={classes.datePickers_box}>
          <Box className={classes.datePicker_box}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                views={['day']}
                label="From"
                format={'MMM DD, YYYY'}
                minDate={moment(calendar.firstPopulatedDate)}
                maxDate={moment(calendar.lastPopulatedDate).subtract(2, 'days')}
                value={fromDate}
                onChange={newValue => {
                  setFromDate(newValue);
                  setToDate(null);
                  setSleepDays({
                    owner1: 0,
                    owner2: 0,
                  });
                }}
                renderInput={params => (
                  <TextField {...params} helperText={null} placeholder="tt.mm.jjjj" />
                )}
              />
            </LocalizationProvider>
          </Box>
          <Box className={classes.datePicker_box}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                views={['day']}
                label="To"
                format={'MMM DD, YYYY'}
                minDate={
                  fromDate ? moment(fromDate).add(1, 'days') : moment(calendar.firstPopulatedDate)
                }
                maxDate={moment(calendar.lastPopulatedDate)}
                value={toDate}
                defaultValue={toDate}
                onChange={newValue => {
                  setToDate(newValue);
                  setSleepDays({
                    owner1: 0,
                    owner2: 0,
                  });
                }}
                renderInput={params => <TextField {...params} helperText={null} />}
              />
            </LocalizationProvider>
          </Box>
        </Box>
        <Box className={classes.PieChartBox}>
          <Box>
            <Box className={classes.PieChart1}>
              <span className={classes.counter}>{sleepDays.owner1}</span>
              <span className={classes.percentage}>
                {sleepDays.owner1 && countPercent(sleepDays.owner1)}%
              </span>
            </Box>
            <Box className={classes.parentName}>
              {calendar.parent1Name ? calendar.parent1Name : 'Parent 1'}
            </Box>
          </Box>
          <Box>
            <Box className={classes.PieChart2}>
              <span className={classes.counter}>{sleepDays.owner2}</span>
              <span className={classes.percentage}>
                {sleepDays.owner2 && countPercent(sleepDays.owner2)}%
              </span>
            </Box>
            <Box className={classes.parentName}>
              {calendar.parent1Name ? calendar.parent2Name : 'Parent 2'}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default SleepCounter;
