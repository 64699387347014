import firebase from 'utils/firebase';

import { getUser } from './user';

const db = firebase.firestore();

export const USER = 'auth/USER';
export const LOADING = 'auth/loading';

const initialState = {
  loading: true,
  user: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case USER: {
      return {
        ...state,
        user: action.user,
        loading: false,
      };
    }
    case LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    default: {
      return { ...state };
    }
  }
};

export const addAuthStateListener =
  () =>
  async (dispatch, getState, { firebase }) => {
    dispatch({ type: LOADING });
    firebase.auth().onAuthStateChanged(async auth => {
      if (!auth) {
        dispatch({ type: USER, user: null });
        return;
      }
      db.collection('users').doc(auth.uid).update({
        lastLogin: auth.metadata.lastSignInTime,
        lastLoginInDevice: 'Web',
      });
      const devicesRef = await db
        .collection('users')
        .doc(auth.uid)
        .collection('devices')
        .doc(auth.uid)
        .get();

      const devicesData = devicesRef.data();
      if (devicesData) {
        db.collection('users').doc(auth.uid).collection('devices').doc(auth.uid).update({
          web: auth.metadata.lastSignInTime,
        });
      } else {
        db.collection('users').doc(auth.uid).collection('devices').doc(auth.uid).set({
          web: auth.metadata.lastSignInTime,
        });
      }

      const user = {
        displayName: auth.displayName,
        email: auth.email,
        emailVerified: auth.emailVerified,
        photoURL: auth.photoURL,
        isAnonymous: auth.isAnonymous,
        uid: auth.uid,
        providerData: auth.providerData,
      };
      dispatch({ type: USER, user });
      dispatch(getUser());
    });
  };
