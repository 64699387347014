import React from 'react';
import { useTranslation } from 'react-i18next';
import ExpiredCalendarSubscriptionIcon from 'shared/Icons/svgs/ExpireSubscription.jsx';

import useStyles from './ExpiredPage.css.jsx';

const ExpiredSharedCalendarSubscription = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <div className={classes.imageBox}>
        <ExpiredCalendarSubscriptionIcon />
      </div>
      <div
        className={classes.titleBox}
        dangerouslySetInnerHTML={{ __html: t('expiredSharedCalendarSubscriptionTitle') }}
      ></div>
      <div
        className={classes.subtitleBox}
        dangerouslySetInnerHTML={{ __html: t('expiredSharedCalendarSubscriptionSubtitle') }}
      />
    </div>
  );
};

export default ExpiredSharedCalendarSubscription;
