import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import { PARENT1_MOTHER_FATHER } from 'constants';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    '&>div>fieldset': {
      top: 0,
    },
    '&>div>fieldset>legend': {
      display: 'none',
    },
  },
}));
const useStyles2 = makeStyles(theme => ({
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const Parent1MotherFather = props => {
  const {
    disabled,
    setParent1MotherFatherDay,
    parent1MotherFatherDay,
    showParent1Label = false,
  } = props;
  const classes = useStyles();
  const classes2 = useStyles2();
  const { t } = useTranslation();

  return (
    <FormControl
      fullWidth
      className={!showParent1Label ? classes.formControl : classes2.formControl}
      variant="outlined"
    >
      {showParent1Label && <InputLabel htmlFor="parent1MotherFather">{t('parent1is')}</InputLabel>}

      <Select
        value={parent1MotherFatherDay}
        onChange={event => setParent1MotherFatherDay(event.target.value)}
        labelWidth={100}
        disabled={disabled}
      >
        {PARENT1_MOTHER_FATHER.map((data, index) => (
          <MenuItem key={index} value={data.value}>
            {data.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Parent1MotherFather;
