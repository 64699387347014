import { getCalendars } from 'reducers/calendar';
import { closeModal } from 'reducers/modals';
import { addSnackbar } from 'reducers/snackbar';

import api from '../utils/api';

export const SAVE_CALENDAR_INFO_START = 'calendarInfo/FETCH_CALENDARS_START';
export const SAVE_CALENDAR_INFO_DONE = 'calendarInfo/FETCH_CALENDARS_DONE';
export const SAVE_CALENDAR_INFO_ERROR = 'calendarInfo/FETCH_CALENDARS_ERROR';

const initialState = {
  loading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SAVE_CALENDAR_INFO_START: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case SAVE_CALENDAR_INFO_DONE: {
      return {
        ...state,
        loading: false,
        error: null,
      };
    }
    case SAVE_CALENDAR_INFO_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    default: {
      return { ...state };
    }
  }
};

export const saveCalendarInfo = data => async dispatch => {
  dispatch({ type: SAVE_CALENDAR_INFO_START });
  try {
    await api.editCalendar(data);

    dispatch({ type: SAVE_CALENDAR_INFO_DONE });
    dispatch(closeModal());
    dispatch(getCalendars({}));
    dispatch(
      addSnackbar({
        text: `Calendar updated`,
        variant: 'success',
        closeButton: false,
        //retryButton: () => dispatch(saveCalendarInfo())
      }),
    );
  } catch (error) {
    dispatch(
      addSnackbar({
        text: `${error.message}`,
        variant: 'error',
        closeButton: true,
        //retryButton: () => dispatch(saveCalendarInfo())
      }),
    );
    dispatch({ type: SAVE_CALENDAR_INFO_ERROR, error });
  }
};
