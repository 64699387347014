import moment from 'moment';

export const defaultSecondaryRange1Dates = (schoolBreaks, withinDistance) => {
  if (withinDistance === false) {
    return {
      start: moment(schoolBreaks.summer.start).clone().month(5).date(15),
      end: moment(schoolBreaks.summer.start).clone().month(6).date(27),
    };
  }
  return {
    start: moment(schoolBreaks.summer.end).month(6).date(1),
    end: moment(schoolBreaks.summer.start).month(6).date(31),
  };
};

export const fathersDay = schoolYear => {
  // FIXME: remove hard coded father's day
  const dates = {
    1920: moment('2020-06-21'),
    2021: moment('2021-06-20'),
    2122: moment('2022-06-19'),
    2223: moment('2023-06-18'),
    2324: moment('2024-06-16'),
    2425: moment('2025-06-15'),
    2526: moment('2026-06-21'),
    2627: moment('2027-06-20'),
    2728: moment('2028-06-18'),
    2829: moment('2029-06-17'),
    2930: moment('2030-06-16'),
  };
  return dates[schoolYear];
};

export const mothersDay = schoolYear => {
  const dates = {
    1920: moment('2020-05-09'),
    2021: moment('2021-05-09'),
    2022: moment('2022-06-08'),
    2023: moment('2023-05-14'),
  };
  return dates[schoolYear];
};
