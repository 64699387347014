import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  container: {
    marginTop: '-30px',
    marginRight: '24px',
    '@media (max-width: 600px)': {
      marginTop: '24px',
      marginBottom: '52px',
      marginRight: 0,
    },
  },
  title: {
    fontWeight: 500,
    fontSize: '16px',
    color: 'black',
    lineHeight: '140%',
    marginBottom: '8px',
  },
  content_box: {
    display: 'flex',
    alignItems: 'center',
    gap: '32px',
    '@media (max-width: 600px)': {
      flexDirection: 'column',
    },
  },
  datePickers_box: {
    display: 'flex',
    gap: '24px',
  },

  datePicker_box: {
    maxWidth: '166px',
    '&>div>div>input': {
      fontSize: '13px',
    },
  },

  PieChartBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '40px',
    gap: '24px',
  },
  PieChart1: {
    fontWeight: '800',
    color: '#FFFF',

    borderRadius: '50%',
    backgroundColor: 'rgba(31, 156, 163, 1)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '56px',
    height: '56px',
  },
  PieChart2: {
    fontWeight: '800',
    color: '#FFFF',
    // height: "100%",
    borderRadius: '50%',
    backgroundColor: 'rgba(255, 122, 120, 1)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '56px',
    height: '56px',
  },
  percentage: {
    fontSize: '12px',
  },
  counter: {
    fontSize: '18px',
  },
  parentName: {
    color: '#000000',
    lineHeight: '21px',
    fontSize: '16px',
    fontWeight: 400,
    textAlign: 'center',
    // overflowWrap:'break-word',
    overflow: 'hidden',
    maxWidth: '60px',
    marginTop: '4px',
    marginBottom: '-30px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));
