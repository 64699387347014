import withStyles from '@material-ui/core/styles/withStyles';
import landingPageStyle from 'assets/jss/material-kit-pro-react/views/landingPageStyle.jsx';
import RenderCalendar from 'components/Calendar/RenderCalendar';
import { MODAL_CREATE_CALENDAR, MODAL_MAJOR_UPDATE_INFORMATION } from 'constants.js';
import { sortBy } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openModal } from 'reducers/modals';
import { getSchoolBreakTemplates } from 'reducers/schoolBreaks';
import {
  getCalendarDates,
  getCalendars,
  setCurrentCalendarId,
  setCurrentDate,
  subscribeUserCollection,
  unsubscribeUserCollection,
  updateSleepCounterState,
} from 'reducers/sharedCalendars';
import { getUserById } from 'reducers/user';
import Icon from 'shared/Icons/Icons';
import Loader from 'shared/Loader/Loader.jsx';
import Tab from 'shared/Tabs/Tab';
import Tabs from 'shared/Tabs/Tabs';
import * as calendarUtil from 'utils/calendar';
import firebase from 'utils/firebase';

import useStyles from './Calendars.css.jsx';
import EmptyState from './EmptyState/EmptyState';
import ExpiredSharedCalendarSubscription from './ExpiredSubscription/index.jsx';

function Calenders({ match, history }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const loadingCalendars = useSelector(state => state.sharedCalendars.loading);
  const loadingDates = useSelector(state => state.sharedCalendars.list.loading);
  const calendars = useSelector(state => state.sharedCalendars.list.calendars);
  const currentCalendarId = useSelector(state => state.sharedCalendars.currentCalendarId);
  const userOwnerData = useSelector(state => state.user.calendarOwnerUser);
  const userData = useSelector(state => state.sharedCalendars.userData);
  const [calendarParent1Name, setCalendarParent1Name] = useState('');
  const [calendarParent2Name, setCalendarParent2Name] = useState('');
  const hasCalendars = !!Object.keys(calendars).length;
  const userId = userData && userData.uid;
  const currentCalendar = calendarUtil.getCurrentCalendar(calendars, currentCalendarId);
  const currentDate = useSelector(state => state.sharedCalendars.currentDate);

  const paramsCalendarId = history.location.search;
  // Check if the curentDate exists in this calendar. If not, redirect to the last existing date in this calendar
  useEffect(() => {
    if (
      currentCalendar &&
      (currentCalendar.dates === null || currentCalendar.dates === undefined)
    ) {
      const lastDatesObject = currentCalendar.allDates[currentCalendar.allDates.length - 1];
      const lastDateOfObject = lastDatesObject[Object.keys(lastDatesObject).pop()];
      if (match.params.calendarDate) {
        if (
          moment(match.params.calendarDate)
            .startOf('month')
            .startOf('day')
            .isBefore(moment(currentCalendar.firstPopulatedDate).startOf('month').startOf('day'))
        ) {
          history.push(
            `/profile/shared/${moment(currentCalendar.firstPopulatedDate).format(
              'YYYY-MM',
            )}?calendarId=${currentCalendarId}`,
          );
        } else if (
          moment(match.params.calendarDate)
            .startOf('month')
            .startOf('day')
            .isAfter(moment(lastDateOfObject.date).startOf('month').startOf('day'))
        ) {
          history.push(
            `/profile/shared/${moment(lastDateOfObject.date).format(
              'YYYY-MM',
            )}?calendarId=${currentCalendarId}`,
          );
        }
      } else {
        history.push(
          `/profile/shared/${moment(currentCalendar.firstPopulatedDate).format(
            'YYYY-MM',
          )}?calendarId=${currentCalendarId}`,
        );
      }
    }
  }, [currentCalendar]);

  useEffect(() => {
    firebase.analytics().logEvent('page_view_calendars');
    if (
      paramsCalendarId &&
      paramsCalendarId.slice(1, paramsCalendarId.lastIndexOf('=')) === 'calendarId'
    ) {
      dispatch(
        setCurrentCalendarId(paramsCalendarId.substr(paramsCalendarId.lastIndexOf('=') + 1)),
      );
    }
  }, []);

  useEffect(() => {
    if (currentCalendar) {
      dispatch(getSchoolBreakTemplates(currentCalendar.schoolYear));
      setCalendarParent1Name(currentCalendar.parent1Name);
      setCalendarParent2Name(currentCalendar.parent2Name);
    }
  }, [calendars, currentCalendarId, dispatch]);

  useEffect(() => {
    const currentDate = moment(match.params.calendarDate);
    dispatch(setCurrentDate(currentDate));
    if (hasCalendars && currentCalendarId) {
      dispatch(getCalendarDates(currentCalendarId));
      fetchCalendars(currentDate);
    } else {
      dispatch(getCalendars({}));
    }
  }, [match.params.calendarDate, hasCalendars, currentCalendarId, dispatch]);
  useEffect(() => {
    dispatch(subscribeUserCollection(userId));
    return () => {
      unsubscribeUserCollection();
    };
  }, [userId, dispatch]);
  useEffect(() => {
    if (
      currentCalendar &&
      !currentCalendar.newCalendarVersion &&
      !currentCalendar.accessCard.disableOct2022Notification
    ) {
      dispatch(openModal({ type: MODAL_MAJOR_UPDATE_INFORMATION }));
    }

    if (currentCalendar && currentCalendar.accessCard.calendarOwnerEmail) {
      dispatch(
        getUserById({
          email: currentCalendar.accessCard.calendarOwnerEmail,
        }),
      );
    } else {
      dispatch(
        getUserById({
          email: null,
        }),
      );
    }
  }, [currentCalendar]);
  function fetchCalendars(currentDate) {
    if (currentCalendar) {
      const currenSchoolYear = currentCalendar.schoolYear;
      const firstPopulatedDate = moment(currentCalendar.firstPopulatedDate);
      const nextSchoolYear = calendarUtil.getCurrentSchoolYear(
        currentDate,
        firstPopulatedDate,
        false,
      );
      if (!loadingCalendars && currenSchoolYear !== nextSchoolYear) {
        dispatch(getCalendars({}));
      }
    }
  }

  function onTabChange(event, newCalendarId) {
    firebase.analytics().logEvent('page_action_calendars_tab_change');
    dispatch(setCurrentCalendarId(newCalendarId));
    dispatch(updateSleepCounterState([null, null]));
    history.push({
      search: `?calendarId=${newCalendarId}`,
    });
  }

  function onCreateCalendar() {
    dispatch(openModal({ type: MODAL_CREATE_CALENDAR, disableClose: true }));
  }

  if (!hasCalendars && !loadingCalendars) {
    return <EmptyState onCreateCalendar={onCreateCalendar} />;
  }
  return (
    <div className={classes.root}>
      {currentCalendar && (
        <Tabs
          value={currentCalendarId}
          indicatorColor="primary"
          textColor="primary"
          onChange={onTabChange}
          aria-label="Calendars"
          variant="scrollable"
          scrollButtons="auto"
        >
          {hasCalendars &&
            sortBy(Object.values(calendars), ['createdDate']).map((calendar, index) => (
              <Tab
                key={index}
                value={calendar.calendarId}
                label={calendar.accessCard.calendarName}
                icon={<Icon icon="calendar" size="lg" />}
              />
            ))}
        </Tabs>
      )}
      {userOwnerData &&
      !calendarUtil.isSubscriptionExpired(
        userOwnerData.paymentExpDate,
        userOwnerData.freeAccessExpDate,
      ) ? (
        <div className={classes.container}>
          <div className={classes.main}>
            {loadingCalendars || loadingDates ? (
              <Loader />
            ) : (
              <RenderCalendar
                calendars={calendars}
                currentCalendarId={currentCalendarId}
                calendarParent1Name={calendarParent1Name}
                calendarParent2Name={calendarParent2Name}
                currentPagePath={'shared'}
                history={history}
                currentDate={currentDate}
              />
            )}
          </div>
        </div>
      ) : loadingCalendars || loadingDates ? (
        <Loader />
      ) : (
        <ExpiredSharedCalendarSubscription />
      )}
    </div>
  );
}

export default withStyles(landingPageStyle)(Calenders);
