import { addSnackbar } from 'reducers/snackbar';

export const FETCH_ADMIN_SETTINGS_START = 'admin/FETCH_ADMIN_SETTINGS_START';
export const FETCH_ADMIN_SETTINGS_DONE = 'calendar/FETCH_ADMIN_SETTINGS_DONE';
export const FETCH_ADMIN_SETTINGS_ERROR = 'calendar/FETCH_ADMIN_SETTINGS_ERROR';
export const UPDATE_ADMIN_SETTINGS_START = 'calendar/UPDATE_ADMIN_SETTINGS_START';
export const UPDATE_ADMIN_SETTINGS_ERROR = 'calendar/UPDATE_ADMIN_SETTINGS_ERROR';
export const UPDATE_ADMIN_SETTINGS_DONE = 'calendar/UPDATE_ADMIN_SETTINGS_DONE';

const initialState = {
  loading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ADMIN_SETTINGS_START: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case FETCH_ADMIN_SETTINGS_DONE: {
      return {
        ...state,
        loading: false,
        error: null,
        settings: action.settings,
      };
    }
    case FETCH_ADMIN_SETTINGS_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case UPDATE_ADMIN_SETTINGS_START: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case UPDATE_ADMIN_SETTINGS_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case UPDATE_ADMIN_SETTINGS_DONE: {
      return {
        ...state,
        loading: false,
        error: null,
      };
    }
    default: {
      return { ...state };
    }
  }
};

export const getAdminSettings =
  () =>
  async (dispatch, getState, { firebase }) => {
    dispatch({ type: FETCH_ADMIN_SETTINGS_START });
    const db = firebase.firestore();
    try {
      const adminSettingsResponse = await db.collection('adminSettings').doc('admin').get();
      const adminSettings = adminSettingsResponse.data();
      dispatch({ type: FETCH_ADMIN_SETTINGS_DONE, settings: adminSettings });
    } catch (error) {
      dispatch({ type: FETCH_ADMIN_SETTINGS_ERROR });
      dispatch(
        addSnackbar({
          text: `${error.message}`,
          variant: 'error',
          closeButton: true,
          //retryButton: () => dispatch(getCalendarDates(calendarId))
        }),
      );
    }
  };

export const updateAdminSettings =
  data =>
  async (dispatch, getState, { firebase }) => {
    dispatch({ type: UPDATE_ADMIN_SETTINGS_START });
    try {
      const updateAdminSettings = firebase.functions().httpsCallable('updateSettiingsV2');
      await updateAdminSettings(data);

      dispatch({ type: UPDATE_ADMIN_SETTINGS_DONE });
      dispatch(getAdminSettings());
    } catch (error) {
      dispatch({ type: UPDATE_ADMIN_SETTINGS_ERROR });
      dispatch(
        addSnackbar({
          text: `${error.message}`,
          variant: 'error',
          closeButton: true,
          //retryButton: () => dispatch(getCalendarDates(calendarId))
        }),
      );
    }
  };
