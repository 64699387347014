import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  snackbarVariantInfo: {
    backgroundColor: theme.custom.color.color1,
    color: '#fff',
  },
  snackbarVariantWarning: {
    color: theme.custom.color.color1,
  },
  snackbarVariantError: {
    color: '#fff',
  },
  snackbarVariantSuccess: {
    color: '#fff',
  },
}));
