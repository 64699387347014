import {
  ELECT_SPO_TEXAS,
  EVENT_TYPE_ADDITIONAL_INFO,
  EVENT_TYPE_BIRTHDAY,
  EVENT_TYPE_DATE,
  EVENT_TYPE_HOLIDAY,
  EVENT_TYPE_LASTDAY,
  EVENT_TYPE_MODIFIED,
  EVENT_TYPE_PARENTS_DAY,
  EVENT_TYPE_SCHOOLBREAK,
  EVENT_TYPE_TODAY,
  EVENT_TYPE_WEEKENDS,
  LEGAL_ROLE_PRIMARY,
  LEGAL_ROLE_SECONDARY,
  LEGAL_ROLE_VIEW,
  MODAL_ALERT_EXTEND_CALENDAR,
  MODAL_EDIT_BIRTHDAY,
  MODAL_EDIT_CALENDAR_DATE,
  MODAL_EDIT_PARENT1_NAME,
  MODAL_EDIT_PARENT2_NAME,
  MODAL_EDIT_SCHOOL_BREAK,
  MODAL_EDIT_WEEKENDS,
  MODAL_EXTEND_OLD_CALENDAR,
  MODAL_EXTEND_SHARED_CALENDAR,
  MODAL_INFO_FATHER_DAY,
  MODAL_INFO_LAST_DAY,
  MODAL_INFO_MODIFIED_DAY,
  MODAL_INFO_MOTHER_DAY,
  MODAL_RESTRICTED_PREV_DATE,
  MODAL_REVIEW_DATE_ADDITIONAL,
  MODAL_SHARE,
  MODAL_SUMMER_SESSIONS,
  MODAL_SUMMER_SESSIONS_DATES,
  MODAL_SUMMER_SESSIONS_ERROR,
} from 'constants';
import moment from 'moment';
import { getPdfCalendar } from 'reducers/calendar';
import { setCurrentBirthday } from 'reducers/editBirthday';
import { currentCalendarDay } from 'reducers/editCalendarDate';
import { currentSchoolBreak } from 'reducers/editSchoolBreakDate';
import { currentAllWeekends } from 'reducers/editWeekend';
import { extendCalendar } from 'reducers/extendCalendar';
import { openModal } from 'reducers/modals';
import { addSnackbar } from 'reducers/snackbar';
import { getCurrentSchoolYear } from 'utils/calendar';
import firebase from 'utils/firebase';

const parent1 = 'parent1';
const firstOwner = 1;

export function printToPdf({ dispatch, currentCalendarId, schoolYear, currentCalendar }) {
  const startDate = moment(Object.values(currentCalendar.dates)[0].date)
    .startOf('month')
    .format('YYYY-MM-DD');
  dispatch(getPdfCalendar(currentCalendarId, schoolYear, startDate));
}

export function onSummerSessions({ currentCalendar, shouldExtendCalendar, dispatch, openModal }) {
  if (shouldExtendCalendar) {
    dispatch(openModal({ type: MODAL_SUMMER_SESSIONS_ERROR, disableClose: false }));
  } else if (currentCalendar.summerPossessions) {
    dispatch(
      openModal({
        type: MODAL_SUMMER_SESSIONS_DATES,
        disableClose: false,
      }),
    );
  } else {
    dispatch(openModal({ type: MODAL_SUMMER_SESSIONS, disableClose: false }));
  }
}

export function getClassNameFromRole({ role, owner, classes }) {
  if (role === LEGAL_ROLE_PRIMARY || role === LEGAL_ROLE_SECONDARY || role === LEGAL_ROLE_VIEW) {
    if (owner === 1) {
      return classes.calendarFullday;
    } else if (owner === 2) {
      return classes.calendarOtherFullday;
    }
  }
}

export function onChangeCurrentDate({
  date,
  dispatch,
  openModal,
  currentCalendar,
  shouldExtendCalendarDatePicker,
  currentPagePath,
  currentCalendarId,
  history,
}) {
  const chosenDate = moment(date);

  if (shouldExtendCalendarDatePicker) {
    //   (calendarUtil.shouldExtendCalendarDatePicker(currentCalendar, currentDate))
    return dispatch(
      openModal({
        type: MODAL_ALERT_EXTEND_CALENDAR,
        disableClose: false,
        customData: currentCalendar.lastPopulatedDate,
      }),
    );
  } else {
    history.replace(
      `/profile/${currentPagePath}/${chosenDate.format('YYYY-MM')}?calendarId=${currentCalendarId}`,
    );
  }
}

export const onShareHandle = ({ dispatch }) => {
  dispatch(openModal({ type: MODAL_SHARE, disableClose: false }));
};

export function onChangeDateToday({ calendarRef, currentPagePath, currentCalendarId, history }) {
  firebase.analytics().logEvent('page_action_calendars_goto_today');
  calendarRef.current.calendar.today();
  const calendarDate = calendarRef.current.calendar.getDate();
  const currentDate = moment(calendarDate);
  history.replace(
    `/profile/${currentPagePath}/${currentDate.format('YYYY-MM')}?calendarId=${currentCalendarId}`,
  );
}

export function onChangeDatePrevious({
  calendarRef,
  currentPagePath,
  currentCalendarId,
  currentCalendar,
  dispatch,
  history,
}) {
  firebase.analytics().logEvent('page_action_calendars_goto_prev');
  const oldCalendarDate = calendarRef.current.calendar.getDate();
  calendarRef.current.calendar.prev();
  const calendarDate = calendarRef.current.calendar.getDate();
  const currentDate = moment(calendarDate);

  history.replace(
    `/profile/${currentPagePath}/${currentDate.format('YYYY-MM')}?calendarId=${currentCalendarId}`,
  );
  if (
    moment(currentCalendar.createdDate).format('YYYY-MM') ===
    moment(oldCalendarDate).format('YYYY-MM')
  ) {
    dispatch(
      openModal({
        type: MODAL_RESTRICTED_PREV_DATE,
        disableClose: false,
      }),
    );
  }
}

export function onChangeDateNext({
  currentCalendar,
  shouldExtendCalendar,
  dispatch,
  calendarRef,
  currentPagePath,
  currentCalendarId,
  history,
}) {
  firebase.analytics().logEvent('page_action_calendars_goto_next');
  if (currentPagePath === 'shared' && shouldExtendCalendar) {
    return dispatch(
      openModal({
        type: MODAL_EXTEND_SHARED_CALENDAR,
        disableClose: false,
      }),
    );
  }
  if (!currentCalendar.newCalendarVersion && shouldExtendCalendar) {
    return dispatch(
      openModal({
        type: MODAL_EXTEND_OLD_CALENDAR,
        disableClose: false,
      }),
    );
  } else if (shouldExtendCalendar) {
    if (ELECT_SPO_TEXAS.includes(currentCalendar.electedSecondary)) {
      return dispatch(
        openModal({
          type: MODAL_ALERT_EXTEND_CALENDAR,
          disableClose: false,
          customData: currentCalendar.lastPopulatedDate,
        }),
      );
    } else {
      const schoolYear = getCurrentSchoolYear(
        moment(currentCalendar.lastPopulatedDate).add(1, 'day'),
        moment(currentCalendar.lastPopulatedDate).add(1, 'year'),
      );
      const data = {
        schoolYear: schoolYear,
        calendarId: currentCalendarId,
      };
      dispatch(extendCalendar(data));
    }
  }

  calendarRef.current.calendar.next();
  const nextCalendarDate = calendarRef.current.calendar.getDate();
  const nextCurrentDate = moment(nextCalendarDate);
  history.replace(
    `/profile/${currentPagePath}/${nextCurrentDate.format(
      'YYYY-MM',
    )}?calendarId=${currentCalendarId}`,
  );
}

export const onDateClick =
  ({ events, schoolYear, dispatch, t, currentCalendar }) =>
  info => {
    const day = events.find(event => event.date === info.dateStr);
    if (day === undefined) {
      return;
    }

    const { extra, owner } = day;
    const { calendarId, role, modified, additionalInfo } = extra;
    if (role !== LEGAL_ROLE_VIEW) {
      if (moment(info.dateStr).isBefore(moment(), 'day')) {
        dispatch(
          addSnackbar({
            text: t('warningEditPastDate'),
            variant: 'warning',
          }),
        );
        return;
      }

      const data = {
        calendarId,
        owner,
        role,
        schoolYear,
        modified,
        date: moment(info.dateStr),
        additionalInfo,
      };
      dispatch(currentCalendarDay(data));
      dispatch(openModal({ type: MODAL_EDIT_CALENDAR_DATE, disableClose: false }));
    } else {
      dispatch(
        addSnackbar({
          text: t('warningEditSharedCalendar', {
            email: currentCalendar.accessCard.calendarOwnerEmail,
          }),
          variant: 'warning',
          duration: 8000,
        }),
      );
    }
  };

export function onEventClick({ info, currentCalendar, dispatch, schoolYear, t, events }) {
  const { role } = currentCalendar.accessCard;
  const day = events.find(event => moment(event.date).isSame(info.event.start));
  if (day === undefined) {
    return;
  }
  if (role !== LEGAL_ROLE_VIEW) {
    const { extra } = info.event.extendedProps;
    const { owner, extra: dayExtra } = day;
    const { modified } = dayExtra;
    const { eventType } = extra;
    if (eventType === EVENT_TYPE_PARENTS_DAY) {
      extra.name && extra.name.toLowerCase().includes('father')
        ? dispatch(
            openModal({
              type: MODAL_INFO_FATHER_DAY,
              disableClose: false,
            }),
          )
        : dispatch(
            openModal({
              type: MODAL_INFO_MOTHER_DAY,
              disableClose: false,
            }),
          );
    } else if (eventType === EVENT_TYPE_ADDITIONAL_INFO) {
      const { calendarId, additionalInfo } = extra;
      const data = {
        calendarId,
        owner,
        role,
        schoolYear,
        modified,
        date: moment(info.event.start),
        additionalInfo,
      };
      dispatch(currentCalendarDay(data));
      dispatch(
        openModal({
          type: MODAL_REVIEW_DATE_ADDITIONAL,
          disableClose: false,
        }),
      );
    } else if (eventType === EVENT_TYPE_MODIFIED) {
      dispatch(openModal({ type: MODAL_INFO_MODIFIED_DAY, disableClose: false }));
    } else if (eventType === EVENT_TYPE_LASTDAY) {
      dispatch(
        openModal({
          type: MODAL_INFO_LAST_DAY,
          disableClose: false,
          customData: currentCalendar.lastPopulatedDate,
        }),
      );
    } else if (eventType === EVENT_TYPE_SCHOOLBREAK) {
      const { name, extra } = info.event.extendedProps;
      const { startDate, endDate, calendarId } = extra;
      const data = {
        calendarId,
        schoolYear,
        name,
        startDate,
        endDate,
      };

      dispatch(currentSchoolBreak(data));
      dispatch(
        openModal({
          type: MODAL_EDIT_SCHOOL_BREAK,
          disableClose: false,
        }),
      );
    } else if (eventType === EVENT_TYPE_BIRTHDAY) {
      const { extra } = info.event.extendedProps;
      const { date, name } = extra;
      dispatch(setCurrentBirthday({ date, name }));
      dispatch(
        openModal({
          type: MODAL_EDIT_BIRTHDAY,
          disableClose: false,
        }),
      );
    } else if (eventType === EVENT_TYPE_WEEKENDS) {
      const { name, extra } = info.event.extendedProps;
      const { start, end, calendarId, defaultWeekends } = extra;
      const data = {
        calendarId,
        schoolYear,
        name,
        defaultWeekends,
        start,
        end,
      };
      dispatch(currentAllWeekends(data));
      dispatch(
        openModal({
          type: MODAL_EDIT_WEEKENDS,
          disableClose: false,
        }),
      );
    }
  } else {
    dispatch(
      addSnackbar({
        text: t('warningEditSharedCalendar', {
          email: currentCalendar.accessCard.calendarOwnerEmail,
        }),
        variant: 'warning',
        duration: 8000,
      }),
    );
  }
}

export function handleParent({ parent, dispatch }) {
  firebase.analytics().logEvent('page_action_calendars_open_edit_parent_name_modal');
  parent === parent1
    ? dispatch(
        openModal({
          type: MODAL_EDIT_PARENT1_NAME,
          disableClose: false,
        }),
      )
    : dispatch(
        openModal({
          type: MODAL_EDIT_PARENT2_NAME,
          disableClose: false,
        }),
      );
}

export function calendarEventsBuilder({ currentCalendar, classes, templateSchoolBreaks, t }) {
  const {
    dates,
    schoolBreaks,
    birthdays,
    calendarId,
    firstPopulatedDate,
    lastPopulatedDate,
    allWeekends,
    electedSecondary,
  } = currentCalendar;
  const { role } = currentCalendar.accessCard;
  const events = [];
  if (dates) {
    for (let [, values] of Object.entries(dates)) {
      const date = values.date;
      const owner = values.owner;
      const classNames = [getClassNameFromRole({ role, owner, classes })];
      const extra = {
        calendarId,
        role,
        owner,
        modified: !!values.modified,
        eventType: EVENT_TYPE_DATE,
        additionalInfo: values.additionalInfo ? values.additionalInfo : '',
      };
      events.push({
        date,
        owner,
        extra,
        rendering: 'background',
        classNames,
      });

      // FIXME: make this nicer
      if (values.event) {
        if (values.event.name) {
          if (values.event.showModal === true) {
            const extra = {
              eventType: EVENT_TYPE_PARENTS_DAY,
              name: values.event.name,
            };
            events.push({
              date,
              title: values.event.name,
              name: values.event.name,
              calendarId,
              allDay: true,
              editable: false,
              extra,
              textColor: '#fff',
              borderColor: '#3a516e',
              backgroundColor: '#3a516e',
            });
          } else {
            events.push({
              date,
              title: values.event.name,
              name: values.event.name,
              calendarId,
              allDay: true,
              editable: false,
              extra,
              textColor: '#fff',
              borderColor: '#3a516e',
              backgroundColor: '#3a516e',
            });
          }
        } else {
          //For backwards compability when event was not an object
          const extra = { eventType: EVENT_TYPE_PARENTS_DAY };
          events.push({
            date,
            title: values.event,
            name: values.event,
            calendarId,
            allDay: true,
            editable: false,
            extra,
            textColor: '#fff',
            borderColor: '#3a516e',
            backgroundColor: '#3a516e',
          });
        }
      }
      if (values.additionalInfo) {
        const extra = {
          eventType: EVENT_TYPE_ADDITIONAL_INFO,
          calendarId,
          additionalInfo: values.additionalInfo,
        };
        events.push({
          date,
          title: values.additionalInfo,
          name: values.additionalInfo,
          calendarId,
          allDay: true,
          editable: false,
          extra,
          textColor: '#3a516e',
          borderColor: '#3a516e22',
          backgroundColor: '#3a516e22',
        });
      }

      if (values.modified) {
        const extra = { eventType: EVENT_TYPE_MODIFIED };
        events.push({
          date,
          // title: t("calendarEventModifiedName"),
          title: values.modified.allDay
            ? t('calendarEventModifiedName')
            : `${t('calendarEventModifiedName')} ${values.modified.start} to ${
                values.modified.end
              }`,
          name: 'modified',
          calendarId,
          allDay: true,
          editable: false,
          extra,
          textColor: '#3a516e',
          borderColor: '#3a516e22',
          backgroundColor: values.modified.originalOwner === firstOwner ? '#FF9C9A' : '#64B5BA',
        });
      }

      // if (moment(date).isSame(currentCalendar.lastPopulatedDate)) {
      //   const extra = { eventType: EVENT_TYPE_LASTDAY };
      //   events.push({
      //     date,
      //     title: t('calendarLatestDay'),
      //     name: 'lastDay',
      //     calendarId,
      //     allDay: true,
      //     editable: false,
      //     extra,
      //     textColor: '#fff',
      //     borderColor: '#3a516e',
      //     backgroundColor: '#3a516e',
      //   });
      // }
    }
  }
  if (ELECT_SPO_TEXAS.includes(electedSecondary)) {
    if (schoolBreaks && schoolBreaks.thanksgiving) {
      const startDate = moment(schoolBreaks.thanksgiving.start).format('YYYY-MM-DD');
      const endDate = moment(schoolBreaks.thanksgiving.end).format('YYYY-MM-DD');

      const start = startDate;
      const end = moment(endDate).add(1, 'day').format('YYYY-MM-DD');

      const extra = {
        calendarId,
        startDate,
        endDate,
        eventType: EVENT_TYPE_SCHOOLBREAK,
      };
      events.push({
        start,
        end,
        extra,
        title: t('calendarEventSchoolBreak', { schoolBreak: 'THANKSGIVING' }),
        name: 'thanksgiving',
        allDay: true,
        textColor: '#3a516e',
        borderColor: '#3a516e22',
        backgroundColor: '#3a516e22',
      });
    }

    if (schoolBreaks && schoolBreaks.christmas_newyear) {
      const startDate = moment(schoolBreaks.christmas_newyear.start).format('YYYY-MM-DD');
      const endDate = moment(schoolBreaks.christmas_newyear.end).format('YYYY-MM-DD');

      const start = startDate;
      const end = moment(endDate).add(1, 'day').format('YYYY-MM-DD');

      const extra = {
        calendarId,
        startDate,
        endDate,
        eventType: EVENT_TYPE_SCHOOLBREAK,
      };
      events.push({
        start,
        end,
        extra,
        title: t('calendarEventSchoolBreak', {
          schoolBreak: 'CHRISTMAS/NEW-YEAR',
        }),
        name: 'christmas_newyear',
        allDay: true,
        textColor: '#3a516e',
        borderColor: '#3a516e22',
        backgroundColor: '#3a516e22',
      });
    }

    if (schoolBreaks && schoolBreaks.spring) {
      const startDate = moment(schoolBreaks.spring.start).format('YYYY-MM-DD');
      const endDate = moment(schoolBreaks.spring.end).format('YYYY-MM-DD');

      const start = startDate;
      const end = moment(endDate).add(1, 'day').format('YYYY-MM-DD');

      const extra = {
        calendarId,
        startDate,
        endDate,
        eventType: EVENT_TYPE_SCHOOLBREAK,
      };
      events.push({
        start,
        end,
        extra,
        title: t('calendarEventSchoolBreak', { schoolBreak: 'SPRING' }),
        name: 'spring',
        allDay: true,
        textColor: '#3a516e',
        borderColor: '#3a516e22',
        backgroundColor: '#3a516e22',
      });
    }

    if (schoolBreaks && schoolBreaks.summer) {
      const startDate = moment(schoolBreaks.summer.start).format('YYYY-MM-DD');
      const endDate = moment(schoolBreaks.summer.end).format('YYYY-MM-DD');

      const start = startDate;
      const end = moment(endDate).add(1, 'day').format('YYYY-MM-DD');

      const extra = {
        calendarId,
        startDate,
        endDate,
        eventType: EVENT_TYPE_SCHOOLBREAK,
      };
      events.push({
        start,
        end,
        extra,
        title: t('calendarEventSchoolBreak', { schoolBreak: 'SUMMER' }),
        name: 'summer',
        allDay: true,
        textColor: '#3a516e',
        borderColor: '#3a516e22',
        backgroundColor: '#3a516e22',
      });
    }
  }

  if (templateSchoolBreaks && templateSchoolBreaks.holidays) {
    for (let [key, value] of Object.entries(templateSchoolBreaks.holidays)) {
      const extra = { eventType: EVENT_TYPE_HOLIDAY };
      if (key === 'fathers_day' || key === 'mothers_day') {
        continue;
      }
      events.push({
        start: moment(value).format('YYYY-MM-DD'),
        title: t(key),
        name: key,
        extra,
        allDay: true,
        editable: false,
        textColor: '#fff',
        borderColor: '#3a516e',
        backgroundColor: '#3a516e',
      });
    }
  }

  if (allWeekends) {
    for (let [, values] of Object.entries(allWeekends)) {
      const startDate = values.start;
      const endDate = values.end;
      const role = currentCalendar.accessCard.role;
      const owner = 1;
      const defaultWeekends = currentCalendar.defaultWeekends;
      let start = moment(startDate).format('YYYY-MM-DD');
      let end = moment(startDate).clone().add(1, 'days').format('YYYY-MM-DD');
      let extra = {
        calendarId,
        start,
        end,
        role,
        owner,
        defaultWeekends,
        modified: true,
        eventType: EVENT_TYPE_WEEKENDS,
      };
      if (defaultWeekends === false) {
        events.push({
          start,
          end,
          extra,
          title: t('calendarEventWeekends', { allWeekends: 'ALLWEEKENDS' }),
          name: 'weekend day 1',
          allDay: true,
          textColor: '#3a516e',
          borderColor: '#3a516e22',
          backgroundColor: '#3a516e22',
        });
        start = moment(startDate).clone().add(1, 'days').format('YYYY-MM-DD');
        end = moment(endDate).format('YYYY-MM-DD');
        extra = {
          calendarId,
          start,
          end,
          role,
          owner,
          defaultWeekends,
          modified: true,
          eventType: EVENT_TYPE_WEEKENDS,
        };
        events.push({
          start,
          end,
          extra,
          title: t('calendarEventWeekends', { allWeekends: 'ALLWEEKENDS' }),
          name: 'weekend day 2',
          allDay: true,
          textColor: '#3a516e',
          borderColor: '#3a516e22',
          backgroundColor: '#3a516e22',
        });
        start = moment(endDate).format('YYYY-MM-DD');
        end = moment(endDate).clone().add(1, 'days').format('YYYY-MM-DD');
        extra = {
          calendarId,
          start,
          end,
          role,
          owner,
          defaultWeekends,
          modified: true,
          eventType: EVENT_TYPE_WEEKENDS,
        };
        events.push({
          start,
          end,
          extra,
          title: t('calendarEventWeekends', { allWeekends: 'ALLWEEKENDS' }),
          name: 'weekend day 3',
          allDay: true,
          textColor: '#3a516e',
          borderColor: '#3a516e22',
          backgroundColor: '#3a516e22',
        });
      }
    }
  }

  //Make an event of todays date
  const extra = { eventType: EVENT_TYPE_TODAY };

  events.push({
    start: moment().format('YYYY-MM-DD'),
    title: t('calendarEventToday'),
    name: 'today',
    extra,
    allDay: true,
    editable: false,
    textColor: '#fff',
    borderColor: '#3a516e',
    backgroundColor: '#3a516e',
  });

  for (let i = 0; i < birthdays.length; i++) {
    const birthday = birthdays[i];
    const date = moment(birthday['b-day']);
    const name = birthday['name'];
    const extra = { date, name, eventType: EVENT_TYPE_BIRTHDAY };

    events.push({
      title: t('eventTitleBirthday', { name }),
      color: '#3a516e',
      extra,
      rrule: {
        freq: 'yearly',
        interval: 1,
        bymonth: date.month() + 1,
        bymonthday: date.date(),
        dtstart: moment(firstPopulatedDate).format('YYYY-MM-DD'),
        until: moment(lastPopulatedDate).format('YYYY-MM-DD'),
      },
    });
  }

  return events;
}
