import MaterialTab from '@material-ui/core/Tab';
import React from 'react';

import useStyle from './Tab.css.jsx';

function Tab(props) {
  const classes = useStyle();

  return (
    <MaterialTab
      classes={{
        root: classes.root,
        selected: classes.selected,
        wrapper: classes.wrapper,
        labelIcon: classes.labelIcon,
      }}
      {...props}
    ></MaterialTab>
  );
}

export default Tab;
