import { MODAL_SUMMER_SESSIONS } from 'constants.js';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, openModal } from 'reducers/modals';
import Button from 'shared/Button/Button';
import Icon from 'shared/Icons/Icons';
import SummaryList from 'shared/SummaryList/SummaryList';
import { getCurrentCalendar, getCurrentSchoolYear } from 'utils/calendar';
import { defaultSecondaryRange1Dates } from 'utils/dates';
import firebase from 'utils/firebase';

import ModalContent from '../ModalContent/ModalContent';

export default function SummerPossessionsDates() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const calendar = useSelector(state => state.calendar);
  const currentCalendar = getCurrentCalendar(calendar.list.calendars, calendar.currentCalendarId);
  const { summerPossessions, schoolBreaks, withinDistance } = currentCalendar;
  const { secondary, primary } = summerPossessions;
  let range1Start;
  let range1End;

  let range2Start;
  let range2End;

  let primaryRange1Start;
  let primaryRange1End;

  let primaryRange2Start;
  let primaryRange2End;

  let primaryRange3Start;
  let primaryRange3End;

  let primaryRange4Start;
  let primaryRange4End;

  if (secondary && secondary.possessions && secondary.possessions.range1) {
    if (secondary.possessions.range1) {
      range1Start = moment(secondary.possessions.range1.start).format('L');
      range1End = moment(secondary.possessions.range1.end).format('L');
    }

    if (secondary.possessions.range2) {
      range2Start = moment(secondary.possessions.range2.start).format('L');
      range2End = moment(secondary.possessions.range2.end).format('L');
    }
  } else {
    // user has filled out default date for range 1
    const defaultDates = defaultSecondaryRange1Dates(schoolBreaks);
    range1Start = moment(defaultDates.start).format('L');
    range1End = moment(defaultDates.end).format('L');
  }

  if (primary && primary.possessions) {
    if (primary.possessions.range1) {
      primaryRange1Start = moment(primary.possessions.range1.start).format('L');
      primaryRange1End = moment(primary.possessions.range1.end).format('L');
    }

    if (primary.possessions.range2) {
      primaryRange2Start = moment(primary.possessions.range2.start).format('L');
      primaryRange2End = moment(primary.possessions.range2.end).format('L');
    }

    if (primary.possessions.range3) {
      primaryRange3Start = moment(primary.possessions.range3.start).format('L');
      primaryRange3End = moment(primary.possessions.range3.end).format('L');
    }

    if (primary.possessions.range4) {
      primaryRange4Start = moment(primary.possessions.range4.start).format('L');
      primaryRange4End = moment(primary.possessions.range4.end).format('L');
    }
  }

  useEffect(() => {
    firebase.analytics().logEvent('modal_show_info_summer_possession');
  }, []);

  function onCloseModal() {
    firebase.analytics().logEvent('modal_cancel_info_summer_possession');
    dispatch(closeModal());
  }

  function onUpdate() {
    firebase.analytics().logEvent('modal_action_info_summer_possession_continue');
    dispatch(openModal({ type: MODAL_SUMMER_SESSIONS, disableClose: false }));
  }

  function sections() {
    const list = [
      { title: t('modalSummerPossessionsSectionSecondaryTitle'), sections: [] },
      { title: t('modalSummerPossessionsSectionPrimaryTitle'), sections: [] },
    ];

    if (range1Start) {
      list[0].sections.push({
        title: t('secondaryRange1Title'),
        value: `${range1Start} - ${range1End}`,
        icon: <Icon icon="calendar-day" size="lg" />,
      });
    }

    list[0].sections.push({
      title: t('secondaryRange2Title'),
      value: range2Start ? `${range2Start} - ${range2End}` : t('secondaryRange2NoValue'),
      icon: <Icon icon="calendar-day" size="lg" />,
    });

    list[1].sections.push({
      title: t('primaryWeeekend1Title'),
      value: primaryRange1Start
        ? `${primaryRange1Start} - ${primaryRange1End}`
        : t('primaryWeeekendNotChosen'),
      icon: <Icon icon="calendar-day" size="lg" />,
    });

    list[1].sections.push({
      title: withinDistance ? t('primaryWeeekend2Title') : t('primaryWeeekend2GT100MilesTitle'),
      value: primaryRange2Start
        ? `${primaryRange2Start} - ${primaryRange2End}`
        : t('primaryWeeekendNotChosen'),
      icon: <Icon icon="calendar-day" size="lg" />,
    });

    if (primaryRange3Start) {
      list[1].sections.push({
        title: t('primaryPeriod1Title'),
        value: primaryRange3Start
          ? `${primaryRange3Start} - ${primaryRange3End}`
          : t('primaryRangeNotChosen'),
        icon: <Icon icon="calendar-day" size="lg" />,
      });
    }
    if (primaryRange4Start) {
      list[1].sections.push({
        title: t('primaryPeriod2Title'),
        value: primaryRange4Start
          ? `${primaryRange4Start} - ${primaryRange4End}`
          : t('primaryRangeNotChosen'),
        icon: <Icon icon="calendar-day" size="lg" />,
      });
    }
    return list;
  }

  function getActions() {
    return (
      <>
        <Button onClick={onCloseModal} color="primary">
          {t('actionOk')}
        </Button>
        <Button onClick={onUpdate} color="primary">
          {t('actionUpdate')}
        </Button>
      </>
    );
  }

  return (
    <>
      <ModalContent
        title={t('modalSummerPossessionsDatesTitle', {
          calendarName: currentCalendar.accessCard.calendarName,
          schoolYear: getCurrentSchoolYear(
            moment(schoolBreaks.summer.start),
            moment(currentCalendar.firstPopulatedDate),
            false,
            true,
          ),
        })}
        subtitle={t('modalSummerPossessionsDatesSubTitle')}
        actions={getActions()}
      >
        <SummaryList sections={sections()} />
      </ModalContent>
    </>
  );
}
