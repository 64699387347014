import environment from 'environment.js';
import 'firebase/analytics';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';

firebase.initializeApp(environment.firebase);

if (window.location.hostname === 'localhost') {
  firebase.firestore().settings({
    host: 'localhost:9123',
    ssl: false,
  });
  firebase.functions().useFunctionsEmulator('http://localhost:3001');
  // firebase.auth().useEmulator("http://localhost:9099");
}

export default firebase;
