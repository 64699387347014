import MaterialTabs from '@material-ui/core/Tabs';
import React from 'react';

function Tabs(props) {
  const { children, ...rest } = props;

  return <MaterialTabs {...rest}>{children}</MaterialTabs>;
}

export default Tabs;
