import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  stepperRoot: {
    padding: 0,
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: 'flex',
  },

  datePickerContainer: {
    display: 'flex',
  },
  firstDatePicker: {
    flexGrow: 1,
    marginRight: theme.spacing(2),
  },
  secondDatePicker: {
    flexGrow: 1,
  },
  labelAndCheckBox: {
    display: 'flex',
  },
  labelText: {
    flexGrow: 1,
    marginRight: theme.spacing(2),
  },
  checkBox: {
    flexGrow: 1,
  },
  loaderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '80vh',
  },
  loader: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
  fathersDayLegend: {
    display: 'box',
    alignItems: 'top',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  fathersDayLegendItem: {
    height: '20px',
    width: '20px',
    background: theme.custom.color.color3,
    borderRadius: '50%',
  },
  fathersDayLegendItemText: {
    padding: `0 4px`,
    fontStyle: 'italic',
    color: '#777',
  },
  fathersDay: {
    backgroundColor: theme.custom.color.color3,
  },
}));
