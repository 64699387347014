import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import LinearProgress from '@material-ui/core/LinearProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import withStyles from '@material-ui/core/styles/withStyles';
import profilePageStyle from 'assets/jss/material-kit-pro-react/views/profilePageStyle.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { openModal } from 'reducers/modals';
import { saveUser } from 'reducers/user';
import { getUser } from 'reducers/user.js';
import Button from 'shared/Button/Button';
import Loader from 'shared/Loader/Loader.jsx';
import Typography from 'shared/Typography/Typography';
import firebase from 'utils/firebase';
import History from 'utils/history.js';

import { MODAL_DELETE_USER } from '../../constants';
import useStyle from './Info.css.jsx';

// import deleteUser from "utils/deleteUser.js";

function Info() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const authUser = useSelector(state => state.auth.user);
  const user = useSelector(state => state.user.user);
  const loadingUser = useSelector(state => state.user.loading);
  const saving = useSelector(state => state.user.saving);
  const deleting = useSelector(state => state.user.deleting);
  const [displayName, setDisplayName] = useState('');
  const classes = useStyle();

  useEffect(() => {
    firebase.analytics().logEvent('page_view_account_info');
  }, []);

  useEffect(() => {
    if (!user) {
      dispatch(getUser());
    }
    if (user && user.displayName) {
      setDisplayName(user.displayName);
    }
  }, [user]);
  function onChangeDisplayName(event) {
    setDisplayName(event.target.value);
  }

  function onSave() {
    dispatch(saveUser({ displayName }));
    firebase.analytics().logEvent('page_action_account_info_save');
  }

  function onCancel() {
    History.push('/');
    firebase.analytics().logEvent('page_action_account_info_cancel');
  }

  function onDelete() {
    firebase.analytics().logEvent('menu_action_create_delete');
    firebase.analytics().logEvent('delete account');
    dispatch(openModal({ type: MODAL_DELETE_USER, disableClose: true }));
  }

  function dateText(dateString) {
    const momentDate = moment(dateString);
    if (!momentDate.isValid()) {
      return t('unavailableDate');
    } else {
      return moment(dateString).format('MMM DD, YYYY');
    }
  }

  if (loadingUser) {
    return <Loader />;
  }
  return (
    <>
      <div className={classes.root}>
        <GridContainer justifyContent="center">
          <GridItem xs={12} sm={12} md={8}>
            <Typography variant="h5">{t('infoPageTitle')}</Typography>

            <List>
              <ListItem alignItems="flex-start">
                <FormControl fullWidth variant="outlined">
                  <Typography align="left" variant="subtitle2">
                    {t('infoPageDisplayName')}
                  </Typography>
                  <TextField
                    margin="normal"
                    id="displayName"
                    label={t('infoPageDisplayName')}
                    placeholder={t('infoPageDisplayName')}
                    variant="outlined"
                    value={displayName}
                    onChange={onChangeDisplayName}
                    inputProps={{
                      maxLength: 20,
                    }}
                  />
                </FormControl>
              </ListItem>
              <Divider component="li" />

              <ListItem alignItems="flex-start">
                <ListItemText primary={t('infoPageEmail')} secondary={user && user.email} />
              </ListItem>
              <Divider component="li" />

              <ListItem alignItems="flex-start">
                <ListItemText
                  primary={t('infoPageEmailVerified')}
                  secondary={authUser.emailVerified ? 'Yes' : 'No'}
                />
              </ListItem>
              <Divider component="li" />

              <ListItem alignItems="flex-start">
                <ListItemText
                  primary={t('infoPageMemberSince')}
                  secondary={dateText(user && user.joinedDate)}
                />
              </ListItem>
              <Divider component="li" />

              <ListItem alignItems="flex-start">
                <ListItemText
                  primary={t('infoPageNumberCalendars')}
                  secondary={user && user.cals ? user.cals.length : 0}
                />
              </ListItem>
              <Divider component="li" />

              <ListItem alignItems="flex-start">
                <ListItemText
                  primary={t('infoPagePaymentExpirationDate')}
                  secondary={dateText(user && user.paymentExpDate)}
                />
              </ListItem>
              <Divider component="li" />

              <ListItem alignItems="flex-start">
                <ListItemText
                  primary={t('infoPageFreeAccessUntil')}
                  secondary={dateText(user && user.freeAccessExpDate)}
                />
              </ListItem>
              <Divider component="li" />
            </List>
          </GridItem>

          <GridItem xs={12} sm={12} md={8}>
            <div className={classes.actions}>
              <Button
                className={classes.deleteBtn}
                variant="outlined"
                color="primary"
                onClick={onDelete}
                disabled={saving}
              >
                {t('actionDeleteAccount')}
              </Button>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onCancel}
                  disabled={saving}
                  style={{ marginRight: '1rem' }}
                >
                  {t('actionCancel')}
                </Button>
                <Button variant="contained" color="primary" onClick={onSave} disabled={saving}>
                  {t('actionSave')}
                </Button>
              </div>
            </div>
          </GridItem>
        </GridContainer>
      </div>
      {saving && <LinearProgress />}
      {deleting && <LinearProgress />}
    </>
  );
}

Info.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(profilePageStyle)(Info);
