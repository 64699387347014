import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  box: {
    width: '200px',
    marginRight: '2rem',
    marginBottom: '2rem',
    maxWidth: '200px',
    position: 'relative',
  },
  listRoot: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '2rem',
  },
  listItem: {
    maxWidth: '200px',
  },
  listItemIcon: {
    color: 'red',
    position: 'absolute',
    top: -12,
    left: 197,
  },
  button: {
    backgroundColor: '#202D3E',
    color: '#fff',
    borderRadius: '5px',
    border: '1px solid #fff',
    '&:hover,&:focus': {
      backgroundColor: '#202D3E',
      color: '#64b5f6',
    },
  },
}));
