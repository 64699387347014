import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Step from '@material-ui/core/Step';
import StepContent from '@material-ui/core/StepContent';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import Switch from '@material-ui/core/Switch';
import {
  ALTERNATING_FIRST_WEEKEND_OF_THE_MONTH,
  DEFAULT_WEEKENDS_COPY,
  ELECT_SPO_COPY,
  ELECT_SPO_DAYS_ROTATION,
  ELECT_SPO_TEXAS,
  PARENT_STARTING_VISITATION,
  SCHOOL_BREAK_CHRISTMAS_NEW_YEAR,
  SCHOOL_BREAK_SPRING,
  SCHOOL_BREAK_SUMMER,
  SCHOOL_BREAK_THANKSGIVING,
  WITHIN_DISTANCE_COPY,
  customizeRotationRules,
  primaryParentValue,
} from 'constants.js';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getSchoolBreakTemplates } from 'reducers/schoolBreaks';
import AlertBox from 'shared/AlertBox/AlertBox';
import Button from 'shared/Button/Button';
import AlternateWeekendsPossession from 'shared/Forms/AlternateWeekendsPossession';
import CalendarName from 'shared/Forms/CalendarName';
import ChildBirthday from 'shared/Forms/ChildBirthday';
import Parent1MotherFather from 'shared/Forms/CreateCalendar/Parent1MotherFather';
import DefaultWeekends from 'shared/Forms/DefaultWeekends';
import ElectSPO from 'shared/Forms/ElectSPO';
import CustomPossession from 'shared/Forms/LegalStep/Customize/CustomPossession';
import ParentStartingDay from 'shared/Forms/LegalStep/ParentStartingDay';
import SchoolBreakDatePicker from 'shared/Forms/SchoolBreakDatePicker';
import WithinDistance from 'shared/Forms/WithinDistance';
import Icon from 'shared/Icons/Icons';
import SummaryList from 'shared/SummaryList/SummaryList';
import Typography from 'shared/Typography/Typography';
import WarningBox from 'shared/WarningBox/WarningBox';
import {
  getChristmasYear,
  getCurrentSchoolYear,
  getMonthName,
  getMoreThan100MilesWeekends,
  getNewyearYear,
  getValidMonthsInTheCalendar,
} from 'utils/calendar';
import firebase from 'utils/firebase';
import {
  getAllValidWeekendsOfTheMonth,
  isValidAlternateWeekendsPossession,
  isValidCalendarName,
  isValidChildrenDateOfBirth,
  isValidChildrenName,
  isValidSchoolBreakDate,
  isValidSelectableValue,
} from 'utils/validator';
import { isValidCustomPossession } from 'utils/validator';

import ModalContent from '../ModalContent/ModalContent';
import useStyles from './CreateCalendar.css.jsx';
import {
  addChild,
  getChristmasNewYearTitle,
  handleBack,
  handleNext,
  onChangeAlternateWeekendsPossession,
  onChangeCalendarName,
  onChangeChildDateOfBirth,
  onChangeChildName,
  onChangeDefaultDates,
  onChangeDefaultWeekends,
  onChangeElectSPO,
  onChangeSchoolbreakEndDate,
  onChangeSchoolbreakStartDate,
  onChangeTermsAndConditions,
  onChangeWithinDistance,
  onDeleteChild,
  onGenerateCalendar,
  onHandleCancel,
  onHandleIncompleteSave,
} from './CreateCalendarLogic';

function Create() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const [calendarName, setCalendarName] = useState('');
  const [children, setChildren] = useState([{ name: '', dateOfBirth: null }]);
  const [electSPO, setElectSPO] = useState(0);
  const [withinDistance, setWithinDistance] = useState('');
  const [parent1MotherFatherDay, setParent1MotherFatherDay] = useState('');
  const [alternateTheFirstWeekend] = useState(ALTERNATING_FIRST_WEEKEND_OF_THE_MONTH[0].value);
  const [rotationRuleParentStartingDay, setRotationRuleParentStartingDay] = useState(
    PARENT_STARTING_VISITATION[0].value,
  );
  const [calendarStartingDate, setCalendarStartingDate] = useState(moment().toDate());
  const [defaultWeekends, setDefaultWeekends] = useState('');
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [shouldUseDefaultDates, setShouldUseDefaultDates] = useState(false);
  const loading = useSelector(state => state.createCalendar.loading);
  const { loading: calendarLoading } = useSelector(state => state.calendar);
  const templateSchoolBreaks = useSelector(state => state.schoolBreaks.templates.schoolBreaks);
  const incompleteCalendar = useSelector(
    state => state.incompleteCalendar.currentIncompleteCalendar,
  );
  let allWeekends = [{ start: null, end: null }];
  const [weekendIndices, setWeekendIndices] = useState({
    January: { index: '' },
    February: { index: '' },
    March: { index: '' },
    April: { index: '' },
    May: { index: '' },
    June: { index: '' },
    July: { index: '' },
    August: { index: '' },
    September: { index: '' },
    October: { index: '' },
    November: { index: '' },
    December: { index: '' },
  });

  const [schoolBreaks, setSchoolBreaks] = useState({
    thanksgiving: { start: null, end: null },
    christmas_newyear: { start: null, end: null },
    christmas: { start: null, end: null },
    newyear: { start: null, end: null },
    spring: { start: null, end: null },
    summer: { start: null, end: null },
  });
  const [alternateWeekendsPossession, setAlternateWeekendsPossession] = useState({
    January: { start: null, end: null },
    February: { start: null, end: null },
    March: { start: null, end: null },
    April: { start: null, end: null },
    May: { start: null, end: null },
    June: { start: null, end: null },
    July: { start: null, end: null },
    August: { start: null, end: null },
    September: { start: null, end: null },
    October: { start: null, end: null },
    November: { start: null, end: null },
    December: { start: null, end: null },
  });
  const [secondAugustAlternateWeekendsPossession, setSecondAugustAlternateWeekendsPossession] =
    useState({
      start: null,
      end: null,
    });
  const [secondAugustWeekendIndex, setSecondAugustWeekendIndex] = useState({
    index: '',
  });

  const [customPossessionDays, setCustomPossessionDays] = useState({
    MO: { parent: primaryParentValue, rotation: customizeRotationRules.fixed },
    TU: { parent: primaryParentValue, rotation: customizeRotationRules.fixed },
    WE: { parent: primaryParentValue, rotation: customizeRotationRules.fixed },
    TH: { parent: primaryParentValue, rotation: customizeRotationRules.fixed },
    FR: { parent: primaryParentValue, rotation: customizeRotationRules.fixed },
    SA: { parent: primaryParentValue, rotation: customizeRotationRules.fixed },
    SU: { parent: primaryParentValue, rotation: customizeRotationRules.fixed },
  });

  let allValid = true;
  const schoolYearLongFormat = getCurrentSchoolYear(moment(), moment(), false, true);
  const dispatch = useDispatch();
  const steps = t('createCalendarSteps', { returnObjects: true });
  const parentRotationStartVisitation = PARENT_STARTING_VISITATION.find(
    el => el.value === rotationRuleParentStartingDay,
  );

  useEffect(() => {
    firebase.analytics().logEvent('modal_view_create_calendar');
  }, []);
  //Get data from incomplete calendar
  useEffect(() => {
    if (incompleteCalendar) {
      incompleteCalendar.accessCard.calendarName &&
        setCalendarName(incompleteCalendar.accessCard.calendarName);
      incompleteCalendar.birthdays &&
        incompleteCalendar.birthdays.length > 0 &&
        setChildren(
          incompleteCalendar.birthdays.map(birthday => {
            return { name: birthday.name, dateOfBirth: birthday['b-day'] };
          }),
        );
      typeof incompleteCalendar.defaultWeekends === 'boolean' &&
        setDefaultWeekends(incompleteCalendar.defaultWeekends === true ? 0 : 1);
      typeof incompleteCalendar.electedSecondary === 'string' &&
        setElectSPO(ELECT_SPO_COPY.indexOf(incompleteCalendar.electedSecondary));
      incompleteCalendar.schoolBreaks && setSchoolBreaks({ ...incompleteCalendar.schoolBreaks });
      typeof incompleteCalendar.withinDistance === 'boolean' &&
        setWithinDistance(incompleteCalendar.withinDistance === true ? 0 : 1);

      incompleteCalendar.parent1 && setParent1MotherFatherDay(incompleteCalendar.parent1);

      incompleteCalendar.customPossession &&
        setCustomPossessionDays(incompleteCalendar.customPossession);

      incompleteCalendar.alternateWeekendsPossession &&
        setAlternateWeekendsPossession(incompleteCalendar.alternateWeekendsPossession);
    }
  }, [incompleteCalendar]);

  useEffect(() => {
    dispatch(getSchoolBreakTemplates(getCurrentSchoolYear(moment(), moment())));
  }, [dispatch]);
  function isStepValid(step) {
    if (step === 0) {
      const [valid] = isValidCalendarName(calendarName);
      return valid;
    } else if (step === 1) {
      const validNames = children.map(child => isValidChildrenName(child.name)[0]);
      const validDateOfBirths = children.map(
        child => isValidChildrenDateOfBirth(child.dateOfBirth)[0],
      );
      return !validNames.includes(false) && !validDateOfBirths.includes(false);
    } else if (step === 2) {
      const [validElectSPO] = isValidSelectableValue(electSPO);
      if (Number(electSPO) >= 0 && ELECT_SPO_TEXAS.includes(ELECT_SPO_COPY[electSPO])) {
        const [validWithinDistance] = isValidSelectableValue(withinDistance);
        return validElectSPO && validWithinDistance && parent1MotherFatherDay;
      }
      if (Number(electSPO) >= 0 && ELECT_SPO_COPY[electSPO] === 'CUSTOMIZE') {
        const [validCustomPossession] = isValidCustomPossession(customPossessionDays);
        return validElectSPO && validCustomPossession;
      }

      return validElectSPO;
    } else if (step === 3) {
      if (Number(electSPO) >= 0 && !ELECT_SPO_TEXAS.includes(ELECT_SPO_COPY[electSPO])) {
        return true;
      } else {
        const breaks = Object.assign({}, schoolBreaks);

        //remove these values (christmas, newyear) because they are not used in the general logic
        delete breaks.christmas;
        delete breaks.newyear;

        // delete the value for validation in accordance with what will be shown to the user at the moment
        if (
          templateSchoolBreaks.thanksgiving &&
          moment(templateSchoolBreaks.thanksgiving.end).endOf('month') < moment()
        ) {
          delete breaks.thanksgiving;
        }
        if (
          templateSchoolBreaks.christmas_newyear &&
          moment(templateSchoolBreaks.christmas_newyear.end).add(10, 'days') < moment()
        ) {
          delete breaks.christmas_newyear;
        }
        if (
          templateSchoolBreaks.spring &&
          moment(templateSchoolBreaks.spring.end).add(10, 'days') < moment()
        ) {
          delete breaks.spring;
        }

        const validSchoolBreakDates = Object.values(breaks).map(schoolBreak => {
          if (schoolBreak.start) {
            return isValidSchoolBreakDate(schoolBreak.start, schoolBreak.end)[0];
          } else {
            return false;
          }
        });
        return !validSchoolBreakDates.includes(false);
      }
    } else if (withinDistance === 1 && step === 4) {
      const [validDefaultWeekends] = isValidSelectableValue(defaultWeekends);
      if (validDefaultWeekends) {
        allWeekends = getMoreThan100MilesWeekends(
          alternateWeekendsPossession,
          secondAugustAlternateWeekendsPossession,
          schoolBreaks,
          templateSchoolBreaks,
          defaultWeekends,
          schoolYearLongFormat,
          moment(),
          schoolBreaks.summer.end,
        );
      }
      return withinDistance ? validDefaultWeekends : true;
    } else if (
      step === 4 &&
      Number(electSPO) >= 0 &&
      !ELECT_SPO_TEXAS.includes(ELECT_SPO_COPY[electSPO])
    ) {
      return termsAndConditions;
    } else if (step === 5 && defaultWeekends === 0) {
      return true;
    } else if (step === 5 && defaultWeekends === 1) {
      const validAlternateWeekendsPossession = Object.values(alternateWeekendsPossession).map(
        alternateWeekendsPossession =>
          isValidAlternateWeekendsPossession(alternateWeekendsPossession.start),
      );

      const lastIndex = getValidMonthsInTheCalendar(moment(), schoolBreaks.summer.end);
      let index = Number(moment().format('MM')) - 1;
      let counter = 0;
      let anyValid = false;
      allValid = true;
      while (counter < lastIndex) {
        allValid = allValid && validAlternateWeekendsPossession[index][0];
        anyValid = anyValid || validAlternateWeekendsPossession[index][0];
        counter++;
        index++;
        if (index > 11) {
          index = 0;
        }
      }
      if (anyValid) {
        return true;
      }
    } else if (
      (step === 5 && withinDistance === 0) ||
      (step === 6 && defaultWeekends === 0) ||
      (step === 7 && defaultWeekends)
    ) {
      return termsAndConditions;
    } else {
      return true;
    }
  }
  function getSectionsForSummaryList() {
    return [
      {
        title: t('calendarInfoGeneralTitle'),
        sections: [
          {
            title: t('calendarName'),
            value: calendarName,
            icon: <Icon icon="calendar" size="lg" />,
          },
        ],
      },
      {
        title: t('calendarInfoBirthdayTitle'),
        sections: children.map(child => {
          return {
            title: child.name,
            value: moment(child.dateOfBirth).format('MMM DD, YYYY'),
            icon: <Icon icon="birthday" size="lg" />,
          };
        }),
      },
      {
        title: t('calendarInfoLegalTitle'),
        sections: [
          {
            title: t('labelSecondaryElectSPOQuestion'),
            value: ELECT_SPO_COPY[electSPO],
            icon: <Icon icon="vote" size="lg" />,
          },
          ELECT_SPO_DAYS_ROTATION.includes(ELECT_SPO_COPY[electSPO]) && {
            title: t('parentStartingVisitation'),
            value: parentRotationStartVisitation.label,
            icon: <Icon icon="user" size="lg" />,
          },
          Number(electSPO) >= 0 && ELECT_SPO_TEXAS.includes(ELECT_SPO_COPY[electSPO])
            ? {
                title: t('labelWithInDistance'),
                value: WITHIN_DISTANCE_COPY[Number(withinDistance)]
                  ? WITHIN_DISTANCE_COPY[Number(withinDistance)].toUpperCase()
                  : null,
                icon: <Icon icon="distance" size="lg" />,
              }
            : null,
          withinDistance === 1
            ? {
                title: t('labelDefaultWeekends'),
                value: DEFAULT_WEEKENDS_COPY[Number(defaultWeekends)]
                  ? DEFAULT_WEEKENDS_COPY[Number(defaultWeekends)].toUpperCase()
                  : null,
                icon: <Icon icon="weekend" size="lg" />,
              }
            : null,
        ],
      },
      Number(electSPO) >= 0 && ELECT_SPO_TEXAS.includes(ELECT_SPO_COPY[electSPO])
        ? {
            title: t('calendarInfoSchoolbreak'),
            sections: [
              moment(schoolBreaks.thanksgiving.end).endOf('month') < moment() ||
              !schoolBreaks.thanksgiving.end
                ? null
                : {
                    title: t('schoolBreakThanksgiving'),
                    value: `
              ${moment(schoolBreaks.thanksgiving.start).format('MMM DD, YYYY')} -
              ${moment(schoolBreaks.thanksgiving.end).format('MMM DD, YYYY')}
            `,
                    icon: <Icon icon="thanksgiving" size="lg" />,
                  },
              moment(schoolBreaks.christmas_newyear.end).add(10, 'days') < moment() ||
              !schoolBreaks.christmas_newyear.end
                ? null
                : {
                    title: t('schoolBreakChristmas'),
                    value: `
              ${moment(schoolBreaks.christmas_newyear.start).format('MMM DD, YYYY')} -
              ${moment(schoolBreaks.christmas_newyear.end).format('MMM DD, YYYY')}
            `,
                    icon: <Icon icon="snowman" size="lg" />,
                  },
              moment(schoolBreaks.spring.end).add(10, 'days') < moment() || !schoolBreaks.spring.end
                ? null
                : {
                    title: t('schoolBreakSpring'),
                    value: `
              ${moment(schoolBreaks.spring.start).format('MMM DD, YYYY')} -
              ${moment(schoolBreaks.spring.end).format('MMM DD, YYYY')}
            `,
                    icon: <Icon icon="spring" size="lg" />,
                  },
              moment(schoolBreaks.summer.end).add(10, 'days') < moment() || !schoolBreaks.summer.end
                ? null
                : {
                    title: t('schoolBreakSummer'),
                    value: `
              ${moment(schoolBreaks.summer.start).format('MMM DD, YYYY')} -
              ${moment(schoolBreaks.summer.end).format('MMM DD, YYYY')}
            `,
                    icon: <Icon icon="summer" size="lg" />,
                  },
            ],
          }
        : null,
    ];
  }

  function getStepContent(step) {
    if (step === 0) {
      return (
        <CalendarName
          calendarName={calendarName}
          onChangeCalendarName={value => onChangeCalendarName({ value, setCalendarName })}
        />
      );
    } /*else if (step === 1) {
      return (
        <>
          <LegalZipCode
            zipCode={legalZipCode}
            onChange={onChangeLegalZipCode}
          />
        </>
      );
    } */ else if (step === 1) {
      return (
        <>
          {children.map((child, index) => (
            <ChildBirthday
              key={index}
              name={child.name}
              dateOfBirth={child.dateOfBirth}
              index={index}
              onDeleteChild={onDeleteChild({ index, children, setChildren })}
              onChangeChildName={onChangeChildName({ index, children, setChildren })}
              onChangeChildDateOfBirth={onChangeChildDateOfBirth(index, children, setChildren)}
            />
          ))}
          <div>
            <Button
              size="small"
              color="primary"
              onClick={() => addChild({ children, setChildren })}
            >
              {t('actionAddChild')}
            </Button>
          </div>
          <WarningBox text={t('warningLegalZipCode')} />
        </>
      );
    } else if (step === 2) {
      return (
        <>
          <ElectSPO
            electSPO={electSPO}
            disabled={false}
            onChangeElectSPO={value => onChangeElectSPO({ value, setElectSPO })}
          />
          {Number(electSPO) >= 0 && ELECT_SPO_COPY[electSPO] === 'CUSTOMIZE' && (
            <CustomPossession
              customPossessionDays={customPossessionDays}
              setCustomPossessionDays={setCustomPossessionDays}
              showDayStart={true}
              showUserName={false}
              startingDateValue={calendarStartingDate}
              setStartingDateValue={setCalendarStartingDate}
            />
          )}
          {Number(electSPO) >= 0 && Number(electSPO) <= 4 && (
            <ParentStartingDay
              parentStartingDay={rotationRuleParentStartingDay}
              setParentStartingDay={setRotationRuleParentStartingDay}
              startingDateValue={calendarStartingDate}
              setStartingDateValue={setCalendarStartingDate}
            />
          )}
          {Number(electSPO) >= 0 && ELECT_SPO_TEXAS.includes(ELECT_SPO_COPY[electSPO]) && (
            <WithinDistance
              withinDistance={withinDistance}
              onChangeWithinDistance={value => onChangeWithinDistance({ value, setWithinDistance })}
            />
          )}
          {Number(electSPO) >= 0 && ELECT_SPO_TEXAS.includes(ELECT_SPO_COPY[electSPO]) && (
            <Parent1MotherFather
              parent1MotherFatherDay={parent1MotherFatherDay}
              setParent1MotherFatherDay={setParent1MotherFatherDay}
              showParent1Label={true}
            />
          )}
          {/* {Number(electSPO) >= 0 &&
            (ELECT_SPO_COPY[electSPO] === ELECT_SPO_ALTERNATING_WEEKENDS ||
              ELECT_SPO_COPY[electSPO] === ELECT_SPO_ALTERNATING_WEEK) && (
              <AlternatingWeekendsFirstWeekend
                alternateTheFirstWeekend={alternateTheFirstWeekend}
                onChangeAlternateTheFirstWeekend={onChangeAlternateTheFirstWeekend}
                disabled={false}
                monthName={moment().format('MMMM YYYY')}
                electSPO={ELECT_SPO_COPY[electSPO]}
              />
            )} */}
        </>
      );
    } else if (step === 3) {
      return (
        <>
          <Typography variant="subtitle2">
            {t('createCalendarSchoolbreakTitle', {
              schoolYear: schoolYearLongFormat,
            })}
          </Typography>
          <FormControlLabel
            label={t('defaultSchoolBreakDatesTitle')}
            control={
              <Switch
                color="primary"
                checked={shouldUseDefaultDates}
                onChange={() =>
                  onChangeDefaultDates({
                    shouldUseDefaultDates,
                    setShouldUseDefaultDates,
                    schoolBreaks,
                    templateSchoolBreaks,
                    setSchoolBreaks,
                  })
                }
                value="defaultDates"
              />
            }
          />
          {templateSchoolBreaks.thanksgiving &&
            moment(templateSchoolBreaks.thanksgiving.end).endOf('month') > moment() && (
              <SchoolBreakDatePicker
                type={SCHOOL_BREAK_THANKSGIVING}
                schoolBreak={schoolBreaks.thanksgiving}
                templateSchoolBreak={templateSchoolBreaks.thanksgiving}
                endProps={{ disabled: !schoolBreaks.thanksgiving.start }}
                onChangeStartDate={onChangeSchoolbreakStartDate({
                  name: SCHOOL_BREAK_THANKSGIVING,
                  schoolBreaks,
                  setSchoolBreaks,
                })}
                onChangeEndDate={onChangeSchoolbreakEndDate({
                  name: SCHOOL_BREAK_THANKSGIVING,
                  schoolBreaks,
                  setSchoolBreaks,
                })}
              />
            )}
          {templateSchoolBreaks.christmas_newyear &&
            moment(templateSchoolBreaks.christmas_newyear.end).add(10, 'days') > moment() && (
              <SchoolBreakDatePicker
                title={getChristmasNewYearTitle({
                  getChristmasYear,
                  getNewyearYear,
                  templateSchoolBreaks,
                  t,
                })}
                type={SCHOOL_BREAK_CHRISTMAS_NEW_YEAR}
                schoolBreak={schoolBreaks.christmas_newyear}
                templateSchoolBreak={templateSchoolBreaks.christmas_newyear}
                startProps={{ disabled: false }}
                endProps={{ disabled: false }}
                onChangeStartDate={onChangeSchoolbreakStartDate({
                  name: SCHOOL_BREAK_CHRISTMAS_NEW_YEAR,
                  schoolBreaks,
                  setSchoolBreaks,
                })}
                onChangeEndDate={onChangeSchoolbreakEndDate({
                  name: SCHOOL_BREAK_CHRISTMAS_NEW_YEAR,
                  schoolBreaks,
                  setSchoolBreaks,
                })}
              />
            )}
          {templateSchoolBreaks.spring &&
            moment(templateSchoolBreaks.spring.end).add(10, 'days') > moment() && (
              <SchoolBreakDatePicker
                type={SCHOOL_BREAK_SPRING}
                schoolBreak={schoolBreaks.spring}
                templateSchoolBreak={templateSchoolBreaks.spring}
                endProps={{ disabled: !schoolBreaks.spring.start }}
                onChangeStartDate={onChangeSchoolbreakStartDate({
                  name: SCHOOL_BREAK_SPRING,
                  schoolBreaks,
                  setSchoolBreaks,
                })}
                onChangeEndDate={onChangeSchoolbreakEndDate({
                  name: SCHOOL_BREAK_SPRING,
                  schoolBreaks,
                  setSchoolBreaks,
                })}
              />
            )}

          <SchoolBreakDatePicker
            type={SCHOOL_BREAK_SUMMER}
            schoolBreak={schoolBreaks.summer}
            templateSchoolBreak={templateSchoolBreaks.summer}
            endProps={{ disabled: !schoolBreaks.summer.start }}
            onChangeStartDate={onChangeSchoolbreakStartDate({
              name: SCHOOL_BREAK_SUMMER,
              schoolBreaks,
              setSchoolBreaks,
            })}
            onChangeEndDate={onChangeSchoolbreakEndDate({
              name: SCHOOL_BREAK_SUMMER,
              schoolBreaks,
              setSchoolBreaks,
            })}
          />
        </>
      );
    } else if (step === 4) {
      return (
        <>
          {withinDistance === 1 ? (
            <DefaultWeekends
              defaultWeekends={defaultWeekends}
              onChangeDefaultWeekends={value =>
                onChangeDefaultWeekends({ value, setDefaultWeekends })
              }
            ></DefaultWeekends>
          ) : (
            <div>{<AlertBox variant="info" title={t('alertWithin100milesDefaultWeekends')} />}</div>
          )}
        </>
      );
    } else if (step === 5) {
      const items = [];
      let loop = 0;
      const lastIndex = getValidMonthsInTheCalendar(moment(), schoolBreaks.summer.end);
      while (loop + 8 < lastIndex) {
        items.push(
          <div key={Math.random()} className={classes.alternateWeekendsPossessionWrapper}>
            <AlternateWeekendsPossession
              key={loop}
              className={classes.alternateWeekendsPossessionMonth1}
              monthIndex={loop}
              schoolBreaks={schoolBreaks}
              alternateWeekendsPossession={
                alternateWeekendsPossession[moment().add(loop, 'months').format('MMMM')]
              }
              weekIndex={''}
              weekend={
                loop >= 12
                  ? secondAugustWeekendIndex
                  : weekendIndices[moment().add(loop, 'months').format('MMMM')]
              }
              disabled={false}
              onChangeAlternateWeekendsPossession={(monthIndex, weekIndex) =>
                onChangeAlternateWeekendsPossession({
                  monthIndex,
                  weekIndex,
                  getMonthName,
                  alternateWeekendsPossession,
                  setAlternateWeekendsPossession,
                  weekendIndices,
                  setWeekendIndices,
                  getAllValidWeekendsOfTheMonth,
                  schoolBreaks,
                  setSecondAugustAlternateWeekendsPossession,
                  setSecondAugustWeekendIndex,
                })
              }
            ></AlternateWeekendsPossession>
            {loop + 9 < lastIndex ? (
              <AlternateWeekendsPossession
                key={loop + 1}
                className={classes.alternateWeekendsPossessionMonth2}
                monthIndex={loop + 1}
                schoolBreaks={schoolBreaks}
                alternateWeekendsPossession={
                  alternateWeekendsPossession[
                    moment()
                      .add(loop + 1, 'months')
                      .format('MMMM')
                  ]
                }
                weekIndex={''}
                weekend={
                  loop >= 11
                    ? secondAugustWeekendIndex
                    : weekendIndices[
                        moment()
                          .add(loop + 1, 'months')
                          .format('MMMM')
                      ]
                }
                disabled={loop + 9 >= lastIndex ? true : false}
                onChangeAlternateWeekendsPossession={(monthIndex, weekIndex) =>
                  onChangeAlternateWeekendsPossession({
                    monthIndex,
                    weekIndex,
                    getMonthName,
                    alternateWeekendsPossession,
                    setAlternateWeekendsPossession,
                    weekendIndices,
                    setWeekendIndices,
                    getAllValidWeekendsOfTheMonth,
                    schoolBreaks,
                    setSecondAugustAlternateWeekendsPossession,
                    setSecondAugustWeekendIndex,
                  })
                }
              ></AlternateWeekendsPossession>
            ) : (
              <div className={classes.alternateWeekendsPossessionMonth2}></div>
            )}
          </div>,
        );
        loop += 2;
      }
      return (
        <>
          {
            <div>
              <Typography variant="subtitle2">
                {t('labelAddAlternateWeekendsPossessionTitle')}
              </Typography>
              <div>{items}</div>
            </div>
          }
          {allValid ? <div></div> : <WarningBox text={t('warningAlternateWeekends')} />}
        </>
      );
    } else if (step === 6) {
      allWeekends = getMoreThan100MilesWeekends(
        alternateWeekendsPossession,
        secondAugustAlternateWeekendsPossession,
        schoolBreaks,
        templateSchoolBreaks,
        defaultWeekends,
        schoolYearLongFormat,
        moment(),
        schoolBreaks.summer.end,
      );
      return <SummaryList sections={getSectionsForSummaryList()} />;
    } else if (step === 7) {
      return (
        <>
          <div>
            <Typography variant="body1">
              <Trans i18nKey="actionAcceptTermsAndConditionsDescription">
                <b></b>
              </Trans>
            </Typography>
          </div>

          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={termsAndConditions}
                  onChange={() =>
                    onChangeTermsAndConditions({ setTermsAndConditions, termsAndConditions })
                  }
                  value="checkedB"
                  color="primary"
                  inputProps={{
                    'aria-label': 'terms and conditions',
                  }}
                />
              }
              label={
                <Trans i18nKey="actionAcceptTermsAndConditions">
                  <Link
                    style={{ color: '#9c27b0' }}
                    to="/ourdayscalendar-terms-condition.pdf"
                    target="_blank"
                  ></Link>
                </Trans>
              }
            ></FormControlLabel>
          </FormControl>
        </>
      );
    } else {
      return <div>Unknown</div>;
    }
  }

  function showStep(step) {
    const disableOnlyAlternateWeekends = withinDistance === 1 && defaultWeekends === 0;
    const disableAltAndDefaultWeekends = withinDistance === 0;

    if (
      Number(electSPO) >= 0 &&
      !ELECT_SPO_TEXAS.includes(ELECT_SPO_COPY[electSPO]) &&
      (step === 3 || step === 4 || step === 5)
    ) {
      return false;
    }
    if (disableOnlyAlternateWeekends && step === 5) {
      return false;
    }
    if (disableAltAndDefaultWeekends && (step === 4 || step === 5)) {
      return false;
    }
    return true;
  }

  function getActions() {
    const totalStepsLength = steps.length;
    const effectiveStepsLength =
      Number(electSPO) >= 0 && !ELECT_SPO_TEXAS.includes(ELECT_SPO_COPY[electSPO])
        ? totalStepsLength - 3
        : withinDistance === 0
        ? totalStepsLength - 2
        : defaultWeekends === 0
        ? totalStepsLength - 1
        : totalStepsLength;
    return (
      <>
        {incompleteCalendar ? null : (
          <Button
            disabled={!calendarName || loading || calendarLoading}
            onClick={() =>
              onHandleIncompleteSave({
                calendarName,
                withinDistance,
                defaultWeekends,
                electSPO,
                allWeekends,
                schoolBreaks,
                alternateWeekendsPossession,
                customPossessionDays,
                children,
                dispatch,
              })
            }
          >
            {t('actionSaveIncomplete')}
          </Button>
        )}
        <Button disabled={loading || calendarLoading} onClick={() => onHandleCancel({ dispatch })}>
          {t('actionCancel')}
        </Button>
        <Button
          disabled={activeStep === 0 || loading || calendarLoading}
          onClick={() => handleBack({ setActiveStep })}
        >
          {t('actionBack')}
        </Button>
        {activeStep < effectiveStepsLength - 1 ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleNext({ setActiveStep })}
            disabled={!isStepValid(activeStep)}
          >
            {t('actionNext')}
          </Button>
        ) : null}
        {activeStep === effectiveStepsLength - 1 ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              onGenerateCalendar({
                electSPO,
                schoolBreaks,
                allWeekends,
                calendarName,
                withinDistance,
                defaultWeekends,
                children,
                rotationRuleParentStartingDay,
                customPossessionDays,
                alternateTheFirstWeekend,
                dispatch,
                incompleteCalendar,
                parent1MotherFatherDay,
                calendarStartingDate,
                alternateWeekendsPossession
              })
            }
            disabled={loading || !isStepValid(activeStep)}
          >
            {t('actionCreateCalendar')}
          </Button>
        ) : null}
      </>
    );
  }
  return (
    <ModalContent
      title={t('modalTitleCreateCalendar')}
      subtitle={t('modalSubTitleCreateCalendar')}
      actions={getActions()}
      loading={loading || calendarLoading}
    >
      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        classes={{ root: classes.stepperRoot }}
      >
        {steps.map((label, index) =>
          showStep(index) ? (
            <Step key={label}>
              <StepLabel icon={'►'}>{label}</StepLabel>
              <StepContent>{getStepContent(index)}</StepContent>
            </Step>
          ) : null,
        )}
      </Stepper>
    </ModalContent>
  );
}

export default Create;
