import TextField from '@material-ui/core/TextField';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AlertBox from 'shared/AlertBox/AlertBox';
import Button from 'shared/Button/Button';
import Icon from 'shared/Icons/Icons';

import useStyles from './DeleteCalendar.css.jsx';

export default function DeleteCalendar(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const performOnClick = props.onClick;
  const calendarName = props.calendarName;

  const [verifyCalendarName, setVerifyCalendarName] = useState('');

  function onChangeVerifyDelete(event) {
    setVerifyCalendarName(event.target.value);
  }

  return (
    <div className={classes.deleteContainer}>
      <AlertBox
        variant="danger"
        title={t('alertBoxDeleteCalendarTitle')}
        description={t('alertBoxDeleteCalendarDescription')}
      ></AlertBox>
      <TextField
        margin="dense"
        variant="outlined"
        fullWidth
        id="verifyDeleteCalendarName"
        placeholder={t('labelVerifyDeleteCalendarName')}
        value={verifyCalendarName}
        onChange={onChangeVerifyDelete}
      />
      <Button
        fullWidth
        disabled={calendarName !== verifyCalendarName}
        variant="outlined"
        startIcon={<Icon icon="trash" />}
        onClick={performOnClick}
      >
        {t('actionDeleteCalendar')}
      </Button>
    </div>
  );
}
