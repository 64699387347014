import { formatLong } from 'Modals/CreateCalendar/CreateCalendarLogic';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AlternateWeekendsPossession from 'shared/Forms/AlternateWeekendsPossession';
import Typography from 'shared/Typography/Typography';

// import { getValidMonthsInTheCalendar } from 'utils/calendar';
import useStyles from '../../Modals/CreateCalendar/CreateCalendar.css.jsx';

const AlternativeWeekendsEdit = ({ onChangeAlternateWeekendsPossession, value, disabled }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { secondAugustWeekendIndex, weekendIndices, schoolBreaks, alternateWeekendsPossession } =
    value;
  const items = [];
  let loop = 0;
  const startYearDate = moment(schoolBreaks.summer.end)
    .subtract(1, 'year')
    .month(8)
    .startOf('month')
    .format(formatLong);

  let startDate;
  let loopQtt = 12;
  if (moment(startYearDate).isBefore(moment())) {
    startDate = moment().format(formatLong);
    const monthDiff = moment().diff(moment(startYearDate), 'months', false);
    loopQtt = loopQtt - monthDiff;
  } else {
    startDate = startYearDate;
  }

  while (loop < loopQtt) {
    items.push(
      <div key={Math.random()} className={classes.alternateWeekendsPossessionWrapper}>
        <AlternateWeekendsPossession
          key={loop}
          disabled={disabled}
          className={classes.alternateWeekendsPossessionMonth1}
          monthRealIndex={Number(moment(startDate).clone().add(loop, 'months').format('M'))}
          monthIndex={loop}
          firstPopulatedDate={startDate}
          schoolBreaks={schoolBreaks}
          alternateWeekendsPossession={
            alternateWeekendsPossession[
              moment(startDate).clone().add(loop, 'months').format('MMMM')
            ]
          }
          weekIndex={''}
          weekend={
            loop >= 12
              ? secondAugustWeekendIndex
              : weekendIndices[moment(startDate).clone().add(loop, 'months').format('MMMM')]
          }
          onChangeAlternateWeekendsPossession={onChangeAlternateWeekendsPossession}
        />

        <AlternateWeekendsPossession
          key={loop + 1}
          className={classes.alternateWeekendsPossessionMonth2}
          monthRealIndex={Number(
            moment(startDate)
              .clone()
              .add(loop + 1, 'months')
              .format('M'),
          )}
          monthIndex={loop + 1}
          schoolBreaks={schoolBreaks}
          firstPopulatedDate={startDate}
          alternateWeekendsPossession={
            alternateWeekendsPossession[
              moment(startDate)
                .clone()
                .add(loop + 1, 'months')
                .format('MMMM')
            ]
          }
          weekIndex={''}
          weekend={
            loop >= 11
              ? secondAugustWeekendIndex
              : weekendIndices[
                  moment(startDate)
                    .clone()
                    .add(loop + 1, 'months')
                    .format('MMMM')
                ]
          }
          onChangeAlternateWeekendsPossession={onChangeAlternateWeekendsPossession}
        />
      </div>,
    );
    loop += 2;
  }
  return (
    <>
      {
        <div
          style={{
            width: '100%',
          }}
        >
          <Typography variant="subtitle2">
            {t('labelAddAlternateWeekendsPossessionTitle')}
          </Typography>
          <div>{items}</div>
        </div>
      }
      {/* {allValid ? (
        <div></div>
      ) : (
        <WarningBox text={t("warningAlternateWeekends")} />
      )} */}
    </>
  );
};
export default AlternativeWeekendsEdit;
