import { MODAL_PAYMENT_EXPIRED } from 'constants.js';
import environment from 'environment.js';
import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useDispatch, useSelector } from 'react-redux';
import { addAuthStateListener } from 'reducers/auth';
import { getConfigurations } from 'reducers/configurations';
import { openModal } from 'reducers/modals';
import { checkSubscription } from 'utils/calendar';

import './App.css';

function App({ children }) {
  const authLoading = useSelector(state => state.auth.loading);
  const confLoading = useSelector(state => state.configurations.loading);
  const user = useSelector(state => state.user.user);
  const loading = authLoading || confLoading;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(addAuthStateListener());
    dispatch(getConfigurations());
  }, [dispatch]);

  useEffect(() => {
    ReactGA.initialize(environment.analyticsID);
  });

  useEffect(() => {
    if (user && Object.keys(user).length > 0) {
      const [subscriptionAlmostExpired] = checkSubscription(user);
      if (subscriptionAlmostExpired)
        dispatch(openModal({ type: MODAL_PAYMENT_EXPIRED, disableClose: true }));
    }
  }, [user]);

  if (loading) {
    return null;
  }
  return children;
}

export default App;
