/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// core components
import parallaxStyle from 'assets/jss/material-kit-pro-react/components/parallaxStyle.jsx';
// nodejs library that concatenates classes
import classNames from 'classnames';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
import React from 'react';

class Parallax extends React.Component {
  constructor(props) {
    super(props);
    let windowScrollTop;
    if (window.innerWidth >= 768) {
      windowScrollTop = window.pageYOffset / 3;
    } else {
      windowScrollTop = 0;
    }
    this.state = {
      transform: 'translate3d(0,' + windowScrollTop + 'px,0)',
    };
  }
  componentDidMount() {
    if (window.innerWidth >= 768) {
      var windowScrollTop = window.pageYOffset / 3;
      this.setState({
        transform: 'translate3d(0,' + windowScrollTop + 'px,0)',
      });
      window.addEventListener('scroll', this.resetTransform);
    }
  }
  componentWillUnmount() {
    if (window.innerWidth >= 768) {
      window.removeEventListener('scroll', this.resetTransform);
    }
  }
  resetTransform = () => {
    var windowScrollTop = window.pageYOffset / 3;
    this.setState({
      transform: 'translate3d(0,' + windowScrollTop + 'px,0)',
    });
  };
  render() {
    const { classes, filter, className, children, style, image, small } = this.props;
    const parallaxClasses = classNames({
      [classes.parallax]: true,
      [classes[filter + 'Color']]: filter !== undefined,
      [classes.small]: small,
      [className]: className !== undefined,
    });
    return (
      <div
        className={parallaxClasses}
        style={{
          ...style,
          backgroundImage: 'url(' + image + ')',
          ...this.state,
        }}
      >
        {children}
      </div>
    );
  }
}

Parallax.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  filter: PropTypes.oneOf(['primary', 'rose', 'dark', 'info', 'success', 'warning', 'danger']),
  children: PropTypes.node,
  style: PropTypes.string,
  image: PropTypes.string,
  small: PropTypes.bool,
};

export default withStyles(parallaxStyle)(Parallax);
