import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { closeModal } from 'reducers/modals';
import Button from 'shared/Button/Button';
import firebase from 'utils/firebase';

import ModalContent from '../ModalContent/ModalContent';

export default function AlertExtendOldCalendar() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    firebase.analytics().logEvent('modal_view_extend_calendar_warning');
  }, []);

  function onCloseModal() {
    firebase.analytics().logEvent('modal_cancel_extend_calendar_warning');
    dispatch(closeModal());
  }

  function getActions() {
    return (
      <>
        <Button onClick={onCloseModal} color="primary">
          {t('actionOk')}
        </Button>
      </>
    );
  }

  return (
    <ModalContent title={t('alertExtendOldCalendarTitle')} actions={getActions()}></ModalContent>
  );
}
