import { formatLong } from 'Modals/CreateCalendar/CreateCalendarLogic';
import { ALTERNATE_WEEKENDS_EDIT } from 'constants';
import {
  DEFAULT_WEEKENDS,
  EDIT_CHILD_INFO,
  ELECT_SPO,
  ELECT_SPO_COPY,
  ELECT_SPO_CUSTOM,
  ELECT_SPO_DAYS_ROTATION,
  ELECT_SPO_TEXAS,
  FETCH_CALENDARS_DELAY,
  FIRST_WEEKEND,
  LEGAL_ROLE_VIEW,
  LEGAL_STATES,
  PARENT1_IS,
  PARENT1_MOTHER_FATHER,
  PARENT_STARTING_VISITATION,
  PARENT_STARTING_VISITATION_LABEL,
  SCHOOL_BREAK_CHRISTMAS,
  SCHOOL_BREAK_CHRISTMAS_NEW_YEAR,
  SCHOOL_BREAK_NEWYEAR,
  SCHOOL_BREAK_SPRING,
  SCHOOL_BREAK_SUMMER,
  SCHOOL_BREAK_THANKSGIVING,
  WITHIN_DISTANCE,
  customizeRotationRules,
  primaryParentValue,
} from 'constants.js';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getCalendars } from 'reducers/calendar';
import { getCalendarsQtt } from 'reducers/calendar';
import { saveCalendarInfo } from 'reducers/calendarInfo';
import { closeModal } from 'reducers/modals';
import { addSnackbar } from 'reducers/snackbar';
import Button from 'shared/Button/Button';
import Icon from 'shared/Icons/Icons';
import SummaryList from 'shared/SummaryList/SummaryList';
import api from 'utils/api.js';
import {
  convertCustomizeToNewVersion,
  getCurrentCalendar,
  getCurrentSchoolYear,
  getMonthName,
} from 'utils/calendar';
import firebase from 'utils/firebase';
import {
  getAllValidWeekendsOfTheMonth,
  includeSchoolBrakesValues,
  isValidAlternateWeekendsPossession,
  isValidCalendarName,
  isValidChildrenValue,
  isValidSelectableValue,
} from 'utils/validator';
import { isValidCustomPossession } from 'utils/validator';

import { getYearsFrom } from '../../utils/calendar';
import ModalContent from '../ModalContent/ModalContent';
import DeleteCalendar from './DeleteCalendar';

export default function CalendarInfo() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const calendar = useSelector(state =>
    pathname.includes('calendars') ? state.calendar : state.sharedCalendars,
  );
  const isSavingCalendar = useSelector(state => state.calendarInfo.loading);
  const { t } = useTranslation();
  const currentCalendar = getCurrentCalendar(calendar.list.calendars, calendar.currentCalendarId);
  const templateSchoolBreaks = useSelector(state => state.schoolBreaks.templates.schoolBreaks);
  const [legalState] = useState('0');
  const [electSPO, setElectSPO] = useState('');
  const [withinDistance, setWithinDistance] = useState('');
  const [defaultWeekends, setDefaultWeekends] = useState('');

  const [parent1MotherFatherDay, setParent1MotherFatherDay] = useState(
    PARENT1_MOTHER_FATHER[0].value,
  );
  const [calendarName, setCalendarName] = useState('');

  const [parentStartingDay, setParentStartingDay] = useState(PARENT_STARTING_VISITATION[0].value);

  const [calendarStartingDate, setCalendarStartingDate] = useState(moment().toDate());

  const [schoolBreaks, setSchoolBreaks] = useState({
    thanksgiving: { start: null, end: null },
    christmas_newyear: { start: null, end: null },
    christmas: { start: null, end: null },
    newyear: { start: null, end: null },
    spring: { start: null, end: null },
    summer: { start: null, end: null },
  });
  const [childrenBirth, setChildrenBirth] = useState([]);
  const [isDeletingCalendar, setIsDeletingCalendar] = useState(false);
  const [isRebuildingCalendar] = useState(false);
  const [alternateWeekendsPossession, setAlternateWeekendsPossession] = useState({
    January: { start: '', end: '' },
    February: { start: '', end: '' },
    March: { start: '', end: '' },
    April: { start: '', end: '' },
    May: { start: '', end: '' },
    June: { start: '', end: '' },
    July: { start: '', end: '' },
    August: { start: '', end: '' },
    September: { start: '', end: '' },
    October: { start: '', end: '' },
    November: { start: '', end: '' },
    December: { start: '', end: '' },
  });

  const [weekendIndices, setWeekendIndices] = useState({
    January: { index: '' },
    February: { index: '' },
    March: { index: '' },
    April: { index: '' },
    May: { index: '' },
    June: { index: '' },
    July: { index: '' },
    August: { index: '' },
    September: { index: '' },
    October: { index: '' },
    November: { index: '' },
    December: { index: '' },
  });
  const [secondAugustWeekendIndex] = useState({
    index: '',
  });
  const [secondAugustAlternateWeekendsPossession] = useState({
    start: null,
    end: null,
  });
  const currentDate = moment(calendar.currentDate);
  const firstPopulatedDate = moment(currentCalendar.firstPopulatedDate);
  // const schoolYearLongFormat = getCurrentSchoolYear(moment(), moment(), false, true);
  useEffect(() => {
    firebase.analytics().logEvent('modal_view_calendar_info');
  }, []);
  useEffect(() => {
    if (currentCalendar) {
      currentCalendar.calendarStartingDate
        ? setCalendarStartingDate(currentCalendar.calendarStartingDate)
        : setCalendarStartingDate(currentCalendar.firstPopulatedDate);
      currentCalendar.rotationFirstParen &&
        setParentStartingDay(currentCalendar.rotationFirstParen);
    }
  }, [currentCalendar]);
  useEffect(() => {
    if (currentCalendar && currentCalendar.accessCard) {
      setCalendarName(currentCalendar.accessCard.calendarName);
    }
    if (currentCalendar && currentCalendar.schoolBreaks) {
      for (const [key, value] of Object.entries(currentCalendar.schoolBreaks)) {
        currentCalendar.schoolBreaks[key]['start'] = moment(value.start).format(formatLong);
        currentCalendar.schoolBreaks[key]['end'] = moment(value.end).format(formatLong);
      }
      setSchoolBreaks({
        ...currentCalendar.schoolBreaks,
      });
    }
    if (currentCalendar) {
      setElectSPO(ELECT_SPO_COPY.indexOf(currentCalendar.electedSecondary));
      setWithinDistance(Number(!currentCalendar.withinDistance));
      currentCalendar.parent1 && setParent1MotherFatherDay(currentCalendar.parent1);
      setDefaultWeekends(
        currentCalendar.defaultWeekends !== null ? Number(!currentCalendar.defaultWeekends) : '',
      );
      setChildrenBirth(currentCalendar.birthdays);
    }
    if (currentCalendar.defaultWeekends === false && currentCalendar.allWeekends) {
      for (const [, value] of Object.entries(currentCalendar.allWeekends)) {
        const monthName = moment(value.start).format('MMMM');
        alternateWeekendsPossession[monthName].start = value.start;
        alternateWeekendsPossession[monthName].end = value.end;
      }
      setAlternateWeekendsPossession({ ...alternateWeekendsPossession });
    }
    if (
      currentCalendar &&
      currentCalendar.customPossession &&
      Object.keys(currentCalendar.customPossession).length
    ) {
      const rules = convertCustomizeToNewVersion(currentCalendar.customPossession);
      setCustomPossessionDays(rules);
    }
  }, [currentCalendar]);

  const [customPossessionDays, setCustomPossessionDays] = useState({
    MO: { parent: primaryParentValue, rotation: customizeRotationRules.fixed },
    TU: { parent: primaryParentValue, rotation: customizeRotationRules.fixed },
    WE: { parent: primaryParentValue, rotation: customizeRotationRules.fixed },
    TH: { parent: primaryParentValue, rotation: customizeRotationRules.fixed },
    FR: { parent: primaryParentValue, rotation: customizeRotationRules.fixed },
    SA: { parent: primaryParentValue, rotation: customizeRotationRules.fixed },
    SU: { parent: primaryParentValue, rotation: customizeRotationRules.fixed },
  });

  function onCloseModal() {
    firebase.analytics().logEvent('modal_cancel_calendar_info');
    dispatch(closeModal());
  }

  const getSchoolBreaksValidatorTypes = () => {
    const types = [];
    if (moment(templateSchoolBreaks.christmas_newyear.end).add(10, 'days') > moment()) {
      types.push(SCHOOL_BREAK_CHRISTMAS_NEW_YEAR);
    }
    if (moment(templateSchoolBreaks.spring.end).add(10, 'days') > moment()) {
      types.push(SCHOOL_BREAK_SPRING);
    }
    if (moment(templateSchoolBreaks.thanksgiving.end).endOf('month') > moment()) {
      types.push(SCHOOL_BREAK_THANKSGIVING);
    }
    types.push(SCHOOL_BREAK_SUMMER);
    return types;
  };

  function isValidForm() {
    if (ELECT_SPO_TEXAS.includes(ELECT_SPO_COPY[electSPO])) {
      const [valid] = isValidCalendarName(calendarName);
      const validChildValue = isValidChildrenValue(childrenBirth);
      const [validElectSPO] = isValidSelectableValue(electSPO);
      const [validWithinDistance] = isValidSelectableValue(withinDistance);
      let validAlternateWeekends = true;
      let validSchoolBreak = true;
      let validDefaultWeekends = true;

      if (withinDistance === 1) {
        validDefaultWeekends = isValidSelectableValue(defaultWeekends)[0];
      }
      if (schoolBreaks) {
        validSchoolBreak = includeSchoolBrakesValues(schoolBreaks, getSchoolBreaksValidatorTypes());
      }
      if (schoolBreaks && schoolBreaks.summer && defaultWeekends === 1) {
        const validAlternateWeekendsPossession = Object.values(alternateWeekendsPossession).map(
          alternateWeekendsPossession =>
            isValidAlternateWeekendsPossession(alternateWeekendsPossession.start),
        );
        if (validAlternateWeekendsPossession.some(item => item[0])) {
          validAlternateWeekends = true;
        } else {
          validAlternateWeekends = false;
        }
      }
      return (
        validElectSPO &&
        validWithinDistance &&
        valid &&
        validDefaultWeekends &&
        validAlternateWeekends &&
        validSchoolBreak &&
        validChildValue &&
        parent1MotherFatherDay
      );
    } else if (ELECT_SPO_COPY[electSPO] === 'CUSTOMIZE') {
      const [valid] = isValidCalendarName(calendarName);
      const validChildValue = isValidChildrenValue(childrenBirth);
      const [validCustomPossession] = isValidCustomPossession(customPossessionDays);
      return validCustomPossession && valid && validChildValue;
    } else {
      const [valid] = isValidCalendarName(calendarName);
      const validChildValue = isValidChildrenValue(childrenBirth);
      const [validElectSPO] = isValidSelectableValue(electSPO);
      return valid && validElectSPO && validChildValue;
    }
  }

  function onSave() {
    // allWeekends = getMoreThan100MilesWeekends(
    //   alternateWeekendsPossession,
    //   secondAugustAlternateWeekendsPossession,
    //   schoolBreaks,
    //   templateSchoolBreaks,
    //   defaultWeekends,
    //   schoolYearLongFormat,
    //   moment(),
    //   schoolBreaks.summer.end,
    // );

    const data = ELECT_SPO_DAYS_ROTATION.includes(ELECT_SPO_COPY[electSPO])
      ? {
          electedSecondary: ELECT_SPO_COPY[electSPO],
          childrenBirth,
          calendarName,
          parentStartingVisitation: parentStartingDay,
          rotationFirstParen: parentStartingDay,
          calendarStartingDate,
          schoolYear: currentCalendar.schoolYear,
          calendarId: currentCalendar.calendarId,
        }
      : ELECT_SPO_COPY[electSPO] === 'CUSTOMIZE'
      ? {
          electedSecondary: ELECT_SPO_COPY[electSPO],
          customPossessionDays,
          childrenBirth,
          calendarName,
          calendarStartingDate,
          schoolYear: currentCalendar.schoolYear,
          calendarId: currentCalendar.calendarId,
        }
      : {
          state: LEGAL_STATES[legalState].abbrevation,
          schoolBreaks: schoolBreaks,
          alternateWeekendsPossession,
          secondAugustAlternateWeekendsPossession,
          calendarName,
          customPossessionDays,
          withinDistance: withinDistance === 0 ? true : false,
          defaultWeekends: defaultWeekends === '' ? null : defaultWeekends === 0 ? true : false,
          electedSecondary: ELECT_SPO_COPY[electSPO],
          calendarId: currentCalendar.calendarId,
          schoolYear: currentCalendar.schoolYear,
          childrenBirth,
          parent1: parent1MotherFatherDay,
        };
    dispatch(saveCalendarInfo(data));
    firebase.analytics().logEvent('modal_action_calendar_info_save');
  }

  function getSchoolBreakDate(type) {
    if (schoolBreaks) {
      if (SCHOOL_BREAK_CHRISTMAS_NEW_YEAR === type) {
        return {
          start: schoolBreaks.christmas.start,
          end: schoolBreaks.newyear.end,
        };
      } else {
        return {
          start: schoolBreaks[type].start,
          end: schoolBreaks[type].end,
        };
      }
    } else {
      return t('infoMissingSchoolBreakForGivenSchoolYear');
    }
  }

  function onChangeStartDate(type, date) {
    if (type === SCHOOL_BREAK_CHRISTMAS_NEW_YEAR) {
      const christmasEnd = moment(date).clone().date(27).format('YYYY-MM-DD');
      const newYearStart = moment(date).clone().date(28).format('YYYY-MM-DD');
      setSchoolBreaks({
        ...schoolBreaks,
        [SCHOOL_BREAK_CHRISTMAS]: { start: date, end: christmasEnd },
        [SCHOOL_BREAK_NEWYEAR]: { start: newYearStart, end: null },
        [SCHOOL_BREAK_CHRISTMAS_NEW_YEAR]: { start: date, end: null },
      });
    } else {
      setSchoolBreaks({
        ...schoolBreaks,
        [type]: { start: date, end: null },
      });
    }
  }
  function onChangeEndDate(type, date) {
    if (type === SCHOOL_BREAK_CHRISTMAS_NEW_YEAR) {
      schoolBreaks[SCHOOL_BREAK_NEWYEAR].end = date;
      const newYearEnd = date;
      const newYearStart = schoolBreaks[SCHOOL_BREAK_NEWYEAR].start;
      const christmas_newyearStart = schoolBreaks[SCHOOL_BREAK_CHRISTMAS_NEW_YEAR].start;
      setSchoolBreaks({
        ...schoolBreaks,
        [SCHOOL_BREAK_NEWYEAR]: { start: newYearStart, end: newYearEnd },
        [SCHOOL_BREAK_CHRISTMAS_NEW_YEAR]: {
          start: christmas_newyearStart,
          end: date,
        },
      });
    } else {
      setSchoolBreaks({
        ...schoolBreaks,
        [type]: { ...schoolBreaks[type], end: date },
      });
    }
  }

  function onChangeElectSPO(value) {
    setElectSPO(value);
  }
  function onChangeChildBirthDate(date, entryIndex) {
    const ChildrenBirth = [...childrenBirth];
    ChildrenBirth[entryIndex]['b-day'] = date;
    setChildrenBirth(ChildrenBirth);
  }

  function onChangeChildBirthName(value, entryIndex) {
    const ChildrenBirth = [...childrenBirth];
    ChildrenBirth[entryIndex].name = value;
    setChildrenBirth(ChildrenBirth);
  }
  function onChangeWithinDistance(value) {
    setWithinDistance(value);
    if (value === 0) {
      setDefaultWeekends('');
    }
  }
  // const onChangeAlternateTheFirstWeekend = useCallback(event => {
  //   setAlternateTheFirstWeekend(event.target.value);
  // }, []);
  function onChangeDefaultWeekends(value) {
    setDefaultWeekends(value);
  }

  function onChangeAlternateWeekendsPossession(monthIndex, weekIndex, monthRealIndex) {
    let weekends, weekend;
    let month = getMonthName(monthRealIndex - 1);

    const startYearDate = moment(schoolBreaks.summer.end)
      .subtract(1, 'year')
      .month(8)
      .startOf('month')
      .format(formatLong);

    let startDate;
    if (moment(startYearDate).isBefore(moment())) {
      startDate = moment().format(formatLong);
    } else {
      startDate = startYearDate;
    }

    if (weekIndex === '') {
      alternateWeekendsPossession[month].start = null;
      alternateWeekendsPossession[month].end = null;
      setAlternateWeekendsPossession({ ...alternateWeekendsPossession });
      weekendIndices[month].index = '';
      setWeekendIndices({ ...weekendIndices });
    } else {
      weekends = getAllValidWeekendsOfTheMonth(
        monthIndex - 1,
        schoolBreaks,
        startDate,
        schoolBreaks.summer.end,
      );
      weekend = weekends[weekIndex];

      if (weekend) {
        month = moment(weekend.day1).clone().format('MMMM');
        alternateWeekendsPossession[month].start = moment(weekend.day1)
          .clone()
          .format('YYYY-MM-DD');
        alternateWeekendsPossession[month].end = moment(weekend.day3).clone().format('YYYY-MM-DD');
        setAlternateWeekendsPossession({ ...alternateWeekendsPossession });
        weekendIndices[month].index = weekIndex;
        setWeekendIndices({ ...weekendIndices });
      }
      // if (monthIndex === 12) {
      //   setSecondAugustAlternateWeekendsPossession({
      //     start: moment(weekend.day1).clone().format('YYYY-MM-DD'),
      //     end: moment(weekend.day3).clone().format('YYYY-MM-DD'),
      //   });
      //   setSecondAugustWeekendIndex({ index: weekIndex });
      // } else {

      // }
    }
  }
  const { role } = currentCalendar.accessCard;
  function getSections() {
    const year = getYearsFrom(currentCalendar.schoolYear);

    return [
      {
        title: t('calendarInfoGeneralTitle'),
        sections: [
          {
            title: t('calendarName'),
            value: calendarName,
            disabled: role === LEGAL_ROLE_VIEW,
            icon: <Icon icon="calendar" size="lg" />,
            maxLength: 50,
            onChange: event => setCalendarName(event.target.value),
          },
          {
            title: t('calendarCreatedAt'),
            value: moment(currentCalendar.createdDate).format('MMM DD, YYYY'),
            icon: <Icon icon="calendar" size="lg" />,
          },
          {
            title: t('calendarUpdatedAt'),
            value: moment(
              currentCalendar.updatedDate
                ? currentCalendar.updatedDate
                : currentCalendar.createdDate,
            ).format('MMM DD, YYYY'),
            icon: <Icon icon="calendar" size="lg" />,
          },
          {
            title: t('calendarId'),
            value: currentCalendar.calendarId,
            icon: <Icon icon="tag" size="lg" />,
          },
        ],
      },
      {
        title: t('calendarInfoBirthdayTitle'),
        sections: childrenBirth.map((birthday, index) => {
          return {
            childName: birthday.name,
            value: moment(birthday['b-day']).format('MMM DD, YYYY'),
            entryIndex: index,
            type: EDIT_CHILD_INFO,
            disabled: role === LEGAL_ROLE_VIEW,
            maxLength: 50,
            icon: <Icon icon="birthday" size="lg" />,
            onChangeDate: (date, entryIndex) => onChangeChildBirthDate(date, entryIndex),
            onChangeName: (value, entryIndex) => onChangeChildBirthName(value, entryIndex),
          };
        }),
      },
      {
        title: t('calendarInfoLegalTitle'),
        sections: [
          {
            title: t('labelSecondaryElectSPOQuestion'),
            type: ELECT_SPO,
            value: electSPO,
            icon: <Icon icon="vote" size="lg" />,
            disabled: role === LEGAL_ROLE_VIEW,
            onChange: value => onChangeElectSPO(value),
          },
          ELECT_SPO_COPY[electSPO] === 'CUSTOMIZE'
            ? {
                title: t('labelSecondaryElectSPOQuestion'),
                type: ELECT_SPO_CUSTOM,
                value: customPossessionDays,
                disabled: role === LEGAL_ROLE_VIEW,
                setCustomPossessionDays: setCustomPossessionDays,
              }
            : null,
          ELECT_SPO_TEXAS.includes(ELECT_SPO_COPY[electSPO])
            ? {
                title: t('labelWithInDistance'),
                type: WITHIN_DISTANCE,
                value: withinDistance,
                disabled: role === LEGAL_ROLE_VIEW,
                icon: <Icon icon="distance" size="lg" />,
                onChange: value => onChangeWithinDistance(value),
              }
            : null,
          ELECT_SPO_TEXAS.includes(ELECT_SPO_COPY[electSPO])
            ? {
                title: currentCalendar.parent1Name
                  ? t('parent1WithName', { name: currentCalendar.parent1Name })
                  : t('parent1is'),
                type: PARENT1_IS,
                value: parent1MotherFatherDay,
                disabled: role === LEGAL_ROLE_VIEW,
                parent1Name: currentCalendar.parent1Name,
                icon: <Icon icon="user" size="lg" />,
                onChange: setParent1MotherFatherDay,
              }
            : null,
          (ELECT_SPO_DAYS_ROTATION.includes(ELECT_SPO_COPY[electSPO]) ||
            ELECT_SPO_COPY[electSPO] === 'CUSTOMIZE') && {
            title: t('startingDate'),
            type: FIRST_WEEKEND,
            value: calendarStartingDate,
            disabled: role === LEGAL_ROLE_VIEW,
            onChange: date => setCalendarStartingDate(date),
            icon: <Icon icon="calendar-day" size="lg" />,
            electSPO: ELECT_SPO_COPY[electSPO],
            firstPopulatedDate: currentCalendar.firstPopulatedDate,
            lastPopulatedDate: currentCalendar.lastPopulatedDate,
          },
          ELECT_SPO_DAYS_ROTATION.includes(ELECT_SPO_COPY[electSPO]) && {
            title: t('parentStartingVisitation'),
            type: PARENT_STARTING_VISITATION_LABEL,
            value: parentStartingDay,
            disabled: role === LEGAL_ROLE_VIEW,
            onChange: value => setParentStartingDay(value),
            icon: <Icon icon="user" size="lg" />,
            electSPO: ELECT_SPO_COPY[electSPO],
          },
        ],
      },
      ELECT_SPO_TEXAS.includes(ELECT_SPO_COPY[electSPO])
        ? {
            title: t('calendarInfoSchoolbreakTitle', {
              schoolYear: getCurrentSchoolYear(currentDate, firstPopulatedDate, false, true),
            }),
            info: t('calendarInfoSchoolbreakInfo'),
            sections: [
              moment().year(year[0]).month('November').format('YYYY-MM') <
                moment().format('YYYY-MM') || !schoolBreaks.thanksgiving
                ? null
                : {
                    title: t('schoolBreakThanksgiving'),
                    disabled: role === LEGAL_ROLE_VIEW,
                    value: getSchoolBreakDate(SCHOOL_BREAK_THANKSGIVING),
                    icon: <Icon icon="thanksgiving" size="lg" />,
                    type: SCHOOL_BREAK_THANKSGIVING,
                    onChangeStartDate: (type, date) => onChangeStartDate(type, date),
                    onChangeEndDate: (type, date) => onChangeEndDate(type, date),
                  },
              moment().year(year[1]).month('January').format('YYYY-MM') <
                moment().format('YYYY-MM') ||
              !schoolBreaks.christmas ||
              !schoolBreaks.newyear
                ? null
                : {
                    title: t('editSchoolBreak_christmas_newYear'),
                    value: getSchoolBreakDate(SCHOOL_BREAK_CHRISTMAS_NEW_YEAR),
                    disabled: role === LEGAL_ROLE_VIEW,
                    icon: <Icon icon="snowman" size="lg" />,
                    type: SCHOOL_BREAK_CHRISTMAS_NEW_YEAR,
                    onChangeStartDate: (type, date) => onChangeStartDate(type, date),
                    onChangeEndDate: (type, date) => onChangeEndDate(type, date),
                  },
              moment().year(year[1]).month('April').format('YYYY-MM') <
                moment().format('YYYY-MM') || !schoolBreaks.spring
                ? null
                : {
                    title: t('schoolBreakSpring'),
                    value: getSchoolBreakDate(SCHOOL_BREAK_SPRING),
                    disabled: role === LEGAL_ROLE_VIEW,
                    icon: <Icon icon="spring" size="lg" />,
                    type: SCHOOL_BREAK_SPRING,
                    onChangeStartDate: (type, date) => onChangeStartDate(type, date),
                    onChangeEndDate: (type, date) => onChangeEndDate(type, date),
                  },
              {
                title: t('schoolBreakSummer'),
                value: getSchoolBreakDate(SCHOOL_BREAK_SUMMER),
                disabled: role === LEGAL_ROLE_VIEW,
                icon: <Icon icon="summer" size="lg" />,
                type: SCHOOL_BREAK_SUMMER,
                onChangeStartDate: (type, date) => onChangeStartDate(type, date),
                onChangeEndDate: (type, date) => onChangeEndDate(type, date),
              },
            ],
          }
        : null,
      withinDistance === 1 &&
      ELECT_SPO_TEXAS.includes(ELECT_SPO_COPY[electSPO]) &&
      schoolBreaks.summer.start &&
      schoolBreaks.summer.end
        ? {
            title: t('calendarInfoWeekendsTitle'),
            sections: [
              {
                title: t('labelDefaultWeekends'),
                disabled: role === LEGAL_ROLE_VIEW,
                type: DEFAULT_WEEKENDS,
                value: defaultWeekends,
                icon: <Icon icon="weekend" size="lg" />,
                onChange: value => onChangeDefaultWeekends(value),
              },
              defaultWeekends === 1 && ELECT_SPO_TEXAS.includes(ELECT_SPO_COPY[electSPO])
                ? {
                    type: ALTERNATE_WEEKENDS_EDIT,
                    disabled: role === LEGAL_ROLE_VIEW,
                    value: {
                      schoolBreaks,
                      alternateWeekendsPossession,
                      secondAugustWeekendIndex,
                      weekendIndices,
                      firstPopulatedDate,
                    },
                    onChange: (monthIndex, weekIndex, monthRealIndex) =>
                      onChangeAlternateWeekendsPossession(monthIndex, weekIndex, monthRealIndex),
                  }
                : null,
            ],
          }
        : null,
    ];
  }

  function actions() {
    return (
      <>
        <Button
          color="primary"
          onClick={onCloseModal}
          disabled={isSavingCalendar || isDeletingCalendar || isRebuildingCalendar}
        >
          {t('actionCancel')}
        </Button>
        <Button
          color="primary"
          onClick={onSave}
          disabled={
            !isValidForm() ||
            role === LEGAL_ROLE_VIEW ||
            isSavingCalendar ||
            isDeletingCalendar ||
            isRebuildingCalendar
          }
        >
          {t('actionSave')}
        </Button>
      </>
    );
  }

  async function onDeleteCalendar() {
    const calendarId = currentCalendar.calendarId;
    try {
      setIsDeletingCalendar(true);
      await api.deleteCalendar({ calendarId });
      // add a little delay to prevent white screen
      setTimeout(() => {
        dispatch(getCalendars({ completeRefresh: true }));
        dispatch(getCalendarsQtt());
        setIsDeletingCalendar(false);
        dispatch(closeModal());
      }, FETCH_CALENDARS_DELAY);
    } catch (error) {
      setIsDeletingCalendar(false);
      console.error(JSON.stringify(error));
      dispatch(
        addSnackbar({
          text: `${error.message}`,
          variant: 'error',
          closeButton: true,
        }),
      );
    }
  }

  if (!currentCalendar) {
    return null;
  }

  return (
    <>
      <ModalContent
        title={calendarName}
        actions={actions()}
        loading={isSavingCalendar || isDeletingCalendar || isRebuildingCalendar}
      >
        <SummaryList sections={getSections()} />

        {role !== LEGAL_ROLE_VIEW && (
          <DeleteCalendar onClick={onDeleteCalendar} calendarName={calendarName} />
        )}
      </ModalContent>
    </>
  );
}
