import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import { ELECT_SPO_COPY } from 'constants.js';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  title: {
    whiteSpace: 'nowrap',
  },
}));

function ElectSPO(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const electSPO = props.electSPO;

  function onChangeElectSPO(event) {
    if (props.onChangeElectSPO) {
      props.onChangeElectSPO(event.target.value);
    }
  }

  return (
    <FormControl fullWidth className={classes.formControl} variant="outlined">
      <InputLabel className={classes.title} htmlFor="electSPO">
        {t('labelSecondaryElectSPOQuestion')}
      </InputLabel>
      <Select
        value={electSPO}
        onChange={onChangeElectSPO}
        labelWidth={140}
        disabled={props.disabled}
      >
        {/* <MenuItem value="">{t('labelSecondaryElectSPOQuestion')}</MenuItem> */}
        {ELECT_SPO_COPY.map((electSPO, index) => (
          <MenuItem key={index} value={index}>
            {electSPO}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default ElectSPO;
