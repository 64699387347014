import { MODAL_EXTEND_CALENDAR } from 'constants.js';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { closeModal, openModal } from 'reducers/modals';
import AlertBox from 'shared/AlertBox/AlertBox';
import Button from 'shared/Button/Button';
import Typography from 'shared/Typography/Typography';
import * as calendarUtil from 'utils/calendar';
import firebase from 'utils/firebase';

import ModalContent from '../ModalContent/ModalContent';
import useStyles from './AlertExtendCalendar.css.jsx';

export default function AlertExtendCalendar() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const classes = useStyles();
  const calendar = useSelector(state =>
    pathname.includes('calendars') ? state.calendar : state.sharedCalendars,
  );
  const currentCalendar = calendarUtil.getCurrentCalendar(
    calendar.list.calendars,
    calendar.currentCalendarId,
  );
  const lastPopulatedDate = moment(currentCalendar.lastPopulatedDate).format('MMM DD, YYYY');

  useEffect(() => {
    firebase.analytics().logEvent('modal_view_extend_calendar_warning');
  }, []);

  function onCloseModal() {
    firebase.analytics().logEvent('modal_cancel_extend_calendar_warning');
    dispatch(closeModal());
  }

  function onExtend() {
    firebase.analytics().logEvent('modal_action_extend_calendar_warning_continue');
    dispatch(openModal({ type: MODAL_EXTEND_CALENDAR, disableClose: false }));
  }

  function getActions() {
    return (
      <>
        <Button onClick={onCloseModal} color="primary">
          {t('actionCancel')}
        </Button>
        <Button color="primary" onClick={onExtend}>
          {t('actionContinue')}
        </Button>
      </>
    );
  }

  return (
    <ModalContent title={t('alertExtendCalendarTitle')} actions={getActions()}>
      <AlertBox
        variant="warning"
        description={t('alertExtendCalendarWarning', { lastPopulatedDate })}
      />
      <div className={classes.textContent}>
        <Typography variant="subtitle2">{t('alertExtendCalendarSubTitle')}</Typography>
      </div>
      <AlertBox variant="danger" description={t('alertExtendCalendarDanger')} />
    </ModalContent>
  );
}
