import {
  ELECT_SPO_ALTERNATING_WEEK,
  ELECT_SPO_ALTERNATING_WEEKENDS,
  ELECT_SPO_COPY,
  ELECT_SPO_DAYS_ROTATION,
  ELECT_SPO_TEXAS,
  SCHOOL_BREAK_CHRISTMAS,
  SCHOOL_BREAK_CHRISTMAS_NEW_YEAR,
  SCHOOL_BREAK_NEWYEAR,
} from 'constants';
import moment from 'moment';
import { saveIncompleteCalendar } from 'reducers/calendar';
import { createCalendar } from 'reducers/createCalendar';
import {
  deleteIncompleteCalFromState,
  deleteIncompleteCalendar,
} from 'reducers/incompleteCalendar';
import { closeModal } from 'reducers/modals';
import firebase from 'utils/firebase';

export const formatLong = 'YYYY-MM-DD';

export function handleNext({ setActiveStep }) {
  firebase.analytics().logEvent('modal_action_create_calendar_next');
  setActiveStep(prevActiveStep => prevActiveStep + 1);
}

export function handleBack({ setActiveStep }) {
  firebase.analytics().logEvent('modal_action_create_calendar_back');
  setActiveStep(prevActiveStep => prevActiveStep - 1);
}

export function onHandleCancel({ dispatch }) {
  firebase.analytics().logEvent('modal_cancel_create_calendar');
  dispatch(closeModal());
}

export function onHandleIncompleteSave({
  calendarName,
  withinDistance,
  defaultWeekends,
  electSPO,
  allWeekends,
  schoolBreaks,
  alternateWeekendsPossession,
  customPossessionDays,
  children,
  dispatch,
}) {
  const data = {
    calendarName,
  };

  if (withinDistance !== '') data.withinDistance = withinDistance === 0 ? true : false;

  if (defaultWeekends !== '') data.defaultWeekends = defaultWeekends === 0 ? true : false;

  if (electSPO && Number(electSPO) >= 0) data.electedSecondary = ELECT_SPO_COPY[electSPO];

  if (electSPO && Number(electSPO) >= 0 && ELECT_SPO_TEXAS.includes(ELECT_SPO_COPY[electSPO])) {
    data.allWeekends = allWeekends.reduce(
      (obj, item, index) => ({
        ...obj,
        [index]: item,
      }),
      {},
    );
    data.schoolBreaks = schoolBreaks;
  }
  if (defaultWeekends !== '' && defaultWeekends === 1) {
    data.alternateWeekendsPossession = alternateWeekendsPossession;
  }
  if (
    electSPO &&
    ELECT_SPO_COPY[electSPO] === 'CUSTOMIZE' &&
    Object.keys(customPossessionDays).length > 0
  ) {
    data.customPossessionDays = customPossessionDays;
  }
  if (children[0].name && children[0].dateOfBirth) {
    data.childrenBirthdays = children.reduce(
      (acc, curr) => {
        acc['b-days'].push({
          'b-day': moment(curr.dateOfBirth).format('YYYY-MM-DD'),
          name: curr.name,
        });
        return acc;
      },
      { 'b-days': [] },
    );
  }

  dispatch(saveIncompleteCalendar(data));
  firebase.analytics().logEvent('modal_action_create_incomplete_calendar', data);
}

export function onChangeDefaultDates({
  shouldUseDefaultDates,
  setShouldUseDefaultDates,
  schoolBreaks,
  templateSchoolBreaks,
  setSchoolBreaks,
}) {
  const useDefault = !shouldUseDefaultDates;
  setShouldUseDefaultDates(useDefault);
  if (useDefault) {
    schoolBreaks['thanksgiving'].start = moment(templateSchoolBreaks.thanksgiving.start).format(formatLong);
    schoolBreaks['thanksgiving'].end = moment(templateSchoolBreaks.thanksgiving.end).format(formatLong);

    schoolBreaks['christmas_newyear'].start = moment(templateSchoolBreaks.christmas_newyear.start).format(formatLong);
    schoolBreaks['christmas_newyear'].end = moment(templateSchoolBreaks.christmas_newyear.end).format(formatLong);

    schoolBreaks['christmas'].start = moment(templateSchoolBreaks.christmas.start).format(formatLong);
    schoolBreaks['christmas'].end = moment(templateSchoolBreaks.christmas.end).format(formatLong);

    schoolBreaks['newyear'].start = moment(templateSchoolBreaks.newyear.start).format(formatLong);
    schoolBreaks['newyear'].end = moment(templateSchoolBreaks.newyear.end).format(formatLong);

    schoolBreaks['spring'].start = moment(templateSchoolBreaks.spring.start).format(formatLong);
    schoolBreaks['spring'].end = moment(templateSchoolBreaks.spring.end).format(formatLong);

    schoolBreaks['summer'].start = moment(templateSchoolBreaks.summer.start).format(formatLong);
    schoolBreaks['summer'].end = moment(templateSchoolBreaks.summer.end).format(formatLong);
  } else {
    schoolBreaks['thanksgiving'].start = null;
    schoolBreaks['thanksgiving'].end = null;

    schoolBreaks['christmas_newyear'].start = null;
    schoolBreaks['christmas_newyear'].end = null;

    schoolBreaks['christmas'].start = null;
    schoolBreaks['christmas'].end = null;

    schoolBreaks['newyear'].start = null;
    schoolBreaks['newyear'].end = null;

    schoolBreaks['spring'].start = null;
    schoolBreaks['spring'].end = null;

    schoolBreaks['summer'].start = null;
    schoolBreaks['summer'].end = null;
  }
  setSchoolBreaks({ ...schoolBreaks });
}

export const onChangeChildName =
  ({ index, children, setChildren }) =>
  name => {
    children[index].name = name;
    setChildren([...children]);
  };

export const onChangeChildDateOfBirth = (index, children, setChildren) => date => {
  children[index].dateOfBirth = date;
  setChildren([...children]);
};

export function onChangeAlternateWeekendsPossession({
  monthIndex,
  weekIndex,
  getMonthName,
  alternateWeekendsPossession,
  setAlternateWeekendsPossession,
  weekendIndices,
  setWeekendIndices,
  getAllValidWeekendsOfTheMonth,
  schoolBreaks,
  setSecondAugustAlternateWeekendsPossession,
  setSecondAugustWeekendIndex,
}) {
  let weekends, weekend;
  let month = getMonthName(Number(moment().format('MM')) + Number(monthIndex) - 1);
  //console.log(monthIndex, month, weekIndex);
  if (weekIndex === '') {
    alternateWeekendsPossession[month].start = null;
    alternateWeekendsPossession[month].end = null;
    setAlternateWeekendsPossession({ ...alternateWeekendsPossession });
    weekendIndices[month].index = '';
    setWeekendIndices({ ...weekendIndices });
  } else {
    weekends = getAllValidWeekendsOfTheMonth(
      monthIndex - 1,
      schoolBreaks,
      moment(),
      schoolBreaks.summer.end,
    );
    weekend = weekends[weekIndex];
    if (monthIndex === 12) {
      setSecondAugustAlternateWeekendsPossession({
        start: moment(weekend.day1).clone().format('YYYY-MM-DD'),
        end: moment(weekend.day3).clone().format('YYYY-MM-DD'),
      });
      setSecondAugustWeekendIndex({ index: weekIndex });
    } else {
      if (weekend) {
        month = moment(weekend.day1).clone().format('MMMM');
        //console.log(month, weekend.day1.format("YYYY-MM-DD"), weekend.day3.format("YYYY-MM-DD"));
        alternateWeekendsPossession[month].start = moment(weekend.day1)
          .clone()
          .format('YYYY-MM-DD');
        alternateWeekendsPossession[month].end = moment(weekend.day3).clone().format('YYYY-MM-DD');
        setAlternateWeekendsPossession({ ...alternateWeekendsPossession });
        weekendIndices[month].index = weekIndex;
        setWeekendIndices({ ...weekendIndices });
      }
    }
  }
}

export const addChild = ({ children, setChildren }) => {
  children.push({ name: '', dateOfBirth: null });
  setChildren([...children]);
  firebase.analytics().logEvent('modal_action_create_calendar_add_child');
};

export const onDeleteChild =
  ({ index, children, setChildren }) =>
  () => {
    children.splice(index, 1);
    setChildren([...children]);
    firebase.analytics().logEvent('modal_action_create_calendar_delete_child');
  };

export const onChangeSchoolbreakStartDate =
  ({ name, schoolBreaks, setSchoolBreaks }) =>
  date => {
    if (name === SCHOOL_BREAK_CHRISTMAS_NEW_YEAR) {
      schoolBreaks[SCHOOL_BREAK_CHRISTMAS].start = date.format(formatLong);
      schoolBreaks[SCHOOL_BREAK_CHRISTMAS].end = date.clone().date(28).format(formatLong);
      schoolBreaks[SCHOOL_BREAK_NEWYEAR].start = date.clone().date(29).format(formatLong);
    }
    schoolBreaks[name].start = date.format(formatLong);
    schoolBreaks[name].end = null;
    setSchoolBreaks({ ...schoolBreaks });
  };

export const onChangeSchoolbreakEndDate =
  ({ name, schoolBreaks, setSchoolBreaks }) =>
  date => {
    if (name === SCHOOL_BREAK_CHRISTMAS_NEW_YEAR) {
      schoolBreaks[SCHOOL_BREAK_NEWYEAR].end = date.format(formatLong);
    }
    if (name === SCHOOL_BREAK_CHRISTMAS) {
      // New start date should always be one day after christmas end date.
      schoolBreaks[SCHOOL_BREAK_NEWYEAR].start = date.clone().add(1, 'days').format(formatLong);
    }
    schoolBreaks[name].end = date.format(formatLong);
    setSchoolBreaks({ ...schoolBreaks });
  };

export function onChangeElectSPO({ value, setElectSPO }) {
  setElectSPO(value);
}

export function onChangeWithinDistance({ value, setWithinDistance }) {
  setWithinDistance(value);
}

export function onChangeDefaultWeekends({ value, setDefaultWeekends }) {
  setDefaultWeekends(value);
}

export function onChangeCalendarName({ value, setCalendarName }) {
  setCalendarName(value);
}

export function onChangeTermsAndConditions({ setTermsAndConditions, termsAndConditions }) {
  setTermsAndConditions(!termsAndConditions);
}

export function onGenerateCalendar({
  electSPO,
  schoolBreaks,
  calendarName,
  withinDistance,
  defaultWeekends,
  children,
  rotationRuleParentStartingDay,
  customPossessionDays,
  alternateTheFirstWeekend,
  dispatch,
  incompleteCalendar,
  parent1MotherFatherDay,
  calendarStartingDate,
  alternateWeekendsPossession,
}) {
  const data = ELECT_SPO_TEXAS.includes(ELECT_SPO_COPY[electSPO])
    ? {
        zip: '99999', //legalZipCode,
        schoolBreaks: schoolBreaks,
        calendarName,
        withinDistance: withinDistance === 0 ? true : false,
        defaultWeekends: defaultWeekends === '' ? null : defaultWeekends === 0 ? true : false,
        electedSecondary: ELECT_SPO_COPY[electSPO],
        alternateWeekendsPossession,
        childrenBirthdays: children.reduce(
          (acc, curr) => {
            acc['b-days'].push({
              'b-day': moment(curr.dateOfBirth).format('YYYY-MM-DD'),
              name: curr.name,
            });
            return acc;
          },
          { 'b-days': [] },
        ),
        parent1: parent1MotherFatherDay,
      }
    : ELECT_SPO_DAYS_ROTATION.includes(ELECT_SPO_COPY[electSPO])
    ? {
        electedSecondary: ELECT_SPO_COPY[electSPO],
        childrenBirthdays: children.reduce(
          (acc, curr) => {
            acc['b-days'].push({
              'b-day': moment(curr.dateOfBirth).format('YYYY-MM-DD'),
              name: curr.name,
            });
            return acc;
          },
          { 'b-days': [] },
        ),
        calendarName,
        rotationFirstParen: rotationRuleParentStartingDay,
        calendarStartingDate,
      }
    : {
        zip: '99999', //legalZipCode,
        schoolBreaks: null,
        allWeekends: null,
        calendarName,
        calendarStartingDate,
        customPossessionDays,
        withinDistance: null,
        defaultWeekends: null,
        electedSecondary: ELECT_SPO_COPY[electSPO],
        alternateWeekendFirstOwner:
          ELECT_SPO_COPY[electSPO] === ELECT_SPO_ALTERNATING_WEEKENDS
            ? alternateTheFirstWeekend
            : null,
        alternateWeekFirstOwner:
          ELECT_SPO_COPY[electSPO] === ELECT_SPO_ALTERNATING_WEEK ? alternateTheFirstWeekend : null,
        childrenBirthdays: children.reduce(
          (acc, curr) => {
            acc['b-days'].push({
              'b-day': moment(curr.dateOfBirth).format('YYYY-MM-DD'),
              name: curr.name,
            });
            return acc;
          },
          { 'b-days': [] },
        ),
      };
  dispatch(createCalendar(JSON.stringify(data)));

  if (incompleteCalendar) {
    dispatch(deleteIncompleteCalFromState());
    dispatch(deleteIncompleteCalendar(incompleteCalendar.calendarId));
  }

  firebase.analytics().logEvent('modal_action_create_calendar', data);
}

export function getChristmasNewYearTitle({
  getChristmasYear,
  getNewyearYear,
  templateSchoolBreaks,
  t,
}) {
  const christmasYear = getChristmasYear(templateSchoolBreaks);
  const newyearYear = getNewyearYear(templateSchoolBreaks);
  const title = t('schoolBreak_christmas_newyear', {
    christmasYear,
    newyearYear,
  });
  return title;
}
