import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from 'shared/Typography/Typography';

import useStyle from './AlertBox.css.jsx';

function AlertBox(props) {
  // const { ...rest } = props;
  const classes = useStyle();
  const variant = props.variant;

  return (
    <div
      className={classNames({
        [classes.card]: true,
        [classes.danger]: variant === 'danger',
        [classes.warning]: variant === 'warning',
        [classes.info]: variant === 'info',
      })}
    >
      {props.title && <Typography variant="subtitle2">{props.title} </Typography>}
      {props.description && <Typography variant="body2">{props.description} </Typography>}
    </div>
  );
}

AlertBox.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string,
  variant: PropTypes.oneOf(['danger', 'warning', 'info']),
};

export default AlertBox;
