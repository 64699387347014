import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './EditChildInfo.module.css';

function EditChildInfo(props) {
  const { t } = useTranslation();

  function onChangeName(event) {
    props.onChangeName(event.target.value, props.entryIndex);
  }

  function onChangeChildDateOfBirth(date) {
    props.onChangeDate(moment(date).format('YYYY-MM-DD'), props.entryIndex);
  }

  return (
    <div className={styles.editChildBox}>
      <div className={styles.editChildInputBox}>
        <input
          disabled={props.disabled}
          type="text"
          className={styles.itemInput}
          value={props.childName}
          onChange={onChangeName}
          maxLength={props.maxLength}
        />
      </div>

      <div className={styles.editChildInputBox}>
        <DatePicker
          disabled={props.disabled}
          autoOk
          fullWidth
          inputVariant="outlined"
          disableFuture
          openTo="year"
          margin="normal"
          format="MMM DD, YYYY"
          maxDate={moment()}
          minDate={moment().subtract(20, 'year')}
          label={t('labelAddChildDateOfBirth')}
          placeholder={t('placeholderAddChildDateOfBirth')}
          views={['year', 'month', 'date']}
          value={props.date}
          onChange={onChangeChildDateOfBirth}
        />
      </div>
    </div>
  );
}
export default EditChildInfo;
