import { MODAL_SUCCESS_MODIFY_SUMMER_SESSIONS } from 'constants.js';
import { getCalendars as fetchCalendars } from 'reducers/calendar';
import { openModal } from 'reducers/modals';
import { addSnackbar } from 'reducers/snackbar';

export const UPDATE_SUMMER_POSESSION_START = 'summerPossession/UPDATE_SUMMER_POSESSION_START';
export const UPDATE_SUMMER_POSESSION_DONE = 'summerPossession/UPDATE_SUMMER_POSESSION_DONE';
export const UPDATE_SUMMER_POSESSION_ERROR = 'summerPossession/UPDATE_SUMMER_POSESSION_ERROR';

export const GET_SUMMER_POSESSION_DRYRUN_START =
  'summerPossession/GET_SUMMER_POSESSION_DRYRUN_START';
export const GET_SUMMER_POSESSION_DRYRUN_DONE = 'summerPossession/GET_SUMMER_POSESSION_DRYRUN_DONE';
export const GET_SUMMER_POSESSION_DRYRUN_ERROR =
  'summerPossession/GET_SUMMER_POSESSION_DRYRUN_ERROR';

const initialState = {
  update: {
    loading: false,
    error: undefined,
  },
  dryRun: {
    loading: false,
    dates: null,
    error: undefined,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SUMMER_POSESSION_DRYRUN_START: {
      return {
        ...state,
        dryRun: {
          ...state.dryRun,
          dates: null,
          loading: true,
          error: undefined,
        },
      };
    }
    case GET_SUMMER_POSESSION_DRYRUN_DONE: {
      return {
        ...state,
        dryRun: {
          ...state.dryRun,
          dates: { ...action.dates },
          loading: false,
          error: undefined,
        },
      };
    }
    case GET_SUMMER_POSESSION_DRYRUN_ERROR: {
      return {
        ...state,
        dryRun: {
          ...state.dryRun,
          dates: null,
          loading: false,
          error: action.error,
        },
      };
    }

    case UPDATE_SUMMER_POSESSION_START: {
      return {
        ...state,
        update: {
          ...state.update,
          loading: true,
          error: undefined,
        },
      };
    }
    case UPDATE_SUMMER_POSESSION_DONE: {
      return {
        ...state,
        update: {
          ...state.update,
          loading: false,
          error: undefined,
        },
      };
    }
    case UPDATE_SUMMER_POSESSION_ERROR: {
      return {
        ...state,
        update: {
          ...state.update,
          loading: false,
          error: action.error,
        },
      };
    }
    default: {
      return { ...state };
    }
  }
};

export const updateSummerPossession =
  data =>
  async (dispatch, getState, { firebase }) => {
    dispatch({ type: UPDATE_SUMMER_POSESSION_START });
    const { calendar } = getState();
    const { currentCalendarId } = calendar;
    const { schoolYear, primary, secondary } = data;
    // console.log(primary);
    const modifySummerPossession = firebase.functions().httpsCallable('modifySummerPossessionV2');
    try {
      const payload = {
        calendarId: currentCalendarId,
        schoolYear,
        primary,
        secondary,
      };
      await modifySummerPossession(payload);
      dispatch({ type: UPDATE_SUMMER_POSESSION_DONE });
      dispatch(
        openModal({
          type: MODAL_SUCCESS_MODIFY_SUMMER_SESSIONS,
          disableClose: true,
        }),
      );
      dispatch(fetchCalendars({}));
    } catch (error) {
      console.error(JSON.stringify(error));
      dispatch({ type: UPDATE_SUMMER_POSESSION_ERROR, error });
      dispatch(
        addSnackbar({
          text: `${error.message}`,
          variant: 'error',
          closeButton: true,
          //retryButton: () => dispatch(updateSummerPossession(data))
        }),
      );
    }
  };

export const getSummerPossessionDryRun =
  data =>
  async (dispatch, getState, { firebase }) => {
    dispatch({ type: GET_SUMMER_POSESSION_DRYRUN_START });
    const { calendar } = getState();
    const { currentCalendarId } = calendar;
    const { schoolYear, primary, secondary } = data;
    const modifySummerPossession = firebase.functions().httpsCallable('modifySummerPossessionV2');

    try {
      const response = await modifySummerPossession({
        calendarId: currentCalendarId,
        schoolYear,
        primary,
        secondary,
        isDryRun: true,
      });

      const dates = Object.values(response.data);
      const modifiedDates = {};
      for (let i = 0; i < dates.length; i++) {
        for (let [, values] of Object.entries(dates[i])) {
          const date = values.date;
          modifiedDates[date] = values;
        }
      }
      dispatch({ type: GET_SUMMER_POSESSION_DRYRUN_DONE, dates: modifiedDates });
    } catch (error) {
      console.error(JSON.stringify(error));
      dispatch({ type: GET_SUMMER_POSESSION_DRYRUN_ERROR, error });
      dispatch(
        addSnackbar({
          text: `${error.message}`,
          variant: 'error',
          closeButton: true,
        }),
      );
    }
  };
