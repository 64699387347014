import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Button from 'shared/Button/Button';
import Icon from 'shared/Icons/Icons';

import styles from './Snackbar.module.css';

function Snackbar(props) {
  const { children } = props;
  const data = useSelector(state => state.snackbar.data);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    const onClose = key => () => {
      closeSnackbar(key);
    };

    const actions = key => (
      <>
        {data.closeButton && (
          <Button
            className={styles.closeButton}
            color="inherit"
            size="medium"
            onClick={onClose(key)}
          >
            <Icon icon="close" />
          </Button>
        )}
      </>
    );

    if (!data) {
      return;
    }
    enqueueSnackbar(data.text, {
      variant: data.variant,
      persist: data.closeButton,
      autoHideDuration: data.duration ? data.duration : 3000,
      anchorOrigin: {
        horizontal: data.horizontal ? data.horizontal : 'left',
        vertical: data.vertical ? data.vertical : 'bottom',
      },
      content: Array.isArray(data.text)
        ? (key, message) => {
            return (
              <div className={styles.customSnackBar}>
                <ul className={styles.list}>
                  {data.title && <h3>{data.title}</h3>}
                  {message.map(e => (
                    <li key={e}>{e}</li>
                  ))}
                </ul>
                <div>
                  <Button
                    className={styles.closeButton}
                    color="inherit"
                    size="medium"
                    onClick={onClose(key)}
                  >
                    <Icon icon="close" />
                  </Button>
                </div>
              </div>
            );
          }
        : null,
      action: actions,
    });
  }, [data, enqueueSnackbar, closeSnackbar]);

  return children;
}

export default Snackbar;
