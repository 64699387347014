import MaterialTypography from '@material-ui/core/Typography';
import React from 'react';

import useStyle from './Typography.css.jsx';

function Typography(props) {
  const { children, ...rest } = props;
  const classes = useStyle();
  return (
    <MaterialTypography
      classes={{
        root: classes.root,
        h1: classes.h1,
        h2: classes.h2,
        h3: classes.h3,
        h4: classes.h4,
        h5: classes.h5,
        h6: classes.h6,
        subtitle2: classes.subtitle2,
      }}
      {...rest}
    >
      {children}
    </MaterialTypography>
  );
}

export default Typography;
