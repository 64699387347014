import * as React from 'react';

function CalendarCircleIcon({ ...props }) {
  return (
    <svg
      width="22"
      height="21"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.28571 2.42857V3.85714H4.14286C2.95982 3.85714 2 4.81696 2 6V8.14286H22V6C22 4.81696 21.0402 3.85714 19.8571 3.85714H17.7143V2.42857C17.7143 1.63839 17.0759 1 16.2857 1C15.4955 1 14.8571 1.63839 14.8571 2.42857V3.85714H9.14286V2.42857C9.14286 1.63839 8.50446 1 7.71429 1C6.92411 1 6.28571 1.63839 6.28571 2.42857ZM22 9.57143H2V21.7143C2 22.8973 2.95982 23.8571 4.14286 23.8571H19.8571C21.0402 23.8571 22 22.8973 22 21.7143V9.57143Z"
        fill="currentColor"
      />
      <path
        d="M15.3516 20.0047C14.8336 19.1047 13.8609 18.5 12.75 18.5H11.25C10.1391 18.5 9.16641 19.1047 8.64844 20.0047C9.47344 20.9234 10.6688 21.5 12 21.5C13.3313 21.5 14.5266 20.9211 15.3516 20.0047ZM6 17C6 15.4087 6.63214 13.8826 7.75736 12.7574C8.88258 11.6321 10.4087 11 12 11C13.5913 11 15.1174 11.6321 16.2426 12.7574C17.3679 13.8826 18 15.4087 18 17C18 18.5913 17.3679 20.1174 16.2426 21.2426C15.1174 22.3679 13.5913 23 12 23C10.4087 23 8.88258 22.3679 7.75736 21.2426C6.63214 20.1174 6 18.5913 6 17ZM12 17.375C12.4476 17.375 12.8768 17.1972 13.1932 16.8807C13.5097 16.5643 13.6875 16.1351 13.6875 15.6875C13.6875 15.2399 13.5097 14.8107 13.1932 14.4943C12.8768 14.1778 12.4476 14 12 14C11.5524 14 11.1232 14.1778 10.8068 14.4943C10.4903 14.8107 10.3125 15.2399 10.3125 15.6875C10.3125 16.1351 10.4903 16.5643 10.8068 16.8807C11.1232 17.1972 11.5524 17.375 12 17.375Z"
        fill="#232933"
      />
    </svg>
  );
}

export default CalendarCircleIcon;
