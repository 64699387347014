import { red } from '@material-ui/core/colors';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { closeModal } from 'reducers/modals';
import Button from 'shared/Button/Button';
import Icon from 'shared/Icons/Icons';
import firebase from 'utils/firebase';

import ModalContent from '../ModalContent/ModalContent';

function PaymentExpiredOwnerCalendar() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    firebase.analytics().logEvent('modal_show_paywall');
  }, []);

  function onCloseModal() {
    firebase.analytics().logEvent('modal_cancel_paywall');
    dispatch(closeModal());
  }

  return (
    <ModalContent
      title={t('modalPaymentOfOwnerCalendarExpiredTitle')}
      subtitle=""
      icon={<Icon icon="stop" size="xl" color={red[500]} />}
      actions={
        <>
          <Button color="primary" onClick={onCloseModal}>
            {t('actionOk')}
          </Button>
        </>
      }
    ></ModalContent>
  );
}

export default PaymentExpiredOwnerCalendar;
