import { combineReducers } from 'redux';

import admin from './admin';
import allWeekends from './allWeekends';
import auth from './auth';
import calendar from './calendar';
import calendarInfo from './calendarInfo';
import configurations from './configurations';
import createCalendar from './createCalendar';
import editBirthday from './editBirthday';
import editCalendarDate from './editCalendarDate';
import editSchoolBreakCustodian from './editSchoolBreakCustodian';
import editSchoolBreakDate from './editSchoolBreakDate';
import editWeekend from './editWeekend';
import extendCalendar from './extendCalendar';
import incompleteCalendar from './incompleteCalendar';
import modals from './modals';
import payment from './payment';
import schoolBreaks from './schoolBreaks';
import sharedCalendars from './sharedCalendars';
import snackbar from './snackbar';
import summerPossession from './summerPossession';
import user from './user';

const reducer = combineReducers({
  auth,
  calendar,
  calendarInfo,
  configurations,
  createCalendar,
  editBirthday,
  editCalendarDate,
  editSchoolBreakDate,
  editWeekend,
  extendCalendar,
  modals,
  payment,
  schoolBreaks,
  allWeekends,
  snackbar,
  summerPossession,
  user,
  editSchoolBreakCustodian,
  incompleteCalendar,
  admin,
  sharedCalendars,
});

export default reducer;
