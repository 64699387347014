import withStyles from '@material-ui/core/styles/withStyles';
import profilePageStyle from 'assets/jss/material-kit-pro-react/views/profilePageStyle.jsx';
import classNames from 'classnames';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import NavPills from 'components/NavPills/NavPills.jsx';
import Parallax from 'components/Parallax/Parallax.jsx';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from 'reducers/user';
import Icon from 'shared/Icons/Icons';
import Loader from 'shared/Loader/Loader.jsx';

import style from './Account.module.css';
import Info from './Info.jsx';
import Payment from './Payment.jsx';
import Settings from './Settings.jsx';

function ProfilePage(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const authUser = useSelector(state => state.auth.user);
  const loadingUser = useSelector(state => state.user.loading);
  const { classes } = props;

  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  if (loadingUser) {
    return <Loader />;
  }
  return (
    <div>
      <Parallax className={classNames(classes.parallax, style.coverBackground)} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <div className={style.container}>
            <GridContainer justifyContent="center">
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.profile}>
                  <div>
                    <div className={style.avatarContainer}>
                      {authUser.photoURL && (
                        <img src={authUser.photoURL} alt="..." className={style.profileImage} />
                      )}
                      {!authUser.photoURL && (
                        <div className={style.avatar}>
                          <Icon icon="user" />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </GridItem>
            </GridContainer>

            <NavPills
              color="primary"
              alignCenter={true}
              tabs={[
                {
                  tabButton: t('accountPageInfoTab'),
                  tabContent: <Info></Info>,
                },
                {
                  tabButton: t('accountPagePaymentTab'),
                  tabContent: <Payment></Payment>,
                },
                {
                  tabButton: t('accountPageSettingsTab'),
                  tabContent: <Settings></Settings>,
                },
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

ProfilePage.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(profilePageStyle)(ProfilePage);
