import { getCalendars } from 'reducers/calendar';
import { closeModal } from 'reducers/modals';
import { addSnackbar } from 'reducers/snackbar';

export const EDIT_SCHOOL_BREAK_CUSTODIAN_START =
  'editSchoolBreakCustodian/EDIT_SCHOOL_BREAK_CUSTODIAN_START';
export const EDIT_SCHOOL_BREAK_CUSTODIAN_DONE =
  'editSchoolBreakCustodian/EDIT_SCHOOL_BREAK_CUSTODIAN_DONE';
export const EDIT_SCHOOL_BREAK_CUSTODIAN_ERROR =
  'editSchoolBreakCustodian/EDIT_SCHOOL_BREAK_CUSTODIAN_ERROR';

const initialState = {
  loading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case EDIT_SCHOOL_BREAK_CUSTODIAN_START: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case EDIT_SCHOOL_BREAK_CUSTODIAN_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case EDIT_SCHOOL_BREAK_CUSTODIAN_DONE: {
      return {
        ...state,
        loading: false,
      };
    }
    default: {
      return { ...state };
    }
  }
};

export const modifySchoolBreaksCustodian =
  data =>
  async (dispatch, getState, { firebase }) => {
    dispatch({ type: EDIT_SCHOOL_BREAK_CUSTODIAN_START });
    const modifyCal = firebase.functions().httpsCallable('modifyCalendarDatesV2');
    const payload = {
      calendarId: data.calendarId,
      startDateToModify: data.start.format('YYYY-MM-DD'),
      endDateToModify: data.end.format('YYYY-MM-DD'),
      newOwner: data.newOwner,
    };
    try {
      await modifyCal(payload);
      dispatch({ type: EDIT_SCHOOL_BREAK_CUSTODIAN_DONE });
      dispatch(getCalendars({}));
      dispatch(closeModal());
    } catch (error) {
      dispatch({ type: EDIT_SCHOOL_BREAK_CUSTODIAN_ERROR });
      dispatch(
        addSnackbar({
          text: `${error.message}`,
          variant: 'error',
        }),
      );
    }
  };
