import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'column',
    backgroundColor: '#fff',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    gap: '24px',
  },
  imageBox: {},
  titleBox: {
    fontWeight: 600,
    fontSize: '24px',
    color: '#212121',
    lineHeight: '32px',
    textAlign: 'center',
  },
  subtitleBox: {
    fontWeight: 400,
    fontSize: '16px',
    color: '#212121',
    lineHeight: '22px',
    textAlign: 'center',
  },
  button: {
    width: '224px',
  },
}));
