import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    marginTop: '1rem',
  },
  formTitle: {
    fontSize: '1rem',
  },
}));
