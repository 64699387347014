import { getCalendars } from 'reducers/calendar';
import { closeModal } from 'reducers/modals';
import { addSnackbar } from 'reducers/snackbar';

export const CURRENT_WEEKEND = 'editWeekend/CURRENT_WEEKEND';
export const MODIFY_WEEKEND_START = 'editWeekend/MODIFY_WEEKEND_START';
export const MODIFY_WEEKEND_DONE = 'editWeekend/MODIFY_WEEKEND_DONE';
export const MODIFY_WEEKEND_ERROR = 'editWeekend/MODIFY_WEEKEND_ERROR';

const initialState = {
  calendarId: '',
  schoolYear: '',
  name: '',
  defaultWeekends: false,
  start: '',
  end: '',
  loading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CURRENT_WEEKEND: {
      //console.log(action.data.defaultWeekends);
      //console.log(action.data.start);
      return {
        ...state,
        calendarId: action.data.calendarId,
        schoolYear: action.data.schoolYear,
        name: action.data.name,
        defaultWeekends: action.data.defaultWeekends,
        start: action.data.start,
        end: action.data.end,
        loading: false,
        error: null,
      };
    }
    case MODIFY_WEEKEND_START: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case MODIFY_WEEKEND_DONE: {
      return {
        ...state,
        loading: false,
        error: null,
      };
    }
    case MODIFY_WEEKEND_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    default: {
      return { ...state };
    }
  }
};

export const currentAllWeekends = data => ({
  type: CURRENT_WEEKEND,
  data,
});

export const modifyAllWeekends =
  data =>
  async (dispatch, getState, { firebase }) => {
    //console.log(data.start);
    //console.log(data.calendarId);
    //console.log(data.month);
    dispatch({ type: MODIFY_WEEKEND_START });
    const modifyAllWeekends = firebase.functions().httpsCallable('modifyAllWeekendsV2');
    const payload = {
      calendarId: data.calendarId,
      schoolYear: data.schoolYear,
      start: data.start,
      newStart: data.newStart,
    };

    try {
      await modifyAllWeekends(payload);
      dispatch({ type: MODIFY_WEEKEND_DONE });
      dispatch(getCalendars({}));
      dispatch(closeModal());
    } catch (error) {
      dispatch({ type: MODIFY_WEEKEND_ERROR, error });
      dispatch(
        addSnackbar({
          text: `${error.message}`,
          variant: 'error',
        }),
      );
    }
  };
