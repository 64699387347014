import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getAllWeekendsTemplates } from 'reducers/allWeekends';
import { modifyAllWeekends } from 'reducers/editWeekend';
import { closeModal } from 'reducers/modals';
import { getSchoolBreakTemplates } from 'reducers/schoolBreaks';
import Button from 'shared/Button/Button';
import WarningBox from 'shared/WarningBox/WarningBox';
import { getCurrentCalendar } from 'utils/calendar';
import { getCurrentSchoolYear } from 'utils/calendar';
import firebase from 'utils/firebase';
import { getAllValidWeekendsOfTheMonth, isValidAlternateWeekendsPossession } from 'utils/validator';

import ModalContent from '../ModalContent/ModalContent';
import useStyles from './EditWeekend.css.jsx';

export default function EditWeekend() {
  const dispatch = useDispatch();
  const calendars = useSelector(state => state.calendar.list.calendars);
  const calendarId = useSelector(state => state.editWeekend.calendarId);
  const currentCalendar = getCurrentCalendar(calendars, calendarId);
  const defaultWeekends = useSelector(state => state.editWeekend.defaultWeekends);
  const start = useSelector(state => state.editWeekend.start);
  const title = moment(start).format('MM-YYYY');
  const schoolBreaks = currentCalendar.schoolBreaks;
  const firstPopulatedDate = currentCalendar.firstPopulatedDate;
  const lastPopulatedDate = currentCalendar.lastPopulatedDate;
  const schoolYear = getCurrentSchoolYear(moment(start), moment(start), false, true);
  const monthIndex = moment(start)
    .clone()
    .startOf('month')
    .diff(moment(firstPopulatedDate).startOf('month'), 'months');
  const allWeekendsOfTheMonth = getAllValidWeekendsOfTheMonth(
    Number(monthIndex) - 1,
    schoolBreaks,
    firstPopulatedDate,
    lastPopulatedDate,
  );
  const [weekend, setWeekend] = useState('');
  const loading = useSelector(state => state.editWeekend.loading);
  const classes = useStyles();
  //console.log(schoolYear);
  const [allWeekendsPossession, setAllWeekendsPossession] = useState({
    January: { start: null, end: null },
    February: { start: null, end: null },
    March: { start: null, end: null },
    April: { start: null, end: null },
    May: { start: null, end: null },
    June: { start: null, end: null },
    July: { start: null, end: null },
    August: { start: null, end: null },
    September: { start: null, end: null },
    October: { start: null, end: null },
    November: { start: null, end: null },
    December: { start: null, end: null },
  });

  const isDateBeforeToday = () => {
    const now = moment();
    const rv = moment(start).isBefore(now);
    return rv;
  };
  const { t } = useTranslation();

  useEffect(() => {
    firebase.analytics().logEvent('modal_show_edit_weekend', { monthIndex, schoolYear });
  }, [monthIndex, schoolYear]); //NOTE that in theory whenever one of these changes, the effect will be executed, but in our case we never modify these in this modal

  useEffect(() => {
    dispatch(getAllWeekendsTemplates(schoolYear));
  }, [dispatch, schoolYear]);

  useEffect(() => {
    dispatch(getSchoolBreakTemplates(schoolYear));
  }, [dispatch, schoolYear]);

  function onCloseModal() {
    firebase.analytics().logEvent('modal_cancel_edit_weekend');
    dispatch(closeModal());
  }

  function onConfirm() {
    const schoolYearCombo = schoolYear.substr(2, 2) + schoolYear.substr(7, 2);
    const data = {
      calendarId,
      schoolYear: schoolYearCombo,
      start: start,
      newStart: allWeekendsPossession[moment(start).format('MMMM')].start.format('YYYY-MM-DD'),
    };
    //console.log(data);
    //console.log(moment(lastPopulatedDate).startOf("year").format("YYYY-MM-DD"), moment(start).clone().diff(moment(lastPopulatedDate).startOf("year"), "months"));

    dispatch(modifyAllWeekends(data));
    firebase.analytics().logEvent('modal_action_edit_weekend', data);
  }

  function getTitle() {
    const calendarName = currentCalendar.accessCard.calendarName;
    return `${calendarName}: ${title}`;
  }

  function shouldShowConfirmButton() {
    if (isDateBeforeToday()) {
      return null;
    }
    //console.log(allWeekendsPossession[moment(start).format("MMMM")].start);
    //console.log(isValidAlternateWeekendsPossession(allWeekendsPossession[moment(start).format("MMMM")].start));
    return (
      <Button
        color="primary"
        onClick={onConfirm}
        disabled={
          loading || start
            ? !isValidAlternateWeekendsPossession(
                allWeekendsPossession[moment(start).format('MMMM')].start,
              )[0]
            : false
        }
      >
        {t('actionConfirm')}
      </Button>
    );
  }

  function getActions() {
    return (
      <>
        <Button onClick={onCloseModal} color="primary">
          {t('actionCancel')}
        </Button>
        {shouldShowConfirmButton()}
      </>
    );
  }
  function getActionsForNonEdit() {
    return (
      <>
        <Button onClick={onCloseModal} color="primary">
          {t('actionCancel')}
        </Button>
      </>
    );
  }

  function warningBoxText() {
    if (isDateBeforeToday()) {
      const text = t('modalEditWeekendsPastStartAndEndDates');
      return <WarningBox text={text} />;
    }
  }
  function defaultWeekendsWarningText() {
    const text = t('modalEditWeekendNotAllowedForDefaultWeekends');
    return <WarningBox text={text} />;
  }

  function onChangeAllWeekendsPossession(event) {
    const index = event.target.value;
    setWeekend(index);
    //console.log(monthIndex);
    const weekends = getAllValidWeekendsOfTheMonth(
      Number(monthIndex) - 1,
      schoolBreaks,
      firstPopulatedDate,
      lastPopulatedDate,
    );
    const weekend = weekends[index];
    const month = moment(weekend.day1).clone().format('MMMM');
    allWeekendsPossession[month].start = weekend.day1;
    allWeekendsPossession[month].end = weekend.day3;
    setAllWeekendsPossession({ ...allWeekendsPossession });
  }

  if (defaultWeekends) {
    return (
      <>
        <ModalContent
          title={getTitle()}
          subtitle={t('modalEditWeekendWarningSubTitle')}
          actions={getActionsForNonEdit()}
          loading={loading}
        >
          {defaultWeekendsWarningText()}
        </ModalContent>
      </>
    );
  }

  return (
    <>
      <ModalContent
        title={getTitle()}
        subtitle={t('modalEditWeekendSubTitle')}
        actions={getActions()}
        loading={loading}
      >
        {warningBoxText()}
        <FormControl fullWidth variant="outlined" className={classes.formControl}>
          <InputLabel htmlFor="editWeekend">
            {moment(firstPopulatedDate).add(Number(monthIndex), 'months').format('MMMM')}
          </InputLabel>
          <Select value={weekend} onChange={onChangeAllWeekendsPossession} labelWidth={160}>
            <MenuItem value="">
              {moment(firstPopulatedDate).add(Number(monthIndex), 'months').format('MMMM')}
            </MenuItem>
            {allWeekendsOfTheMonth.map((range, index) => (
              <MenuItem key={index} value={index}>
                {`${range.day1.format('MMM DD, YYYY')} - ${range.day3.format('MMM DD, YYYY')}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </ModalContent>
    </>
  );
}
