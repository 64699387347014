import {
  faArrowLeft,
  faArrowRight,
  faBan,
  faBars,
  faBirthdayCake,
  faCalendarAlt,
  faCalendarDay,
  faCalendarTimes,
  faCalendarXmark,
  faCheck,
  faCheckCircle,
  faChild,
  faCloudSun,
  faDownload,
  faDrumstickBite,
  faEnvelope,
  faExclamationTriangle,
  faFileContract,
  faFileExport,
  faFilePdf,
  faGlassCheers,
  faInfo,
  faInfoCircle,
  faMapMarked,
  faMobile,
  faPeopleArrows,
  faQuestionCircle,
  faRedo,
  faSignOutAlt,
  faSnowman,
  faStreetView,
  faTag,
  faTimes,
  faTimesCircle,
  faTrashAlt,
  faUmbrellaBeach,
  faUser,
  faUserSecret,
  faVoteYea,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

function Icon(props) {
  const { icon, size, color } = props;

  function getIcon(icon) {
    if (icon === 'birthday') {
      return faBirthdayCake;
    }
    if (icon === 'summer') {
      return faUmbrellaBeach;
    }
    if (icon === 'snowman') {
      return faSnowman;
    }
    if (icon === 'info') {
      return faInfoCircle;
    }
    if (icon === 'arrow-left') {
      return faArrowLeft;
    }
    if (icon === 'arrow-right') {
      return faArrowRight;
    }
    if (icon === 'user') {
      return faUser;
    }
    if (icon === 'calendar-day') {
      return faCalendarDay;
    }
    if (icon === 'bars') {
      return faBars;
    }
    if (icon === 'close') {
      return faTimes;
    }
    if (icon === 'warning') {
      return faExclamationTriangle;
    }
    if (icon === 'success') {
      return faCheckCircle;
    }
    if (icon === 'calendar-missing') {
      return faCalendarTimes;
    }
    if (icon === 'near-me') {
      return faStreetView;
    }
    if (icon === 'calendar') {
      return faCalendarAlt;
    }
    if (icon === 'help') {
      return faQuestionCircle;
    }
    if (icon === 'info') {
      return faInfo;
    }
    if (icon === 'sign-out') {
      return faSignOutAlt;
    }
    if (icon === 'stop') {
      return faBan;
    }
    if (icon === 'check') {
      return faCheck;
    }
    if (icon === 'error') {
      return faTimes;
    }
    if (icon === 'new-year') {
      return faGlassCheers;
    }
    if (icon === 'spring') {
      return faCloudSun;
    }
    if (icon === 'thanksgiving') {
      return faDrumstickBite;
    }
    if (icon === 'trash') {
      return faTrashAlt;
    }
    if (icon === 'legal') {
      return faFileContract;
    }
    if (icon === 'child') {
      return faChild;
    }
    if (icon === 'tag') {
      return faTag;
    }
    if (icon === 'distance') {
      return faPeopleArrows;
    }
    if (icon === 'state') {
      return faMapMarked;
    }
    if (icon === 'vote') {
      return faVoteYea;
    }
    if (icon === 'redo') {
      return faRedo;
    }
    if (icon === 'weekend') {
      return faCloudSun;
    }
    if (icon === 'export') {
      return faFileExport;
    }
    if (icon === 'pdf') {
      return faFilePdf;
    }
    if (icon === 'delete') {
      return faTimesCircle;
    }
    if (icon === 'admin') {
      return faUserSecret;
    }
    if (icon === 'mobile') {
      return faMobile;
    }
    if (icon === 'download') {
      return faDownload;
    }
    if (icon === 'calendar_draft') {
      return faCalendarXmark;
    }
    if (icon === 'letter') {
      return faEnvelope;
    }
  }

  return <FontAwesomeIcon icon={getIcon(icon)} size={size} color={color} />;
}

export default Icon;
