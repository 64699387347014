import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { green, red } from '@material-ui/core/colors';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import { MODAL_PAYMENT } from 'constants.js';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { openModal } from 'reducers/modals';
import { getPayments } from 'reducers/payment';
import Button from 'shared/Button/Button';
import Icon from 'shared/Icons/Icons';
import Loader from 'shared/Loader/Loader';
import Typography from 'shared/Typography/Typography';
import firebase from 'utils/firebase';

import useStyles from './Payment.css.jsx';

function Payment() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const paymentList = useSelector(state => state.payment.payment.list);
  const loading = useSelector(state => state.payment.payment.loading);
  const user = useSelector(state => state.user.user);
  const { t } = useTranslation();

  useEffect(() => {
    firebase.analytics().logEvent('page_view_account_payments');
  }, []);

  useEffect(() => {
    dispatch(getPayments());
  }, [dispatch]);

  function onPayment() {
    firebase.analytics().logEvent('page_action_update_payment');
    dispatch(openModal({ type: MODAL_PAYMENT, disableClose: false }));
  }

  function paymentText(d) {
    const now = moment();
    const paymentExpDate = moment(d);
    const date = paymentExpDate.format('MMM DD, YYYY');
    if (!paymentExpDate.isValid()) {
      return null;
    } else if (now.isSameOrBefore(paymentExpDate, 'day')) {
      return <Typography variant="h6">{t('paymentPagePaymentExpDateFuture', { date })}</Typography>;
    } else {
      return <Typography variant="h6">{t('paymentPagePaymentExpDatePast', { date })}</Typography>;
    }
  }

  function chargesList() {
    if (loading) {
      return (
        <div className={classes.loader}>
          <Loader></Loader>
        </div>
      );
    } else if (!loading && paymentList.length === 0) {
      return (
        <div className={classes.emptyStateContainer}>
          <Typography variant="body1">{t('paymentPageEmptyState')}</Typography>
        </div>
      );
    } else {
      return (
        <List>
          {paymentList.map(charge => {
            return (
              <React.Fragment key={charge.id}>
                <ListItem alignItems="flex-start">
                  <ListItemIcon>
                    {charge.status === 'succeeded' ? (
                      <Icon icon="check" color={green[500]} size="lg" />
                    ) : (
                      <Icon icon="error" color={red[500]} size="lg" />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={t('paymentPageListTitle', {
                      amount: charge.amount / 100,
                      date: moment(charge.created * 1000).format('MMM DD, YYYY'),
                    })}
                    secondary={charge.description.replace('1x ', '')}
                  />
                </ListItem>
                <Divider component="li" />
              </React.Fragment>
            );
          })}
        </List>
      );
    }
  }

  return (
    <div className={classes.root}>
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={12} md={8}>
          {paymentText(user.paymentExpDate)}
          <div className={classes.purchaseButton}>
            <Button variant="contained" color="primary" onClick={onPayment}>
              {t('actionNewPayment')}
            </Button>
          </div>
          <br />
          <br />
          <Typography variant="h5">{t('paymentPagePreviousCharges')}</Typography>
          {chargesList()}
        </GridItem>
      </GridContainer>
    </div>
  );
}

Payment.propTypes = {
  classes: PropTypes.object,
};

export default Payment;
