/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import {
  blackColor,
  cardTitle,
  container,
  description,
  grayColor,
  hexToRgb,
  whiteColor,
} from 'assets/jss/material-kit-pro-react.jsx';

const signupPageStyle = theme => ({
  description,
  cardTitle: {
    ...cardTitle,
    color: whiteColor + '  !important',
  },
  container: {
    ...container,
    zIndex: '4',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '100px',
    },
  },
  pageHeader: {
    color: whiteColor,
    border: '0',
    height: '100%',
    margin: '0',
    display: 'flex!important',
    padding: '120px 0',
    position: 'relative',
    minHeight: '100vh',
    alignItems: 'center',
    '&:before': {
      background: 'rgba(' + hexToRgb(blackColor) + ', 0.5)',
    },
    '&:before,&:after': {
      position: 'absolute',
      zIndex: '1',
      width: '100%',
      height: '100%',
      display: 'block',
      left: '0',
      top: '0',
      content: '""',
    },
  },
  form: {
    margin: '0',
  },
  cardHeader: {
    width: 'auto',
    textAlign: 'center',
  },
  socialLine: {
    marginTop: '1rem',
    textAlign: 'center',
    padding: '0',
  },
  inputIconsColor: {
    color: grayColor[13],
  },
  textCenter: {
    textAlign: 'center',
  },
  iconButtons: {
    marginRight: '3px !important',
    marginLeft: '3px !important',
  },
  block: {
    color: 'inherit',
    padding: '0.9375rem',
    fontWeight: '500',
    fontSize: '12px',
    textTransform: 'uppercase',
    borderRadius: '3px',
    textDecoration: 'none',
    position: 'relative',
    display: 'block',
  },
  inlineBlock: {
    display: 'inline-block',
    padding: '0px',
    width: 'auto',
  },
  list: {
    marginBottom: '0',
    padding: '0',
    marginTop: '0',
  },
  left: {
    float: 'left!important',
    display: 'block',
    '&,& *,& *:hover,& *:focus': {
      color: whiteColor + '  !important',
    },
  },
  right: {
    padding: '15px 0',
    margin: '0',
    float: 'right',
    '&,& *,& *:hover,& *:focus': {
      color: whiteColor + '  !important',
    },
  },
  icon: {
    width: '18px',
    height: '18px',
    top: '3px',
    position: 'relative',
  },
  footer: {
    position: 'absolute',
    width: '100%',
    background: 'transparent',
    bottom: '0',
    color: whiteColor,
    zIndex: '2',
  },
});

export default signupPageStyle;
