import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { validSchoolBreakDatesRanges } from 'utils/validator';

import styles from './EditCalendarSchoolBreak.module.css';

function EditCalendarSchoolBreak({
  value,
  type,
  onChangeStartDate,
  onChangeEndDate,
  templateSchoolBreak,
  disabled,
}) {
  const { t } = useTranslation();
  const ranges = validSchoolBreakDatesRanges({
    type,
    start: moment(value.start),
    templateSchoolBreak,
  });

  function handleStartDate(date) {
    onChangeStartDate(type, moment(date).format('YYYY-MM-DD'));
  }
  function handleEndDate(date) {
    onChangeEndDate(type, moment(date).format('YYYY-MM-DD'));
  }

  return (
    <div className={styles.dataPickerContainer}>
      <div className={styles.startPicker}>
        <DatePicker
          format="MMM DD, YYYY"
          fullWidth
          placeholder={t('labelSchoolBreakStartDate')}
          value={value.start ? moment(value.start) : null}
          minDate={ranges.start.min}
          maxDate={ranges.start.max}
          onChange={handleStartDate}
          disabled={disabled}
        />
      </div>
      <span>-</span>
      <div className={styles.endPicker}>
        <DatePicker
          format="MMM DD, YYYY"
          fullWidth
          placeholder={t('labelSchoolBreakEndDate')}
          value={value.end ? moment(value.end) : null}
          minDate={ranges.end.min}
          maxDate={ranges.end.max}
          onChange={handleEndDate}
          disabled={disabled}
        />
      </div>
    </div>
  );
}

export default EditCalendarSchoolBreak;
