import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { closeModal } from 'reducers/modals';
import Button from 'shared/Button/Button';
import firebase from 'utils/firebase';

import { MODAL_ALERT_EXTEND_CALENDAR } from '../../constants.js';
import { openModal } from '../../reducers/modals';
import ModalContent from '../ModalContent/ModalContent';

export default function InformAboutLastDay() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    firebase.analytics().logEvent('modal_show_inform_about_last_day');
  }, []);

  function onCloseModal() {
    firebase.analytics().logEvent('modal_cancel_inform_about_last_day');
    dispatch(closeModal());
  }

  function onContinue() {
    dispatch(openModal({ type: MODAL_ALERT_EXTEND_CALENDAR, disableClose: false }));
    firebase.analytics().logEvent('modal_action_inform_about_last_day_continue');
  }

  function getActions() {
    return (
      <>
        <Button onClick={onCloseModal} color="primary">
          {t('actionCancel')}
        </Button>

        <Button onClick={onContinue} color="primary">
          {t('actionContinue')}
        </Button>
      </>
    );
  }

  return (
    <>
      <ModalContent
        title={t('modalLastGeneratedDayTitle')}
        subtitle={t('modalLastGeneratedDaySubTitle')}
        actions={getActions()}
      />
    </>
  );
}
