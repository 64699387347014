import { Box, Link } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from 'reducers/modals';
import Button from 'shared/Button/Button';
import Icon from 'shared/Icons/Icons';
import firebase from 'utils/firebase';

import ModalContent from '../ModalContent/ModalContent.jsx';

function DownloadPdf() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { pdfLink } = useSelector(state => state.calendar);

  function getActions() {
    return (
      <>
        <Button color="primary" onClick={onCloseModal}>
          {t('actionCancel')}
        </Button>
      </>
    );
  }

  function onCloseModal() {
    firebase.analytics().logEvent('cancel_downloading_pdf');
    firebase.analytics().logEvent('modal_cancel_downloading_pdf');
    dispatch(closeModal());
  }
  return (
    <ModalContent title={t('modalDownloadPfd')} actions={getActions()}>
      <Box display={'flex'} justifyContent={'center'}>
        <Link href={pdfLink} target="_blank" download={'FileName.pdf'}>
          <Button
            variant="contained"
            style={{ color: '#fff', backgroundColor: '#1976D2' }}
            startIcon={<Icon icon="download" />}
            onClick={() => dispatch(closeModal())}
          >
            {t('download')}
          </Button>
        </Link>
      </Box>
    </ModalContent>
  );
}

export default DownloadPdf;
