import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Step from '@material-ui/core/Step';
import StepContent from '@material-ui/core/StepContent';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import Switch from '@material-ui/core/Switch';
import {
  ELECT_SPO_TEXAS,
  SCHOOL_BREAK_CHRISTMAS,
  SCHOOL_BREAK_CHRISTMAS_NEW_YEAR,
  SCHOOL_BREAK_NEWYEAR,
  SCHOOL_BREAK_SPRING,
  SCHOOL_BREAK_SUMMER,
  SCHOOL_BREAK_THANKSGIVING,
} from 'constants.js';
import { DEFAULT_WEEKENDS_COPY } from 'constants.js';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { extendCalendar } from 'reducers/extendCalendar';
import { closeModal } from 'reducers/modals';
import { getSchoolBreakTemplates } from 'reducers/schoolBreaks';
import AlertBox from 'shared/AlertBox/AlertBox';
import Button from 'shared/Button/Button';
import AlternateWeekendsPossession from 'shared/Forms/AlternateWeekendsPossession';
import DefaultWeekends from 'shared/Forms/DefaultWeekends';
import SchoolBreakDatePicker from 'shared/Forms/SchoolBreakDatePicker';
import Icon from 'shared/Icons/Icons';
import SummaryList from 'shared/SummaryList/SummaryList';
import Typography from 'shared/Typography/Typography';
import WarningBox from 'shared/WarningBox/WarningBox';
import {
  getChristmasYear,
  getCurrentCalendar,
  getCurrentSchoolYear,
  getMonthName,
  getNewyearYear,
  getValidMonthsInTheCalendar,
} from 'utils/calendar';
import firebase from 'utils/firebase';
//import { getAlternateWeekendsPossessionTemplates } from "reducers/alternateWeekendsPossession"
import {
  getAllValidWeekendsOfTheMonth,
  isValidAlternateWeekendsPossession,
  isValidSchoolBreakDate, //isValidLegalZipCode,
  isValidSelectableValue,
} from 'utils/validator';

import ModalContent from '../ModalContent/ModalContent';
import useStyles from './ExtendCalendar.css.jsx';

function Extend() {
  const { t } = useTranslation();
  const classes = useStyles();
  const currentCalendarId = useSelector(state => state.calendar.currentCalendarId);
  const calendars = useSelector(state => state.calendar.list.calendars);
  const currentCalendar = getCurrentCalendar(calendars, currentCalendarId);

  const [withinDistance] = useState(currentCalendar.withinDistance === true ? '0' : '1');
  const [electedSecondary] = useState(currentCalendar.electedSecondary);
  const [defaultWeekends, setDefaultWeekends] = useState(
    currentCalendar.defaultWeekends === null
      ? ''
      : currentCalendar.defaultWeekends === true
      ? '0'
      : '1',
  );
  const [activeStep, setActiveStep] = useState(0);
  const [shouldUseDefaultDates, setShouldUseDefaultDates] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const loading = useSelector(state => state.extendCalendar.loading);
  const templateSchoolBreaks = useSelector(state => state.schoolBreaks.templates.schoolBreaks);
  const schoolYear = getCurrentSchoolYear(
    moment(currentCalendar.lastPopulatedDate).add(1, 'day'),
    moment(currentCalendar.lastPopulatedDate).add(1, 'year'),
  );
  const schoolYearLongFormat = getCurrentSchoolYear(
    moment(currentCalendar.lastPopulatedDate).add(1, 'day'),
    moment(currentCalendar.lastPopulatedDate).add(1, 'year'),
    false,
    true,
  );
  const [firstPopulatedDate, setFirstPopulatedDate] = useState('');
  const [lastPopulatedDate, setLastPopulatedDate] = useState('');

  const dispatch = useDispatch();
  const steps = t('extendCalendarSteps', { returnObjects: true });
  const [weekendIndices, setWeekendIndices] = useState({
    January: { index: '' },
    February: { index: '' },
    March: { index: '' },
    April: { index: '' },
    May: { index: '' },
    June: { index: '' },
    July: { index: '' },
    August: { index: '' },
    September: { index: '' },
    October: { index: '' },
    November: { index: '' },
    December: { index: '' },
  });
  const [secondAugustWeekendIndex, setSecondAugustWeekendIndex] = useState({
    index: '',
  });
  const [schoolBreaks, setSchoolBreaks] = useState({
    thanksgiving: { start: null, end: null },
    christmas_newyear: { start: null, end: null },
    christmas: { start: null, end: null },
    newyear: { start: null, end: null },
    spring: { start: null, end: null },
    summer: { start: null, end: null },
  });
  const [alternateWeekendsPossession, setAlternateWeekendsPossession] = useState({
    January: { start: null, end: null },
    February: { start: null, end: null },
    March: { start: null, end: null },
    April: { start: null, end: null },
    May: { start: null, end: null },
    June: { start: null, end: null },
    July: { start: null, end: null },
    August: { start: null, end: null },
    September: { start: null, end: null },
    October: { start: null, end: null },
    November: { start: null, end: null },
    December: { start: null, end: null },
  });

  const [secondAugustAlternateWeekendsPossession, setSecondAugustAlternateWeekendsPossession] =
    useState({
      start: null,
      end: null,
    });

  let allValid = true;

  useEffect(() => {
    if (currentCalendar && currentCalendar.accessCard) {
      setFirstPopulatedDate(
        moment(currentCalendar.lastPopulatedDate).add(1, 'day').format('YYYY-MM-DD'),
      );
      setLastPopulatedDate(
        moment(currentCalendar.lastPopulatedDate).add(1, 'year').format('YYYY-MM-DD'),
      );
      // setParentalRole(currentCalendar.accessCard.parentalRole);
      // setLegalRole(currentCalendar.accessCard.role);
    }
  }, [currentCalendar]);

  useEffect(() => {
    firebase.analytics().logEvent('modal_view_extend_calendar');
  }, []);

  useEffect(() => {
    dispatch(getSchoolBreakTemplates(schoolYear));
  }, [dispatch, schoolYear]);

  function handleNext() {
    firebase.analytics().logEvent('modal_action_create_calendar_next');
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  }

  function handleBack() {
    firebase.analytics().logEvent('modal_action_create_calendar_back');
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  }

  function onHandleCancel() {
    firebase.analytics().logEvent('modal_cancel_create_calendar');
    dispatch(closeModal());
  }

  function onChangeDefaultDates() {
    const useDefault = !shouldUseDefaultDates;
    setShouldUseDefaultDates(useDefault);
    if (useDefault) {
      schoolBreaks['thanksgiving'].start = moment(templateSchoolBreaks.thanksgiving.start);
      schoolBreaks['thanksgiving'].end = moment(templateSchoolBreaks.thanksgiving.end);

      schoolBreaks['christmas_newyear'].start = moment(
        templateSchoolBreaks.christmas_newyear.start,
      );
      schoolBreaks['christmas_newyear'].end = moment(templateSchoolBreaks.christmas_newyear.end);

      schoolBreaks['christmas'].start = moment(templateSchoolBreaks.christmas.start);
      schoolBreaks['christmas'].end = moment(templateSchoolBreaks.christmas.end);

      schoolBreaks['newyear'].start = moment(templateSchoolBreaks.newyear.start);
      schoolBreaks['newyear'].end = moment(templateSchoolBreaks.newyear.end);

      schoolBreaks['spring'].start = moment(templateSchoolBreaks.spring.start);
      schoolBreaks['spring'].end = moment(templateSchoolBreaks.spring.end);

      schoolBreaks['summer'].start = moment(templateSchoolBreaks.summer.start);
      schoolBreaks['summer'].end = moment(templateSchoolBreaks.summer.end);
    } else {
      schoolBreaks['thanksgiving'].start = null;
      schoolBreaks['thanksgiving'].end = null;

      schoolBreaks['christmas_newyear'].start = null;
      schoolBreaks['christmas_newyear'].end = null;

      schoolBreaks['christmas'].start = null;
      schoolBreaks['christmas'].end = null;

      schoolBreaks['newyear'].start = null;
      schoolBreaks['newyear'].end = null;

      schoolBreaks['spring'].start = null;
      schoolBreaks['spring'].end = null;

      schoolBreaks['summer'].start = null;
      schoolBreaks['summer'].end = null;
    }
    setSchoolBreaks({ ...schoolBreaks });
  }

  function onChangeAlternateWeekendsPossession(monthIndex, weekIndex) {
    let weekends, weekend;
    let month = getMonthName(monthIndex + 7);
    // console.log(month, monthIndex, weekIndex);
    if (weekIndex === '') {
      //console.log(alternateWeekendsPossession, month);
      alternateWeekendsPossession[month].start = null;
      alternateWeekendsPossession[month].end = null;
      setAlternateWeekendsPossession({ ...alternateWeekendsPossession });
      weekendIndices[month].index = '';
      setWeekendIndices({ ...weekendIndices });
    } else {
      weekends = getAllValidWeekendsOfTheMonth(
        monthIndex - 1,
        schoolBreaks,
        firstPopulatedDate,
        lastPopulatedDate,
      );
      weekend = weekends[weekIndex];
      if (weekend) {
        if (monthIndex === 12) {
          setSecondAugustAlternateWeekendsPossession({
            start: moment(weekend.day1).clone().format('YYYY-MM-DD'),
            end: moment(weekend.day3).clone().format('YYYY-MM-DD'),
          });
          setSecondAugustWeekendIndex({ index: weekIndex });
        } else {
          month = moment(weekend.day1).clone().format('MMMM');
          alternateWeekendsPossession[month].start = moment(weekend.day1)
            .clone()
            .format('YYYY-MM-DD');
          alternateWeekendsPossession[month].end = moment(weekend.day3)
            .clone()
            .format('YYYY-MM-DD');
          setAlternateWeekendsPossession({ ...alternateWeekendsPossession });
          weekendIndices[month].index = weekIndex;
          setWeekendIndices({ ...weekendIndices });
        }
      }
    }
  }

  const onChangeSchoolbreakStartDate = name => date => {
    if (name === SCHOOL_BREAK_CHRISTMAS_NEW_YEAR) {
      schoolBreaks[SCHOOL_BREAK_CHRISTMAS].start = date;
      schoolBreaks[SCHOOL_BREAK_CHRISTMAS].end = date.clone().date(28);
      schoolBreaks[SCHOOL_BREAK_NEWYEAR].start = date.clone().date(29);
    }
    schoolBreaks[name].start = date;
    schoolBreaks[name].end = null;
    setSchoolBreaks({ ...schoolBreaks });
  };

  const onChangeSchoolbreakEndDate = name => date => {
    if (name === SCHOOL_BREAK_CHRISTMAS_NEW_YEAR) {
      schoolBreaks[SCHOOL_BREAK_NEWYEAR].end = date;
    }
    if (name === SCHOOL_BREAK_CHRISTMAS) {
      // New start date should always be one day after christmas end date.
      schoolBreaks[SCHOOL_BREAK_NEWYEAR].start = date.clone().add(1, 'days');
    }
    schoolBreaks[name].end = date;
    setSchoolBreaks({ ...schoolBreaks });
  };

  function onChangeDefaultWeekends(value) {
    setDefaultWeekends(value);
    //setAllWeekendsString(allWeekendsString);
    //setAllWeekends({...allWeekends});
  }

  function onChangeTermsAndConditions() {
    setTermsAndConditions(!termsAndConditions);
  }

  function onExtendCalendar() {
    const data = ELECT_SPO_TEXAS.includes(electedSecondary)
      ? {
          calendarId: currentCalendarId,
          schoolBreaks: schoolBreaks,
          schoolYear: schoolYear,
          secondAugustAlternateWeekendsPossession: secondAugustAlternateWeekendsPossession,
          alternateWeekendsPossession: alternateWeekendsPossession,
          withinDistance: withinDistance === 0 ? true : false,
          defaultWeekends: defaultWeekends === '' ? null : defaultWeekends === '0' ? true : false,
        }
      : { schoolYear: schoolYear, calendarId: currentCalendarId };
    dispatch(extendCalendar(data));
    firebase.analytics().logEvent('modal_action_extend_calendar', data);
  }

  function getChristmasNewYearTitle() {
    const christmasYear = getChristmasYear(templateSchoolBreaks);
    const newyearYear = getNewyearYear(templateSchoolBreaks);
    const title = t('schoolBreak_christmas_newyear', {
      christmasYear,
      newyearYear,
    });
    return title;
  }

  function isStepValid(step) {
    if (step === 0 && !ELECT_SPO_TEXAS.includes(electedSecondary)) {
      return termsAndConditions;
    } else if (step === 0) {
      const validSchoolBreakDates = Object.values(schoolBreaks).map(
        schoolBreak => isValidSchoolBreakDate(schoolBreak.start, schoolBreak.end)[0],
      );
      return !validSchoolBreakDates.includes(false);
    } else if (withinDistance === '1' && step === 1) {
      const [validDefaultWeekends] = isValidSelectableValue(defaultWeekends);

      return withinDistance === '1' ? validDefaultWeekends : true;
    } else if (step === 2 && defaultWeekends === '0') {
      return true;
    } else if (step === 2 && defaultWeekends === '1') {
      const validAlternateWeekendsPossession = Object.values(alternateWeekendsPossession).map(
        alternateWeekendsPossession =>
          isValidAlternateWeekendsPossession(alternateWeekendsPossession.start),
      );
      const validSecondAugust = moment().isBefore(moment(firstPopulatedDate))
        ? isValidAlternateWeekendsPossession(secondAugustAlternateWeekendsPossession.start)
        : true;

      const lastIndex = getValidMonthsInTheCalendar(firstPopulatedDate, lastPopulatedDate);
      let index = Number(moment().format('MM')) - 1;
      let counter = 0;
      let anyValid = false;
      allValid = true;
      while (counter < lastIndex) {
        allValid = allValid && validAlternateWeekendsPossession[index][0];
        anyValid = anyValid || validAlternateWeekendsPossession[index][0];
        counter++;
        index++;
        if (index > 11) {
          index = 0;
        }
      }
      allValid = allValid && validSecondAugust;
      anyValid = allValid || validSecondAugust;
      if (anyValid) {
        return true;
      }
    } else if (
      step === 3 &&
      withinDistance === '1' &&
      defaultWeekends !== null &&
      Number(defaultWeekends) === 0
    ) {
      return termsAndConditions;
    } else if (step === 2 && withinDistance === '0') {
      return termsAndConditions;
    } else if (step === 4 && withinDistance === '1' && Number(defaultWeekends) === 1) {
      return termsAndConditions;
    }
    // else if (step === 2 && withinDistance === "0") {
    //   return true;
    // }
    else if (step === 3 && withinDistance === '1' && Number(defaultWeekends) === 1) {
      return true;
    } else {
      return true;
    }
  }

  function getSections() {
    //console.log(withinDistance, defaultWeekends);
    return [
      ELECT_SPO_TEXAS.includes(electedSecondary) && {
        title: t('calendarInfoSchoolbreak'),
        sections: [
          {
            title: t('schoolBreakThanksgiving'),
            value: `
              ${moment(schoolBreaks.thanksgiving.start).format('MMM DD, YYYY')} -
              ${moment(schoolBreaks.thanksgiving.end).format('MMM DD, YYYY')}
            `,
            icon: <Icon icon="thanksgiving" size="lg" />,
          },
          {
            title: t('schoolBreakChristmas'),
            value: `
              ${moment(schoolBreaks.christmas.start).format('MMM DD, YYYY')} -
              ${moment(schoolBreaks.christmas.end).format('MMM DD, YYYY')}
            `,
            icon: <Icon icon="snowman" size="lg" />,
          },
          {
            title: t('schoolBreakNewYear'),
            value: `
              ${moment(schoolBreaks.newyear.start).format('MMM DD, YYYY')} -
              ${moment(schoolBreaks.newyear.end).format('MMM DD, YYYY')}
            `,
            icon: <Icon icon="new-year" size="lg" />,
          },
          {
            title: t('schoolBreakSpring'),
            value: `
              ${moment(schoolBreaks.spring.start).format('MMM DD, YYYY')} -
              ${moment(schoolBreaks.spring.end).format('MMM DD, YYYY')}
            `,
            icon: <Icon icon="spring" size="lg" />,
          },
          {
            title: t('schoolBreakSummer'),
            value: `
              ${moment(schoolBreaks.summer.start).format('MMM DD, YYYY')} -
              ${moment(schoolBreaks.summer.end).format('MMM DD, YYYY')}
            `,
            icon: <Icon icon="summer" size="lg" />,
          },
          withinDistance === '1'
            ? {
                title: t('labelDefaultWeekends'),
                value: DEFAULT_WEEKENDS_COPY[Number(defaultWeekends)]
                  ? DEFAULT_WEEKENDS_COPY[Number(defaultWeekends)].toUpperCase()
                  : null,
                icon: <Icon icon="weekend" size="lg" />,
              }
            : null,
        ],
      },
    ];
  }

  function getStepContent(step) {
    if (step === 0) {
      return (
        <>
          <Typography variant="subtitle2">
            {t('modalExtendCalendarSchoolBreakTitle', {
              schoolYear: schoolYearLongFormat,
            })}
          </Typography>
          <FormControlLabel
            label={t('defaultSchoolBreakDatesTitle')}
            control={
              <Switch
                color="primary"
                checked={shouldUseDefaultDates}
                onChange={onChangeDefaultDates}
                value="defaultDates"
              />
            }
          />

          <SchoolBreakDatePicker
            type={SCHOOL_BREAK_THANKSGIVING}
            schoolBreak={schoolBreaks.thanksgiving}
            templateSchoolBreak={templateSchoolBreaks.thanksgiving}
            endProps={{ disabled: !schoolBreaks.thanksgiving.start }}
            onChangeStartDate={onChangeSchoolbreakStartDate(SCHOOL_BREAK_THANKSGIVING)}
            onChangeEndDate={onChangeSchoolbreakEndDate(SCHOOL_BREAK_THANKSGIVING)}
          />
          <SchoolBreakDatePicker
            title={getChristmasNewYearTitle()}
            type={SCHOOL_BREAK_CHRISTMAS_NEW_YEAR}
            schoolBreak={schoolBreaks.christmas_newyear}
            templateSchoolBreak={templateSchoolBreaks.christmas_newyear}
            startProps={{ disabled: false }}
            endProps={{ disabled: false }}
            onChangeStartDate={onChangeSchoolbreakStartDate(SCHOOL_BREAK_CHRISTMAS_NEW_YEAR)}
            onChangeEndDate={onChangeSchoolbreakEndDate(SCHOOL_BREAK_CHRISTMAS_NEW_YEAR)}
          />
          <SchoolBreakDatePicker
            type={SCHOOL_BREAK_SPRING}
            schoolBreak={schoolBreaks.spring}
            templateSchoolBreak={templateSchoolBreaks.spring}
            endProps={{ disabled: !schoolBreaks.spring.start }}
            onChangeStartDate={onChangeSchoolbreakStartDate(SCHOOL_BREAK_SPRING)}
            onChangeEndDate={onChangeSchoolbreakEndDate(SCHOOL_BREAK_SPRING)}
          />
          <SchoolBreakDatePicker
            type={SCHOOL_BREAK_SUMMER}
            schoolBreak={schoolBreaks.summer}
            templateSchoolBreak={templateSchoolBreaks.summer}
            endProps={{ disabled: !schoolBreaks.summer.start }}
            onChangeStartDate={onChangeSchoolbreakStartDate(SCHOOL_BREAK_SUMMER)}
            onChangeEndDate={onChangeSchoolbreakEndDate(SCHOOL_BREAK_SUMMER)}
          />
        </>
      );
    } else if (step === 1) {
      return (
        <>
          {withinDistance === '1' ? (
            <DefaultWeekends
              defaultWeekends={defaultWeekends}
              onChangeDefaultWeekends={onChangeDefaultWeekends}
            ></DefaultWeekends>
          ) : (
            <div>{<AlertBox variant="info" title={t('alertWithin100milesDefaultWeekends')} />}</div>
          )}
        </>
      );
    } else if (step === 2) {
      const items = [];
      let loop = 0;
      const lastIndex = getValidMonthsInTheCalendar(firstPopulatedDate, lastPopulatedDate);
      //console.log(lastIndex);
      while (8 + loop < lastIndex) {
        items.push(
          <div className={classes.alternateWeekendsPossessionWrapper}>
            <AlternateWeekendsPossession
              className={classes.alternateWeekendsPossessionMonth1}
              key={loop}
              monthIndex={loop}
              schoolBreaks={schoolBreaks}
              firstPopulatedDate={firstPopulatedDate}
              alternateWeekendsPossession={
                alternateWeekendsPossession[
                  moment(firstPopulatedDate).add(loop, 'months').format('MMMM')
                ]
              }
              weekIndex={''}
              weekend={
                loop + 10 >= lastIndex
                  ? secondAugustWeekendIndex
                  : weekendIndices[moment(firstPopulatedDate).add(loop, 'months').format('MMMM')]
              }
              disabled={false}
              onChangeAlternateWeekendsPossession={onChangeAlternateWeekendsPossession}
            ></AlternateWeekendsPossession>
            {loop + 9 < lastIndex ? (
              <AlternateWeekendsPossession
                className={classes.alternateWeekendsPossessionMonth2}
                key={loop + 1}
                monthIndex={loop + 1}
                schoolBreaks={schoolBreaks}
                firstPopulatedDate={firstPopulatedDate}
                alternateWeekendsPossession={
                  alternateWeekendsPossession[
                    moment(firstPopulatedDate)
                      .add(loop + 1, 'months')
                      .format('MMMM')
                  ]
                }
                weekIndex={''}
                weekend={
                  loop + 10 >= lastIndex
                    ? secondAugustWeekendIndex
                    : weekendIndices[
                        moment(firstPopulatedDate)
                          .add(loop + 1, 'months')
                          .format('MMMM')
                      ]
                }
                disabled={loop + 9 >= lastIndex ? true : false}
                onChangeAlternateWeekendsPossession={onChangeAlternateWeekendsPossession}
              ></AlternateWeekendsPossession>
            ) : (
              <div className={classes.alternateWeekendsPossessionMonth2}></div>
            )}
          </div>,
        );
        loop += 2;
      }
      return (
        <>
          {
            <div>
              <Typography variant="subtitle2">
                {t('labelAddAlternateWeekendsPossessionTitle')}
              </Typography>
              <div>{items}</div>
            </div>
          }
          {allValid ? <div></div> : <WarningBox text={t('warningAlternateWeekends')} />}
        </>
      );
    } else if (step === 3) {
      return <SummaryList sections={getSections()} />;
    } else if (step === 4) {
      return (
        <>
          <div>
            <Typography variant="body1">
              <Trans i18nKey="actionAcceptTermsAndConditionsDescription">
                <b></b>
              </Trans>
            </Typography>
          </div>

          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={termsAndConditions}
                  onChange={onChangeTermsAndConditions}
                  value="checkedB"
                  color="primary"
                  inputProps={{
                    'aria-label': 'terms and conditions',
                  }}
                />
              }
              label={
                <Trans i18nKey="actionAcceptTermsAndConditions">
                  <Link
                    style={{ color: '#9c27b0' }}
                    to="/ourdayscalendar-terms-condition.pdf"
                    target="_blank"
                  ></Link>
                </Trans>
              }
            ></FormControlLabel>
          </FormControl>
        </>
      );
    } else {
      return <div>Unknown</div>;
    }
  }

  function showStep(step) {
    const disableOnlyAlternateWeekends = withinDistance === '1' && Number(defaultWeekends) === 0;
    const disableAltAndDefaultWeekends = withinDistance === '0';
    //console.log(withinDistance, defaultWeekends, disableOnlyAlternateWeekends, disableAltAndDefaultWeekends);
    if (disableOnlyAlternateWeekends && step === 2) {
      return false;
    }
    if (disableAltAndDefaultWeekends && (step === 1 || step === 2)) {
      return false;
    }
    if (
      !ELECT_SPO_TEXAS.includes(electedSecondary) &&
      (step === 0 || step === 1 || step === 2 || step === 3)
    ) {
      return false;
    }
    return true;
  }

  function getActions() {
    const totalStepsLength = steps.length;
    const effectiveStepsLength = !ELECT_SPO_TEXAS.includes(electedSecondary)
      ? totalStepsLength - 4
      : withinDistance === '0'
      ? totalStepsLength - 2
      : Number(defaultWeekends) === 0
      ? totalStepsLength - 1
      : totalStepsLength;
    return (
      <>
        <Button onClick={onHandleCancel}>{t('actionCancel')}</Button>
        <Button disabled={activeStep === 0 || loading} onClick={handleBack}>
          {t('actionBack')}
        </Button>
        {activeStep < effectiveStepsLength - 1 && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleNext}
            disabled={!isStepValid(activeStep)}
          >
            {t('actionNext')}
          </Button>
        )}
        {activeStep === effectiveStepsLength - 1 && (
          <Button
            variant="contained"
            color="primary"
            onClick={onExtendCalendar}
            disabled={!isStepValid(activeStep)}
          >
            {t('actionExtendCalendar')}
          </Button>
        )}
      </>
    );
  }

  return (
    <ModalContent
      title={t('modalTitleExtendCalendar', {
        calendarName: currentCalendar.accessCard.calendarName,
        schoolYear: schoolYearLongFormat,
      })}
      subtitle={
        ELECT_SPO_TEXAS.includes(electedSecondary)
          ? t('modalSubTitleExtendCalendar', {
              calendarName: currentCalendar.accessCard.calendarName,
              schoolYear: schoolYearLongFormat,
            })
          : t('modalSubtitleExtendNonTexasCalendar', {
              calendarName: currentCalendar.accessCard.calendarName,
            })
      }
      actions={getActions()}
      loading={loading}
    >
      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        classes={{ root: classes.stepperRoot }}
      >
        {steps.map((label, index) =>
          showStep(index) ? (
            <Step key={label}>
              <StepLabel icon={'►'}>{label}</StepLabel>
              <StepContent>{getStepContent(index)}</StepContent>
            </Step>
          ) : null,
        )}
      </Stepper>
    </ModalContent>
  );
}

export default Extend;
