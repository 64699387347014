import { getCalendars } from 'reducers/calendar';
import { closeModal } from 'reducers/modals';
import { addSnackbar } from 'reducers/snackbar';

export const CURRENT_SCHOOL_BREAK_DATE = 'editSchoolBreakDate/CURRENT_SCHOOL_BREAK_DATE';
export const MODIFY_SCHOOL_BREAK_DATE_START = 'editSchoolBreakDate/MODIFY_SCHOOL_BREAK_DATE_START';
export const MODIFY_SCHOOL_BREAK_DATE_DONE = 'editSchoolBreakDate/MODIFY_SCHOOL_BREAK_DATE_DONE';
export const MODIFY_SCHOOL_BREAK_DATE_ERROR = 'editSchoolBreakDate/MODIFY_SCHOOL_BREAK_DATE_ERROR';

const initialState = {
  calendarId: '',
  schoolYear: '',
  name: '',
  startDate: null,
  endDate: null,
  loading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CURRENT_SCHOOL_BREAK_DATE: {
      return {
        ...state,
        calendarId: action.data.calendarId,
        schoolYear: action.data.schoolYear,
        name: action.data.name,
        startDate: action.data.startDate,
        endDate: action.data.endDate,
        loading: false,
        error: null,
      };
    }
    case MODIFY_SCHOOL_BREAK_DATE_START: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case MODIFY_SCHOOL_BREAK_DATE_DONE: {
      return {
        ...state,
        loading: false,
        error: null,
      };
    }
    case MODIFY_SCHOOL_BREAK_DATE_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    default: {
      return { ...state };
    }
  }
};

export const currentSchoolBreak = data => ({
  type: CURRENT_SCHOOL_BREAK_DATE,
  data,
});

export const modifySchoolBreakDate =
  data =>
  async (dispatch, getState, { firebase }) => {
    dispatch({ type: MODIFY_SCHOOL_BREAK_DATE_START });
    const modifySchoolBr = firebase.functions().httpsCallable('modifySchoolBreakDateV2');
    const payload = {
      calendarId: data.calendarId,
      schoolYear: data.schoolYear,
      name: data.name,
      start: data.schoolBreak.start.format('YYYY-MM-DD'),
      end: data.schoolBreak.end.format('YYYY-MM-DD'),
    };
    try {
      await modifySchoolBr(payload);
      dispatch({ type: MODIFY_SCHOOL_BREAK_DATE_DONE });
      dispatch(getCalendars({}));
      dispatch(closeModal());
    } catch (error) {
      dispatch({ type: MODIFY_SCHOOL_BREAK_DATE_ERROR, error });
      dispatch(
        addSnackbar({
          text: `${error.message}`,
          variant: 'error',
        }),
      );
    }
  };
