import { addSnackbar } from 'reducers/snackbar';

const authTypes = {
  password: 'password',
  google: 'google.com',
  apple: 'apple.com',
  facebook: 'facebook.com',
};

export const GET_USER_START = 'user/GET_USER_START';
export const GET_USER_DONE = 'user/GET_USER_DONE';
export const GET_USER_ERROR = 'user/GET_USER_ERROR';

export const GET_USER_START_BY_ID = 'user/GET_USER_START_BY_ID';
export const GET_USER_DONE_BY_ID = 'user/GET_USER_DONE_BY_ID';
export const GET_USER_ERROR_BY_ID = 'user/GET_USER_ERROR_BY_ID';

export const SAVE_USER_START = 'user/SAVE_USER_START';
export const SAVE_USER_DONE = 'user/SAVE_USER_DONE';
export const SAVE_USER_ERROR = 'user/SAVE_USER_ERROR';

export const DELETE_USER_START = 'user/DELETE_USER_START';
export const DELETE_USER_DONE = 'user/DELETE_USER_DONE';
export const DELETE_USER_ERROR = 'user/DELETE_USER_ERROR';

export const GET_USER_DEVICE_DONE = 'user/GET_USER_DEVICE_DONE';

const initialState = {
  loading: true,
  user: {},
  calendarOwnerUser: {},
  saving: false,
  deleting: false,
  devices: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_START: {
      return { ...state, loading: true, error: null, user: {} };
    }
    case GET_USER_DONE: {
      return { ...state, loading: false, error: null, user: action.user };
    }
    case GET_USER_ERROR: {
      return { ...state, loading: false, error: action.error, user: {} };
    }
    case GET_USER_START_BY_ID: {
      return { ...state, loading: true, error: null, calendarOwnerUser: {} };
    }
    case GET_USER_DONE_BY_ID: {
      return {
        ...state,
        loading: false,
        error: null,
        calendarOwnerUser: action.calendarOwnerUser,
      };
    }
    case GET_USER_ERROR_BY_ID: {
      return {
        ...state,
        loading: false,
        error: action.error,
        calendarOwnerUser: {},
      };
    }

    case SAVE_USER_START: {
      return { ...state, saving: true };
    }
    case SAVE_USER_DONE: {
      return { ...state, saving: false };
    }
    case SAVE_USER_ERROR: {
      return { ...state, saving: false };
    }

    case DELETE_USER_START: {
      return { ...state, deleting: true };
    }
    case DELETE_USER_DONE: {
      return { ...state, deleting: false, user: {} };
    }
    case DELETE_USER_ERROR: {
      return { ...state, deleting: false };
    }
    case GET_USER_DEVICE_DONE: {
      return { ...state, deleting: false, devices: action.devices };
    }
    default: {
      return { ...state };
    }
  }
};

export const getUser =
  () =>
  async (dispatch, getState, { firebase }) => {
    dispatch({ type: GET_USER_START });

    const { auth } = getState();
    const userId = auth.user.uid;
    const db = firebase.firestore();
    try {
      const userResponse = await db.collection('users').doc(userId).get();
      dispatch({ type: GET_USER_DONE, user: userResponse.data() });
    } catch (error) {
      dispatch({ type: GET_USER_ERROR, error });
      dispatch(
        addSnackbar({
          text: `${error.message}`,
          variant: 'error',
          closeButton: true,
          //retryButton: () => dispatch(saveUser(data))
        }),
      );
    }
  };
export const getUserById =
  data =>
  async (dispatch, getState, { firebase }) => {
    const { email } = data;
    try {
      if (email) {
        dispatch({ type: GET_USER_START_BY_ID });
        const getUserByEmail = firebase.functions().httpsCallable('getUserByEmailV2');

        const userData = await getUserByEmail({
          email,
        });
        dispatch({
          type: GET_USER_DONE_BY_ID,
          calendarOwnerUser: userData.data.user[0],
        });
      } else {
        dispatch({
          type: GET_USER_DONE_BY_ID,
          calendarOwnerUser: {},
        });
      }
    } catch (error) {
      dispatch({ type: GET_USER_ERROR_BY_ID, error });
      dispatch(
        addSnackbar({
          text: `${error.message}`,
          variant: 'error',
          closeButton: true,
          //retryButton: () => dispatch(saveUser(data))
        }),
      );
    }
  };

export const getUserDevices =
  () =>
  async (dispatch, getState, { firebase }) => {
    dispatch({ type: GET_USER_START });
    const { auth } = getState();
    const userId = auth.user.uid;
    const db = firebase.firestore();
    try {
      const userDevicesResponse = await db
        .collection('users')
        .doc(userId)
        .collection('devices')
        .doc(userId)
        .get();
      dispatch({
        type: GET_USER_DEVICE_DONE,
        devices: userDevicesResponse.data(),
      });
    } catch (error) {
      dispatch({ type: GET_USER_ERROR, error });
      dispatch(
        addSnackbar({
          text: `${error.message}`,
          variant: 'error',
          closeButton: true,
          //retryButton: () => dispatch(saveUser(data))
        }),
      );
    }
  };

export const saveUser =
  data =>
  async (dispatch, getState, { firebase }) => {
    dispatch({ type: SAVE_USER_START });
    const { displayName } = data;
    const db = firebase.firestore();
    const { auth } = getState();
    const userId = auth.user.uid;

    try {
      await db.collection('users').doc(userId).update({ displayName });

      dispatch({ type: SAVE_USER_DONE });

      dispatch(
        addSnackbar({
          text: `Successfully saved`,
          variant: 'success',
          closeButton: false,
          //retryButton: () => dispatch(saveUser(data))
        }),
      );
    } catch (error) {
      dispatch(
        addSnackbar({
          text: `${error.message}`,
          variant: 'error',
          closeButton: true,
          //retryButton: () => dispatch(saveUser(data))
        }),
      );
      dispatch({ type: SAVE_USER_ERROR, error });
    }
  };
export const saveUserSettings =
  data =>
  async (dispatch, getState, { firebase }) => {
    dispatch({ type: SAVE_USER_START });
    const { settings } = data;
    const db = firebase.firestore();
    const { auth } = getState();
    const userId = auth.user.uid;

    try {
      await db.collection('users').doc(userId).update({ settings });

      dispatch({ type: SAVE_USER_DONE });
      dispatch(getUser());
      dispatch(
        addSnackbar({
          text: `Successfully saved`,
          variant: 'success',
          closeButton: false,
          //retryButton: () => dispatch(saveUser(data))
        }),
      );
    } catch (error) {
      console.error(JSON.stringify(error));
      dispatch(
        addSnackbar({
          text: `${error.message}`,
          variant: 'error',
          closeButton: true,
          //retryButton: () => dispatch(saveUser(data))
        }),
      );
      dispatch({ type: SAVE_USER_ERROR, error });
    }
  };

export const deleteUser =
  data =>
  async (dispatch, getState, { firebase }) => {
    const user = firebase.auth().currentUser;
    const providerId = user.providerData[0].providerId;
    if (providerId === authTypes.password) {
      dispatch({ type: DELETE_USER_START });
      const credential = firebase.auth.EmailAuthProvider.credential(data.email, data.password);
      user
        .reauthenticateWithCredential(credential)
        .then(() => {
          firebase.functions().httpsCallable('deleteSharedCalendarsAccess');
          user.delete().then(
            () => {
              dispatch({ type: DELETE_USER_DONE });
            },
            error => {
              dispatch(
                addSnackbar({
                  text: `${error.message}`,
                  variant: 'error',
                  closeButton: true,
                }),
              );
              dispatch({ type: DELETE_USER_ERROR, error });
            },
          );
        })
        .catch(error => {
          dispatch(
            addSnackbar({
              text: `${error.message}`,
              variant: 'error',
              closeButton: true,
            }),
          );
          dispatch({ type: DELETE_USER_ERROR, error });
        });
    } else {
      dispatch({ type: DELETE_USER_START });
      const provider = new firebase.auth.OAuthProvider(providerId);
      console.log(providerId);
      switch (providerId) {
        case authTypes.google:
          provider.addScope('profile');
          provider.addScope('email');
          break;
        case authTypes.apple:
          provider.addScope('email');
          provider.addScope('name');
          break;
        case authTypes.facebook:
          // provider.addScope('profile');
          // provider.addScope('email');
          break;
        default:
          provider.addScope('email');
          provider.addScope('name');
      }
      console.log(provider);
      user.reauthenticateWithPopup(provider).then(
        function (result) {
          const user = result.user;

          const credential = result.credential;
          if (credential) {
            user.delete().then(
              () => {
                dispatch({ type: DELETE_USER_DONE });
              },
              error => {
                dispatch(
                  addSnackbar({
                    text: `${error.message}`,
                    variant: 'error',
                    closeButton: true,
                  }),
                );
                dispatch({ type: DELETE_USER_ERROR, error });
              },
            );
          }
        },
        function (error) {
          dispatch(
            addSnackbar({
              text: `${error.message}`,
              variant: 'error',
              closeButton: true,
            }),
          );
          dispatch({ type: DELETE_USER_ERROR, error });
        },
      );
    }
  };

// export const checkNewVersions = () => async (
//   dispatch,
//   getState,
//   { firebase }
// ) => {
//   const { auth } = getState();
//   const userId = auth && auth.user && auth.user.uid;
//   try {
//     if (firebase.auth().currentUser) {
//       const webVersions = await db.collection("version").get();
//       const userResponse = await db
//         .collection("users")
//         .doc(userId)
//         .get();

//       const data = userResponse.data();

//       const allVersionsFeatures = webVersions.docs.map((doc) => doc.data());
//       const allVersionsName = webVersions.docs.map((doc) => doc.id);
//       const latestVersion = allVersionsName.pop();
//       const webAppVersion =
//         data && data.webAppVersion ? data.webAppVersion : latestVersion;
//       const latestVersionFeatures = allVersionsFeatures.pop();

//       if (data && !data.webAppVersion) {
//         await db
//           .collection("users")
//           .doc(userId)
//           .update({ webAppVersion: latestVersion });
//       }

//       if (
//         Number(latestVersion.replace(/\./g, "")) >
//         Number(webAppVersion.replace(/\./g, ""))
//       ) {
//         dispatch(
//           addSnackbar({
//             title: `v${latestVersion}`,
//             text: latestVersionFeatures.features,
//             variant: "info",
//             closeButton: true,
//             horizontal: "center",
//             vertical: "top",
//           })
//         );
//         await db
//           .collection("users")
//           .doc(userId)
//           .update({ webAppVersion: latestVersion });

//         data.webAppVersion = latestVersion;

//         dispatch({ type: GET_USER_DONE, user: data });
//       }
//     }
//   } catch (error) {
//     console.log(error);
//   }
// };

export const updateUserTimeZone =
  data =>
  async (dispatch, getState, { firebase }) => {
    try {
      const { timeZone, userId } = data;
      const db = firebase.firestore();
      if (firebase.auth().currentUser)
        await db.collection('users').doc(userId).update({ timeZone });
    } catch (error) {
      console.log(error);
    }
  };
