import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import React from 'react';
import { useDispatch } from 'react-redux';
import { closeModal } from 'reducers/modals';
import Icon from 'shared/Icons/Icons';
import Typography from 'shared/Typography/Typography';

import useStyles from './ModalContent.css.jsx';

export default function CalendarInfo(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  function onClose() {
    dispatch(closeModal());
  }

  return (
    <>
      <div className={classes.header}>
        <Typography className={classes.headerTitle} variant="h6">
          {props.title}
        </Typography>
        <div className={classes.headerClose}>
          <IconButton onClick={onClose}>
            <Icon icon="close" />
          </IconButton>
        </div>
      </div>
      {props.title2 && (
        <div className={classes.textContent}>
          <Typography variant="subtitle2">{props.title2}</Typography>
        </div>
      )}
      {props.subtitle && (
        <div className={classes.textContent}>
          <Typography variant="body1" dangerouslySetInnerHTML={{ __html: props.subtitle }} />
        </div>
      )}
      {props.subtitle2 && (
        <div className={classes.textContent}>
          <Typography variant="body1">{props.subtitle2}</Typography>
        </div>
      )}
      {props.icon && <div className={classes.iconContainer}>{props.icon}</div>}
      {props.children && <div className={classes.content}>{props.children}</div>}
      <div className={classes.actionsContainer}>
        <div className={classes.loader}>{props.loading && <LinearProgress />}</div>
        {props.actions && <div className={classes.actions}>{props.actions}</div>}
      </div>
    </>
  );
}
