import classnames from 'classnames';
import { WORDPRESS_SITE } from 'constants.js';
import React from 'react';
import { useTranslation } from 'react-i18next';

import useStyle from './Footer.css.jsx';

function Footer(props) {
  const classes = useStyle();
  const position = props.position;
  const theme = props.theme;
  const { t } = useTranslation();

  return (
    <footer
      className={classnames({
        [classes.footer]: true,
        [classes.footerBottom]: position === 'bottom',
      })}
    >
      <ul className={classes.list}>
        <li className={classes.listItem}>
          <a
            className={classnames({
              [classes.link]: true,
              [classes.linkDarkTheme]: theme === 'dark',
            })}
            href={`${WORDPRESS_SITE}/about-us/`}
          >
            {t('footerLinkAboutUs')}
          </a>
        </li>
        <li className={classes.listItem}>
          <a
            className={classnames({
              [classes.link]: true,
              [classes.linkDarkTheme]: theme === 'dark',
            })}
            href={`${WORDPRESS_SITE}/terms-condition/`}
          >
            {t('footerLinkTermsConditions')}
          </a>
        </li>
        <li className={classes.listItem}>
          <a
            className={classnames({
              [classes.link]: true,
              [classes.linkDarkTheme]: theme === 'dark',
            })}
            href={`${WORDPRESS_SITE}/privacy-policy/`}
          >
            {t('footerLinkPrivacyPolicy')}
          </a>
        </li>
      </ul>
      <div className={classes.spacer}></div>
      <div
        className={classnames({
          [classes.copyRight]: true,
          [classes.copyRightDarkTheme]: theme === 'dark',
        })}
      >
        {t('footerCompanyAddress', { year: 1900 + new Date().getYear() })}
        <br />
        {t('appEmail')}
      </div>
    </footer>
  );
}

export default Footer;
