import { amber, red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    borderRadius: theme.spacing(0.5),
    overflow: 'hidden',
    color: '#fff',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
  },
  danger: {
    backgroundColor: red[700],
  },
  warning: {
    backgroundColor: amber[700],
    color: theme.custom.color.color1,
  },
  info: {
    backgroundColor: theme.custom.color.color1,
    color: '#fff',
  },
}));
