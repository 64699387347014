import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
} from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { disableOct2022Notification } from 'reducers/calendar';
import { sharedDisableOct2022Notification } from 'reducers/sharedCalendars';

import { closeModal } from '../../reducers/modals';

const MajorUpdateWarning = () => {
  const [disableNotification, setDisableNotification] = useState(false);
  const { pathname } = useLocation();
  const authUser = useSelector(state => state.auth.user);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleCheck = useCallback(() => setDisableNotification(prevState => !prevState), []);

  const handleClose = useCallback(() => {
    if (disableNotification) {
      if (pathname.includes('calendars')) {
        dispatch(disableOct2022Notification());
      } else {
        dispatch(sharedDisableOct2022Notification());
      }
    }

    dispatch(closeModal());
  }, [disableNotification]);

  return (
    <Box p={5}>
      <Stack spacing={3} alignItems="center">
        <Typography variant="h5" textAlign="center">
          {t('modalMajorUpdateTitle', { displayName: authUser.displayName })}
        </Typography>
        <Typography>{t('modalMajorUpdateText')}</Typography>
        <Typography textAlign={'start'} width={'100%'}>
          {t('modalMajorUpdateTextParagraph')}
        </Typography>
        <FormGroup sx={{ alignSelf: 'flex-start' }}>
          <FormControlLabel
            control={<Checkbox checked={disableNotification} onChange={handleCheck} />}
            label={t('actionDontShowAgain')}
          />
        </FormGroup>

        <Box width={50}>
          <Button onClick={handleClose} color="primary">
            {t('actionClose')}
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};

export default MajorUpdateWarning;
