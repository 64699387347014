import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from 'reducers/modals';
import Button from 'shared/Button/Button';
import firebase from 'utils/firebase';

import ModalContent from '../ModalContent/ModalContent';

export default function CreateCalendar() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentName = useSelector(state => state.editBirthday.name);
  const birthdayDate = useSelector(state => state.editBirthday.date);
  const currentDate = useSelector(state => state.calendar.currentDate);

  useEffect(() => {
    firebase.analytics().logEvent('modal_show_edit_birthday');
  }, []);

  function onSaveBirthday() {
    firebase.analytics().logEvent('modal_action_edit_birthday_save');
    dispatch(closeModal());
  }

  function years() {
    const diffYears = Math.ceil(currentDate.diff(birthdayDate, 'years', true));
    return diffYears;
  }

  return (
    <ModalContent
      title={t('modalTitleEditBirthday', { name: currentName, years: years() })}
      subtitle={t('modalSubTitleEditBirthday')}
      actions={
        <Button onClick={onSaveBirthday} color="primary">
          {t('actionOk')}
        </Button>
      }
    />
  );
}
