import { MODAL_DOWNLOAD_CALENDAR } from 'constants.js';
import moment from 'moment';
import { closeModal, openModal } from 'reducers/modals';
import { addSnackbar } from 'reducers/snackbar';
import * as calendarUtil from 'utils/calendar';

import { getUser } from './user';

export const FETCH_CALENDARS_START = 'shared/FETCH_CALENDARS_START';
export const FETCH_CALENDARS_DONE = 'shared/FETCH_CALENDARS_DONE';
export const FETCH_CALENDARS_ERROR = 'shared/FETCH_CALENDARS_ERROR';
export const GET_CALENDAR_DATES_START = 'shared/GET_CALENDAR_DATES_START';
export const GET_CALENDAR_DATES_DONE = 'shared/GET_CALENDAR_DATES_DONE';
export const GET_CALENDAR_DATES_ERROR = 'shared/GET_CALENDAR_DATES_ERROR';
export const SET_SCHOOL_YEAR = 'shared/SET_SCHOOL_YEAR';
export const SET_CURRENT_CALENDAR_ID = 'shared/SET_CURRENT_CALENDAR_ID';
export const SET_CURRENT_DATE = 'shared/SET_CURRENT_DATE';
export const RESET_CALENDAR_STATE = 'shared/RESET_CALENDAR';
export const UPDATE_USER_DATA = 'shared/UPDATE_USER_DATA';
export const START_CREATE_PDF = 'shared/START_CREATE_PDF';
export const END_CREATE_PDF = 'shared/END_CREATE_PDF';
export const ERROR_CREATE_PDF = 'shared/ERROR_CREATE_PDF';
export const GET_ALL_CALENDAR_DATES_START = 'shared/GET_ALL_CALENDAR_DATES_START';
export const GET_ALL_CALENDAR_DATES_DONE = 'shared/GET_ALL_CALENDAR_DATES_DONE';
export const GET_ALL_CALENDAR_DATES_ERROR = 'shared/GET_ALL_CALENDAR_DATES_ERROR';
export const UPDATE_SLEEP_COUNTER_DATE_STATE = 'shared/UPDATE_SLEEP_COUNTER_DATE_STATE';
export const SAVING_INCOMPLETE_CALENDAR_START = 'shared/SAVING_INCOMPLETE_CALENDAR_START';
export const SAVING_INCOMPLETE_CALENDAR_END = 'shared/SAVING_INCOMPLETE_CALENDAR_END';
export const SAVING_INCOMPLETE_CALENDAR_ERROR = 'shared/SAVING_INCOMPLETE_CALENDAR_ERROR';
export const SHARE_CALENDAR_START = 'shared/SHARE_CALENDAR_START';
export const SHARE_CALENDAR_END = 'shared/SHARE_CALENDAR_END';
export const SHARE_CALENDAR_ERROR = 'shared/SHARE_CALENDAR_ERROR';
export const EDIT_CALENDAR_PARENT_NAME_START = 'shared/EDIT_CALENDAR_PARENT_NAME_START';
export const EDIT_CALENDAR_PARENT_NAME_END = 'shared/EDIT_CALENDAR_PARENT_NAME_END';
export const EDIT_CALENDAR_PARENT_NAME_ERROR = 'shared/EDIT_CALENDAR_PARENT_NAME_ERROR';
export const CLEAR_STATE = 'shared/CLEAR_STATE';
export const DISABLE_OCTOBER_UPDATE_NOTIFICATION = 'shared/SET_DISABLE_OCTOBER_UPDATE_NOTIFICATION';
export const UPDATE_SHARED_CALENDARS_QTT = 'shared/UPDATE_SHARED_CALENDARS_QTT';
export const GET_SHARED_CALENDARS_QTT_ERR = 'shared/GET_SHARED_CALENDARS_QTT_ERR';

let userCollectionSubscription = undefined;

const initialState = {
  schoolYear: null,
  sleepCounterDateValue: [null, null],
  currentCalendarId: null,
  currentDate: null,
  userData: null,
  loading: false,
  pdfLink: null,
  shareLoading: false,
  editParentNameLoading: false,
  error: null,
  sharedCalendarsQtt: 0,
  list: {
    loading: false,
    error: null,
    calendars: {},
  },
  create: {
    loading: false,
    error: null,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_USER_DATA: {
      return {
        ...state,
        userData: action.userData,
      };
    }
    case FETCH_CALENDARS_START: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case FETCH_CALENDARS_DONE: {
      return {
        ...state,
        userData: action.userData,
        loading: false,
        error: null,
        list: {
          ...state.list,
          calendars: action.calendars,
        },
      };
    }
    case FETCH_CALENDARS_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case GET_CALENDAR_DATES_START: {
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          error: null,
        },
      };
    }
    case GET_CALENDAR_DATES_DONE: {
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: null,
          calendars: {
            ...state.list.calendars,
            [action.calendarId]: {
              ...state.list.calendars[action.calendarId],
              dates: action.dates,
            },
          },
        },
      };
    }
    case GET_CALENDAR_DATES_ERROR: {
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: action.error,
        },
      };
    }
    case GET_ALL_CALENDAR_DATES_START: {
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          error: null,
        },
      };
    }
    case GET_ALL_CALENDAR_DATES_DONE: {
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: null,
          calendars: {
            ...state.list.calendars,
            [action.calendarId]: {
              ...state.list.calendars[action.calendarId],
              allDates: action.allDates,
              datesIds: action.datesIds,
            },
          },
        },
      };
    }
    case GET_ALL_CALENDAR_DATES_ERROR: {
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: action.error,
        },
      };
    }
    case SET_CURRENT_CALENDAR_ID: {
      return {
        ...state,
        currentCalendarId: action.calendarId,
      };
    }
    case SET_SCHOOL_YEAR: {
      return {
        ...state,
        schoolYear: action.schoolYear,
      };
    }
    case SET_CURRENT_DATE: {
      return {
        ...state,
        currentDate: action.currentDate,
      };
    }
    case RESET_CALENDAR_STATE: {
      return {
        ...initialState,
        currentCalendarId: state.currentCalendarId,
      };
    }
    case UPDATE_SLEEP_COUNTER_DATE_STATE: {
      return {
        ...state,
        sleepCounterDateValue: action.dates,
      };
    }
    case UPDATE_SHARED_CALENDARS_QTT: {
      return {
        ...state,
        sharedCalendarsQtt: action.sharedCalendarsQtt,
      };
    }
    case START_CREATE_PDF: {
      return {
        ...state,
        loading: true,
      };
    }
    case END_CREATE_PDF: {
      return {
        ...state,
        pdfLink: action.pdfLink,
        loading: false,
      };
    }
    case ERROR_CREATE_PDF: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case SAVING_INCOMPLETE_CALENDAR_START: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case SAVING_INCOMPLETE_CALENDAR_END: {
      return {
        ...state,
        loading: false,
        error: null,
      };
    }
    case SAVING_INCOMPLETE_CALENDAR_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case SHARE_CALENDAR_START: {
      return {
        ...state,
        shareLoading: true,
        error: null,
      };
    }
    case SHARE_CALENDAR_END: {
      return {
        ...state,
        shareLoading: false,
        error: null,
      };
    }
    case SHARE_CALENDAR_ERROR: {
      return {
        ...state,
        shareLoading: false,
        error: action.error,
      };
    }
    case GET_SHARED_CALENDARS_QTT_ERR: {
      return {
        ...state,
        error: action.error,
      };
    }
    case EDIT_CALENDAR_PARENT_NAME_START: {
      return {
        ...state,
        editParentNameLoading: true,
        error: false,
      };
    }
    case EDIT_CALENDAR_PARENT_NAME_END: {
      return {
        ...state,
        editParentNameLoading: false,
        error: false,
      };
    }
    case EDIT_CALENDAR_PARENT_NAME_ERROR: {
      return {
        ...state,
        editParentNameLoading: false,
        error: action.error,
      };
    }
    case DISABLE_OCTOBER_UPDATE_NOTIFICATION: {
      const currentCalendar = state.list.calendars[state.currentCalendarId];

      currentCalendar.accessCard.disableOct2022Notification = true;

      return state;
    }
    case CLEAR_STATE: {
      return {
        ...initialState,
      };
    }

    default: {
      return { ...state };
    }
  }
};

export const getSharedCalendarsQtt =
  () =>
  async (dispatch, getState, { firebase }) => {
    try {
      const db = firebase.firestore();
      let { auth } = getState();
      const userId = auth.user.uid;

      const userResponse = await db.collection('users').doc(userId).get();

      const userData = userResponse.data();
      const { cals = [] } = userData;
      let calsQtt = 0;

      for (let index = 0; index < cals.length; index++) {
        const calendarId = cals[index];
        const calendarsResponse = await db.collection('calendars').doc(calendarId).get();

        const callendar = calendarsResponse.data();
        if (callendar.ownerUserId && callendar.ownerUserId !== userId) calsQtt++;
      }
      dispatch({ type: UPDATE_SHARED_CALENDARS_QTT, sharedCalendarsQtt: calsQtt });
    } catch (error) {
      dispatch({ type: GET_SHARED_CALENDARS_QTT_ERR });
    }
  };

export const subscribeUserCollection =
  userId =>
  async (dispatch, getState, { firebase }) => {
    if (userId && firebase.auth().currentUser) {
      try {
        const db = firebase.firestore();
        userCollectionSubscription = db
          .collection('users')
          .doc(userId)
          .onSnapshot(doc => {
            dispatch({ type: UPDATE_USER_DATA, userData: doc.data() });
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

export const unsubscribeUserCollection = () => {
  if (userCollectionSubscription) {
    userCollectionSubscription();
  }
};

export const getCalendars =
  ({ completeRefresh }) =>
  async (dispatch, getState, { firebase }) => {
    if (firebase.auth().currentUser) {
      dispatch({ type: FETCH_CALENDARS_START });
      const db = firebase.firestore();
      let { auth, sharedCalendars } = getState();
      let { currentCalendarId, currentDate } = sharedCalendars;
      const userId = auth.user.uid;
      const userResponse = await db.collection('users').doc(userId).get();

      if (!userResponse.exists) {
        // Could happen the first time a user logs in and the cloud function
        // `onCreateUser` has not been able to create the users collection.
        // Safe with early exit since we can assume the user has not created
        // any calendars yet.
        dispatch({ type: FETCH_CALENDARS_DONE, calendars: {}, userData: {} });
        return;
      }

      const userData = userResponse.data();
      const { cals = [] } = userData;
      let calendarsObject = {};
      for (let index = 0; index < cals.length; index++) {
        try {
          const calendarId = cals[index];
          const calendarsResponse = await db.collection('calendars').doc(calendarId).get();

          sharedCalendars = calendarsResponse.data();
          const firstPopulatedDate = moment(sharedCalendars.firstPopulatedDate);
          const schoolYear = calendarUtil.getCurrentSchoolYear(
            currentDate,
            firstPopulatedDate,
            false,
          );
          const accessCardsPromise = db
            .collection('calendars')
            .doc(calendarId)
            .collection('accessCards')
            .doc(userId)
            .get();

          const birthdaysPromise = db
            .collection('calendars')
            .doc(calendarId)
            .collection('birthdays')
            .doc('children')
            .get();

          const schoolBreaksPromise = db
            .collection('calendars')
            .doc(calendarId)
            .collection('schoolBreaks')
            .doc(schoolYear)
            .get();

          const summerPossessionsPromise = db
            .collection('calendars')
            .doc(calendarId)
            .collection('summerPossessions')
            .doc(schoolYear)
            .get();

          const allWeekendsPromise = db
            .collection('calendars')
            .doc(calendarId)
            .collection('allWeekends')
            .doc(schoolYear)
            .get();
          const datesPromise = await db
            .collection('calendars')
            .doc(calendarId)
            .collection('dates')
            .get();
          const allDates = datesPromise.docs.map(doc => doc.data());
          const datesIds = datesPromise.docs.map(doc => doc.id);

          const [
            accessCardsResponse,
            birthdaysResponse,
            schoolBreakResponse,
            summerPossessionsResponse,
            allWeekendsResponse,
          ] = await Promise.all([
            accessCardsPromise,
            birthdaysPromise,
            schoolBreaksPromise,
            summerPossessionsPromise,
            allWeekendsPromise,
          ]);

          const accessCard = accessCardsResponse.data();
          const birthdays = birthdaysResponse.data();
          const schoolBreaks = schoolBreakResponse.data();
          const allWeekends = allWeekendsResponse.data();
          const summerPossessions = summerPossessionsResponse.data();
          if (
            sharedCalendars.deletedDate === null &&
            sharedCalendars.ownerUserId &&
            sharedCalendars.ownerUserId !== userId
          ) {
            calendarsObject[calendarId] = {
              ...sharedCalendars,
              schoolYear,
              accessCard,
              schoolBreaks,
              allWeekends,
              summerPossessions,
              birthdays: birthdays['b-days'],
              calendarId,
              allDates,
              datesIds,
            };
          }
        } catch (error) {
          // Ignore error. This could happen if a user's cals property has a reference to a
          // calendar id that does no longer exist.
          console.log(error);
        }
      }

      if (currentCalendarId === null || completeRefresh === true) {
        currentCalendarId =
          Object.keys(calendarsObject).length < 1 ? null : Object.keys(calendarsObject)[0];
        dispatch(setCurrentCalendarId(currentCalendarId));
      }

      if (Object.keys(calendarsObject).length) {
        dispatch(getCalendarDates(currentCalendarId));
      }

      const calendars = calendarsObject || null;
      dispatch({ type: FETCH_CALENDARS_DONE, calendars, userData });
    }
  };

export const getCalendarDates =
  calendarId =>
  async (dispatch, getState, { firebase }) => {
    const { sharedCalendars } = getState();
    const currentDate = sharedCalendars.currentDate;
    const yearMonth = moment(currentDate).format('YYYY-MM');
    dispatch({ type: GET_CALENDAR_DATES_START });
    const db = firebase.firestore();
    try {
      if (firebase.auth().currentUser) {
        const datesPromise = db
          .collection('calendars')
          .doc(calendarId)
          .collection('dates')
          .doc(yearMonth)
          .get();

        const [dateResponse] = await Promise.all([datesPromise]);
        const dates = dateResponse.data();
        dispatch({ type: GET_CALENDAR_DATES_DONE, calendarId, dates });
      }
    } catch (error) {
      console.error(JSON.stringify(error));
      dispatch(
        addSnackbar({
          text: `${error.message}`,
          variant: 'error',
          closeButton: true,
          //retryButton: () => dispatch(getCalendarDates(calendarId))
        }),
      );
      dispatch({ type: GET_CALENDAR_DATES_ERROR });
    }
  };

// export const setSchoolYear = schoolYear => {
//   return {
//     type: SET_SCHOOL_YEAR,
//     schoolYear
//   };
// };

export const setCurrentCalendarId = calendarId => {
  return {
    type: SET_CURRENT_CALENDAR_ID,
    calendarId,
  };
};

export const setCurrentDate = currentDate => {
  return {
    type: SET_CURRENT_DATE,
    currentDate,
  };
};

export const updateSleepCounterState = dates => dispatch => {
  dispatch({ type: UPDATE_SLEEP_COUNTER_DATE_STATE, dates });
};

export const getPdfCalendar =
  (calendarId, schoolYear, startDate) =>
  async (dispatch, getState, { firebase }) => {
    try {
      const payload = { calendarId, schoolYear, startDate };
      dispatch({ type: START_CREATE_PDF });

      const pdf = firebase.functions().httpsCallable('createPdfV2');
      const { data } = await pdf(payload);

      dispatch({ type: END_CREATE_PDF, pdfLink: data });
      dispatch(openModal({ type: MODAL_DOWNLOAD_CALENDAR, disableClose: false }));
    } catch (error) {
      dispatch({ type: ERROR_CREATE_PDF, error });
      dispatch(
        addSnackbar({
          text: `${error.message}`,
          variant: 'error',
          closeButton: true,
          //retryButton: () => dispatch(getCalendarDates(calendarId))
        }),
      );
    }
  };

export const saveIncompleteCalendar =
  data =>
  async (dispatch, getState, { firebase }) => {
    try {
      if (data.schoolBreaks) {
        const formattedSchoolBreaks = {};
        for (let [key, values] of Object.entries(data.schoolBreaks)) {
          formattedSchoolBreaks[key] = {
            start: moment(values.start).format('YYYY-MM-DD'),
            end: moment(values.end).format('YYYY-MM-DD'),
          };
          data.schoolBreaks = formattedSchoolBreaks;
        }
      }
      dispatch({ type: SAVING_INCOMPLETE_CALENDAR_START });
      const save = firebase.functions().httpsCallable('saveIncompleteCalendarV2');
      await save(data);
      dispatch({ type: SAVING_INCOMPLETE_CALENDAR_END });
      dispatch(closeModal());
      dispatch(getUser());
    } catch (error) {
      dispatch({ type: SAVING_INCOMPLETE_CALENDAR_ERROR });
      dispatch(
        addSnackbar({
          text: `${error.message}`,
          variant: 'error',
          closeButton: true,
          //retryButton: () => dispatch(getCalendarDates(calendarId))
        }),
      );
    }
  };

export const shareCalendar =
  data =>
  async (dispatch, getState, { firebase }) => {
    try {
      dispatch({ type: SHARE_CALENDAR_START });
      const share = firebase.functions().httpsCallable('shareCalendarV2');
      await share(data);
      dispatch({ type: SHARE_CALENDAR_END });
      dispatch(closeModal());
      dispatch(
        addSnackbar({
          text: `Access allowed`,
          variant: 'success',
          closeButton: false,
          //retryButton: () => dispatch(saveCalendarInfo())
        }),
      );
      // dispatch(getCalendars());
    } catch (error) {
      dispatch({ type: SHARE_CALENDAR_ERROR });
      dispatch(
        addSnackbar({
          text: `${error.message}`,
          variant: 'error',
          closeButton: false,
          //retryButton: () => dispatch(getCalendarDates(calendarId))
        }),
      );
    }
  };

export const removeAccessToSharedCalendar =
  data =>
  async (dispatch, getState, { firebase }) => {
    try {
      dispatch({ type: SHARE_CALENDAR_START });
      const removeAccess = firebase.functions().httpsCallable('removeAccessToSharedCalendarV2');
      await removeAccess(data);
      dispatch({ type: SHARE_CALENDAR_END });
      dispatch(closeModal());
      // dispatch(getCalendars());
      dispatch(
        addSnackbar({
          text: `Removed`,
          variant: 'success',
          closeButton: false,
          //retryButton: () => dispatch(saveCalendarInfo())
        }),
      );
    } catch (error) {
      dispatch({ type: SHARE_CALENDAR_ERROR });
      dispatch(
        addSnackbar({
          text: `${error.message}`,
          variant: 'error',
          closeButton: false,
          //retryButton: () => dispatch(getCalendarDates(calendarId))
        }),
      );
    }
  };

export const editCalendarParentName =
  data =>
  async (dispatch, getState, { firebase }) => {
    dispatch({ type: EDIT_CALENDAR_PARENT_NAME_START });
    try {
      const removeAccess = firebase.functions().httpsCallable('updateParentName_AccessCardV2');
      await removeAccess(data);

      dispatch({ type: EDIT_CALENDAR_PARENT_NAME_END });
      dispatch(getCalendars({}));
      dispatch(closeModal());
    } catch (error) {
      dispatch({ type: EDIT_CALENDAR_PARENT_NAME_ERROR });
      dispatch(
        addSnackbar({
          text: `${error.message}`,
          variant: 'error',
          closeButton: false,
          //retryButton: () => dispatch(getCalendarDates(calendarId))
        }),
      );
    }
  };
export const clearCalendarState = () => async dispatch => {
  dispatch({ type: CLEAR_STATE });
};

export const sharedDisableOct2022Notification =
  () =>
  async (dispatch, getState, { firebase }) => {
    try {
      const db = firebase.firestore();

      const { sharedCalendars, auth } = getState();
      const { currentCalendarId } = sharedCalendars;
      const userId = auth.user.uid;

      const snapshot = await db
        .collection('calendars')
        .doc(currentCalendarId)
        .collection('accessCards')
        .where('userId', '==', userId)
        .get();

      const [doc] = snapshot.docs;

      await doc.ref.update({ disableOct2022Notification: true });
      dispatch({ type: DISABLE_OCTOBER_UPDATE_NOTIFICATION });
    } catch (e) {
      console.error(e);
      dispatch(
        addSnackbar({
          text: `${e.message}`,
          variant: 'error',
          closeButton: true,
        }),
      );
    }
  };
