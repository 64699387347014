import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    position: 'sticky',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  content: {
    padding: theme.spacing(2),
    height: '40vh',
  },
  img: {
    overflow: 'hidden',
    width: '100%',
    height: '280px',
    objectFit: 'contain',
    WebkitBoxPack: 'center',
    MsFlexPack: 'center',
    justifyContent: 'center',
    MsFlexLinePack: 'center',
    alignContent: 'center',
    display: 'flex',
  },
  positionBottom: {
    position: 'sticky',
  },
}));
