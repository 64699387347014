import { Box } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { DatePicker } from '@material-ui/pickers';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getAdminSettings, updateAdminSettings } from 'reducers/admin.js';
import { addSnackbar } from 'reducers/snackbar';
import Button from 'shared/Button/Button';
import Loader from 'shared/Loader/Loader';
import Typography from 'shared/Typography/Typography.jsx';
import firebase from 'utils/firebase';

import api from '../../utils/api';
import useStyles from './Admin.css.jsx';

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&:before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    '&:after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
    backgroundColor: '#3A506E',
  },
}));

const Admin = () => {
  const classes = useStyles();
  const CalReportRef = useRef(null);
  const PayReportRef = useRef(null);
  const UsersReportRef = useRef(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const admin = useSelector(state => state.admin);
  const user = useSelector(state => state.user.user);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(moment().subtract(2, 'years'));
  const [endDate, setEndDate] = useState(moment());
  const [calendarReportData, setCalendarReportDate] = useState({
    data: [],
    headers: [],
  });
  const [paymentReportData, setPaymentReportDate] = useState({
    data: [],
    headers: [],
  });
  const [usersReportData, setUsersReportDate] = useState({
    data: [],
    headers: [],
  });
  const [allowSendWelcomeEmail, setAllowSendWelcomeEmail] = useState(true);

  useEffect(() => {
    dispatch(getAdminSettings());
  }, [dispatch]);
  useEffect(() => {
    if (admin.settings) {
      setAllowSendWelcomeEmail(admin.settings.welcomeEmail);
    }
  }, [admin]);
  const onHandleStartPicker = date => {
    setStartDate(date);
  };

  const onHandleEndPicker = date => {
    setEndDate(date);
  };

  const getCalendarReportData = async () => {
    setLoading(true);
    firebase.analytics().logEvent('Get_data_for_calendars_report');
    api
      .calendarsReport({
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
      })
      .then(res => {
        setCalendarReportDate(res.data);
        setLoading(false);
        CalReportRef.current.link.click();
      })
      .catch(error => {
        setLoading(false);
        dispatch(
          addSnackbar({
            text: `${error.message}`,
            variant: 'error',
            closeButton: false,
          }),
        );
      });
  };
  const getPaymentReportData = async () => {
    setLoading(true);
    firebase.analytics().logEvent('Get_data_for_payments_report');
    api
      .paymentReport({
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
      })
      .then(res => {
        setPaymentReportDate(res.data);
        setLoading(false);
        PayReportRef.current.link.click();
      })
      .catch(error => {
        setLoading(false);
        dispatch(
          addSnackbar({
            text: `${error.message}`,
            variant: 'error',
            closeButton: false,
          }),
        );
      });
  };
  const getUserReportData = async () => {
    setLoading(true);
    firebase.analytics().logEvent('Get_data_for_users_report');
    api
      .usersReport({
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
      })
      .then(res => {
        setUsersReportDate(res.data);
        setLoading(false);
        UsersReportRef.current.link.click();
      })
      .catch(error => {
        setLoading(false);
        dispatch(
          addSnackbar({
            text: `${error.message}`,
            variant: 'error',
            closeButton: false,
          }),
        );
      });
  };

  const onChangeSendWelcomeEmail = () => {
    setAllowSendWelcomeEmail(!allowSendWelcomeEmail);
  };

  const onSave = () => {
    const data = {};
    data.welcomeEmail = allowSendWelcomeEmail;
    dispatch(updateAdminSettings(data));
  };

  return user && user.role === 'admin' && !admin.loading ? (
    <div className={classes.adminContainer}>
      <Typography></Typography>
      <div className={classes.pickers}>
        <div className={classes.startPicker}>
          <DatePicker
            format="MMM DD, YYYY"
            fullWidth
            placeholder={t('labelSchoolBreakStartDate')}
            autoOk
            inputVariant="outlined"
            label={t('labelSchoolBreakStartDate')}
            value={startDate}
            maxDate={endDate}
            onChange={onHandleStartPicker}
          />
        </div>
        <div className={classes.endPicker}>
          <DatePicker
            format="MMM DD, YYYY"
            fullWidth
            placeholder={t('labelSchoolBreakEndDate')}
            autoOk
            inputVariant="outlined"
            label={t('labelSchoolBreakEndDate')}
            value={endDate}
            maxDate={moment()}
            minDate={startDate}
            onChange={onHandleEndPicker}
          />
        </div>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <div className={classes.buttonBox}>
          <div>
            <Button onClick={getCalendarReportData}>{t('adminFormCalendarReport')}</Button>
            <CSVLink
              ref={CalReportRef}
              filename={`calendarReport-${moment(startDate).format('YYYY/MM/DD')}-${moment(
                endDate,
              ).format('YYYY/MM/DD')}.csv`}
              data={calendarReportData.data}
              headers={calendarReportData.headers}
              target="_blank"
            />
          </div>
          <div>
            <Button onClick={getPaymentReportData}>{t('adminFormPaymentReport')}</Button>
            <CSVLink
              ref={PayReportRef}
              filename={`paymentReport-${moment(startDate).format('YYYY/MM/DD')}-${moment(
                endDate,
              ).format('YYYY/MM/DD')}.csv`}
              data={paymentReportData.data}
              headers={paymentReportData.headers}
              target="_blank"
            />
          </div>
          <div>
            <Button onClick={getUserReportData}>{t('adminFormUserReport')}</Button>
            <CSVLink
              ref={UsersReportRef}
              filename={`usersReport-${moment(startDate).format('YYYY/MM/DD')}-${moment(
                endDate,
              ).format('YYYY/MM/DD')}.csv`}
              data={usersReportData.data}
              headers={usersReportData.headers}
              target="_blank"
            />
          </div>
        </div>
      )}
      <Box display={'flex'} flexDirection={'column'}>
        <FormControl fullWidth variant="outlined" display={'flex'}>
          <Typography align="left" variant="subtitle2">
            {t('adminSendingWelcomeEmail')}
          </Typography>

          <FormControlLabel
            control={
              <MaterialUISwitch
                sx={{ m: 1 }}
                checked={allowSendWelcomeEmail}
                onChange={onChangeSendWelcomeEmail}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label={
              allowSendWelcomeEmail ? t('adminAllowWelcomeEmail') : t('adminStoppedWelcomeEmail')
            }
          />
        </FormControl>
        <Box>
          <Button
            variant="contained"
            color="primary"
            onClick={onSave}
            // disabled={saving}
          >
            {t('actionSave')}
          </Button>
        </Box>
      </Box>
    </div>
  ) : (
    <Loader />
  );
};
export default Admin;
