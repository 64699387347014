import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  stepperRoot: {
    padding: 0,
  },
  alternateWeekendsPossessionWrapper: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '.8rem',
    // flexGrow: "0.5",
    // flexShrink: "1",
    // flexBasis: "content"
  },
  alternateWeekendsPossessionMonth1: {
    marginRight: '8rem',
    marginLeft: '8rem',
    // flexGrow: ".5",
    // flexShrink: "1",
    // justifyContent: "left",
    // flexBasis: "content"
    // flex: 0
  },
  alternateWeekendsPossessionMonth2: {
    marginRight: '8rem',
    marginLeft: '8rem',
    // flexGrow: ".5",
    // flexShrink: "1",
    // justifyContent: "right",
    // flexBasis: "content"
  },
}));
