import { FormControl } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { shareCalendar } from 'reducers/calendar';
import { removeAccessToSharedCalendar } from 'reducers/calendar';
import Button from 'shared/Button/Button';
import Typography from 'shared/Typography/Typography';
import * as calendarUtil from 'utils/calendar';
import firebase from 'utils/firebase';

import ModalContent from '../ModalContent/ModalContent';
import styles from './ModalShare.module.css';

const ModalShare = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentCalendarId = useSelector(state => state.calendar.currentCalendarId);
  const calendars = useSelector(state => state.calendar.list.calendars);
  const loading = useSelector(state => state.calendar.shareLoading);
  const currentCalendar = calendarUtil.getCurrentCalendar(calendars, currentCalendarId);
  const userData = useSelector(state => state.calendar.userData);
  const [email, setEmail] = useState('');
  const [sharedData, setSharedDate] = useState([]);
  // const CustomizedFormControl = styled(FormControl)`
  // & .MuiFormControl-marginNormal {
  //   margin: 0;
  // }
  // `;
  // const formStyle = {
  //   root: {
  //     color:'red',
  //     "& .MuiFormControl-marginNormal ": {
  //       margin: 0,
  //     },
  //   },
  // };
  useEffect(() => {
    if (userData && userData.shared) {
      const filteredSharedData = userData.shared.filter(
        data => data.calendarId === currentCalendarId,
      );
      setSharedDate(filteredSharedData);
    }
  }, [userData, currentCalendarId]);

  const handleInput = event => {
    setEmail(event.target.value);
  };

  const onHandleShareEmail = () => {
    firebase.analytics().logEvent('modal_action_share_calendar', {
      email,
    });
    dispatch(
      shareCalendar({
        email,
        calendarId: currentCalendarId,
        calendarName: currentCalendar.accessCard.calendarName,
      }),
    );
  };

  const onHandleRemoveButton = (userId, calendarId, email) => {
    firebase.analytics().logEvent('modal_action_delete_access_to_shared_calendar', {
      userId,
      calendarId,
      email,
    });
    dispatch(
      removeAccessToSharedCalendar({
        calendarId,
        signedUserId: userId,
        signedUserEmail: email,
      }),
    );
  };

  return (
    <ModalContent
      title={t('modalShareTitle')}
      subtitle2={t('modalShareSubtitle')}
      loading={loading}
    >
      <div className={styles.modalShareContent}>
        <div className={styles.shareBox}>
          <Typography className={styles.shareBoxTitle}>{t('modalShareInputTitle')}</Typography>
          <FormControl>
            <TextField
              margin="normal"
              id={`Share`}
              label={t('infoPageEmail')}
              placeholder={t('infoPageEmail')}
              variant="outlined"
              value={email}
              onChange={handleInput}
              inputProps={{}}
            />
          </FormControl>
          <Button
            style={{ marginLeft: 'auto' }}
            onClick={onHandleShareEmail}
            disabled={!email || loading}
          >
            {t('share')}
          </Button>
        </div>
        <div className={styles.sharedBox} style={{ marginTop: '20px', flexBasis: '25%' }}>
          <Typography className={styles.shareBoxTitle}>{t('modalCurrentlyShared')}</Typography>

          <div className={styles.sharedList}>
            {sharedData.length > 0 ? (
              sharedData.map(e => (
                <div key={e.signedUserEmail} className={styles.listItem}>
                  <Typography>{e.signedUserEmail}</Typography>
                  <Button
                    style={{ color: 'red', marginLeft: 'auto' }}
                    onClick={() =>
                      onHandleRemoveButton(e.signedUserId, e.calendarId, e.signedUserEmail)
                    }
                  >
                    {t('actionRemove')}
                  </Button>
                </div>
              ))
            ) : (
              <span>{t('none')}</span>
            )}
          </div>
        </div>
      </div>
    </ModalContent>
  );
};
export default ModalShare;
