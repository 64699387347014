import { IconButton } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import Icon from 'shared/Icons/Icons';
import Typography from 'shared/Typography/Typography';

import style from './WarningBox.module.css';

function WarningBox(props) {
  const { openWarnings, setOpenWarnings, showClose = false } = props;
  return (
    <div className={style.container}>
      <div className={style.warningIcon}>
        <Icon icon="warning" />
      </div>
      <div className={style.textContainer}>
        <Typography variant="body1" gutterBottom>
          {props.text}
        </Typography>
        {props.linkText && props.linkTo && (
          <Typography
            className={style.link}
            variant="body1"
            display="block"
            to={props.linkTo}
            component={Link}
            gutterBottom
          >
            {props.linkText}
          </Typography>
        )}
      </div>
      {showClose && (
        <div className={style.close_box} onClick={() => setOpenWarnings(!openWarnings)}>
          <IconButton size="small">
            <Icon icon={'close'} />
          </IconButton>
        </div>
      )}
    </div>
  );
}

export default WarningBox;
