export const OPEN_MODAL = 'modals/OPEN_MODAL';
export const CLOSE_MODAL = 'modals/CLOSE_MODAL';

const initialState = {
  open: false,
  type: '',
  disableClose: false,
  customData: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case OPEN_MODAL: {
      return {
        ...state,
        open: true,
        type: action.data.type,
        disableClose: action.data.disableClose,
        customData: action.data.customData,
      };
    }
    case CLOSE_MODAL: {
      return { ...state, open: false, type: '' };
    }

    default: {
      return { ...state };
    }
  }
};

export const openModal = data => ({
  type: OPEN_MODAL,
  data: data,
});

export const closeModal = () => ({
  type: CLOSE_MODAL,
});
