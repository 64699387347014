export const ADD_SNACKBAR = 'snackbar/USER';

const initialState = {
  data: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_SNACKBAR: {
      return {
        ...state,
        data: {
          text: action.data.text,
          variant: action.data.variant,
          closeButton: action.data.closeButton,
          retryButton: action.data.retryButton,
          horizontal: action.data.horizontal,
          vertical: action.data.vertical,
          title: action.data.title,
          duration: action.data.duration,
        },
      };
    }
    default: {
      return { ...state };
    }
  }
};

export const addSnackbar = data => ({
  type: ADD_SNACKBAR,
  data,
});
