import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  footer: {
    margin: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  footerBottom: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 10,
  },
  list: {
    margin: 0,
    padding: 0,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    listStyle: 'none',
  },
  listItem: {
    padding: theme.spacing(1),
  },
  link: {
    fontWeight: '700',
    fontSize: '1.0em',
    color: theme.custom.color.link,
    '&:hover': {
      color: theme.custom.color.link,
      opacity: '0.5',
    },
  },
  linkDarkTheme: {
    color: 'white',
    '&:hover': {
      color: 'white',
      opacity: '0.5',
    },
  },
  spacer: {
    flexGrow: 1,
  },
  copyRight: {
    fontWeight: '400',
    fontSize: '1.2em',
    color: theme.custom.color.link,
    textAlign: 'center',
    flexGrow: 1,
    flexBasis: '100%',
    [theme.breakpoints.up('md')]: {
      flexBasis: 'auto',
      textAlign: 'right',
    },
  },
  copyRightDarkTheme: {
    color: 'white',
  },
}));
