const environment = {
  staging: {
    analyticsID: 'dummy',
    firebase: {
      apiKey: 'AIzaSyAJJKqDJ2GGtLiufyMcpKSbE55eO5k5k8M',
      authDomain: 'staging.ourdayscalendar.com',
      databaseURL: 'https://ourdays-calendar-staging.firebaseio.com',
      projectId: 'ourdays-calendar-staging',
      storageBucket: 'ourdays-calendar-staging.appspot.com',
      messagingSenderId: '174599687341',
      appId: '1:174599687341:web:d029cfba78092c618aca24',
      measurementId: 'G-T1CMZ0ZNLY',
    },
    stripe: {
      publishableKey: 'pk_test_uONaIXJzviXWgvvJUkLju6dy000R5kqxtC',
      products: [
        {
          id: 'prod_GlA1B52wVSmp8Y',
          name: '6 months • $3.99/mo',
          months: '6',
          description: 'test',
          sku: 'sku_GlA13Do06xk1g5',
          price: '23.99',
          currency: '$',
          image: '/product-6.jpg',
          promoText: '',
        },
        {
          id: 'prod_GlA2bLxe3OMFTv',
          name: '12 months • $2.99/mo',
          months: '12',
          description: 'test',
          sku: 'sku_GlA2DSJVysJRoN',
          price: '35.99',
          currency: '$',
          image: '/product-12.jpg',
          promoText: 'MOST POPULAR',
        },
        {
          id: 'prod_HnrvhC0V9JQY8S',
          name: '24 months • $1.99/mo',
          months: '24',
          description: 'test',
          sku: 'sku_HnrvSHj1aSmkZA',
          price: '47.99',
          currency: '$',
          image: '/product-24.jpg',
          promoText: '',
        },
      ],
    },
  },
  production: {
    analyticsID: 'UA-70945003-2',
    firebase: {
      apiKey: 'AIzaSyAgcmKU661FMMIBHhXCt2YmuIWl4XvkC3k',
      authDomain: 'my.ourdayscalendar.com',
      databaseURL: 'https://splittime.firebaseio.com',
      projectId: 'splittime',
      storageBucket: 'splittime.appspot.com',
      messagingSenderId: '305989507183',
      appId: '1:305989507183:web:6c0b08f83ffb3cc3',
      measurementId: 'G-N8TQE798S2',
    },
    stripe: {
      publishableKey: 'pk_live_ZWjc5HWCbkEDYHCQh6fyNtGH00RqPq8h2r',
      products: [
        {
          id: 'prod_GyXt3EJBdyacha',
          name: '6 months • $3.99/mo',
          months: '6',
          description: '',
          sku: 'sku_GyXtopKjcNzGHF',
          price: '23.99',
          currency: '$',
          image: '/product-6.jpg',
          promoText: '',
        },
        {
          id: 'prod_GyXqxBFXm7h2Cx',
          name: '12 months • $2.99/mo',
          months: '12',
          description: '',
          sku: 'sku_GyXq0DkiQr0JpQ',
          price: '35.99',
          currency: '$',
          image: '/product-12.jpg',
          promoText: 'MOST Popular',
        },
        {
          id: 'prod_HnrzslXR71r4v5',
          name: '24 months • $1.99/mo',
          months: '24',
          description: '',
          sku: 'sku_HnrzfarJ4VweFI',
          price: '47.99',
          currency: '$',
          image: '/product-24.jpg',
          promoText: '',
        },
      ],
    },
  },
};
export default environment[process.env.REACT_APP_ENVIRONMENT];
